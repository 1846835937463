import { useNavigate, useParams } from "react-router-dom";
import PageNotFoundDashboard from "../InvestorDashboard/components/PageNotFoundDashboard";

import { useEffectOnce } from "../../helpers/hooks";
import { useState } from "react";

import { getPropertyPage } from "../../services/projectService";
import { PropertyPageModel } from "./interfaces/PropertyPageModel";



/* Sections */

import Benefits from "../NidoDeAgua/Benefits";

import { TopSectionPropertyPage, TimeLine, AboutInvestment, Location, AccommodationsProperty, CollaboratorsPropertyPage, InvestorProfilesPropertyPage, GoogleMapsReviews, InsightsPropertyPage } from "./sections";
import { Menu , SkeletonProperty} from "./components";
import {  VerticalSimulator } from "../../components/Simulator";
import useTitle, { useDescription, useKeywords } from "../../hooks/useSEO";


/* import { Menu } from "./components"; */

export const PropertyPage = () => {




  const [isLoading, setIsLoading] = useState(false);
  const [propertyPage, setPropertyPage] = useState<PropertyPageModel>();
  const { projectCode } = useParams();
  const navigate = useNavigate();
 
  useKeywords("proyecto sostenible, inversión inmobiliaria, diseño ecológico, proyectos verdes, inversión en bienes raíces, Lokl Life, propiedades ecológicas, desarrollo inmobiliario sostenible, proyectos con impacto ambiental, arquitectura verde, inversión en proyectos sostenibles");
  useDescription(propertyPage ? propertyPage.description : "Inversiones Inmobiliarias");
useTitle(propertyPage ? `${propertyPage.name} - Proyecto Inmobiliario Sostenible | Lokl Colombia` : "Inversiones Inmobiliarias");
  useEffectOnce(() => {
    if (!projectCode) return;

    const fetchProjectInfo = async () => {
      try {
        setIsLoading(true);
        const response = await getPropertyPage(projectCode);

        if (!response?.main?.success || !response?.secondary?.success) {
          navigate("/");
        } else {
          setPropertyPage({
            ...response.main.data,
            ...response.secondary.data,
          });
        }
      } catch (error) {
        console.error(`Error fetching property page of ${projectCode}`, error);
        navigate("/");
      } finally {
        setIsLoading(false);
      }
    };

    fetchProjectInfo();
  });

  if (!projectCode) return <PageNotFoundDashboard />;

  const totalRoomCount = propertyPage?.accommodation?.reduce((total, accommodation) => total + (accommodation.roomCount || 0), 0) ?? 0;
  const totalValue = propertyPage?.accommodation?.reduce((total, accommodation) => total + (accommodation.value || 0), 0) ?? 0;
  const averageValue = propertyPage?.accommodation?.length ? totalValue / propertyPage.accommodation.length : 0;

  const featureFlags = (propertyPage?.features || []).reduce<Record<string, boolean>>((flags, feature) => {
    if (feature.enabled) {
      flags[feature.name] = true;
    }
    return flags;
  }, {});
  const secondaryMarketEnabled = !!featureFlags["SECONDARY_MARKET"];
  const progressTimeLineEnabled = !!featureFlags["PROGRESS_TIMELINE"];

  const itemsPage = [
    { name: "Acerca de la inversión", id: "aboutInvestment" },
    { name: "¿Donde está ubicado?", id: "location" },
    { name: "Líneas de negocio", id: "businessLines" },
    { name: "Equipo del proyecto", id: "team" },
    { name: "Beneficios", id: "benefits" },
    ...(progressTimeLineEnabled ? [{ name: "Cronograma", id: "timeline" }] : []),
  ];

  return (
    <>
      {
        isLoading ? <SkeletonProperty/> :
        
          <main className="flex flex-col items-start container mx-auto xl:max-w-7xl pt-52 pb-40 space-y-20 lg:space-y-16">
          
      
           {propertyPage && (
              <>
                <TopSectionPropertyPage
                  projectId={propertyPage.projectId}
                  minAmountInvestment={propertyPage.minAmountInvestment}
                  description={propertyPage.description}
                  phase={propertyPage.phase.name}
                  minRent={propertyPage.minRent}
                  investors={propertyPage.investors}
                  unitPrice={propertyPage.investmentDetails?.unitPrice || 0}
                  neighborhood={propertyPage.location?.neighborhood || ''}
                  municipality={propertyPage.location?.municipality || ''}
                  maxRent={propertyPage.maxRent}
                  name={propertyPage.name}
                  images={Array.isArray(propertyPage.mainGallery) ? propertyPage.mainGallery : []}
                  squareMeters={propertyPage.squareMeters}
                  roomCount={totalRoomCount}
                  value={averageValue}
                  secondaryMarketEnabled={secondaryMarketEnabled}
                  availableSpots={propertyPage.availableSpots}
                  totalSpots={propertyPage.totalSpots}
                  totalInvestment={propertyPage.totalInvestment}
                  unitValuePhases={Array.isArray(propertyPage.unitValuePhases) ? propertyPage.unitValuePhases.map(phase => ({ ...phase, endDate: phase.endDate })) : []}
                  endDate={propertyPage.investmentDetails.endDate}
                  maxInvestmentQuota={propertyPage.investmentDetails.maxInvestmentQuota}
                />

                <aside className="w-full">
                  <Menu menuItems={itemsPage} scroll={true} />
                </aside>

                <section className="grid grid-cols-12">
                  <section className="col-span-12 lg:col-span-8 lg:mr-8">
                    <article id="aboutInvestment" className="w-full mb-12">
                      <AboutInvestment icons={propertyPage.attributesAboutInvestment || []} />
                    </article>
                    <hr />
                    <article className="col-span-12 lg:col-span-4 block md:hidden mt-5" id="mobileVerticalSimulator">
                      <VerticalSimulator />

                    </article>
                    <article id="location" className="w-full my-12">
                      <Location
                        locationGallery={Array.isArray(propertyPage.locationGallery) ? propertyPage.locationGallery : []}
                        location={propertyPage.location || {}}
                        name={propertyPage.name}
                      />
                    </article>

                    <article id="businessLines" className="w-full my-12">
                      <AccommodationsProperty accommodation={Array.isArray(propertyPage.accommodation) ? propertyPage.accommodation : []}
                        amenities={Array.isArray(propertyPage.amenities) ? propertyPage.amenities : []} />
                    </article>
                    <hr />
                    <article id="team" className="w-full my-12">
                      <CollaboratorsPropertyPage collaborator={Array.isArray(propertyPage.collaborator) ? propertyPage.collaborator : []} />
                    </article>

                  </section>
                  <aside className="col-span-12 lg:col-span-4">

                    <article className="col-span-12 lg:col-span-4 hidden md:block" id="desktopVerticalSimulator">
                      <VerticalSimulator />

                    </article>

                    <article className="my-6 w-full" id="investor-profile">
                      {Array.isArray(propertyPage.benefits) && propertyPage.benefits.length > 0 && (
                        <InvestorProfilesPropertyPage unitPrice={propertyPage.investmentDetails?.unitPrice || 0} benefits={propertyPage.benefits} />
                      )}
                    </article>
                    
                    <article className="my-6 w-full" id='insights'>
                      <InsightsPropertyPage  name={propertyPage.name} projectId={propertyPage.projectId} attributesInsight={Array.isArray(propertyPage.attributesInsight) ? propertyPage.attributesInsight : []}/>
                    </article>
                                        
                  </aside>


                </section>

                <aside className="w-full space-y-20" id="timeline">
                  
                  {progressTimeLineEnabled && Array.isArray(propertyPage.phases) && (
                    <>
                    <hr />
                    <TimeLine phases={propertyPage.phases} />
                    </>
                    
                  )}
                </aside>

                <section id="google-maps-reviews" className="w-full">
                  <hr/>
                  {propertyPage.googleMaps?.reviews && <GoogleMapsReviews reviews={propertyPage.googleMaps?.reviews} />}
                </section>

                
                <section id="benefits" className="w-full my-12 space-y-20">
                  <hr />
                  <Benefits />
                </section>


              </>
            )}
           </main>
          
          
      }
    </>
  );
};
