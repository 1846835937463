import React from 'react'
import img1 from "../../assets/img/blog/card-1.png";
import img2 from "../../assets/img/blog/card-2.png";
import { motion } from "framer-motion";

import image from "../../assets/img/blog/manage-money.png";
import llaves from "../../assets/img/blog/llaves.png";
import mapa from "../../assets/img/blog/mapa.png";
import planta from "../../assets/img/blog/planta.png";
import useTitle, { useDescription, useKeywords } from '../../hooks/useSEO';
// import image2 from "../../assets/img/blog/image-2-article.png";
// import vecindario from "../../assets/img/blog/vecindario.svg";
// import lokl from "../../assets/img/blog/lokl-black.svg";
// import forbes from "../../assets/img/blog/forbes.svg";
// import republica from "../../assets/img/blog/republica.svg";
// import semana from "../../assets/img/blog/semana.svg";
// import { Tab, TabList } from 'react-tabs';
// import { scrollToSection } from '../../helpers/functions';

function ArticlePresupuesto() {
  useTitle('5 secretos para usar el dinero adecuadamente | LOKL');
  useDescription('Cómo planificar un presupuesto e invertir a largo plazo. El dinero es una herramienta poderosa que puede ayudarnos a lograr nuestros objetivos, incluyendo la inversión a largo plazo. Sin embargo, para invertir de manera efectiva, es importante planificar un presupuesto que nos permita ahorrar e invertir de manera constante.');
  useKeywords(' gestión del dinero, ahorro efectivo, cómo invertir inteligentemente, presupuesto personal, planificación financiera, control de gastos, objetivos financieros, educación financiera, estrategias de inversión, y finanzas personales Colombia. ');
  // const optionsList = [
  //   "Dejanos tus comentario",
  //   "Comentarios",
  // ];

  // const [tabOptions /*, setTabOptions*/] = useState(optionsList);
  // const [activeTabIndex /*, setActiveTabIndex */] = useState(0);

  return (
    <main id='article' className='pt-32 bg-[#F9F9F9]'>
      
      <header className="bg-note-img bg-cover bg-center h-[432px] w-full flex items-center justify-center">

        <div className="wrapper-lokl text-white ">

          <div className="w-[90%] md:w-1/2 ml-[5%] md:ml-0">

            <h1 className="font-lato font-extrabold text-[38px] mb-3">
              5 secretos para usar el dinero adecuadamente
            </h1>

            <p className="text-xl">
              Cómo planificar un presupuesto e invertir a largo plazo
            </p>
          
          </div>

        </div>

      </header>

      <article className="wrapper-lokl space-y-10 py-16">

        {/* <div className="w-full flex justify-end">

          <div className="flex space-x-2 mr-3">

            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>

          </div>

          <div className="underline cursor-pointer" onClick={() => scrollToSection('comments', 150)}>35 Comentarios</div>

        </div> */}

        <div className="w-full flex justify-center md:justify-between">

          <div className="w-[90%] md:w-[67%] mr-0 md:mr-10">

            <h2 className="blog-title-md mb-4">
              El dinero es una herramienta poderosa
            </h2>

            <p className="text-base font-roboto text-justify">
              Que puede ayudarnos a lograr nuestros objetivos, incluyendo la inversión a largo plazo. Sin embargo, para invertir de manera efectiva, es importante planificar un presupuesto que nos permita ahorrar e invertir de manera constante. En este post, hablaremos sobre cómo planificar un presupuesto para invertir a largo plazo, 5 secretos para usar de manera adecuada el dinero y cómo puede convertirse en nuestro mejor aliado.
            </p>

          </div>

          <div className="h-auto hidden md:flex justify-end items-start">

            <div className="flex space-x-2 mr-3">

              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>

            </div>

            <div className="underline cursor-pointer">5</div>

          </div>

        </div>

        <div className="w-[90%] md:w-full flex justify-center  md:justify-between items-start ml-[5%] md:ml-0">

          {/* <img className='' width={525} height={357} src={image} alt="casa-lokl" /> */}

          <div className="flex flex-col justify-center">

            <h2 className='blog-title-md'>Cómo planificar un presupuesto para invertir a largo plazo</h2>

            <div className="flex flex-col justify-between mt-2 mb-5">

              <p className="text-base font-roboto text-justify">

                Para planificar un presupuesto efectivo para invertir a largo plazo, es importante seguir los siguientes pasos:

              </p>

            </div>

            <div className="grid grid-rows-1 grid-cols-1 md:grid-cols-2 gap-4">
              
              <div className="flex flex-col justify-center">

                <h2 className='blog-title-sm mb-3'>1. Evalúa tus gastos</h2>

                <div className="flex flex-col justify-between h-28">

                  <p className="text-base font-roboto text-justify">
                    Revisa tus gastos y determina en qué áreas puedes reducir o eliminar gastos innecesarios. Asegúrate de que tus gastos esenciales, como la renta o hipoteca, los servicios públicos, la alimentación, estén incluidos en tu presupuesto.
                  </p>

                </div>

                <img height={260} src={image} alt="casa-lokl" />
              
              </div>
              
              <div className="flex flex-col justify-start ml-4">

                <h2 className='blog-title-sm mb-3'>2. Fija tus objetivos financieros</h2>

                <div className="flex flex-col justify-between h-28">

                  <p className="text-base font-roboto text-justify">
                    Establece objetivos financieros claros y específicos a largo plazo, como ahorrar para la jubilación, la educación de tus hijos o la compra de una propiedad.
                  </p>

                </div>

                <img height={260} src={llaves} alt="casa-lokl" />

              </div>

            </div>

            <div className="my-10">
            
              <div className="mb-8">
                Lee también: <a className='hover:text-[#17008A]' href="/blog/donde-invertir-colombia-2023"> <b> ¿Dónde invertir en Colombia en el 2023? </b> </a>
              </div>

              <hr className="border-t border-gray-400"/>
            
            </div>

            <div className="grid grid-rows-1 grid-cols-1 md:grid-cols-2 gap-4">

              <div className="flex flex-col justify-start">

                <h2 className='blog-title-sm mb-3'>3. Haz un seguimiento de tus gastos</h2>

                <div className="flex flex-col justify-between h-20">

                  <p className="text-base font-roboto text-justify">
                    Lleva un registro de tus gastos diarios y mensuales para ver en qué estás gastando tu dinero y dónde puedes reducir gastos.
                  </p>

                </div>

                <img height={260} src={mapa} alt="casa-lokl" />

              </div>

              <div className="flex flex-col justify-start ml-4">

                <h2 className='blog-title-sm mb-3'>4. Establece un presupuesto de ahorro e inversión</h2>

                <div className="flex flex-col justify-between h-20">

                  <p className="text-base font-roboto text-justify">
                    Determina cuánto dinero puedes ahorrar e invertir cada mes y establece un presupuesto de ahorro e inversión.
                  </p>

                </div>

                <img height={260} src={planta} alt="casa-lokl" />

              </div>

              <div className="flex flex-col justify-start">

                <h2 className='blog-title-sm mb-3'>5. Ajusta tu presupuesto según tus necesidades</h2>

                <div className="flex flex-col justify-between mb-4">

                  <p className="text-base font-roboto text-justify">
                    A medida que cambian tus objetivos y circunstancias financieras, ajusta tu presupuesto para reflejar estos cambios.
                  </p>

                </div>

                <div className="mb-4">

                  <hr className="border-t border-gray-400"/>
                
                </div>

                <div className="text-xl">
                  Recuerda <a className='hover:text-[#17008A]' href="/register"><b>registrarte</b></a> para recibir información de primera mano, sobre nuestros proyectos.
                </div>

              </div>

              <div className="flex flex-col justify-start space-y-2 ml-4">

                <div className="">
                  Empieza a invertir en el futuro que quieres vivir. <br /> <a className='hover:text-[#17008A]' href="/#projects"><b>Invierte con LOKL</b></a>.
                </div>

                <div className="">
                  Escrito por Veronica Porras.
                </div>

              </div>

            </div>

          </div>

        </div>

        {/* <div id='comments'>

          <TabList className="flex space-x-6 font-bold text-lg list-none mb-8">

            {
              tabOptions.map((tab, key) => 
                <Tab key={key} className={ `min-w-[100px] cursor-pointer outline-none text-center font-roboto ${ key === activeTabIndex ? ' text-black ' : 'text-gray-400' }` }>
                  {tab}
                  { key === activeTabIndex && <div className='w-full h-1 bg-black mt-2'></div> }
                </Tab>
              )
            }

          </TabList>

          <div className="bg-white w-2/3 p-12 flex flex-col items-center rounded-xl">

            <div className="flex flex-col w-full">

              <label htmlFor="commentInput">¿Tienes preguntas?</label>
              <textarea className='outline-none' name="" id="commentInput" cols={30} rows={10}></textarea>

            </div>

            <button className="btn-primary">
              Comentar
            </button>

          </div>

        </div> */}


        <div className="w-full inline-flex overflow-x-auto space-x-4 mb-5">

          <motion.div 
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
            className="flex items-center flex-shrink-0 justify-between w-[700px] p-12 text-white bg-[#0A180E] rounded-xl"
          >
            
            <div className="flex flex-col items-start space-y-7 mr-5">

              <h3 className="text-white/50">INVERSIÓN</h3>

              <h2 className='font-lato font-extrabold text-3xl'>¿Dónde invertir en <br /> Colombia en el 2023?</h2>

              <p className='text-sm w-[350px]'>
                Muchos inversionistas buscan oportunidades para hacer crecer su dinero. Con los mercados financieros en constante evolución, es esencial investigar y considerar cuidadosamente las opciones de inversión disponibles.
              </p>

              <div className="bg-[#7271FF] text-black hover:bg-[#7271FF]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/donde-invertir-colombia-2023"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img1} alt="banner-card-lokl" /> 

          </motion.div>

          <motion.div 
            className="flex items-center flex-shrink-0 justify-between p-12 text-black bg-[#CACAC8] rounded-xl"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
          >
            
            <div className="flex flex-col items-start space-y-7 mr-5">

              <h3 className="text-white/50">INVERSIÓN</h3>

              <h2 className='font-lato font-extrabold text-3xl'>
                Inversiones colaborativas en <br /> hospitality: una oportunidad <br /> para las nuevas generaciones
              </h2>

              <p className='text-sm w-[350px]'>
                Las nuevas generaciones tienen un enfoque diferente en cuanto a su relación con el dinero. Muchos jóvenes buscan opciones de inversión que les permitan obtener ganancias, pero también que tengan un impacto positivo en el mundo.
              </p>

              <div 
                className="bg-black text-white hover:bg-black/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/inversiones-colaborativas-hospitality"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img2} alt="banner-card2-lokl" /> 

          </motion.div>

        </div>

      </article>

    </main>
  )
}

export default ArticlePresupuesto;