import axios from "axios";
import { urls } from "../helpers/urls"; 

const getToken = () => localStorage.getItem("token") ?? "";

export const ModalShareCodeUnitService = async (
  name: string, 
  phone: string, 
  email: string
) => {
  try {
    const payload = { name, phone, email }; 
    const result = await axios.post(
      `${urls.NEW_API_URL}ambassador/saveReferedLead`, 
      payload,
      {
        headers: {
          Authorization: `Bearer ${getToken()}`, 
          "Content-Type": "application/json", 
        },
      }
    );
    return result.data; 
  } catch (error) {
    console.error("Error al enviar los datos al servicio de referidos:", error);
    throw error; 
  }
};
