import React, { useEffect, useState } from 'react';
import './spinner.scss'

interface SimulatorLoadingProps {
    setSimulatorLoading: React.Dispatch<React.SetStateAction<boolean>>
}

export const SimulatorLoading: React.FC<SimulatorLoadingProps> = ({ setSimulatorLoading }) => {
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) {
                    clearInterval(interval);
                    setSimulatorLoading(false)
                    return 100;
                }
                return prevProgress + 1;
            });
        }, 50);

        return () => clearInterval(interval);
    }, [setSimulatorLoading]);


    return (
        <div className="mt-10 lg:mt-0 flex flex-col items-center justify-end w-full h-[483px] bg-[#DFDFFF] rounded-3xl">

            <div className='flex flex-col justify-center items-center my-auto'>
                <div className="spinner">
                    <div className="double-bounce1"></div>
                    <div className="double-bounce2"></div>
                </div>
                <div className='flex'>
                    {
                        progress <= 33
                            ?
                            <span className="text-base font-semibold">Analizando variables</span>
                            :
                            progress <= 63
                                ?
                                <span className="text-base font-semibold">Estudiando rentabilidad</span>
                                :
                                <span className="text-base font-semibold">Cargando información</span>
                    }
                </div>
            </div>
        </div>
    );
};