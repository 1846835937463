import { PhaseModel } from '../interfaces/PropertyPageModel'
import timelineIcon from '../../../assets/img/nido/timeline/timeline-icon.svg'
import { TimeLineItem } from '../components'


export const TimeLine = ({ phases }: { phases: PhaseModel[] }) => {

    const sortedPhases = [...phases].sort((a, b) => {
        const dateA = a.startDate ? new Date(a.startDate) : new Date()
        const dateB = b.startDate ? new Date(b.startDate) : new Date()
        return dateA.getTime() - dateB.getTime();
    });
    return (
        <section className="w-full flex flex-col justify-center items-center mb-2">
                 
            <h1 className='text-[#9ca3af] primaryFont font-bold text-2xl mb-5 primaryFont'>Cronograma del proyecto</h1>
            <div className="flex gap-4 flex-row lg:flex-col">
                <ul className='secondaryFont flex flex-col gap-2'>
                    <li className="list-disc">
                        Estabilización estimada del proyecto 2 años a partir del inicio de operación.
                    </li>
                    <li className="list-disc">
                        Las rentabilidades se repartirán trimestralmente.
                    </li>
                </ul>
            </div>

            <div className="flex justify-center flex-col lg:flex-row">
                {sortedPhases.map((phase, index) => (
                    <TimeLineItem
                        key={phase.startDate}
                        timelineIcon={timelineIcon}
                        description={phase.description}
                        date={phase.startDate}
                        lastOne={index === sortedPhases.length - 1}
                    />
                ))}

            </div>

            <div className="my-3">
                <p className="secondaryFont text-[13px] text-center">
                    *Las fechas son estimaciones iniciales. Pueden haber cambios y retrasos durante la ejecución del proyecto
                </p>
            </div>
        </section>
    )
}
