import { Form } from "../Interfaces/FormInterfaces";


export const investorFormData: Form = {
  id: "investor-form",
  name: "Formulario de Inversiónista",
  questions: [
    {
      id: "q1",
      text: "¿En qué área profesional trabajas?",
      type: "select",
      options: [
        { id: "opt1", label: "Tecnología", value: "Tecnología" },
        { id: "opt2", label: "Finanzas", value: "Finanzas" },
        { id: "opt3", label: "Salud", value: "Salud" },
        { id: "opt4", label: "Educación", value: "Educación" },
        { id: "opt5", label: "Otro", value: "Otro" },
      ],
      required: true,
    },
    {
      id: "q3",
      text: "Nombre de la empresa",
      type: "text",
      required: true,
    },
    {
      id: "q4",
      text: "¿Qué tan probable es que recomiendes LOKL a tus amigos o familiares?",
      type: "radio",
      required: true,
      options: [
        {id:"opt2-1",   label: "1 ", value: "1" },
        { id: "opt2-2", label: "2 ", value: "2" },
        { id: "opt2-3", label: "3 ", value: "3" },
        { id: "opt2-4", label: "4", value: "4" },
        { id: "opt2-4", label: "5 ", value: "5" },
        { id: "opt2-4", label: "6", value: "6" },
        { id: "opt2-4", label: "7", value: "7" },
        { id: "opt2-4", label: "8", value: "8" },
        { id: "opt2-4", label: "9", value: "9" },
        { id: "opt2-4", label: "10", value: "10" },
      ]
    },
    {
      id: "q5",
      text: "En general, ¿qué tan satisfecho estás con LOKL?",
      type: "radio",
      required: true,
      options: [
        {id:"opt2-1",   label: "1 ", value: "1" },
        { id: "opt2-2", label: "2 ", value: "2" },
        { id: "opt2-3", label: "3 ", value: "3" },
        { id: "opt2-4", label: "4", value: "4" },
        { id: "opt2-5", label: "5 ", value: "5" },
       
      ]
    },
    {
      id: "q6",
      text: "¿Qué tan sencilla fue tu experiencia a la hora de invertir en LOKL?",
      type: "radio",
      required: true,
      options: [
        {id:"opt2-1",   label: "1 ", value: "1" },
        { id: "opt2-2", label: "2 ", value: "2" },
        { id: "opt2-3", label: "3 ", value: "3" },
        { id: "opt2-4", label: "4", value: "4" },
        { id: "opt2-5", label: "5 ", value: "5" },
        { id: "opt2-6", label: "6", value: "6" },
        { id: "opt2-7", label: "7", value: "7" },
      
      ]
    },
    {
      id: "q7",
      text: "¿Durante el proceso de inversión recibiste ayuda de alguno de nuestros asesores? *",
      type: "radio",
      required: true,
      options: [
        {id:"opt2-1",   label: "Si", value: "Si" },
        { id: "opt2-2", label: "No ", value: "No" },
       
      
      ]
    },
    {
      id: "q8",
      text: "¿Qué tan probable es que reinviertas en Lokl?*",
      type: "radio",
      required: true,
      options: [
        {id:"opt2-1",   label: "Muy probable", value: "Muy probable" },
        { id: "opt2-2", label: "Probable", value: "Probable" },
        { id: "opt2-2", label: "Improbable ", value: "Improbable" },
       
      
      ]
    },
    {
      id: "q9",
      text: "¿Escoge las 3 cosas que mas te gustaron del proyecto en el que invertiste? *",
      type: "checkbox",
      required: true,
      options: [
        {id:"opt2-1",   label: "Ser parte de la comunidad de Lokl", value: "Ser parte de la comunidad de Lokl" },
        { id: "opt2-2", label: "El modelo negocio e impacto positivo del proyecto", value: "El modelo negocio e impacto positivo del proyecto" },
        { id: "opt2-2", label: "Los beneficios y descuentos que tengo en el proyecto ", value: "Los beneficios y descuentos que tengo en el proyecto" },
        { id: "opt2-2", label: "La valorización de mi participacion en el proyecto", value: "La valorización de mi participacion en el proyecto" },
        { id: "opt2-2", label: "Empezar a invertir desde bajos montos 100% digital ", value: "Empezar a invertir desde bajos montos 100% digital" },
        { id: "opt2-2", label: "Las rentas futuras que recibire de la operación", value: "Las rentas futuras que recibire de la operación" },
        { id: "opt2-2", label: "Otro", value: "Otro" },
        
       
      
      ]
    },
    {
      id: "q10",
      text: "¿Por qué invertiste en este proyecto?*",
      type: "text",
      required: true,
    
    },
    {
      id: "q11",
      text: "¿Que le mejorarías al proceso de inversión, proyecto o plataforma?*",
      type: "text",
      required: true,
    
    },
    {
      id: "q12",
      text: "¿Quieres continuar dándonos información acerca de tu experiencia como inversionista?*",
      type: "radio",
      required: true,
      options: [
        {id:"opt2-1",   label: "Sí", value: "Sí" },
        { id: "opt2-2", label: "No", value: "No" },
       
       
      
      ]
    },
    {
      id: "q13",
      text: "¿Selecciona los 3 objetivos mas importantes que tienes al invertir tu dinero? *",
      type: "checkbox",
      required: true,
      options: [
        { id:"opt2-1",   label: "Rentas sobre mi inversión", value: "Rentas sobre mi inversión" },
        { id: "opt2-2", label: "Valorizar mi patrimonio", value: "Valorizar mi patrimonio" },
        { id: "opt2-2", label: "Mas libertad sobre mi tiempo ", value: "Mas libertad sobre mi tiempo" },
        { id: "opt2-2", label: "Ahorrar para mi retiro", value: "Ahorrar para mi retiro" },
        { id: "opt2-2", label: "Crear patrimonio para mi familia", value: "Crear patrimonio para mi familia" },
        { id: "opt2-2", label: "Un impacto positivo con mi inversión", value: "Un impacto positivo con mi inversión" },
        { id: "opt2-2", label: "Otro", value: "Otro" },
        
       
      
      ]
    },
    {
      id: "q14",
      text: "¿Tienes experiencia de inversión?*",
      type: "radio",
      required: true,
      options: [
        { id:"opt2-1",   label: "No, es mi primera inversión!", value: "No, es mi primera inversión!" },
        { id: "opt2-2", label: "Si, tengo 2 a 3 inversiones adicionales", value: "Si, tengo 2 a 3 inversiones adicionales" },
        { id: "opt2-2", label: "Soy experto y tengo muchas inversiones", value: "Soy experto y tengo muchas inversiones" },
       
       
      
      ]
    },
    {
      id: "q15",
      text: "¿Cuál es tu perfil de riesgo como inversionista? *",
      type: "radio",
      required: true,
      options: [
        { id:"opt2-1",   label: "1", value: "1" },
        { id: "opt2-2", label: "2", value: "2" },
        { id: "opt2-2", label: "3", value: "3" },
        { id: "opt2-2", label: "4", value: "4" },
        { id: "opt2-2", label: "5", value: "5" },
       
       
      
      ]
    },
    {
      id: "q16",
      text: "¿A qué plazo realizas tus inversiones? *",
      type: "radio",
      required: true,
      options: [
        { id:"opt2-1",   label: "Corto (1-2 años)", value: "Corto (1-2 años)" },
        { id: "opt2-2", label: "Mediano (3-4 años)", value: "Mediano (3-4 años)" },
        { id: "opt2-2", label: "Largo (+5 años)", value: "Largo (+5 años)" },
        { id: "opt2-2", label: "Otro", value: "Otro" },
      ]
    },
    {
      id: "q17",
      text: "Activos*",
      type: "radio",
      required: true,
      options: [
        { id:"opt2-1",   label: "Menos de $50M", value: "Menos de $50M" },
        { id: "opt2-2", label: "Entre $50M y $100M", value: "Entre $50M y $100M" },
        { id: "opt2-2", label: "Entre $100M y $200M", value: "Entre $100M y $200M" },
        { id: "opt2-2", label: "Más de $200M", value: "Más de $200M" },
      ]
    },
    {
      id: "q18",
      text: "¿Cuánto inviertes mensualmente? *",
      type: "radio",
      required: true,
      options: [
        { id:"opt2-1",   label: "Menos de $500K", value: "Menos de $500K" },
        { id: "opt2-2", label: "Entre $500K y $1M", value: "Entre $500K y $1M" },
        { id: "opt2-2", label: "Entre $1.1M y $2.5M", value: "Entre $1.1M y $2.5M" },
        { id: "opt2-2", label: "Entre $2.6M y $5M", value: "Entre $2.6M y $5M" },
        { id: "opt2-2", label: "Más de $5M", value: "Más de $5M" },
      ]
    },
    {
      id: "q19",
      text: "Ingresos mensuales*",
      type: "radio",
      required: true,
      options: [
        { id:"opt2-1",   label: "Menos de $2.5M", value: "Menos de $2.5M" },
        { id: "opt2-2", label: "Entre $2.5M y $5M", value: "Entre $2.5M y $5M" },
        { id: "opt2-2", label: "Hasta 5 Millones", value: "Hasta 5 Millones" },
        { id: "opt2-2", label: "Entre $10.1M y $20M", value: "Entre $10.1M y $20M" },
        { id: "opt2-2", label: "Más de $20M", value: "Más de $20M" },
      ]
    },
    {
      id: "q20",
      text: "Egresos mensuales*",
      type: "radio",
      required: true,
      options: [
        { id:"opt2-1",   label: "Menos de $2.5M", value: "Menos de $2.5M" },
        { id: "opt2-2", label: "Entre $2.5M y $5M", value: "Entre $2.5M y $5M" },
        { id: "opt2-2", label: "Entre $10.1M y $20M", value: "Entre $10.1M y $20M" },
        { id: "opt2-2", label: "Más de $20M", value: "Más de $20M" },
      ]
    },
    {
      id: "q21",
      text: "¿Qué información te gustaría ver en un reporte como socio del proyecto? *",
      type: "checkbox",
      required: true,
      options: [
        { id:"opt2-1",   label: "Resultados y estrategias de Operación", value: "Resultados y estrategias de Operación" },
        { id: "opt2-2", label: "Desempeño de inversión", value: "Desempeño de inversión" },
        { id: "opt2-2", label: "Proceso de construcción", value: "Proceso de construcción" },
        { id: "opt2-2", label: "Retos y estrategias", value: "Retos y estrategias" },
        { id: "opt2-2", label: "Todas", value: "Todas" },
        { id: "opt2-2", label: "Otro", value: "Otro" },
      ]
    },
    {
      id: "q22",
      text: "¿Cuáles son tus principales criterios para elegir una inversión? *",
      type: "checkbox",
      required: true,
      options: [
        { id:"opt2-1",   label: "Monto mínimo de inversión", value: "Monto mínimo de inversión" },
        { id: "opt2-2", label: "Rentabilidad total", value: "Rentabilidad total" },
        { id: "opt2-2", label: "Nivel de riesgo", value: "Nivel de riesgo" },
        { id: "opt2-2", label: "Plazo para obtener ganancias", value: "Plazo para obtener ganancias" },
        { id: "opt2-2", label: "Seguridad en la inversión", value: "Seguridad en la inversión" },
        { id: "opt2-2", label: "Costos asociados a la inversión", value: "Costos asociados a la inversión" },
        { id: "opt2-2", label: "Facilidad de Liquidez", value: "Facilidad de Liquidez" },
        { id: "opt2-2", label: "Rentas que se generan", value: "Rentas que se generan" },
        { id: "opt2-2", label: "Industria o Sector", value: "Industria o Sector" },
        { id: "opt2-2", label: "Otro", value: "Otro" },
      ]
    },
    {
      id: "q23",
      text: "¿Que formato  de información te gusta mas para saber sobre inversiones?*",
      type: "checkbox",
      required: true,
      options: [
        { id:"opt2-1",   label: "Videos Largos (Más de 2 min)", value: "Videos Largos (Más de 2 min)" },
        { id: "opt2-2", label: "Clips o Videos Cortos", value: "Clips o Videos Cortos" },
        { id: "opt2-2", label: "Podcast o radio", value: "Podcast o radio" },
        { id: "opt2-2", label: "Eventos Presenciales o Ferias", value: "Eventos Presenciales o Ferias" },
        { id: "opt2-2", label: "Eventos digitales o Webinars", value: "Eventos digitales o Webinars" },
        { id: "opt2-2", label: "Infografías // Imágenes", value: "Infografías // Imágenes" },
        { id: "opt2-2", label: "Amigos y familia", value: "Amigos y familia" },
        { id: "opt2-2", label: "Libros o E-books", value: "Libros o E-books" },
        { id: "opt2-2", label: "Blogs o Articulos", value: "Blogs o Articulos" },
        { id: "opt2-2", label: "Otro", value: "Otro" },
      ]
    },
    {
      id: "q24",
      text: "¿Cuál es tu red social o canal favoritos para saber sobre inversiones?*",
      type: "checkbox",
      required: true,
      options: [
        { id:"opt2-1",   label: "Instagram", value: "Instagram" },
        { id: "opt2-2", label: "Youtube", value: "Youtube" },
        { id: "opt2-2", label: "Twitter", value: "Twitter" },
        { id: "opt2-2", label: "Facebook", value: "Facebook" },
        { id: "opt2-2", label: "LinkedIn", value: "LinkedIn" },
        { id: "opt2-2", label: "Whatsapp", value: "Whatsapp" },
        { id: "opt2-2", label: "Tik Tok", value: "Tik Tok" },
        { id: "opt2-2", label: "Google", value: "Google" },
    
      ]
    },
    {
      id: "q25",
      text: "¿En qué has invertido anteriormente?*",
      type: "checkbox",
      required: true,
      options: [
        { id:"opt2-1",   label: "CDT", value: "CDT" },
        { id: "opt2-2", label: "Acciones", value: "Acciones" },
        { id: "opt2-2", label: "Finca Raíz", value: "Finca Raíz" },
        { id: "opt2-2", label: "Divisas", value: "Divisas" },
        { id: "opt2-2", label: "Crodwfunding", value: "Crodwfunding" },
        { id: "opt2-2", label: "Fondos de inversión colectiva", value: "Fondos de inversión colectiva" },
        { id: "opt2-2", label: "Criptomonedas", value: "Criptomonedas" },
        { id: "opt2-2", label: "Emprendimiento propio", value: "Emprendimiento propio" },
        { id: "opt2-2", label: "Oro", value: "Oro" },
        { id: "opt2-2", label: "Todas las anteriores", value: "Todas las anteriores" },
        { id: "opt2-2", label: "Otro", value: "Otro" },
      ]
    },
    {
      id: "q26",
      text: "¿Quieres continuar dándonos información acerca de tu experiencia de inversión?",
      type: "radio",
      required: true,
      options: [
        { id:"opt2-1",   label: "Sí", value: "Sí" },
        { id: "opt2-2", label: "No", value: "No" },
        
      ]
    },
    {
      id: "q27",
      text: "¿Te gustaría ser tester de las nuevas funcionalidades de Lokl? ",
      type: "radio",
      required: true,
      options: [
        { id:"opt2-1",   label: "Sí", value: "Sí" },
        { id: "opt2-2", label: "No", value: "No" },
        
      ]
    },
    {
      id: "q28",
      text: "¿Cuáles son los principales retos que tienes normalmente para invertir? ",
      type: "text",
      required: true,
  
    },
    {
      id: "q29",
      text: "¿Sobre qué temas de inversión te gustaría aprender? ",
      type: "text",
      required: true,
  
    },
    {
      id: "q30",
      text: "¿Qué beneficios quisieras recibir por tu inversión a parte de tu rentabilidad?",
      type: "text",
      required: true,
  
    },
  
     

  ],
};
