import React from 'react'



/* import { NidosList } from './constants/nidos-constants' */

interface AccommodationsPropertyprops{
    accommodation: Array<{name : string ,squareMeters:number , roomCount:number , iconLink: string}>;
    amenities: Array<{name : string ,description:string , iconLink:string}>;
}


export const AccommodationsProperty :React.FC<AccommodationsPropertyprops> = ({
    accommodation = [],
    amenities = []
  }) => {
  return (
    <div>
      <div className="w-full text-center">
        <h2 className="text-[24px] primaryFont font-extrabold mb-8 w-3/4 mx-auto">
          Sumérgete en una experiencia que transforma a quien la vive
        </h2>
      </div>

      
      <div className="flex gap-3 secondaryFont w-full mb-12 overflow-x-auto">

      {accommodation.map((item, index) => (
            <div key={index} className="border rounded-xl flex px-5 py-4 items-center justify-center flex-grow min-w-fit">
               <div className="mr-4">
                <img className='w-10 h-10' src={item.iconLink} alt={`${index + 1}`} />
              </div>
              <div>
                <p className='primaryFont'>{item.name}</p>
                <p className="numberFont font-bold text-xl">{item.roomCount}</p>
                <p className='numberFont '>{item.squareMeters}m2</p>
            </div>
            </div>
            
          ))}

          
       
      </div>

      <div className="px-5 flex flex-col gap-5">
        {
          amenities.map((item ) => (
            <div className="flex items-center">
              <div className="flex-shrink-0 basis-8 mr-10">
                <img src={item.iconLink} className="w-full" alt="" />
              </div>
              <p className="text-gray-500 secondaryFont">
                <span className="text-2xl font-bold !primaryFont">{item.name}: </span>
                {item.description}
              </p>
            </div>
          ))
        }

      </div>

    </div>
  )
}