import React from 'react'
import img2 from "../../assets/img/blog/card-2.png";
import image from "../../assets/img/blog/image-article.png";
import useTitle, { useDescription, useKeywords } from '../../hooks/useSEO';

function ArticleConceptosBasicosDeInversion() {
  useTitle("5 Conceptos básicos de inversión inmobiliaria que debes conocer | LOKL");
  useDescription("¿Qué elementos son clave en la búsqueda de una inversión inmobiliaria? ¿Cómo invertir en bienes raíces con poco dinero? Descubre los 5 conceptos básicos de inversión inmobiliaria que debes conocer en este artículo de LOKL.");
  useKeywords("inversión inmobiliaria, conceptos básicos de inversión, invertir en bienes raíces, ubicación, mercado inmobiliario, diversificación, tendencias, retorno de inversión, ROI, LOKL");
  return (
    <section id='article' className='pt-32 bg-[#F9F9F9]'>
      
      <header className="bg-note-img bg-cover bg-center h-[432px] w-full flex items-center justify-center">

        <div className="wrapper-lokl text-white ">

          <div className="w-5/12">

            <h1 className="font-lato font-extrabold text-[38px] mb-3">
              5 Conceptos básicos de inversión inmobiliaria que debes conocer
            </h1>

            {/* <p className="text-xl">
              Una mirada en busca de nuevas oportunidades que beneficie a todos.
            </p> */}
          
          </div>

        </div>

      </header>

      <article className="wrapper-lokl space-y-20 py-16">

        <section className="w-full flex justify-between">

          <div className="w-1/2 mr-10">

            <div className="blog-title-md mb-4">
              Te has preguntado...
            </div>

            <p className="text-base font-roboto">
              ¿Qué elementos son clave en la búsqueda de una inversión inmobiliaria?" o "¿Cómo invertir en bienes raíces con poco dinero?". Si es así, ¡estás en el lugar correcto! En el mundo digital de hoy, hay soluciones que permiten a las personas invertir en propiedades con montos reducidos, y en este artículo, te presentaremos cómo hacerlo con la ayuda de la tecnología.
            </p>

          </div>

          <div className="w-1/2 h-auto flex justify-end items-start">

            <div className="flex space-x-2 mr-3">

              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>

            </div>

            <div className="underline cursor-pointer">5</div>

          </div>

        </section>

        <section className="w-full flex justify-between">

          <img className='' width={525} height={357} src={image} alt="casa-lokl" />

          <div className="flex flex-col justify-center ml-14">

            <h2 className='blog-title-md mb-12'>1. La ubicación es primordial.</h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto mb-12">
                El primer concepto que debes entender es la importancia de la ubicación. Esta regla de oro siempre será relevante en el mundo inmobiliario. Pero, ¿cómo saber cuál es la mejor ubicación? Aquí es donde entran en juego las plataformas como LOKL. Gracias a la tecnología, puedes acceder a datos precisos y análisis que te mostrarán las mejores zonas para invertir, especialmente en el sector de la hospitalidad.
              </p>

              {/* <div className="">

                <div className="">
                  Lee también: <a className='hover:text-[#17008A]' href="/blog/inversiones-colaborativas-hospitality"> <b> Inversiones colaborativas en <br /> hospitality </b> </a>
                </div>

              </div> */}

            </div>

          </div>

        </section>

        <h2 className="blog-title-md text-center py-20">
        
          ¡Descubre el poder de la inversión! Genera <br /> 
          ingresos pasivos, crea patrimonio, obtén <br />
          beneficios y asegura tu futuro financiero con <br />
          propiedades inmobiliarias solo en LOKL.
  
        </h2>

        <div className="flex gap-5 justify-between">

          <div className="w-full">
            <h2 className='blog-title-md mb-12'>2. El mercado inmobiliario ya no es solo para grandes inversores.</h2>

            <div className="">

              <p className="text-base font-roboto mb-12">
                El segundo concepto que debes comprender es que el mercado inmobiliario ha cambiado. Ahora, gracias a soluciones tecnológicas como LOKL, es posible invertir en bienes raíces con poco dinero. Estas plataformas digitales democratizan el sector inmobiliario para que personas como tú puedan entrar al juego mucho más jóvenes y con bajos montos para beneficiarse del crecimiento del sector.
              </p>

            </div>

          </div>

          <div className="w-full">

            <h2 className='blog-title-md mb-12'>3. La diversificación es la clave.<br/><br/></h2>

            <div className="">

              <p className="text-base font-roboto mb-12">
                Así como en cualquier inversión, diversificar es esencial. No pongas todos tus huevos en una sola canasta. Y de nuevo, aquí es donde las plataformas digitales muestran su valor. Permiten invertir en diferentes propiedades o proyectos, lo que reduce el riesgo y aumenta las posibilidades de obtener rendimientos.
              </p>

            </div>

          </div>

        </div>

        <div className="w-full flex gap-5">

          <div className="w-full">

            <h2 className='blog-title-md mb-12'>4. Investiga y aprovecha las tendencias. <br/><br/></h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto mb-12">
               El mundo inmobiliario, en especial el sector de la hospitalidad, está en constante evolución. Es crucial estar al tanto de las últimas tendencias y adaptarse a ellas. Plataformas como LOKL ofrecen análisis detallados y actualizados que te ayudarán a tomar decisiones informadas.        
              </p>

            </div>

          </div>

          <div className="w-full">

            <h2 className='blog-title-md mb-12'>5. Entiende la importancia del retorno de inversión (ROI).</h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto mb-12">
                El ROI es una métrica que te permite evaluar la rentabilidad de tu inversión. Es esencial comprender cómo calcularlo y qué factores pueden influir en él. Una inversión inmobiliaria con un alto ROI indica que la propiedad está generando buenos ingresos en relación con su costo. Las plataformas digitales, como LOKL, te ayudan a estimar y maximizar tu ROI, asegurando que tu inversión sea rentable.
              </p>

            </div>

          </div>

        </div>

        <div className="w-3/4 text-center mx-auto">
          <h2 className='blog-title-md mb-12'>Conclusión</h2>
          <div className="flex flex-col justify-between">

            <p className="text-base font-roboto">
              El mundo inmobiliario, en especial el sector de la hospitalidad, está en constante evolución. Es crucial estar al tanto de las últimas tendencias y adaptarse a ellas. Plataformas como LOKL ofrecen análisis detallados y actualizados que te ayudarán a tomar decisiones informadas.        
            </p>

          </div>
        </div>

        <div className="w-3/4 text-center mx-auto">
          <p className="text-base font-roboto">
            <strong>¿Listo para dar el siguiente paso en tu aventura inmobiliaria? </strong>¡Regístrate en <a href="https://www.lokl.life/register?utm_source=Blog&utm_medium=TipsFinanzasInversiones">este link</a> y contacta con nuestro equipo! Estaremos encantados de ofrecerte una atención más personalizada y guiarte en este emocionante camino. ¡Es tu momento de invertir en el futuro!
          </p>
        </div>


        <div className="w-full inline-flex overflow-x-auto space-x-4 mb-5">

          <div className="flex flex-col items-start flex-shrink-0 justify-between p-12 text-white bg-[#0A180E] rounded-xl">
            
            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">EDUCACIÓN</div>

              <div className='font-lato font-extrabold text-2xl'>
                5 secretos para usar el <br /> dinero adecuadamente, <br /> planifica e invierte a largo <br /> plazo.
              </div>

            </div>

            <div 
              className="bg-[#FF7171] text-[#010067] hover:bg-[#FF7171]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
              onClick={() => {
                window.location.href = "/blog/planificar-presupuesto-largo-plazo"
              }}
            >
              Ver nota
            </div>

          </div>


          <div className="flex items-center flex-shrink-0 justify-between p-12 text-black bg-[#CACAC8] rounded-xl">
            
            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">INVERSIÓN</div>

              <div className='font-lato font-extrabold text-3xl'>
                Inversiones colaborativas en <br /> hospitality: una oportunidad <br /> para las nuevas generaciones
    
              </div>

              <p className='text-sm w-[350px]'>
                Muchos inversionistas buscan oportunidades para hacer crecer su dinero. Con los mercados financieros en constante 
              </p>

              <div 
                className="bg-black text-white hover:bg-black/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/inversiones-colaborativas-hospitality"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img2} alt="banner-card2-lokl" /> 

          </div>

        </div>
        
      </article>

    </section>
  )
}

export default ArticleConceptosBasicosDeInversion;