import React  from 'react'
import lokl_real_estate_investment_leader from "../../assets/img/blog/lokl_real_estate_investment_leader_felipe.jpg"
import fractional_investment_for_millennials_generation_z from "../../assets/img/blog/fractional_investment_for_millennials_generation_z.jpg"
import useTitle, { useDescription, useKeywords } from '../../hooks/useSEO'
export const ArticleFractionalInvestmentRealEstate = () => {

  useTitle("Inversión Fraccionada en Bienes Raíces: Accesible, Digital y con Impacto Social | Lokl")
  useDescription("La inversión fraccionada en bienes raíces es una alternativa innovadora que permite a las nuevas generaciones invertir en propiedades de alto valor sin tener que hipotecar sus sueños. Descubre cómo esta nueva forma de invertir está cambiando las reglas del juego y creando valor real.")
  useKeywords("Inversion fraccionada, Inversion en bienes raices accesible, Inversion inmobiliaria digital, Propiedad compartida, Inversion de bajo costo, Impacto social en inversiones, Diversificacion de portafolio, Inversion inmobiliaria para millennials, Acceso a bienes raices, Inversion en comunidades");
 
  return (
    <div className="pt-28 md:pt-40 ">

 
     <section className="mb-8">
        <img
          src={fractional_investment_for_millennials_generation_z}  
          alt="inversion fraccionada en bienes raices - generacion millenial y generacion z"
          className="w-full h-auto object-cover"
        />
      </section>
      <div className='max-w-7xl px-10 md:px-1 mx-auto primaryFont'>
      <h1 className="text-4xl primaryFont font-bold text-center mb-6">
        Bienvenido a la Nueva Era de la Inversión
      </h1>

      <section>
        <p className="text-lg mb-6 secondaryFont">
          El sector real estate lo conocemos tradicionalmente por su enfoque conservador y poco innovador,
          y ahora la tecnología está irrumpiendo para cambiar las reglas del juego. Permitiéndole a las nuevas
          generaciones ser protagonistas de sus inversiones al invertir en grandes proyectos inmobiliarios sin
          tener que hipotecar sus sueños ni vaciar sus cuentas bancarias.
        </p>

        <h2 className="text-3xl font-semibold mb-4 primaryFont">Entonces, ¿Qué es la inversión fraccionada?</h2>
        <p className="text-lg mb-6 secondaryFont">
          La <span className='font-bold'>inversión fraccionada</span> es, básicamente, la opción de comprar una parte de una propiedad. Sí, en lugar de
          ser dueño de todo un edificio (que suena genial, pero tiene un precio fuera del alcance para muchos), ahora
          puedes comprar solo una fracción. <span className='font-bold'>¡Y no necesitas ser millonario para hacerlo!</span> Esta alternativa abre la puerta
          a bienes de alto valor, como hoteles o residencias de lujo, sin que tengas que vender tu alma ni tu riñón para
          entrar en el mercado.
        </p>

        <h2 className="text-3xl font-semibold mb-4 primaryFont">¿Por qué esto es tan revolucionario?</h2>
        <p className="text-lg mb-6 secondaryFont">
          Porque, hasta ahora, invertir en bienes raíces era como pertenecer a un club exclusivo y solo para los que
          tenían mucho dinero; actualmente la inversión fraccionada es como una llave maestra que abre esa puerta para
          que más personas participen en proyectos inmobiliarios y reciban beneficios sin tener que aportar enormes sumas
          de dinero. ¿El resultado? Mucha más gente puede rentabilizar su inversión y generar ingresos (piensa en rentas
          y ganancias), y al mismo tiempo ser parte de una comunidad con una visión mucho más amplia.
        </p>

        <h2 className="text-3xl font-semibold mb-4 primaryFont">La Inversión Fraccionada como Estilo de Vida de las Nuevas Generaciones</h2>
        <p className="text-lg mb-6 secondaryFont">
          En las generaciones del hoy, invertir va más allá de los números y las ganancias; se trata de elegir conscientemente
          dónde invertir nuestros recursos para construir un futuro que resuene con nuestros valores y aspiraciones. Plataformas
          como <a className='text-blue-500' href="https://www.lokl.life/aboutus?utm_source=inversion&utm_medium=fraccionada&utm_campaign=revolucion&utm_id=articulo_1&utm_term=comunidades&utm_content=valor_real">LOKL</a> creen que la inversión debe ser una extensión de nuestro propósito personal, un vehículo para crear impacto
          positivo en las comunidades y el entorno. Por este motivo impulsan proyectos que no solo generen valor económico, sino
          que contribuyan activamente al bienestar social y ambiental.
        </p>

        <a className=' no-underline' href="https://www.lokl.life/?utm_source=inversion&utm_medium=fraccionada&utm_campaign=revolucion&utm_id=articulo_1&utm_term=comunidades&utm_content=valor_real#newprojects">
            <h2 className="text-xl font-extralight primaryFont italic mb-4">
                ¡Empieza hoy a construir tu futuro con inversiones desde $1,000,000 COP!
            </h2>
            </a>

        <h2 className="text-3xl font-semibold mb-4 primaryFont">¿Cómo Esto Crea Valor Real?</h2>
        <p className="text-lg mb-6 secondaryFont">
          Democratizando el acceso a las inversiones inmobiliarias con características como:
        </p>

        <h3 className="text-2xl font-medium mb-2 primaryFont">Bajo Umbral de Entrada</h3>
        <p className="text-lg mb-6 secondaryFont">
          Con inversiones iniciales reducidas (algunos proyectos comienzan desde $1,000.000 COP), así, cualquier persona con
          un capital moderado puede participar en proyectos inmobiliarios que anteriormente solo estaban al alcance de grandes
          inversores o fondos de inversión.
        </p>

        <h3 className="text-2xl font-medium mb-2 primaryFont">Acceso a Proyectos con Alto Atractivo</h3>
        <p className="text-lg mb-6 secondaryFont">
          Las plataformas de inversión fraccionada dividen propiedades de alto valor y atractivo, como edificios comerciales,
          hoteles o proyectos residenciales de lujo. Esto permite que los pequeños inversionistas sean co-dueños de proyectos
          que, de otro modo, no podrían adquirir individualmente.
        </p>

        <h3 className="text-2xl font-medium mb-2 primaryFont">Diversificación de Portafolio</h3>
        <p className="text-lg mb-6 secondaryFont">
          La inversión fraccionada permite a los inversores individuales distribuir su capital entre múltiples propiedades o
          proyectos. Esto reduce el riesgo al no depender de un único activo y brinda la posibilidad de participar en mercados
          con altas tasas de crecimiento, lo cual sería difícil en una inversión directa.
        </p>

        <h3 className="text-2xl font-medium mb-2 primaryFont">Flexibilidad Mejorada</h3>
        <p className="text-lg mb-6 secondaryFont">
          Algunas plataformas de inversión fraccionada ofrecen opciones de venta en el mercado secundario, donde los
          inversionistas pueden vender sus participaciones a otros. Esto mejora la liquidez, un aspecto clave, ya que las
          inversiones inmobiliarias suelen ser de naturaleza menos líquida.
        </p>

        <h3 className="text-2xl font-medium mb-2 primaryFont">Inversiones con Impacto</h3>
        <p className="text-lg mb-6 secondaryFont">
          Plataformas como  <a  className='text-blue-500' href="https://www.lokl.life/aboutus?utm_source=inversion&utm_medium=fraccionada&utm_campaign=revolucion&utm_id=articulo_1&utm_term=comunidades&utm_content=valor_real">Lokl</a> conectan individuos apasionados por transformar sus ciudades y apoyar iniciativas con un
          significado más profundo. Cada inversión se convierte en un paso hacia un estilo de vida alineado con un propósito
          mayor de crecer juntos, celebrando logros colectivos, creando espacios de coliving y desarrollando proyectos de
          hospitalidad que respetan la cultura y el entorno local.
        </p>

        <h2 className="text-3xl font-semibold mb-4 primaryFont">Conclusión</h2>
        <p className="text-lg mb-6 secondaryFont">
          La inversión fraccionada está ganando popularidad globalmente ya que plataformas digitales como Lokl permiten a
          personas comunes o nómadas digitales acceder a mercados inmobiliarios de primer nivel, sin las complejidades de
          adquirir una propiedad completa. Además, contribuye al concepto de "propiedad compartida", una tendencia creciente
          que promueve la optimización de recursos y el uso compartido de bienes de alto valor entre sus comunidades, ampliándose
          a un público en el cual, el verdadero valor de la inversión está en la oportunidad de impactar positivamente y de ser
          parte de algo más grande que ellos mismos.
        </p>

        <h3 className="text-2xl font-medium text-center mt-4 mb-6">
          <a className='text-blue-500 italic' href="https://www.lokl.life/?utm_source=inversion&utm_medium=fraccionada&utm_campaign=revolucion&utm_id=articulo_1&utm_term=comunidades&utm_content=valor_real#newprojects">¡Empieza hoy a construir tu futuro con inversiones desde $1,000,000 COP! </a>
          <br /> <br />
        </h3>
        <h3 className='text-2xl  text-center mt-4 mb-6 italic primaryFont font-extralight'>
        ¡Contacta con nuestro asesor inmobiliario
        para recibir todos los detalles!
        </h3>
          
      
      </section> </div>
      

      
      <footer className="mt-8">
        <img
          src={lokl_real_estate_investment_leader} 
          alt="inversion fraccionada en bienes raices - lider en inversion inmobiliaria"
          className="w-full h-auto object-cover"
        />
      </footer>
    </div>
  )
}
