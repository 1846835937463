import React from 'react'
import image1 from "../../assets/img/blog/organizar-mis-finanzas/1-establece-tus-gastos-e-ingresos.jpg"
import image2 from "../../assets/img/blog/organizar-mis-finanzas/2-prioriza-tus-gastos.jpg"
import image3 from "../../assets/img/blog/organizar-mis-finanzas/3-establece-metas-de-ahorro.jpg"
import image4 from "../../assets/img/blog/organizar-mis-finanzas/4-utiliza-herramientas-de-gestión-financiera.jpg"
import image5 from "../../assets/img/blog/organizar-mis-finanzas/5-evita-las-deudas-innecesarias.jpeg"
import image6 from "../../assets/img/blog/organizar-mis-finanzas/6-invierte-en-tu-futuro.jpg"

import img2 from "../../assets/img/blog/card-2.png";
import useTitle, { useDescription, useKeywords } from '../../hooks/useSEO'

function ArticleOrganizarMisFinanzas() {

  useTitle("¿Cómo organizo mis finanzas? | LOKL");
  useDescription("En un mundo donde la estabilidad financiera puede parecer un desafío, organizar tus finanzas es la clave para lograr tranquilidad y éxito a largo plazo. Vamos a explorar algunos pasos que te ayudarán a manejar tu dinero de manera efectiva.");
  useKeywords("organizar finanzas, finanzas personales, finanzas, ahorro, inversiones, presupuesto, dinero, gastos, ingresos");
  
  return (
    <main id='article' className='pt-32 bg-[#F9F9F9]'>
      
      <header className="bg-note-img2 bg-cover bg-top h-[400px] md:h-[400px] xl:h-[430px] w-full flex items-center justify-center">

        <div className="wrapper-lokl px-10 md:px-0 text-white ">

          <div className="md:w-5/12 w-full">
            
          </div>

        </div>

      </header>

      <article className="wrapper-lokl px-10 md:px-0 space-y-20 py-16">

        <section className="w-full flex justify-between">
          <div className="w-full">

            <h1 className="blog-title-md mb-4 text-center">
              ¿Cómo organizo mis finanzas?
            </h1>

            <p className="text-base font-roboto w-full md:w-4/5 mx-auto text-center">
              En un mundo donde la estabilidad financiera puede parecer un desafío, organizar tus finanzas es la clave para lograr tranquilidad y éxito a largo plazo. 
              <br />
              Vamos a explorar algunos <strong>pasos que te ayudarán</strong> a manejar tu dinero de <strong>manera efectiva:</strong>
            </p>

          </div>

          {/* <div className="w-1/2 h-auto justify-end items-start hidden md:flex">

            <div className="flex space-x-2 mr-3">

              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>

            </div>

            <div className="underline cursor-pointer">5</div>

          </div> */}

        </section>

        <section className="w-full gap-5 flex justify-between">

          <div className='w-1/2 hidden md:flex'>
            <img className='' width={525} height={357} src={image1} alt="casa-lokl" />
          </div>

          <div className="w-full md:w-1/2">

            <h2 className='blog-title-md mb-12'>1. Define tus ingresos y gastos</h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto mb-12">
                El primer paso para organizar tu presupuesto es saber cuánto dinero entra y cuánto sale.
                <br />
                Haz una lista de todos tus ingresos mensuales, como tu sueldo, ingresos extras o cualquier otra fuente de dinero.
                <br />
                Luego, haz otra lista con tus gastos fijos (arriendo, servicios, deudas) y variables (comida, entretenimiento, transporte).
              </p>

            </div>

          </div>
        </section>

        <section className="flex gap-5 justify-between">

          <div className="w-full md:w-1/2">
            <h2 className='blog-title-md mb-12'>2. Prioriza tus gastos</h2>

            <div className="">

              <p className="text-base font-roboto mb-12">
                Es importante diferenciar entre lo que necesitas y lo que quieres. Los gastos esenciales, como vivienda, comida y transporte, deben ser tu prioridad.
                <br />
                Luego, puedes asignar una parte de tu presupuesto a gastos no esenciales, como salir a cenar o comprar ropa.
              </p>

            </div>

          </div>

          <div className='w-1/2 aspect-[20/16] overflow-hidden hidden md:flex'>
            <img className='w-full object-cover' src={image2} alt="casa-lokl" />
          </div>


        </section>

        <section className="flex gap-5 justify-between">

          <div className='w-1/2 aspect-[20/16] overflow-hidden hidden md:flex'>
            <img className='object-cover' width={525} height={357} src={image3} alt="casa-lokl" />
          </div>

          <div className="w-full md:w-1/2">

            <h2 className='blog-title-md mb-12'>3. Establece metas de ahorro<br/><br/></h2>

            <div className="">

              <p className="text-base font-roboto mb-12">
                Ahorrar es fundamental para tener una seguridad financiera y alcanzar tus metas.
                <br />
                Define cuánto dinero quieres ahorrar cada mes y trata de hacerlo una prioridad.
                <br />
                Puedes comenzar con un pequeño porcentaje de tus ingresos y aumentarlo gradualmente. Recuerda, cada pequeño ahorro cuenta.
              </p>

            </div>

          </div>

        </section>

        <section className="w-full flex gap-5">

          <div className="w-full md:w-1/2">

            <h2 className='blog-title-md mb-12'>4. Utiliza herramientas de gestión financiera <br/><br/></h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto mb-12">
                Hoy en día, hay muchas aplicaciones y herramientas en línea que te pueden ayudar a seguir tus gastos y controlar tu presupuesto.
                <br />
                Estas herramientas son muy útiles para tener una visión clara de tus finanzas y asegurarte de que estás cumpliendo con tus metas.
              </p>

            </div>

          </div>

          <div className='w-1/2 aspect-[20/16] overflow-hidden hidden md:flex'>
            <img className='object-bottom object-cover' width={525} height={357} src={image4} alt="casa-lokl" />
          </div>
        </section>

        <section className="w-full flex gap-5">

          <div className='w-1/2 aspect-[20/16] overflow-hidden hidden md:flex'>
            <img className='' width={525} height={357} src={image5} alt="casa-lokl" />
          </div>

          <div className="w-full md:w-1/2">

            <h2 className='blog-title-md mb-12'>5. Evita las deudas innecesarias<br/><br/></h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto mb-12">
              Las deudas pueden convertirse en una carga si no se manejan adecuadamente.
              <br />
              Trata de evitar las deudas innecesarias y, si tienes alguna, haz un plan para pagarlas lo antes posible. Prioriza el pago de las deudas con los intereses más altos.
              </p>

            </div>

          </div>
        </section>

        <section className="w-full flex gap-5">
          <div className="w-full md:w-1/2">

            <h2 className='blog-title-md mb-12'>6. Invierte en tu futuro<br/><br/></h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto mb-12">
              Una vez que tengas controlado tu presupuesto y hayas ahorrado un poco, es hora de pensar en invertir. Invertir es una excelente manera de hacer crecer tu dinero y asegurar tu futuro financiero.
              <br />
              <br />
              En <strong>LOKL</strong>, te ofrecemos la oportunidad de invertir en bienes raíces con poco dinero, permitiéndote ser parte de proyectos increíbles sin tener que comprar una propiedad completa. 
              <br />
              <br />
              Organizar tu presupuesto mensual no tiene que ser complicado. Con estos tips prácticos, puedes tomar el control de tus finanzas y comenzar a construir un futuro sólido.
              </p>

            </div>

          </div>
          <div className='w-1/2 aspect-[20/16] overflow-hidden hidden md:flex'>
            <img className='' width={525} height={357} src={image6} alt="casa-lokl" />
          </div>
        </section>

        <section className="w-3/4 text-center mx-auto">
          {/* <h2 className='blog-title-md mb-12'>Conclusión</h2> */}
          <div className="flex flex-col justify-between">

            <p className="font-roboto text-lg">
              <strong>
                ¡En LOKL, estamos para apoyarte en cada paso de tu camino financiero!
              </strong>
            </p>

          </div>
        </section>

        {/* <div className="w-3/4 text-center mx-auto">
          <p className="text-base font-roboto">
            <strong>¿Listo para dar el siguiente paso en tu aventura inmobiliaria? </strong>¡Regístrate en <a href="https://www.lokl.life/register?utm_source=Blog&utm_medium=TipsFinanzasInversiones">este link</a> y contacta con nuestro equipo! Estaremos encantados de ofrecerte una atención más personalizada y guiarte en este emocionante camino. ¡Es tu momento de invertir en el futuro!
          </p>
        </div> */}


        <article className="w-full inline-flex overflow-x-auto space-x-4 mb-5">

          <section className="flex flex-col items-start flex-shrink-0 justify-between p-12 text-white bg-[#0A180E] rounded-xl">
            
            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">EDUCACIÓN</div>

              <div className='font-lato font-extrabold text-2xl'>
                5 secretos para usar el <br /> dinero adecuadamente, <br /> planifica e invierte a largo <br /> plazo.
              </div>

            </div>

            <div 
              className="bg-[#FF7171] text-[#010067] hover:bg-[#FF7171]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
              onClick={() => {
                window.location.href = "/blog/planificar-presupuesto-largo-plazo"
              }}
            >
              Ver nota
            </div>

          </section>


          <section className="flex items-center flex-shrink-0 justify-between p-12 text-black bg-[#CACAC8] rounded-xl">
            
            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">INVERSIÓN</div>

              <div className='font-lato font-extrabold text-3xl'>
                Inversiones colaborativas en <br /> hospitality: una oportunidad <br /> para las nuevas generaciones
    
              </div>

              <p className='text-sm w-[350px]'>
                Muchos inversionistas buscan oportunidades para hacer crecer su dinero. Con los mercados financieros en constante 
              </p>

              <div 
                className="bg-black text-white hover:bg-black/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/inversiones-colaborativas-hospitality"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img2} alt="banner-card2-lokl" /> 

          </section>

        </article>
        
      </article>

    </main>
  )
}

export default ArticleOrganizarMisFinanzas;