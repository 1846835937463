import React from "react";

interface TextInputProps {
  id: string; 
  label: string; 
  value: string; 
  onChange: (value: string) => void; 
  placeholder?: string; 
  required?: boolean; 
  disabled?: boolean; 
}

export const TextInputComponent: React.FC<TextInputProps> = ({
  id,
  label,
  value,
  onChange,
  placeholder = "",
  required = false,
  disabled = false,
}) => {
  return (
    <div className="md:min-h-fit min-h-[40px] md:w-full">
      <label
      htmlFor={id}
      className="text-gray-700 font-medium primaryFont"
      >
      {label} {required && <span className="text-red-500">*</span>}
      </label>


      <input
      id={id}
      type="text"
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      required={required}
      disabled={disabled}
      className={`mt-2 rounded-lg secondaryFont w-full h-[40px] text-black border-[1px] px-2 bg-white focus:border-[#4743E0] sm:text-sm ${
        disabled ? "bg-gray-100 cursor-not-allowed" : ""
      }`}
      
      />
    </div>
  );
};
