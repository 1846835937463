import axios from "axios"
import { urls } from "../helpers/urls";



export const getProjects = async()=> {
  try {
    const result = await axios.get(`${urls.NEW_API_URL}project`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return result?.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getProjectById = async(id: string)=> {
  try {
    const result = await axios.get(`${urls.NEW_API_URL}project/${id}`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return result?.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getProjectCards = async()=> {
  try {
    const result = await axios.get(`${urls.NEW_API_URL}project/cards`, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    return result?.data
  } catch (error) {
    console.log(error)
    throw error
  }
}

export const getPropertyPage = async (projectCode: string) => {
  try {
    const [mainData, secondaryData] = await Promise.all([
      axios.get(`${urls.NEW_API_URL}project/propertyPageMain/${projectCode}`, {
        headers: { "Content-Type": "application/json" },
      }),
      axios.get(`${urls.NEW_API_URL}project/propertyPageSecondary/${projectCode}`, {
        headers: { "Content-Type": "application/json" },
      }),
    ]);

    return {
      main: mainData?.data,
      secondary: secondaryData?.data,
    };
  } catch (error) {
    console.error("Error solicitando el property page:", error);
    throw error;
  }
};

export const getCurrentUnitPrice = async (projectId: string) => {
  try {
    const result = await axios.get(
      `${urls.NEW_API_URL}project/price/${projectId}`,
      {
        headers: {
          "Content-Type": "application/json", 
        },
      }
    );
    return result?.data; 
  } catch (error) {
    console.error("Error solicitando el valor del proyecto", error);
    throw error; 
  }
};
