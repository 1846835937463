import axios from "axios";
import jwt_decode from "jwt-decode";
import moment from "moment";
import { urls } from "../helpers/urls";
import { getUserData } from "./ownerServices";

const getToken = () => localStorage.getItem("token") ?? '';

export const getHomeIndicators = async () => {

  try {

    const response = await axios.get(`${urls.NEW_API_URL}businessInsiths/summaryInvestment`,
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    });
    return response;
  } catch (error: any) {
    throw error?.response.data;
  }

}

export const calculateSubs = async (invesment: string, quota: string, units: number) => {

  try {

    const response = await axios.post(`${urls.URL}calculatesubs`,
    {
      invesment,
      quota,
      units,
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error: any) {
    throw error?.response.data;
  }

}

export const generateLead = async (
  name: string,
  email: string = '',
  investment: string,
  shares: number,
  numberInstallments: number,
  phone: string = '',
  termsAccepted: boolean = false
) => {

  try {

    const response = await axios.post(`${urls.URL}lead/simulator`,
    {
      name,
      email,
      investment,
      shares,
      numberInstallments,
      phone,
      termsAccepted
    },
    {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        "Content-Type": "multipart/form-data",
      },
    });

    return response;
  } catch (error: any) {
    throw error?.response.data;
  }

}

export const generateLeadInCRM = async (
  name: string,
  email: string = '',
  investment: string,
  shares: number,
  numberInstallments: number,
  phone: string = '',
  termsAccepted: boolean = false,
  leadOrigin: string = ''
) => {
  try {
    const date = new Date();
    const offset = date.getTimezoneOffset() * 60000;
    const localDate = new Date(date.getTime() - offset);
    const createdAt =  localDate.toISOString().replace('T', ' ').replace('Z', '')
    const response = await axios.post(`https://api-crm-4.onrender.com/simulation`,
    {
      name,
      email,
      investmentValue : investment,
      shares,
      numberInstallments,
      phone,
      leadOrigin,
      createdAt
    });
    
    return response;
  } catch (error: any) {
    throw error?.response.data;
  }

}

export const createContactInQuiiven = async (
  name: string,
  email: string = '',
  investment: string,
  shares: number,
  numberInstallments: number,
  phone: string = '',
  termsAccepted: boolean = false,
  leadOrigin: string = '',
  utmSource?: string,
  utmMedium?: string,
  utmCampaign?: string,
  utmTerm?: string,
  utmContent?: string
) => {
  try {
    const date = new Date();
    const offset = date.getTimezoneOffset() * 60000;
    const localDate = new Date(date.getTime() - offset);
    const createdAt =  localDate.toISOString().replace('T', ' ').replace('Z', '')
    const response = await axios.post(`https://hook.us1.make.com/b9p1fafcsyju5wsoo5tx9pbsw0s5niqb`,
    {
      name,
  email,
  investmentValue: investment,
  shares,
  numberInstallments,
  phone,
  leadOrigin,
  createdAt,
  utmSource,
  utmMedium,
  utmCampaign,
  utmTerm,
  utmContent
    });
    
    return response;
  } catch (error: any) {
    throw error?.response.data;
  }

}

export const saveSimulation = async (
  projectId: string,
  unitsQuantity: number,
  investmentValue: number,
  installments: number,
  termsAccepted: boolean,
  leadOrigin?: string,
  simulator ?: string,
  email ?: string,
  phone ?: string,
  countryCodePhone ?: string,
  name ?: string,
  utmSource?: string,
  utmMedium?: string,
  utmCampaign?: string,
  utmTerm?: string,
  utmContent?: string
) => {

  try {
    const response = await axios.post(`${urls.NEW_API_URL}simulator/saveSimulation`,
    {
      projectId,
      simulator ,
      unitsQuantity,
      email,
      phone,
      leadOrigin,
      countryCodePhone,
      investmentValue,
      installments,
      name,
      termsAccepted,
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent,
    });
    return response;
  } catch (error: any) {
    throw error?.response.data;
  }

}

interface webinarUrlResponse {
  name: string
}

export async function fetchWebinarURL(): Promise<webinarUrlResponse> {
  try {
    const response = await axios.get(`${urls?.URL}get-url-envivo`, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data.data;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to fetch webinar url');
  }
}

interface tokenUserInterface {
  id: string,
  email: string,
  exp: number,
  iat: number
}

export async function saveOwnerToWebinar() {
  
  try {

    const token = getToken(); 
  
    const user: tokenUserInterface = jwt_decode(token);
  
    const { data } = await getUserData(user.id);

    const date = moment(data?.data?.createdAt);

    const isToday = date.isSame(moment(), 'day');

    const isOlder = isToday === true ? 2 : 1

    const response = await axios.post(`${urls?.URL}save-register-webinar/${isOlder}`, {}, {
      headers: {
        Authorization: `Bearer ${getToken()}`,
        'Content-Type': 'application/json',
      },
    });
    return response.data.data;
  
  } catch (error) {
    console.error(error);
    throw new Error('Failed to save webinar register');
  }
}

interface unitPriceProjects {
    proyecto: object,
    unit_price: number[]
}

export async function fetchPriceUnits(): Promise<unitPriceProjects> {
  try {
    const response = await fetch("https://apidash.lokl.life/price_units");
    const data = await response.json();
    return data;
  } catch (error) {
    console.error(error);
    throw new Error('Failed to fetch unit price');
  }
}
