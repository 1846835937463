
export const getDateInfo = (date: string): string => {
    const parsedDate = new Date(date);

    if (isNaN(parsedDate.getTime())) {
        return "Fecha inválida";
    }

    const month = parsedDate.getMonth() + 1;
    const year = parsedDate.getFullYear();

    let quarter: string;

    if (month >= 1 && month <= 3) {
        quarter = "Primer Trimestre";
    } else if (month >= 4 && month <= 6) {
        quarter = "Segundo Trimestre";
    } else if (month >= 7 && month <= 9) {
        quarter = "Tercer Trimestre";
    } else {
        quarter = "Cuarto Trimestre";
    }

    return `${quarter} del ${year}`;
};

export const TimeLineItem = ({ timelineIcon, description, date, lastOne }: { timelineIcon: string, description: string, date?: string, lastOne: boolean }) => {
    const quarter = getDateInfo(date || "");

    return (
        <>
            {
                lastOne ?

                    <div className="w-48">
                        <div className="flex justify-center items-end h-20">
                            <svg width="23" height="60" viewBox="0 0 23 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12 0L0 31.5H23L12 0Z" fill="#514E4E" />
                                <rect x="10.5" y="54.5" width="3" height="5" fill="#514E4E" />
                                <path d="M4 29.5L12 58L19 30" stroke="#514E4E" />
                            </svg>
                        </div>
                        <div className="border-t-2 flex flex-col justify-center text-center">
                            <p className="px-8 mt-3 font-bold primaryFont">
                                {description}
                            </p>
                            <p className="px-8 text-sm secondaryFont">
                                {quarter}
                            </p>
                        </div>
                    </div>
                    :
                    <div className="w-48">
                        <div className="flex justify-center items-end h-20">
                            <img src={timelineIcon} width="25" alt="" />
                        </div>
                        <div className="border-t-2 flex flex-col justify-center text-center">
                            <p className="px-8 mt-3 font-bold primaryFont">
                                {description}
                            </p>
                            <p className="px-8 text-sm secondaryFont">
                                {quarter}
                            </p>
                        </div>
                    </div>}
        </>
    );
};
