import { useEffect, useState } from 'react'
import jwt_decode from "jwt-decode";

export const useAuth = () => {

  const [isAuth, setIsAuth] = useState(false)

  useEffect(() => {
    checkAuth()
  }, [])

  const checkAuth = () => {
    const token = localStorage.getItem("token");
    if (token !== null){
      const decoded: any = jwt_decode(token);
      const dateNow = new Date();
      if(decoded.exp * 1000 < dateNow.getTime()){
        setIsAuth(false);
      }else{
        setIsAuth(true);
      }
    }else{
      setIsAuth(false);
    }
  }

  return {
    isAuth
  }
}
