import React from 'react'
import banner from '../../../../assets/img/checkout/banner-complete.png';
import { numberWithCommas } from '../../../../helpers/functions';



export const Banner = ({ investUnitAmount, unitValue }: { investUnitAmount: string, unitValue: number }) => {
  const getNextLevel = (investUnitAmount: string) => {
    try {
      const investUnitAmountNumber = parseFloat(investUnitAmount);
      if (investUnitAmountNumber < 100) {
        return unitValue * (100 - investUnitAmountNumber)
      } else if (investUnitAmountNumber < 200) {
        return unitValue * (200 - investUnitAmountNumber)
      } else if (investUnitAmountNumber < 500) {
        return unitValue * (500 - investUnitAmountNumber)
      }
    } catch (error) {
      console.error(error);
    }
    return 0
  }
  return (
    <div className="rounded-lg relative overflow-hidden">
      <div>
        <img src={banner} alt="Banner" />
      </div>
      <div className="absolute top-1/2 mx-10 -translate-y-1/2 text-white">
        {parseFloat(investUnitAmount) < 500 ?
          <p className="text-xl font-bold">Estás a un paso <br /> de obtener más beneficios <br /> invirtiendo <span className="text-[#FFA8E1]">${numberWithCommas(getNextLevel(investUnitAmount))}</span></p> :
          <p className="text-xl font-bold">Disfruta de   <br /> los beneficios de<br /> ser inversionista tipo <span className="text-[#FFA8E1]">Héroe</span></p>
        }
      </div>
    </div>

  )
}
