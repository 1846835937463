import { useState } from 'react';
import shareCode from '../../../assets/img/dashboard/shareCode.svg';
import ModalShareCodeUnit from '../../../components/PopUpAmbassadors/ModalShareCodeUnits';

interface ReferCardInterface {
    code: string
}

const ReferCodeCard = ({ code }: ReferCardInterface) => {
    const [isOpen, setIsOpen] = useState(false)
    return (
        <>
            <div className="w-56 border-b-[1px] border-gray-300 mt-4 mb-1"></div>

            <div className="text-center">

                <div className="font-roboto text-[22px] font-black text-black">{code}</div>
                <div className="font-roboto">Tu código de referidos</div>

                <div className="flex justify-center items-center text-sm mt-2 py-2 px-1 border-[1px] border-black rounded-full cursor-pointer"
                    onClick={() => setIsOpen(true)}
                >
                    <img className='mr-2 font-roboto' src={shareCode} alt="share code icon" />
                    <div className="">Compartir</div>

                </div>
                {isOpen && <ModalShareCodeUnit setIsOpen={setIsOpen}/>}

            </div>

        </>
    )
}

export default ReferCodeCard
