import { ConstructionStatusCard, LaunchStatusCard, UnitIncrementStatusCard } from "./"
import { OperatingStatusCard } from "./"

interface ProjectStatusCardInterface {
    phase: string
}

export const ProjectStatusCard: React.FC<ProjectStatusCardInterface> = ({ phase }) => {

    return (
        <div>
            {
                phase === 'Etapa 0' ?
                    <LaunchStatusCard /> :
                    phase === 'Etapa 2' ?
                        <ConstructionStatusCard /> :
                        phase === 'Etapa 3' ?
                            <OperatingStatusCard /> :
                            phase === 'unitincrement' ?
                                <UnitIncrementStatusCard /> :
                                null
            }
        </div>
    )
}
