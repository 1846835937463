import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Swiper as SwiperType, Autoplay, Pagination } from "swiper";

import "swiper/css";
import "swiper/css/pagination";
import "../../NidoDeAgua/aboutInvestment.scss";

interface AboutInvetmentProps {
  icons?: Array<{ description: string; link: string; type: string }>;
}



export const AboutInvestment: React.FC<AboutInvetmentProps> = ({ icons }) => {
  const swiperRef = useRef<SwiperType>();

  return (
    <div style={{ position: "relative" }} className="about-investment-swiper md:px-10">
      <Swiper
        breakpoints={{
          640: {
            slidesPerView: 1.6,
            slidesPerGroup: 1.6,
            spaceBetween: 5,
          },
          768: {
            slidesPerView: 2,
            slidesPerGroup: 2,
            spaceBetween: 10,
          },
          1024: {
            slidesPerView: 3,
            slidesPerGroup: 3,
            spaceBetween: 10,
          },
        }}
        centeredSlides={false}
        loop={true}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
        }}
        slidesPerView={1.6}
        spaceBetween={5}
        slidesPerGroupSkip={0}
        loopFillGroupWithBlank={true}
        
        modules={[Autoplay, Pagination]}
        pagination={true}
        onBeforeInit={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
       {icons?.map((icon, index) => (
    <SwiperSlide className="logo" key={index}>
        <div className="flex border rounded-2xl h-36 items-center justify-center px-4 mx-px">
            <div className="flex justify-center items-start">
                <div className="flex-shrink-0 basis-7 mr-3">
                    
                        <img src={icon.link} className="w-full" alt={icon.type} />
                    
                </div>
                <div className="w-full">
                    <p className=" text-[13px] secondaryFont">*{icon.description}</p>
                </div>
            </div>
        </div>
    </SwiperSlide>
)) ?? []}

      </Swiper>

      <div className="hidden md:block">
        <button
          onClick={() => swiperRef.current?.slidePrev()}
          className="absolute top-[40%] left-0 translate-y-[-50%] rotate-180 z-30"
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5007 22.9166C18.2538 22.9166 22.9173 18.253 22.9173 12.4999C22.9173 6.74679 18.2538 2.08325 12.5007 2.08325C6.74753 2.08325 2.08398 6.74679 2.08398 12.4999C2.08398 18.253 6.74753 22.9166 12.5007 22.9166Z"
              stroke="#656565"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.1875 16.1772L14.8542 12.5001L11.1875 8.823"
              stroke="#656565"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
        <button
          onClick={() => swiperRef.current?.slideNext()}
          className="absolute top-[40%] right-0 translate-y-[-50%] z-30"
        >
          <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.5007 22.9166C18.2538 22.9166 22.9173 18.253 22.9173 12.4999C22.9173 6.74679 18.2538 2.08325 12.5007 2.08325C6.74753 2.08325 2.08398 6.74679 2.08398 12.4999C2.08398 18.253 6.74753 22.9166 12.5007 22.9166Z"
              stroke="#656565"
              strokeWidth="1.5"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.1875 16.1772L14.8542 12.5001L11.1875 8.823"
              stroke="#656565"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};
