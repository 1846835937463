import React, { useEffect, useRef, useState } from 'react';
import AllyIconCard from '../components/componentsSupplierbar/AllyIconCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';


import iconForbes from '../../../assets/img/about-us/img-logo-forbes.png';
import iconWompi from '../../../assets/img/about-us/img-wompi.png';
import iconZapsing from '../../../assets/img/about-us/img-zapsign.png';
import iconReload from '../../../assets/img/about-us/img-reload-logo.png';
import iconTruora from '../../../assets/img/about-us/img-truora.png';
import iconAbcyd from '../../../assets/img/about-us/img-abd&c.png';
import iconCredicorp from '../../../assets/img/about-us/img-credicorp.png';
import iconBancolombia from '../../../assets/img/about-us/img-bancolombia.png';
import iconLegalnova from '../../../assets/img/about-us/img-legalnova.png';
import iconStartup from '../../../assets/img/about-us/img-startup.png';
import iconRockstart from '../../../assets/img/about-us/img-rockstartblanco.png';


import { Autoplay } from 'swiper';
import { motion } from 'framer-motion';

const icons = [
  { icon: iconForbes, alt: 'Forbes' },
  { icon: iconWompi, alt: 'Wompi' },
  { icon: iconZapsing, alt: 'La Repubblica' },
  { icon: iconTruora, alt: 'Truora' },
  { icon: iconAbcyd, alt: 'ABCyD' },
  { icon: iconCredicorp, alt: 'Credicorp' },
  { icon: iconBancolombia, alt: 'Bancolombia' },
  { icon: iconLegalnova, alt: 'Legalnova' },
  { icon: iconStartup, alt: 'startup' },
  { icon: iconRockstart, alt: 'rockstart' },
  { icon: iconReload, alt: 'Reload' },
];

export const Supplierbar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const iconRefs = useRef<(HTMLDivElement | null)[]>([]); 

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting && !isVisible) {
        setIsVisible(true);
        observer.unobserve(entry.target);
      }
    });

    iconRefs.current.forEach((icon) => {
      if (icon) {
        observer.observe(icon);
      }
    });

    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      iconRefs.current.forEach((icon) => {
        if (icon) {
          observer.unobserve(icon);
        }
      });
    };
  }, [isVisible]);

  return (
    <div className="bg-[#4F4CF1] py-4 w-full text-center" style={{ height: '180px' }}>
      <h3 className="text-white text-center text-xl secondaryFont font-normal mb-4">
        Nuestros aliados y proveedores
      </h3>

      <Swiper
        spaceBetween={0}
        slidesPerView={5}
        pagination={{ clickable: true }}
        scrollbar={{ draggable: true }}
        loop={true}
        className="flex relative items-center justify-center "
        autoplay={{ delay: 2500, disableOnInteraction: false }}
        modules={[Autoplay]}
        breakpoints={{
          425: { slidesPerView: 4, spaceBetween: 0 }, 
          768: { slidesPerView: 3, spaceBetween: 0 },
          1024: { slidesPerView: 5, spaceBetween: 10 }
        }}
      >
        {icons.map(({ icon, alt }, index) => (
          <SwiperSlide key={index}>
            <motion.div
                ref={el => iconRefs.current[index] = el}
                initial={{ opacity: 0, y: 25 }}
                animate={isVisible ? { opacity: 1, y: 0 } : {}}
                transition={{ duration: 0.3, delay: index * 0.1 }}
              >
            <div className="flex items-center justify-center">
              
                <AllyIconCard className="relative md:mx-0" icon={icon} alt={alt} />
                
              
              
              {index < icons.length  && (
                <span className="flex text-white text-xl translate-x-5 md:ml-7 md:text-6xl  ">•</span>
              )}
            </div>
            </motion.div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
