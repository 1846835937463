import React from "react";

interface Option {
  label: string;
  value: string;
}

interface RadioButtonProps {
  id: string;
  name: string;
  label: string;
  value: string;
  checked: boolean;
  onChange: (value: string) => void;
  options?: Option[];
  disabled?: boolean;

}


export const RadioButtonComponent: React.FC<RadioButtonProps> = ({
  id,
  name,
  label,
  value,
  checked,
  onChange,
  options,
  disabled = false,
}) => {
  return (
    <div className="flex flex-row items-center space-x-2 mb-2">
  <input
    type="radio"
    id={id}
    name={name}
    value={value}
    checked={checked}
    onChange={() => onChange(value)}
    disabled={disabled}
    className={`h-4 w-4 text-indigo-600 border-gray-300 focus:ring-indigo-500 ${
      disabled ? "cursor-not-allowed opacity-50" : ""
    }`}
  />
  <label
    htmlFor={id}
    className={`text-sm font-medium text-gray-700 ${
      disabled ? "text-gray-400" : ""
    }`}
  >
    {label}
  </label>
</div>

  );
};
