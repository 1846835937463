import { useState } from 'react'
import "./dashboard.scss";
import profile from '../../assets/img/dashboard/lokl-profile.svg';
import jwt_decode from "jwt-decode";
import { Outlet } from 'react-router-dom';
import { on } from '../../helpers/events';
import { getUserData } from '../../services/ownerServices';
import { checkSessionStorageToken, isAuthenticated } from '../../helpers/functions';
import { useEffectOnce } from '../../helpers/hooks';
import CircularBar from '../../components/CircularBar';
import SideList from './components/SideList';
import ReferCodeCard from './components/ReferCodeCard';
import useTitle, { useDescription } from '../../hooks/useSEO';




const InvestorDashboard = () => {
    useTitle('Dashboard Inversionista | Lokl');
    useDescription('Dashboard de usuario en Lokl, la plataforma de inversión inmobiliaria en México. Invierte en propiedades de forma segura y sencilla.');
    const [name, setName] = useState('');
    const [code, setCode] = useState('');
    const [profileCompleted, setProfileCompleted] = useState(false);
    const [isInvestor, setIsInvestor] = useState(false);
    

    const getData = async (id: string) => {
        const { data } = await getUserData();
        const userName = data.data.firstName + ' ' + data.data.lastName;
        const userCode = (data.data.uniqueCode).toUpperCase();
        sessionStorage.setItem('name', userName.split(" ")[0].toLowerCase());
        setName(userName);
        setCode(userCode);

        // TODO: Define user profile completion
        setProfileCompleted(false);

    }

    useEffectOnce(() => {

        isAuthenticated();

        localStorage.removeItem('fromDash');
        const token: string = localStorage.getItem("token") || '';
        const decoded: any = jwt_decode(token);

        on('reload:dashboard', () => {
            getData(decoded.id)
        });

        if (checkSessionStorageToken()) {
            getData(decoded.id);
        }

    });

    return (
        <div className="lg:px-8 pt-20 md:pt-28 pb-44 min-h-screen flex justify-center lg:justify-start bg-[#EFEFEF]">

            <div className="hidden lg:flex w-[270px] min-h-[600px] py-[40px] flex-col justify-between items-center rounded-lg mr-2.5 bg-white">

                <div className="flex flex-col justify-start items-center">

                    <div className="mb-[20px] flex flex-col justify-center items-center">

                        <div className="relative mb-3">

                            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center">
                                <img className=" rounded-full bg-white" width={100} height={100} src={profile} alt="profile" />
                            </div>

                            <CircularBar percentage={profileCompleted === true ? 100 : 70} />

                        </div>

                        <div className="text-[13px] font-medium text-gray-500">Bienvenido de nuevo</div>
                        <div className="text-[22px] font-roboto font-black px-9 text-center capitalize">{name}</div>

                        {
                            profileCompleted === false &&
                            <div className="mt-3">
                                <a href="https://docs.google.com/forms/d/e/1FAIpQLSe9xNLnx-Qp120FtnjMYn9_PQchzsoNo__jOkPAS8lAEL_s3w/viewform" target="_blank" rel="noopener noreferrer" className='bg-[#D8D1FF] px-4 py-2 font-roboto font-extrabold rounded-full text-sm'>
                                Completar perfil
                                </a>
                            </div>
                        }

                        {code && <ReferCodeCard code={code}  />}

                        <div className="w-56 border-b-[1px] border-gray-300 my-3"></div>

                    </div>

                    <div>

                    </div>
                    <SideList isInvestor={isInvestor} />

                </div>

            </div>

            <div className="w-[94%] lg:w-[80%] h-5/6 2xl:w-[80%] relative">

                

                <div className="w-full flex flex-col lg:ml-4 ">

                    <Outlet context={{ isInvestor, setIsInvestor }} />

                </div>

            </div>

        </div>
    )
}

export default InvestorDashboard;
