import React from "react";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center">
      <div className="bg-white p-6 rounded-lg w-11/12 sm:w-1/3 md:w-1/4 max-w-[400px]">
        <h2 className="text-xl font-semibold text-center">¡Atención!</h2>
        <p className="text-center mt-4 text-sm sm:text-base">
          Estamos realizando algunos ajustes. En este momento no es posible
          solicitar retiros de dinero mientras mejoramos la plataforma. ¡Gracias
          por tu paciencia y comprensión!
        </p>
        <div className="flex justify-center mt-4">
          <button
            className="bg-[#6232EB] text-white py-2 px-6 rounded-full"
            onClick={onClose}
          >
            Cerrar
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
