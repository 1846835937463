import React from 'react'
import { ProjectCardDataInterface } from '../../interfaces/ProjectCardDataInterface'
import { AmenitiesCard, ProjectCapacity, ProjectInfoTopCard, ProjectTitleCard, VideoCard } from './'
import { formatNumberWithCommas } from "../../../../utils"
import { useNavigate ,  } from 'react-router-dom';


interface ProjectCardInterface {
    projectCard: ProjectCardDataInterface
}

export const ProjectCard: React.FC<ProjectCardInterface> = ({ projectCard }) => {
    
    const navigate = useNavigate();

    const handleProject = () => {
        
        if (projectCard.phase !== "Etapa 0" && projectCard.phase !== null) {
          navigate(`/project/${projectCard.projectCode}`);
        } else {
          navigate(`/project-signup/${projectCard.projectCode}`);
        }
      };
      

    return (
<div id='projectCardID' className="flex-shrink-0 w-full lg:w-1/3 pb-4 px-4">
    <div className="h-full flex flex-col justify-between bg-white rounded-3xl pb-4">
        <VideoCard key={projectCard.id} phase={projectCard.phase} name={projectCard.name} imageURL={projectCard.imageURL} videoURL={projectCard.videoURL} />
        <ProjectInfoTopCard maxRent={projectCard.maxRent} minRent={projectCard.minRent} partners={projectCard.partners} unitPrice={projectCard.unitPrice} />
        <div className="px-[8%] flex-grow flex flex-col justify-between">
            <div>
                <ProjectTitleCard title={projectCard.name} availableSpots={projectCard.availableSpots} totalSpots={projectCard.totalSpots} city={projectCard.city} country={projectCard.country} />
                <div className="flex flex-wrap mt-2">
                    <AmenitiesCard amenitie={`${projectCard.accommodations} Alojamientos`} />
                    <AmenitiesCard amenitie={`${projectCard.squareMeters} m²`} />
                    {projectCard.amenities.map((amenity) => (
                        <AmenitiesCard key={amenity} amenitie={amenity} />
                    ))}
                </div>
                <div className="flex flex-col md:flex-row mt-2">
                    <span className="primaryFont text-[base] md:text-[base] lg:text-[0.8vw] font-extrabold">${formatNumberWithCommas(projectCard.minInvestmentUnits * projectCard.unitPrice)}</span>
                    <p className="primaryFont ml-2 text-[base] md:text-[base] lg:text-[0.8vw] text-[#3533FF]"> Cupo de inversión hasta en  <strong> {projectCard.maxInvestmentQuota} {projectCard.maxInvestmentQuota === 1 ? 'Cuota' : 'Cuotas'}</strong></p>
                </div>
            </div>
            <div className="flex flex-col md:flex-row mt-3 justify-center items-center md:items-end">
                <div className="md:hidden">
                    <ProjectCapacity availableSpots={projectCard.availableSpots} totalSpots={projectCard.totalSpots} />
                </div>
                <button onClick={handleProject} className="mt-6 md:mt-0 w-full md:w-fit primaryFont h-fit bg-black rounded-full text-white font-semibold px-4 py-2.5 text-base">
                    Quiero conocer más
                </button>
            </div>
        </div>
    </div>
</div>

    )
}
