import React from 'react';
import { GoogleMapsReviewModel } from "../interfaces/GoogleMapsReviewModel";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Pagination, Autoplay } from 'swiper';
import { motion } from 'framer-motion';
import { GoogleMapsReview } from '../components';

export const GoogleMapsReviews = ({ reviews }: { reviews: GoogleMapsReviewModel[] }) => {
    return (
        <div className='w-full mt-20'>
            <h1 className="text-[#9ca3af] primaryFont font-bold text-2xl mb-5 text-center">
                Comentarios de GoogleMaps
            </h1>
            <Swiper
                slidesPerView={1}
                spaceBetween={10}
                pagination={{ clickable: true, el: '.custom-pagination', bulletClass: 'swiper-pagination-bullet gray-bullet' }}
                autoplay={{ delay: 4500, disableOnInteraction: false }}
                loop={true}
                modules={[Pagination, Autoplay]}
                className="mySwiper"
                breakpoints={{
                    640: { slidesPerView: 1, spaceBetween: 50 },
                    768: { slidesPerView: 1, spaceBetween: 10 },
                    1030: { slidesPerView: 2, spaceBetween: 100 },
                }}
            >
                {reviews.map((review, index) => (
                    <SwiperSlide key={index}>
                        <motion.div
                            initial={{ opacity: 0, scale: 0.9 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition={{ duration: 4, delay: 0.5 }}
                        >
                            <GoogleMapsReview review={review} index={index} />
                        </motion.div>
                    </SwiperSlide>
                ))}
            </Swiper>
        </div>
    );
};
