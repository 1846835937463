import React, { useEffect } from 'react';
import { scrollToSection } from '../helpers/functions';

type MenuItemForMenu = {
  name: string,
  id?: string
}

type MenuProps = {
  menuItems: MenuItemForMenu[],
  setContent?: (item: string) => void,
  scroll?: boolean
  activeItem?: any
  setActiveItem?: any
}

const Menu: React.FC<MenuProps> = ({ menuItems, setContent, scroll, activeItem, setActiveItem }) => {
  const handleItemClick = (item: MenuItemForMenu) => {
    setActiveItem(item);
    if(scroll === true){
      scrollToSection(item.id || '', 160);
    }
  };

  useEffect(() => {
    if(typeof activeItem.name === "string" && setContent){
      setContent(activeItem.name)
    }
  }, [activeItem, setContent])

  return (
    <div className="flex overflow-x-auto whitespace-nowrap">
      <div className="flex-auto items-start justify-around space-x-10">
        {menuItems.map((item, key) => (
          <MenuItem
            key={key}
            label={item.name}
            isActive={activeItem.name === item.name}
            onItemClick={() => handleItemClick(item)}
          />
        ))}
      </div>
    </div>
  );
};

type MenuItemProps = {
  label: string;
  isActive: boolean;
  onItemClick: (item: string) => void;
};

const MenuItem: React.FC<MenuItemProps> = ({ label, isActive, onItemClick }) => {
  const handleClick = () => {
    onItemClick(label);
  };

  const itemClassName = isActive && 'border-b-4 border-blue-500 text-blue-500'

  return (
    <div
      className={`${itemClassName} inline-block cursor-pointer pb-2 px-2 font-roboto font-normal text-lg`}
      onClick={handleClick}
    >
      {label}
    </div>
  );
};

export default Menu;
