import { ContractList, ReportList } from "./ProfileDetail";
import { motion } from "framer-motion";

const ProfileDetailDashboard = () => {
    const containerVariants = {
        hidden: { opacity: 0, y: 40 },
        visible: { opacity: 1, y: 0, transition: { duration: 0.5 } }
    };

    return (
        <div className="flex flex-col md:flex-row">
            
            <motion.div
                initial="hidden"
                animate="visible"
                variants={containerVariants}
                
            >
                <ReportList />
            </motion.div>

            
        <ContractList />
          
        </div>
    );
};

export default ProfileDetailDashboard;
