import axios from "axios";
import { urls } from "../helpers/urls";

export const sendLead = async (data: any) => {
    try {
        const response = await axios.post(`${urls.NEW_API_URL}/lead/enrollment`, data);
        return response.data; 
    } catch (error: any) {
        console.error("❌ Error al enviar lead:", error.response?.data || error.message);      
        return {
            success: false,
            message: error.response?.data?.message || "Ocurrió un error al enviar los datos."
        };
    }
};
