import { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { initialData } from "../../Pages/NidoDeAgua/Simulator";
import { SimulationResponseInterface } from "./simulationInterface";
import { options, specialPercentageFormat } from "./SimulatorUtils";

interface ChartProps {
    simulationResponse: SimulationResponseInterface
}
type BorderRadius = {
    topLeft: number;
    topRight: number;
    bottomLeft: number;
    bottomRight: number;
};
const barsFormat = (utilidadesData: number[], valorizacionData: number[], type: string): BorderRadius[] => {
    const borders: BorderRadius[] = []
    for (let i = 0; i < utilidadesData.length; i++) {
        if (type === 'valorizacion') {
            if (valorizacionData[i] > 0 && utilidadesData[i] > 0) borders.push({ topLeft: 0, topRight: 0, bottomLeft: 1000, bottomRight: 1000 })
            else borders.push({ topLeft: 1000, topRight: 1000, bottomLeft: 1000, bottomRight: 1000 })
        } else {
            if (valorizacionData[i] > 0 && utilidadesData[i] <= 0) borders.push({ topLeft: 1000, topRight: 1000, bottomLeft: 1000, bottomRight: 1000 })
            else borders.push({ topLeft: 1000, topRight: 1000, bottomLeft: 0, bottomRight: 0 })
        }
    }
    return borders
};
const YEARS = ['Año 1', 'Año 2', 'Año 3', 'Año 4', 'Año 5']

export const SimulatorChart: React.FC<ChartProps> = ({ simulationResponse }) => {
    const [data, setData] = useState<any>(initialData);

    useEffect(() => {
        if (initialData) {
            const utilidadesData = simulationResponse.porcentageIncomeYears.map((value) =>
                specialPercentageFormat(value)
            );
            const valorizacionData = simulationResponse.percentageValorizationYears.map((value) =>
                specialPercentageFormat(value)
            );

            const test = {
                labels: YEARS,
                datasets: [
                    {
                        label: 'Valorización',
                        barThickness: 20,
                        data: valorizacionData,
                        backgroundColor: '#4CF2C3',
                        borderColor: '#0401DD',
                        borderWidth: 0,
                        borderSkipped: false,
                        borderRadius: barsFormat(utilidadesData, valorizacionData, 'valorizacion'),
                        order: 1,

                        categoryPercentage: 1.0, // Hace que las barras ocupen todo el ancho disponible para cada categoría
                        barPercentage: 1.0,
                    },
                    {
                        label: 'Utilidades',
                        barThickness: 20,
                        data: utilidadesData,
                        backgroundColor: '#8972FF',
                        borderColor: '#0401DD',
                        borderWidth: 0,
                        borderSkipped: false,
                        borderRadius: barsFormat(utilidadesData, valorizacionData, 'utilidades'),
                        order: 2,
                        categoryPercentage: 1.0, // Hace que las barras ocupen todo el ancho disponible para cada categoría
                        barPercentage: 1.0,
                    },
                ],
            };
            setData(test);
        }
    }, [simulationResponse.percentageValorizationYears, simulationResponse.porcentageIncomeYears]);

    return (
        <div className="w-full h-52">
            <Bar options={options} data={data} />
            <div className="px-[30px] mt-[-1px] opacity-100">
                <hr style={{ borderWidth: '2px' }} />
            </div>
        </div>
    );
};
