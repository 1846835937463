import React from "react";

type Task = {
  name: string;
  type: string;
  plannedStartDate: string;
  plannedEndDate: string;
  realStartDate: string;
  realEndDate: string;
};

interface GanttChartProps {
  tasks: Task[];
}

export const GanttChart: React.FC<GanttChartProps> = ({ tasks }) => {
  const monthNames = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  const getMinMaxDates = () => {
    const dates = tasks.reduce(
      (acc, task) => {
        const startDate = task.plannedStartDate.split(" ")[0];
        const endDate = task.plannedEndDate.split(" ")[0];
        if (startDate < acc.minDate) acc.minDate = startDate;
        if (endDate > acc.maxDate) acc.maxDate = endDate;
        return acc;
      },
      { minDate: "9999-12-31", maxDate: "0000-01-01" }
    );
    return dates;
  };

  const { minDate, maxDate } = getMinMaxDates();

  const generateMonthlyRange = () => {
    const dates = [];
    let currentDate = minDate;
    while (currentDate <= maxDate) {
      dates.push(currentDate);
      const [year, month] = currentDate.split("-");
      const nextMonth = (parseInt(month, 10) % 12) + 1;
      const nextYear = nextMonth === 1 ? parseInt(year, 10) + 1 : year;
      currentDate = `${nextYear}-${String(nextMonth).padStart(2, "0")}-01`;
    }
    return dates;
  };

  const monthlyRange = generateMonthlyRange();

  const calculateMonthIndex = (date: string) => {
    const [taskYear, taskMonth] = date.split("-").map((part) => parseInt(part, 10));
    const [minYear, minMonth] = minDate.split("-").map((part) => parseInt(part, 10));

    return (taskYear - minYear) * 12 + (taskMonth - minMonth);
  };

  const groupedTasks = tasks.reduce((acc, task) => {
    if (!acc[task.type]) {
      acc[task.type] = [];
    }
    acc[task.type].push(task);
    return acc;
  }, {} as Record<string, Task[]>);

  const getBarColor = (task: Task): string => {
    
    if (task.realEndDate) {
      const plannedEnd = new Date(task.plannedEndDate.split(" ")[0]).getTime();
      const realEnd = new Date(task.realEndDate.split(" ")[0]).getTime(); 
  
      if (realEnd > plannedEnd) return "#EF4444"; 
      if (realEnd === plannedEnd) return "#10B981"; 
      return "#10B981"; 
    }
  
    
    const plannedEnd = new Date(task.plannedEndDate.split(" ")[0]).getTime();
    const currentDate = new Date().getTime(); 
  
    
    if (currentDate > plannedEnd) return "#EF4444"; 
  
    
    return "#3B82F6"; 
  };
  
  

  return (
    <section className="block bg-white rounded-lg px-0 md:px-4">
      <div className="w-[90%] ml-6 md:w-auto z-10 pt-6 flex justify-between items-start">
        <div className="flex flex-col">
          <h2 className="primaryFont text-4xl font-bold text-black md:text-left z-10">
            Cronograma del proyecto
          </h2>
          <p className="secondaryFont text-xs mt-1 mb-4">
            Las fechas mostradas son tentativas y están sujetas a cambios según las condiciones del proyecto.
            Podrían ocurrir retrasos en la operación.
          </p>
        </div>

        <div className="flex flex-col md:flex-row md:gap-4 text-xs mt-[10px] ml-6 mr-6">
          <div className="flex items-center mb-2">
            <span className="w-4 h-4 bg-gray-400 mr-2"></span>
            <span>Planeado</span>
          </div>
          <div className="flex items-center mb-2">
            <span className="w-4 h-4 bg-[#3B82F6] mr-2"></span>
            <span>Progreso</span>
          </div>
          <div className="flex items-center mb-2">
            <span className="w-4 h-4 bg-green-500 mr-2"></span>
            <span>Finalizado</span>
          </div>

          <div className="flex items-center mb-2">
            <span className="w-4 h-4 bg-red-500 mr-2"></span>
            <span>Retrasado</span>
          </div>
        </div>
      </div>

      <div className="max-w-full block rounded-lg shadow-md md:max-h-[600px]">
        <div className="flex">
          <div className="flex-shrink-0 sticky left-0 secondaryFont bg-white z-20 hidden md:block w-[200px] border-r border-gray-300">
            <div className="block items-center text-center secondaryFont h-[40px] text-xs md:text-lg font-medium text-gray-700 bg-gray-100 pt-2">
              Actividades
            </div>
            {Object.keys(groupedTasks).map((type) => {
              const taskCount = groupedTasks[type].length;
              const containerHeight = taskCount * 40;

              return (
                <div key={type} className="relative">
                  <div
                    className="absolute top-0 left-0 flex secondaryFont border-b items-center justify-center text-sm font-semibold text-gray-600 bg-gray-200 px-2 border-gray-600"
                    style={{
                      height: `${containerHeight}px`,
                      writingMode: "vertical-rl",
                      textOrientation: "upright",
                    }}
                  >
                    {type}
                  </div>
                  <div className="pl-16">
                    {groupedTasks[type].map((task) => (
                      <div
                        key={task.name}
                        className="h-[40px] text-xs sm:text-sm text-gray-700 border-b border-gray-400 bg-white flex items-center justify-start"
                      >
                        <span className="w-full secondaryFont">{task.name}</span>
                      </div>
                    ))}
                  </div>
                </div>
                
              );
              
            })}
            
          </div>
          <div className="flex-shrink-0 sticky top-0 left-0 secondaryFont bg-white z-20 block md:hidden w-[50px] border-r border-gray-300 rounded-l-lg">
                
                <div className="block items-center text-center secondaryFont h-[40px] text-xs md:text-lg font-medium text-gray-700 bg-gray-100 pt-2 rounded-l-lg">
                  Tipo
                </div>
                
                {Object.keys(groupedTasks).map((type) => {
                  const taskCount = groupedTasks[type].length; 
                  const containerHeight = taskCount * 40; 

                  return (
                    <div
                      key={type}
                      className="relative flex justify-center items-center w-full text-sm font-semibold text-gray-600 bg-gray-200 px-2 border-b border-gray-600"
                      style={{
                        height: `${containerHeight}px`, 
                        writingMode: "vertical-rl", 
                        textOrientation: "upright", 
                      }}
                    >
                      {type}
                    </div>
                  );
                })}
              </div>

          <div className="flex flex-col flex-grow bg-white overflow-x-auto rounded-lg">
            <div className="flex border-b">
              {monthlyRange.map((date, index) => {
                const [year, month] = date.split("-");
                return (
                  <div
                    key={index}
                    className="h-[40px] text-xs sm:text-sm pt-1 font-medium text-gray-500 border text-center"
                    style={{ width: "60px", minWidth: "60px" }}
                  >
                    <div className="flex flex-col pb-1 items-center">
                      <span className="block numberFont font-bold">{monthNames[parseInt(month, 10) - 1]}</span>
                      <span className="block numberFont text-sm">{year}</span>
                    </div>
                  </div>
                );
              })}
            </div>

            {Object.keys(groupedTasks).map((type) => (
              <div key={type}>
                {groupedTasks[type].map((task, taskIndex) => {
                  const startIndexPlanned = calculateMonthIndex(task.plannedStartDate.split(" ")[0]);
                  const endIndexPlanned = calculateMonthIndex(task.plannedEndDate.split(" ")[0]);

                  const startIndexReal = task.realStartDate ? calculateMonthIndex(task.realStartDate.split(" ")[0]) : null;
                  const endIndexReal = task.realEndDate ? calculateMonthIndex(task.realEndDate.split(" ")[0]) : calculateMonthIndex(new Date().toISOString().split("T")[0]);

                  const colorPlanned = "#D1D5DB"; 

                  return (
                    <div key={task.name} className="flex relative items-center rounded-lg">
                      {monthlyRange.map((_, index) => (
                        <div
                          key={index}
                          className="h-[40px] border-b border-gray-300"
                          style={{ width: "60px", minWidth: "60px" }}
                        ></div>
                      ))}

                      <div
                        className="absolute h-[30px] rounded-lg"
                        style={{
                          left: `${startIndexPlanned * 60}px`,
                          width: `${(endIndexPlanned - startIndexPlanned + 1) * 60}px`,
                          backgroundColor: `${colorPlanned}40`,
                        }}
                      >
                        <div
                          className="h-full rounded-lg"
                          style={{
                            backgroundColor: colorPlanned,
                          }}
                        ></div>
                         <div className="absolute secondaryFont z-20 md:hidden top-0 left-1/2 transform -translate-x-1/2 h-full flex flex-col justify-center items-center text-xl text-white pr-2">
                            <span>{task.name}</span>
                          </div>
                      </div>
                      
                      {startIndexReal !== null && endIndexReal !== null && (
                        <>
                          <div
                            className="absolute h-[20px] rounded-lg"
                            style={{
                              top: "10px",
                              left: `${startIndexReal * 60}px`,
                              width: `${(endIndexReal - startIndexReal + 1) * 60}px`,
                              backgroundColor: getBarColor(task),
                            }}
                          ></div>
                         
                        </>
                      )}
                    </div>
                    
                  );
                })}
                
              </div>
              
            ))}
          </div>
          
        </div>
      </div>
    </section>
  );
};
