import React from "react";

interface CheckboxProps {
  id: string;
  label: string;
  checked: boolean;
  imgurl: string;
  name: string;
  onChange: (checked: boolean) => void;
}

export const CheckboxComponent: React.FC<CheckboxProps> = ({ id, label, name, imgurl, checked, onChange }) => {
  return (
    <div 
      className={`cursor-pointer border-2 rounded-lg flex flex-col items-center justify-between text-center transition-all duration-300 ${
        checked ? "border-blue-600 bg-blue-100 shadow-md" : "border-gray-300 bg-white"
      }`}
      onClick={() => onChange(!checked)} 
      style={{ width: '150px', height: '150px', display: 'flex', flexDirection: 'column' }}
    >
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={(e) => onChange(e.target.checked)}
        className="hidden" 
      />
      
      
      <div className="w-full h-1/2 flex justify-center items-center">
        <img src={imgurl} alt={name} className="w-full h-full object-cover rounded-t-lg" />
      </div>

      
      <div className="w-full h-1/2 flex items-center justify-center p-2">
        <p className="text-sm text-gray-700 font-semibold">{label}</p>
      </div>
    </div>
  );
};
