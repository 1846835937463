import React, { useRef, useState } from 'react';
import Menu from '../../../components/MenuTabs';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { InvestorProfileItem } from '../components';
import aventurero from "../../../assets/img/nido/benefits/aventurero.png";
import explorador from "../../../assets/img/nido/benefits/explorador.png";
import heroe from "../../../assets/img/nido/benefits/heroe.jpg";

interface InvestorProfilesPropertyPageProps {
  benefits: Array<{ name: string; typeInvestor: string }>;
  unitPrice: number;
}

export const InvestorProfilesPropertyPage = ({ benefits, unitPrice }: InvestorProfilesPropertyPageProps) => {
  const [activeItem, setActiveItem] = useState({ name: 'Explorador', img: explorador, price: unitPrice, amount: 100, type: 'explorer' });

  const itemsBenefits = [
    { name: 'Explorador', img: explorador, price: unitPrice, amount: 100, type: 'explorer' },
    { name: 'Aventurero', img: aventurero, price: unitPrice, amount: 200, type: 'adventurer' },
    { name: 'Héroe', img: heroe, price: unitPrice, amount: 500, type: 'hero' },
  ];

  const benefitsByType = (type: string) => {
    return benefits
      .filter(benefit => benefit.typeInvestor === type)
      .map(benefit => benefit.name);
  };

  const splideRef = useRef<Splide | null>(null);

  const focusSlide = (slideItem?: string) => {
    const index = itemsBenefits.findIndex((item) => item.name === slideItem);
    if (splideRef.current && index !== -1) {
      splideRef.current.go(index);
    }
  };

  const menuItems = itemsBenefits.map(item => ({
    name: item.name,
    label: item.name,
    value: item.type  
  }));

  const handleSlideMoved = (splide: any) => {
    const index = splide.index;
    const newActiveItem = itemsBenefits[index];
    setActiveItem(newActiveItem);
  };

  return (
    <div className="w-full space-y-5" id='investor-profile-property'>
      <div className="flex justify-center">
        <Menu menuItems={menuItems} setContent={focusSlide} activeItem={activeItem} setActiveItem={setActiveItem} />
      </div>

      <div className="aspect-square">
        <Splide
          ref={(splide) => (splideRef.current = splide)} 
          options={{
            type: 'loop',
            perPage: 1,
            perMove: 1,
            pagination: false,
            arrows: false,
            rewind: false, 
            center: true, 
            autoplay: true,
            interval: 5000,
          }}
          onMoved={handleSlideMoved}
        >
          {itemsBenefits.map((item) => (
            <SplideSlide key={item.name}>
              <InvestorProfileItem
                img={item.img}
                type={item.name}
                price={item.price}
                benefits={benefitsByType(item.type)}
                amount={item.amount}
              />
            </SplideSlide>
          ))}
        </Splide>
      </div>
    </div>
  );
};
