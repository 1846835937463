import React from 'react';

import {  TimeLineColors } from '../components/componetsTimeLine';

export const TimeLine = () => {
 

  return (
    <div className="w-full h-[100vh] flex flex-col justify-between ">
      <TimeLineColors />

      
    </div>
  );
};
