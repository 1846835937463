import React, { useEffect, useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import {  Autoplay } from 'swiper'; 
import { motion } from 'framer-motion';

//Icons importation
import iconHome from "../../../../assets/img/about-us/img-home.png";
import iconChatRoundMoney from "../../../../assets/img/about-us/img-chat-round-money.png";
import iconGraphNewUp from "../../../../assets/img/about-us/img-graph-new-up.png";
import iconHouseCheck from "../../../../assets/img/about-us/img-house-check.png";
import iconHouseBoatOutline from "../../../../assets/img/about-us/img-houseboat-outline.png";
import iconBuildingUp from "../../../../assets/img/about-us/img-building-up.png";
import iconFluentPeople from "../../../../assets/img/about-us/img-fluent-people-add-light.png";
import iconBuildingCheck from "../../../../assets/img/about-us/img-building-check.png";

// Date's events
const events = [
  { year: "2018", description: 'Creación Lokl', color: '#C3E7FB', icon: iconHome }, 
  { year: "2020", description: 'Firma primer proyecto', color: '#FFF068', icon: iconHouseCheck },
  { year: "2021", description: 'Lanzamiento Indie Universe', color: '#DAD2F8', icon: iconBuildingUp },
  { year: "2022", description: 'Lanzamiento Nido de agua', color: '#54FFCC', icon: iconHouseBoatOutline },
  { year: "2023", description: 'Apertura Indie', color: '#C3E7FB', icon: iconBuildingCheck },
  { year: "2023", description: 'Alcanzamos 1.000 inversionistas', color: '#DAD2F8', icon: iconFluentPeople },
  { year: "2024", description: 'Entrega primeros dividendos', color: '#FFF068', icon: iconChatRoundMoney },
  { year: "20♾️", description: 'Construcción de ciudades colaborativas', color: '#54FFCC', icon: iconGraphNewUp },
];

export const TimeLineColors = () => {
  const [isVisible, setIsVisible] = useState(false);
  const timelineRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      if (entry.isIntersecting) {
        setIsVisible(true);
        observer.disconnect(); 
      }
    }, {
      threshold: 0.1, 
    });

    if (timelineRef.current) {
      observer.observe(timelineRef.current);
    }

    return () => {
      if (timelineRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        observer.unobserve(timelineRef.current);
      }
    };
  }, []);

  return (
    <div className="flex flex-col h-[100vh] items-center mx-16  mt-32 md:mt-60 2xl:mt-60" ref={timelineRef}>
      <div className="relative  w-full block "> 

        <div className="w-full h-0.5 bg-gray-400 absolute top-1/2 transform -translate-y-1/2 md:block hidden" />

      
        <Swiper
          modules={[ Autoplay]}
          spaceBetween={10}
          slidesPerView={4}
          autoplay={{ delay: 3400, disableOnInteraction: false }}
          
          className="relative z-10 md:mb-3 md:block  hidden"
        >
          {events.map((event, index) => (
            <SwiperSlide key={index} className="flex flex-col items-center mt-20">
              <motion.div 
                style={{ backgroundColor: event.color }}  
                className="md:w-[50px] md:h-[50px] w-[30px] h-[30px] flex items-center rounded-xl justify-center" 
                initial={{ scale: 0 }}
                animate={isVisible ? { scale: 1 } : { scale: 0 }} 
                transition={{ duration: 0.5 }} 
              >
                <img src={event.icon} alt={`Icono para ${event.year}`} className="md:w-[25px] md:h-[25px] h-[15px] w-[15px]" /> 
              </motion.div>
              <motion.div className="text-center mt-2" 
                initial={{ opacity: 0, y: 10 }} 
                animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: 10 }} 
                transition={{ duration: 0.9, delay: 0.5 }} 
              >
                <div className="text-xl font-bold numberFont">{event.year}</div>
                <div className="text-base primaryFont italic">{event.description}</div>
              </motion.div>
            </SwiperSlide>
          ))}
        </Swiper>

       
        <div className="w-0.5 bg-gray-400 md:hidden block absolute left-1/2 transform -translate-x-1/2 h-full" />

         
         <Swiper
          modules={[ Autoplay]}
          direction="vertical"
          spaceBetween={20} 
          slidesPerView={4}
          autoplay={{ delay: 3400, disableOnInteraction: false }}
         
          className="relative z-10 md:hidden block h-[600px]  overflow-hidden ml-8"
        >
          {events.map((event, index) => (
            <SwiperSlide key={index} className="flex justify-center ml-24"> 
              <div className="flex items-start w-full max-w-[200px]">
                <motion.div 
                  style={{ backgroundColor: event.color }}  
                  className="md:w-[50px] md:h-[50px] w-[30px] h-[30px] flex items-center rounded-xl justify-center mr-4"
                  initial={{ scale: 0 }}
                  animate={isVisible ? { scale: 1 } : { scale: 0 }} 
                  transition={{ duration: 0.5 }} 
                >
                  <img src={event.icon} alt={`Icono para ${event.year}`} className="md:w-[25px] md:h-[25px] h-[15px] w-[15px]" /> 
                </motion.div>
                <motion.div className="text-left max-w-[150px]"> 
                  <div className="text-xl font-bold numberFont">{event.year}</div>
                  <div className="text-base primaryFont italic">{event.description}</div>
                </motion.div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>

      </div>
    </div>
  );
};
