import React, { useState } from "react";
import { TextInputComponent, CheckboxComponent, SelectComponent, RadioButtonComponent , PhoneInputComponent} from ".";
import { Question } from "../Interfaces/FormInterfaces";


interface QuestionProps {
  question: Question;
  cardId: string;
  imgurl: string;
  name: string;
  value: string | string[] | boolean | { phone: string; countryCode: string };
  onChange: (value: string | string[] | boolean | { phone: string; countryCode: string }) => void;
}

export const QuestionComponent: React.FC<QuestionProps> = ({ question, value, cardId,imgurl , name ,  onChange }) => {
  const [otherText, setOtherText] = useState<string>("");
 
  
  const handleSelectChange = (newValue: string) => {
    if (newValue === "otro") {
      onChange(newValue);
      setOtherText("");
    } else {
      onChange(newValue);
      setOtherText(""); 
    }
  };
  
  switch (question.type) {


    
    case "text":
      return (
        <TextInputComponent
          id={question.id}
          label={question.text}
          value={String(value)}
          onChange={onChange}
        />
      );

      case "phone":
        

        return (
          <div>
            <PhoneInputComponent
              value={
                typeof value === "object" && value !== null && "phone" in value
                  ? (value as { phone: string; countryCode: string })
                  : { phone: "", countryCode: "+57" }
              }
              id={question.id}
              label={question.text}
              onChange={(newValue) => onChange(newValue)}
              
            />
           
          </div>
        );

      case "select":
        return (
          <div>
            <SelectComponent
              id={question.id}
              label={question.text}
              selectedValue={String(value) || ""}
              options={question.options || []}
              onChange={handleSelectChange}
            />
           
            {value === "Otro" && (
              <div className="mt-4">
                <label htmlFor="other" className="block text-sm font-medium secondaryFont text-gray-700">
                  Por favor, especifica
                </label>
                <input
                  type="text"
                  id="other"
                  value={otherText}
                  onChange={(e) => setOtherText(e.target.value)}
                  placeholder="Especifica aquí"
                  className="mt-2 rounded-lg numberFont w-full h-[40px] text-black border-[1px]  bg-white focus:border-[#4743E0]"
                />
              </div>
            )}
          </div>
        );
        case "checkbox":
          
          const selectedValues = Array.isArray(value) ? value : [];
          const preselectedId = cardId; 
          return (
            <div className="flex flex-col space-y-2">
              <label className="text-gray-700 font-medium primaryFont">
                {preselectedId ? "También puedes invertir en estos proyectos, ¿te interesan?" : question.text}
              </label>
        
              <div className="flex flex-col md:flex-row  space-y-0 gap-4 items-center  ">
                {question.options?.map((option) => {
                  const isPreselected = option.value === preselectedId;
        
                  return isPreselected ? (
                    
                    <input key={option.value} type="hidden" name={question.id} value={option.value} />
                  ) : (
                   
                    <CheckboxComponent
                      key={option.value}
                      imgurl={option.url || ""}
                      name={name}
                      id={`${question.id}-${option.value}`}
                      label={option.label}
                      checked={selectedValues.includes(option.value)}
                      onChange={(checked) => {
                        let newValues = [...selectedValues];
        
                        if (checked) {
                          newValues.push(option.value);
                        } else {
                          newValues = newValues.filter((val) => val !== option.value);
                        }
        
                        onChange(newValues);
                      }}
                    />
                  );
                })}
              </div>
            </div>
          );
        
        

        
      

    case "radio":
      return (
        <div className="flex flex-col space-y-2 p-4 bg-green-100 ">
          <label className="text-gray-700 font-medium primaryFont">{question.text}</label>
          <div className="flex flex-col md:flex-row space-x-5 items-center">
            {question.options?.map((option) => (
              <RadioButtonComponent
                key={option.value}
                id={`${question.id}-${option.value}`}
                name={question.id}
                label={option.label}
                value={option.value}
                checked={value === option.value}
                onChange={(value) => onChange(value)}
              />
            ))}
          </div>
        </div>
      );
      
      

      

      
      

    default:
      return null;
  }
};
