import React from 'react';
import { numberWithCommas } from '../../../helpers/functions';

interface BenefitSectionProps {
  benefits: Array<string>;
  showBenefit: boolean;
  onShowBenefit: () => void;
  onHideBenefit: () => void;
  price: number;
  type: string
}

const BenefitSection = ({ benefits, showBenefit, onShowBenefit, onHideBenefit, price , type }: BenefitSectionProps) => {
  return (
    <div className="px-10  mb-6">
      <h4 className="text-3xl primaryFont  font-extrabold text-white">{type}</h4>
      
      {!showBenefit ? (
        <>
           <h4 className="text-lg secondaryFont font-normal text-white">Invirtiendo a partir de</h4>
          <h4 className="text-3xl numberFont font-extrabold text-white">${numberWithCommas(price)} COP</h4>
          <p className="text-white secondaryFont text-lg">
            Estás a un paso de ser inversionista pionero y comenzar tu historia
          </p>


          <p
            className="text-white text-center mt-4 font-semibold underline text-lg cursor-pointer secondaryFont"
            onClick={onShowBenefit}
          >
            Conoce los beneficios
          </p>
        </>
      ) : (
        <div className="px-4 max-h-60 overflow-y-auto">
         
          <ul className="list-disc text-white secondaryFont text-lg">
            {benefits.map((benefit, index) => (
              <li key={index}>{benefit}</li>
            ))}
          </ul>
          <div className="w-full text-center">
            <p
              className="text-white text-center font-semibold underline text-lg cursor-pointer"
              onClick={onHideBenefit}
            >
              Volver
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BenefitSection;
