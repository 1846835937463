import { Link } from "react-router-dom";
import benefitOne from "../../assets/img/nido/benefits/benefit-img-1.png";
import benefitTwo from "../../assets/img/nido/benefits/benefit-img-2.png";
import benefitThree from "../../assets/img/nido/benefits/benefit-img-3.png";
import { scrollToSection } from "../../helpers/functions";
import close from "../../assets/img/home/close-white.svg";
import { useState } from "react";


function VideoOnHeader(videoActive: any) {
  
  return(
    <div className="fixed h-screen w-screen bg-black/60 top-0 left-0 z-10">
      <div className="w-[360px] h-[300px] lg:w-[666px] lg:h-[430px] fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] z-20">
        <img 
          className="absolute left-[93%] -top-[8%] cursor-pointer"
          src={close} 
          alt="" 
          onClick={() => videoActive(false)}
        />
        <iframe className='w-full h-full rounded-xl' src="https://www.youtube.com/embed/CQDtTMMa2mQ" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
      </div>
    </div>
  );

}

function Benefits() {

  const scrollTo = (id: any) => {
    scrollToSection(id || '', 160);
  }

  const [videoActive, setVideoActive] = useState(false);


  return (
    <section>
      {videoActive === true && VideoOnHeader(setVideoActive)}
      <h2 className="text-2xl font-bold  mb-12 lg:w-2/4 primaryFont">¿Cuales son los beneficios de invertir a traves de Lokl?</h2>
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-16">
        <div className="col-span-1 flex items-start">
          <div className="flex-shrink-0 basis-[70px] mr-8">
            <img src={benefitOne} alt="Imagen interior" />
          </div>
          <div>
            <h3 className="font-medium text-3xl mb-4 primaryFont">Beneficios y descuentos</h3>
            <p className="text-lg leading-8 text-[#575757] mb-3 secondaryFont">Desde 10% de descuento en los servicios de los proyectos a noches gratuitas...</p>
            <p onClick={() => scrollTo('investor-profile')} className="text-[#3533FF] underline cursor-pointer secondaryFont">Descubre mas beneficios</p>

          </div>
        </div>
        <div className="col-span-1 flex items-start">
          <div className="flex-shrink-0 basis-[70px] mr-8">
            <img src={benefitTwo} alt="Imagen de un guitarrista" />
          </div>
          <div>
            <h3 className="font-medium text-3xl mb-4 primaryFont">Invierte en comunidad</h3>
            <p className="text-lg leading-8 text-[#575757] mb-3 secondaryFont">
              ¡Sé parte de una comunidad que le apasiona crecer con eventos, network y mas!
            </p>
            <div className="flex items-center gap-2">
              <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M11.1667 20.3333C16.2293 20.3333 20.3333 16.2293 20.3333 11.1667C20.3333 6.10406 16.2293 2 11.1667 2C6.10406 2 2 6.10406 2 11.1667C2 16.2293 6.10406 20.3333 11.1667 20.3333Z" stroke="#5452F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9.33203 7.5L14.832 11.1667L9.33203 14.8333V7.5Z" stroke="#5452F6" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
              <p onClick={() => setVideoActive(true)} className="text-[#3533FF] underline cursor-pointer secondaryFont">Conoce a la comunidad</p>
            </div>
          </div>
        </div>
        <div className="col-span-1 flex items-start">
          <div className="flex-shrink-0 basis-[70px] mr-8">
            <img src={benefitThree} alt="Imagen de un edificio" />
          </div>
          <div>
            <h3 className="font-medium text-3xl mb-4 primaryFont">Proyectos con  impacto</h3>
            <p className="text-lg leading-8 text-[#575757] mb-3 secondaryFont">
              Invierte en el futuro que quieres crear con proyectos con impacto positivo
            </p>
            <Link to="/blog/inversion-ahorro-crecimiento-financiero" className="text-[#3533FF] secondaryFont">¿Como evaluamos impacto?</Link>
          </div>
        </div>

      </div>
    </section>
  )
}

export default Benefits;