import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/pagination';
import '../../NidoDeAgua/insights.scss';
import { isAuthenticated } from '../../../helpers/functions';

interface InsightsPropertyPageProps {
    attributesInsight: Array<{ title: string; description: string; link: string; type: string }>;
    name: string;
    projectId: string;
}

export const InsightsPropertyPage = ({ attributesInsight = [], name , projectId}: InsightsPropertyPageProps) => {
const goToBuy = () => {
    isAuthenticated(true, undefined, undefined, projectId);
  }

  return (
    <div className="w-full">
     
      {attributesInsight.length > 0 && (
        <>
          <div className="w-full text-center mb-3">
            <h3 className="text-black/30 text-xl font-bold primaryFont">¿Por qué invertir en {name.charAt(0).toUpperCase() + name.slice(1)}?</h3>
          </div>
          
          <div className="rounded-xl overflow-hidden insights-swiper">
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 5000,
                disableOnInteraction: false,
              }}
              loopFillGroupWithBlank={true}
              
              modules={[Autoplay, Pagination]}
              pagination={true}
            >
              {attributesInsight.map((item, index) => (
               <SwiperSlide key={index}>
               <div
                 className="relative w-full h-[400px] bg-cover bg-center"
                 style={{ backgroundImage: `url(${item.link})` }}
               >
                 <div className="absolute w-full h-full top-0 left-0 bg-black/40"></div>
                 <div className="absolute z-30 top-2/4 left-0 right-0 text-center m-auto translate-y-[-50%] px-12">
                   <h2 className="text-white text-3xl font-extrabold primaryFont text-left mb-3">
                     {item.title}
                   </h2>          
                   <div className="max-h-[220px] scrollbar-thumb-gray-500 scrollbar-track-gray-200">
                     <p className="text-white text-xl font-medium sencondaryFont text-left">
                       {item.description}
                     </p>
                   </div>
                   <button
                     id="btnGoToCheckOutHeader"
                     onClick={goToBuy}
                     className="btn-black content-center justify-center mt-6 primaryFont"
                   >
                     Quiero ser socio
                   </button>
                 </div>
               </div>
             </SwiperSlide>
             
              ))}
            </Swiper>
          </div>
        </>
      )}
    </div>
  );
};
