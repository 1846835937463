import { cloudbedsOccupationInterface } from "../../interfaces";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export interface OccupationChartInterface {
  occupation: Array<cloudbedsOccupationInterface>;
  daysNumber: number;
}

export const OccupationChart = ({ occupation, daysNumber }: OccupationChartInterface) => {
  const chartData = occupation
  .filter((entry) => entry && entry.date && entry.occupation != null) 
  .map((entry) => {
    
    const [year, month, day] = entry.date.split("-").map(Number);
    const formattedDate = `${day.toString().padStart(2, "0")}/${month.toString().padStart(2, "0")}/${year}`;
    
    return {
      date: formattedDate, 
      Ocupacion: Math.round(entry.occupation), 
    };
  })
  .sort((a, b) => {
    
    const [dayA, monthA, yearA] = a.date.split("/").map(Number);
    const [dayB, monthB, yearB] = b.date.split("/").map(Number);
    return new Date(yearA, monthA - 1, dayA).getTime() - new Date(yearB, monthB - 1, dayB).getTime();
  });


  const CustomTooltip = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div className="bg-white p-2 shadow-md border rounded">
          <p className="text-gray-700 font-bold">{`Fecha: ${label}`}</p>
          <p className="text-gray-600">{`Ocupación: ${payload[0].value}%`}</p>
        </div>
      );
    }
    return null;
  };

  const isMobile = window.innerWidth < 768; 
  const leftMargin = isMobile ? 40 : 24; 

  return (
    <div className="relative w-full h-[350px] overflow-x-auto custom-scroll">
      <div className={`${daysNumber <= 7 ? 'w-full' : 'w-[1200px]'} md:w-full h-full`}>
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            data={chartData}
            margin={{
              top: 50,
              right: 54,
              bottom: 20,
              left: leftMargin, 
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis tickFormatter={(value) => `${value}%`} />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line
              type="monotone"
              dataKey="Ocupacion"
              stroke="#221feb"
              strokeWidth={3}
              activeDot={{ r: 8 }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
