import React, { useContext, useEffect, useState } from 'react'
import { useForm, FieldValues, SubmitHandler } from 'react-hook-form';
import Select from 'react-tailwindcss-select';
import { SimulatorGraphic } from './SimulatorGraphic';
import { getProjects } from '../../services/projectService';
import { createSimulation, sendSimulationScenaries } from '../../services/SimulatorService';
import { SimulationResponseAPIInterface, SimulationResponseInterface } from './simulationInterface';
import { AxiosResponse } from 'axios';
import { formatNumberWithCommas, specialPercentageFormat, SCENARIES, checkSessionStorageToken } from './SimulatorUtils';
import advisorPic from '../../assets/img/simulator/icon-seller.jpg'
import { SimulatorChartSkeleton } from './SimulatorChartSkeleton';
import { SkeletonLine } from './SkeletonLine';
import { SkeletonOperation } from './SkeletonOperation';
import './spinner.scss'
import { saveSimulation } from '../../services/globalServices';
import { getNameEmail } from '../../helpers/functions';
import { UTMContext } from '../../contexts';
import { useNavigate } from 'react-router-dom';

interface SimuladorDasboardProps {
    simulationData: {
        project: string;
        investmentValue: string;
        installmentsNumber: string;
        name: string;
        phone: string;
        email: string;
        leadOrigin: string;
        unitValue: number;
        minimumUnitsPurchase: number,
        installmentOptions: any
    };
    setMessageSent: (arg: boolean) => void;
    setSimulationData: React.Dispatch<React.SetStateAction<{
        project: string;
        investmentValue: string;
        installmentsNumber: string;
        name: string;
        phone: string;
        email: string;
        leadOrigin: string;
        unitValue: number;
        minimumUnitsPurchase: number
        installmentOptions: any
    }>>;
    simulationResponse: SimulationResponseInterface | undefined
    setSimulationResponse: React.Dispatch<React.SetStateAction<SimulationResponseInterface | undefined>>
}

export const VerticalSimulationDashboard: React.FC<SimuladorDasboardProps> = ({ setMessageSent, simulationData, setSimulationData, setSimulationResponse, simulationResponse }) => {
    const [projects, setProjects] = useState([])
    const [projectSelected, setProjectSelected] = useState<null | { value: any; label: any }>(null);
    const [unitValueSelected, setUnitValueSelected] = useState<number>(simulationData.unitValue);
    const [minimumUnitsPurchaseSelected, setMinimumUnitsPurchaseSelected] = useState<number>(simulationData.minimumUnitsPurchase);
    const [installmentSelected, setInstallmentSelected] = useState<null | { value: any; label: any }>(null);
    const [scenarySelected, setScenarySelected] = useState<null | { value: any; label: any }>(null);
    const [flag, setFlag] = useState(true)
    const [loadingData, setLoadingData] = useState(false)
    const context = useContext(UTMContext);
    const { UTM } = context;
    const navigate = useNavigate();
    const [installmentOptions, setInstallmentOptions] = useState(simulationData.installmentOptions);
    useEffect(() => {
        if (flag) {
            getProjects().then((data) => {
                const filteredProjects = data.data.filter((project: any) => project.phase !== 'Etapa 0');
                const formattedProjects = filteredProjects.map((project: any) => {
                    const projectOption = {
                        value: project.id,
                        label: project.name,
                        unitValue: project.unitPrice,
                        minimumUnitsPurchase: project.minimumUnitsPurchase,
                        maxInvestmentQuota: project.maxInvestmentQuota
                    };
                    if (project.id === simulationData.project) {
                        setProjectSelected(projectOption);
                    }

                    return projectOption;
                });

                setProjects(formattedProjects);
            });
            installmentOptions.forEach((installment: any) => {
                const installmentOption = {
                    value: installment.value,
                    label: installment.label,
                };

                if (installment.value === simulationData.installmentsNumber) {
                    setInstallmentSelected(installmentOption);
                }
            });
            setFlag(false)
        }
    }, [flag, installmentOptions, simulationData.installmentsNumber, simulationData.project]);

    useEffect(() => {
        if (parseFloat(simulationData.investmentValue) >= unitValueSelected * 500) setScenarySelected({ value: 'heroe', label: 'Héroe' })
        else if (parseFloat(simulationData.investmentValue) >= unitValueSelected * 200) setScenarySelected({ value: 'aventurero', label: 'Aventurero' })
        else if (parseFloat(simulationData.investmentValue) >= unitValueSelected * 100) setScenarySelected({ value: 'exploratorio', label: 'Exploratorio' })
    }, [simulationData.investmentValue, unitValueSelected])

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, touchedFields },
        clearErrors,
        watch
    } = useForm<FieldValues>({
        defaultValues: {
            project: simulationData.project,
            investmentValue: simulationData.investmentValue,
            installmentsNumber: simulationData.installmentsNumber,
            scenary: '',
            unitValue: 0,
            minimumUnitsPurchase: simulationData.minimumUnitsPurchase
        },
        mode: 'onTouched',
    });
    const onSubmit: SubmitHandler<FieldValues> = (data) => {
        setLoadingData(true)
        setSimulationData((prevData: typeof simulationData) => ({
            ...prevData,
            project: data.project,
            investmentValue: data.investmentValue,
            installmentsNumber: data.installmentsNumber,
            minimumUnitsPurchase: minimumUnitsPurchaseSelected
        }));
        createSimulation({
            "investmentValue": parseFloat(data.investmentValue),
            "installmentsNumber": parseFloat(data.installmentsNumber),
            "projectId": data.project
        }).then((response: AxiosResponse<SimulationResponseAPIInterface>) => {
            setSimulationResponse(response.data.data)
        }).catch((error) => {
            console.error("Error al crear la simulación:", error)
        })
            .finally(() => setLoadingData(false))

        /* generateLead(
            simulationData.name,
            getNameEmail() ? getNameEmail().email : simulationData.email,
            simulationData.investmentValue,
            (parseFloat(data.investmentValue) / data.unitValue),
            parseFloat(data.installmentsNumber),
            simulationData.phone,
            true
        ); */
        saveSimulation(
            data.project,
            parseFloat((parseFloat(data.investmentValue) / (watch('unitValue') === 0 ? simulationData.unitValue : watch('unitValue'))).toFixed(1)),
            parseFloat(data.investmentValue),
            parseInt(data.installmentsNumber),
            true,
            simulationData.leadOrigin,
            "new simulator",
            getNameEmail() ? getNameEmail().email : simulationData.email,
            simulationData.phone,
            "",
            getNameEmail() ? getNameEmail().name : simulationData.name,
            UTM.utmSource,
            UTM.utmMedium,
            UTM.utmCampaign,
            UTM.utmTerm,
            UTM.utmContent
        )
    };
    const handleProjectChange = (val: any) => {
        setValue('project', val.value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        });
        setValue('unitValue', val.unitValue);
        setValue('minimumUnitsPurchase', val.minimumUnitsPurchase);
        setUnitValueSelected(val.unitValue)
        setMinimumUnitsPurchaseSelected(val.minimumUnitsPurchase)
        setProjectSelected(val);
        clearErrors('project');

        const maxInvestmentQuota = val.maxInvestmentQuota;
        const installmentOptions = [{
            value: '0',
            label: 'Pago único'
        }]
        for (let i = 2; i <= maxInvestmentQuota; i++) {
            installmentOptions.push({
                value: i.toString(),
                label: i.toString() + ' meses'
            })
        }
        setInstallmentOptions(installmentOptions)
        const resetInstallment: any = { value: "0", label: "Pago único", disabled: false }
        setInstallmentSelected(resetInstallment)
        setValue('installmentsNumber', "0", {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        });
    };
    const handleInstallmentsChange = (val: any) => {
        setValue('installmentsNumber', val.value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        });
        setInstallmentSelected(val);
        clearErrors('installmentsNumber');
    };
    const handleScenaryChange = (val: any) => {
        setValue('scenary', val.value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        });
        setScenarySelected(val);
        if (val.value === 'heroe') setValue('investmentValue', unitValueSelected * 500);
        if (val.value === 'aventurero') setValue('investmentValue', unitValueSelected * 200);
        if (val.value === 'exploratorio') setValue('investmentValue', unitValueSelected * 100);

        clearErrors('scenary');
    };
    const printSimulator = async () => {
        if (!checkSessionStorageToken()) {
            const url = new URL(window.location.href);
            const baseUrl = new URL(url).origin + new URL(url).pathname;
            const urlParams = new URLSearchParams(new URL(url).search);
            const parameters = urlParams.toString();
            const urlToRedirect = `/register?${parameters}&redirect_to=${baseUrl}?simulationdata=${encodeURIComponent(JSON.stringify(simulationData))}`;
            navigate(urlToRedirect);
        } else {
            await sendSimulationScenaries({
                "to": getNameEmail().email,
                "userName": getNameEmail().name,
            });
            setMessageSent(true)
        }
    }

    useEffect(() => {
        clearErrors('investmentValue');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('unitValue'), watch('minimumUnitsPurchase')]);

    const handleKnowMore = () => {
        window.open('/blog/lokl-cambiando-panorama-inversiones-inmobiliarias', '_blank');
    }

    return (
        <div className='flex flex-col'>
            <form onSubmit={handleSubmit(onSubmit)} className='w-full flex flex-col justify-between bg-[#DFDFFF] min-h-[230px] h-auto rounded-3xl px-[3%] pt-4 pb-5'>
                <div className='w-full'>
                    <h1 className='text-2xl font-roboto font-semibold text-[#3533FF] mb-3'>SIMULADOR DE INVERSIONES</h1>
                    <div className='w-full flex justify-between'>
                        <div className='w-[50%] space-y-4'>
                            <div>
                                <Select
                                    classNames={{
                                        menuButton: () =>
                                            `secondaryFont flex justify-between h-[40px] border-[1px] text-base text-[#4F4F4F] bg-white ${errors.project
                                                ? 'border-red-500'
                                                : !errors.project && touchedFields.project
                                                    ? 'border-green-500'
                                                    : 'border-gray-300'
                                            } px-4 rounded-lg outline-none cursor-pointer focus:border-[#4743E0] ${projectSelected ? 'text-gray-800' : 'text-gray-400'
                                            }`,
                                        list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52',
                                        listItem: ({ isSelected }) => `pl-[15px] list-none py-1 cursor-pointer ${isSelected && 'bg-[#4a4a4a] rounded-lg text-white py-2 px-3'}`,
                                    }}
                                    primaryColor="violet"
                                    value={projectSelected}
                                    onChange={handleProjectChange}
                                    options={projects}
                                    placeholder="Proyecto"
                                />
                                {errors.project && (
                                    <div className="text-red-500 text-sm">* El proyecto es requerido.</div>
                                )}
                                <input type="hidden" {...register('project', { required: true })} />
                            </div>

                            <div>
                                <input
                                    type="number"
                                    placeholder="Inversión"
                                    className={`numberFont font-bold text-[#4F4F4F] text-xl w-full placeholder:text-[#4F4F4F] h-[40px] border-[1px] ${errors.investmentValue
                                        ? 'border-red-500'
                                        : !errors.investmentValue && touchedFields.investmentValue
                                            ? 'border-green-500'
                                            : 'border-gray-300'
                                        } py-2 px-4 rounded-lg outline-none focus:border-[#4743E0]`}
                                    {...register('investmentValue', { required: true, min: (watch('minimumUnitsPurchase')) * (watch('unitValue') === 0 ? simulationData.unitValue : watch('unitValue')), max: 500000000 })}
                                />
                                {errors.investmentValue && (
                                    <div className="secondaryFont text-red-500 text-sm">
                                        * El valor de la inversión debe estar entre ${formatNumberWithCommas((watch('minimumUnitsPurchase')) * (watch('unitValue') === 0 ? simulationData.unitValue : watch('unitValue')))} y $500,000,000
                                    </div>
                                )}
                            </div>

                            <div>
                                <Select
                                    classNames={{
                                        menuButton: () =>
                                            `secondaryFont flex justify-between h-[40px] border-[1px] text-[#4F4F4F] bg-white ${errors.installmentsNumber
                                                ? 'border-red-500'
                                                : !errors.installmentsNumber && touchedFields.installmentsNumber
                                                    ? 'border-green-500'
                                                    : 'border-gray-300'
                                            } px-4 rounded-lg outline-none cursor-pointer focus:border-[#4743E0] ${installmentSelected ? 'text-gray-800' : 'text-gray-400'
                                            }`,
                                        list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52',
                                        listItem: ({ isSelected }) => `pl-[15px] list-none py-1 cursor-pointer ${isSelected && 'bg-[#4a4a4a] rounded-lg text-white py-2 px-3'}`,
                                    }}
                                    primaryColor="violet"
                                    value={installmentSelected}
                                    onChange={handleInstallmentsChange}
                                    options={installmentOptions}
                                    placeholder="Cuotas"
                                />
                                {errors.installmentsNumber && (
                                    <div className="secondaryFont text-red-500 text-sm">* El número de cuotas es requerido.</div>
                                )}
                                <input type="hidden" {...register('installmentsNumber', { required: true })} />
                            </div>
                        </div>
                        <div className='w-[44%] space-y-4'>
                            <div className='w-fit flex flex-col h-[40px] justify-center'>
                                {loadingData
                                    ?
                                    <SkeletonLine />
                                    :
                                    <span className='numberFont font-bold text-base leading-none'>{(simulationResponse?.unitsAmount)?.toFixed(1)}</span>
                                }
                                <label className='secondaryFont  text-[#4F4F4F] text-sm'># de units</label>
                            </div>
                            <div className='w-fit flex flex-col h-[40px] justify-center'>
                                {loadingData
                                    ?
                                    <SkeletonLine />
                                    :
                                    <span className='numberFont font-bold text-base leading-none'>${formatNumberWithCommas(simulationResponse?.installmentValue)}</span>
                                }
                                <label className='secondaryFont text-[#4F4F4F] text-sm'>Cuota mensual</label>
                            </div>
                            <div className='w-fit flex flex-col h-[40px] justify-center'>
                                {loadingData
                                    ?
                                    <SkeletonLine />
                                    : <span className='numberFont font-bold text-base leading-none'>${formatNumberWithCommas(simulationResponse?.financingFee)}</span>
                                }
                                <label className='secondaryFont text-[#4F4F4F] text-sm'>Costo financiación</label>
                            </div>
                        </div>
                    </div>

                    <div className='w-full  flex justify-between items-center mt-3 mb-2'>
                        <div className='flex flex-col h-[40px] justify-center'>
                            {loadingData
                                ?
                                <SkeletonLine />
                                : <span className='numberFont font-bold text-base leading-none'>${formatNumberWithCommas(simulationResponse?.totalToPay)}</span>
                            }
                            <label className='secondaryFont text-[#4F4F4F] text-sm'>Total a pagar</label>
                        </div>
                        <div className='flex flex-col h-[40px] justify-center'>
                            {loadingData
                                ?
                                <SkeletonLine />
                                : <span className='numberFont font-bold text-base leading-none'>${formatNumberWithCommas(simulationResponse?.unitValue)}</span>
                            }
                            <label className='secondaryFont text-[#4F4F4F] text-sm'>Costo unit</label>
                        </div>
                        <div className='flex flex-col h-[40px] justify-center'>
                            {loadingData
                                ?
                                <SkeletonLine />
                                : <span className='numberFont font-bold text-base leading-none'>{specialPercentageFormat(simulationResponse?.estimatedParticipation, 3)}%</span>
                            }
                            <label className='secondaryFont font-roboto text-[#4F4F4F] text-sm'>Participación</label>
                        </div>
                    </div>
                    <span className='primaryFont text-[#3533FF] text-lg font-semibold'>Utilidad de tu inversión estimada a 5 años*</span>

                    <div className='w-full  flex justify-between items-center bg-white rounded-xl px-3 py-2 mt-2'>
                        <div className='flex flex-col h-[40px] justify-center'>
                            {loadingData
                                ?
                                <SkeletonLine />
                                : <span className='special-span numberFont font-bold text-sm text-[#4F4F4F] leading-none'>${formatNumberWithCommas(simulationResponse?.totalIncome, 0)}</span>
                            }
                            <label className='secondaryFont text-[#4F4F4F] text-xs'>Rentas percibidas</label>
                        </div>
                        <span className='special-span secondaryFont text-[#4F4F4F] font-extrabold text-xl'>+</span>
                        <div className='flex flex-col h-[40px] justify-center'>
                            {loadingData
                                ?
                                <SkeletonLine />
                                : <span className='special-span numberFont font-bold text-sm text-[#4F4F4F] leading-none'>${formatNumberWithCommas(simulationResponse?.totalValuation, 0)}</span>
                            }
                            <label className='secondaryFont text-[#4F4F4F] text-xs'>Valorización activo</label>
                        </div>
                        <span className='special-span secondaryFont text-[#4F4F4F] font-extrabold text-xl'>=</span>
                        <div className='flex flex-col h-[40px] justify-center'>
                            {
                                loadingData
                                    ?
                                    <SkeletonLine />
                                    : (simulationResponse && simulationResponse.totalIncome && simulationResponse.totalValuation) &&
                                    <span className='special-span numberFont font-bold text-sm text-[#4F4F4F] leading-none'>${formatNumberWithCommas(simulationResponse?.totalIncome + simulationResponse?.totalValuation, 0)}</span>
                            }
                            <label className='secondaryFont text-[#4F4F4F] text-xs'>Utilidad estimada*</label>
                        </div>
                    </div>

                    <button id='btnNewSimulator' disabled={loadingData} className={`primaryFont w-full mt-4 text-xl text-white flex px-10 py-2 items-center rounded-3xl font-semibold justify-center mb-6 ${loadingData ? 'bg-gray-400 cursor-not-allowed' : 'bg-[#221FEB]'}`}>
                        {
                            loadingData ? 'cargando...' : 'Calcular Retorno'
                        }
                    </button>
                </div>
                <div className='w-full'>
                    <div className='w-full h-auto flex justify-between'>
                        <div className='flex flex-col justify-center'>
                            {loadingData
                                ?
                                <SkeletonLine />
                                :
                                (simulationResponse) && <span className='numberFont font-bold text-lg leading-none'>{specialPercentageFormat(Math.min(...simulationResponse.averageAnnualReturnYears))} - {specialPercentageFormat(Math.max(...simulationResponse.averageAnnualReturnYears))}%</span>
                            }
                            <label className='secondaryFont text-[#4F4F4F] text-base'>Retorno Prom. Anual</label>
                        </div>
                        <div className=''>
                            <Select
                                classNames={{
                                    menuButton: () =>
                                        `secondaryFont w-fit flex justify-between h-[40px] border-[1px] text-[#4F4F4F] bg-white ${errors.scenary
                                            ? 'border-red-500'
                                            : !errors.scenary && touchedFields.scenary
                                                ? 'border-green-500'
                                                : 'border-gray-300'
                                        } px-4 rounded-lg outline-none cursor-pointer focus:border-[#4743E0] ${scenarySelected ? 'text-gray-800' : 'text-gray-400'
                                        }`,
                                    list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52',
                                    listItem: ({ isSelected }) => `pl-[15px] list-none py-1 cursor-pointer ${isSelected && 'bg-[#4a4a4a] rounded-lg text-white py-2 px-3'}`,
                                }}
                                primaryColor="violet"
                                value={scenarySelected}
                                onChange={handleScenaryChange}
                                options={SCENARIES}
                                placeholder="Escenario"
                            />
                            <div onClick={handleKnowMore} className='flex justify-start mt-[2px]'>
                                <small className='cursor-pointer hidden text-xxs secondaryFont underline text-[#221FEB]'>Saber más sobre las categorias</small>
                                <small className='cursor-pointer block text-base  secondaryFont underline text-[#221FEB]'>Saber más</small>
                            </div>
                        </div>
                    </div>
                    {
                        loadingData ?
                            <SimulatorChartSkeleton /> :
                            <SimulatorGraphic simulationResponse={simulationResponse} />
                    }
                    <div className='w-full flex flex-col justify-between'>
                        <div className='w-full flex flex-col mt-1'>
                            <span className='primaryFont font-bold text-lg mb-2'>Supuestos Operación*</span>
                            {
                                loadingData ?
                                    <SkeletonOperation />
                                    :
                                    <>
                                        {
                                            simulationResponse?.accommodations && simulationResponse?.averageRate &&
                                            <>
                                                <span className='secondaryFont text-base font-medium'>Alojamientos - {simulationResponse?.accommodations}</span>
                                                <span className='secondaryFont text-base  font-medium'>Tarifa Promedio - ${formatNumberWithCommas(simulationResponse?.averageRate, 0)}</span>
                                            </>
                                        }
                                        <span className='secondaryFont text-base  font-medium'>Ocupación Hotel - {specialPercentageFormat(simulationResponse?.occupation, 0)}%</span>
                                        <span className='secondaryFont text-base  font-medium'>Margen de Costos - {specialPercentageFormat(simulationResponse?.costMargin, 0)}%</span>
                                    </>
                            }
                        </div>
                        <div className="w-full mt-6">
                            <span className='primaryFont font-bold text-lg'>¿Tienes alguna pregunta?</span>
                            <div className="flex items-center gap-2 w-full mt-2">
                                <div className="hidden rounded-full w-[40px] h-[40px] shrink-0 border-2 border-[#3533FF]">
                                    <img src={advisorPic} alt="advisor" className='rounded-full' />
                                </div>
                                <div className='w-full'>
                                    <p className="secondaryFont text-gray-500 text-base"><strong>Asesórate</strong> con nuestros expertos inmobiliarios.</p>
                                    <button
                                        onClick={() => { window.open("https://api.whatsapp.com/send/?phone=573207379535") }}
                                        className="
                                primaryFont
                                min-w-fit
                                mt-2
                                w-full
                                rounded-full
                                bg-black
                                text-white
                                font-semibold
                                text-sm
                                px-2.5
                                py-2
                                text-center
                                relative
                                hover:opacity-80
                                transition">
                                        Agenda
                                    </button>
                                </div>
                            </div>
                            <div>
                            </div>
                        </div>
                    </div>
                </div>
            </form >
            <div className='flex justify-center items-center text-[#3533FF] w-full text-base font-semibold'>
                <svg className='mr-2' width="26" height="25" viewBox="0 0 26 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M12.9987 22.9168C18.7516 22.9168 23.4154 18.2531 23.4154 12.5002C23.4154 6.74719 18.7516 2.0835 12.9987 2.0835C7.24573 2.0835 2.58203 6.74719 2.58203 12.5002C2.58203 14.1665 2.9733 15.7414 3.66897 17.1382C3.85384 17.5093 3.91537 17.9336 3.8082 18.3341L3.18777 20.653C2.91844 21.6595 3.83933 22.5804 4.84594 22.3111L7.16473 21.6907C7.56529 21.5835 7.98954 21.6451 8.36071 21.8298C9.75742 22.5256 11.3323 22.9168 12.9987 22.9168Z" stroke="#3533FF" stroke-width="1.5" />
                    <path d="M13.0013 15.9723C14.1519 15.9723 15.0846 15.1951 15.0846 14.2363C15.0846 13.2775 14.1519 12.5002 13.0013 12.5002C11.8507 12.5002 10.918 11.7229 10.918 10.764C10.918 9.80522 11.8507 9.02794 13.0013 9.02794M13.0013 15.9723C11.8507 15.9723 10.918 15.1951 10.918 14.2363M13.0013 15.9723V16.6668M13.0013 9.02794V8.3335M13.0013 9.02794C14.1519 9.02794 15.0846 9.80522 15.0846 10.764" stroke="#3533FF" stroke-width="1.5" stroke-linecap="round" />
                </svg>
                <p id='downloadSimulation' onClick={printSimulator} className='mt-1 cursor-pointer'><strong className='cursor-pointer ml-2 font-bold text-xl underline'>Regístrate</strong> o <strong className='cursor-pointer ml-2 font-bold text-xl underline'>inicia sesión</strong> para descargar un modelo detallado de esta simulación</p>
            </div>
        </div>
    )
}
