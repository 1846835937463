import React, { useContext, useEffect, useState } from 'react'
import { useForm, FieldValues, SubmitHandler } from 'react-hook-form';
import Select from 'react-tailwindcss-select';
import { getProjects } from '../../services/projectService';
import { useAuth } from '../../hooks/useAuth';
import { getNameEmail, unitPrice } from '../../helpers/functions';
import { generateLeadInCRM, saveSimulation , createContactInQuiiven} from '../../services/globalServices';
import { createSimulation } from '../../services/SimulatorService';
import { AxiosResponse } from 'axios';
import { SimulationResponseAPIInterface, SimulationResponseInterface } from './simulationInterface';
import { formatNumberWithCommas } from './SimulatorUtils';
import './range.scss'
import { UTMContext } from '../../contexts';

const MAXINVESTMENT = 500000000
const MIN_UNITS = 100

interface SimuladorCardProps {
    simulationData: {
        project: string;
        investmentValue: string;
        installmentsNumber: string;
        name: string;
        phone: string;
        email: string;
        leadOrigin: string;
        unitValue: number;
        minimumUnitsPurchase: number
    };
    setSimulationData: React.Dispatch<React.SetStateAction<{
        project: string;
        investmentValue: string;
        installmentsNumber: string;
        name: string;
        phone: string;
        email: string;
        leadOrigin: string;
        unitValue: number;
        minimumUnitsPurchase: number,
        installmentOptions: any
    }>>;
    setSimulationResponse: React.Dispatch<React.SetStateAction<SimulationResponseInterface | undefined>>
}

export const SimulatorCard: React.FC<SimuladorCardProps> = ({ simulationData, setSimulationData, setSimulationResponse }) => {
    const { isAuth } = useAuth()
    const [projects, setProjects] = useState([])
    const [projectSelected, setProjectSelected] = React.useState(null);
    const [installmentSelected, setInstallmentSelected] = React.useState(null);
    const [minimumUnitsPurchaseSelected, setMinimumUnitsPurchaseSelected] = useState<number>(simulationData.minimumUnitsPurchase);
    const context = useContext(UTMContext);
    const { UTM } = context;
    const [installmentOptions, setInstallmentOptions] = useState([{
            value: '0',
            label: 'Pago único'
        }])
    
    useEffect(() => {
        getProjects().then((data) => {
            const filteredProjects = data.data.filter((project: any) => project.phase !== 'Etapa 0');
            setProjects(filteredProjects.map((project: any) => ({
                value: project.id,
                label: project.name,
                unitValue: project.unitPrice,
                minimumUnitsPurchase: project.minimumUnitsPurchase,
                maxInvestmentQuota: project.maxInvestmentQuota
            })))
        })

    }, [])

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors, touchedFields },
        clearErrors,
        watch
    } = useForm<FieldValues>({
        defaultValues: {
            project: simulationData.project,
            investmentValue: simulationData.investmentValue === "" ? (unitPrice() * MIN_UNITS) : simulationData.investmentValue,
            installmentsNumber: simulationData.installmentsNumber,
            unitValue: 0,
            minimumUnitsPurchase: 100
        },
        mode: 'onTouched',
    });
    const onSubmit: SubmitHandler<FieldValues> = (data) => {
        setSimulationData((prevData: typeof simulationData) => ({
            ...prevData,
            project: data.project,
            investmentValue: data.investmentValue,
            installmentsNumber: data.installmentsNumber,
            unitValue: data.unitValue,
            minimumUnitsPurchase: minimumUnitsPurchaseSelected,
            installmentOptions: installmentOptions
        }));
        if (isAuth) {
            createSimulation({
                "investmentValue": parseFloat(data.investmentValue),
                "installmentsNumber": parseFloat(data.installmentsNumber),
                "projectId": data.project
            }).then((response: AxiosResponse<SimulationResponseAPIInterface>) => {
                setSimulationResponse(response.data.data)
            })
            const emailFromToken = getNameEmail().email;
            const nameFromToken = getNameEmail().name;
            /* generateLead(
                nameFromToken,
                emailFromToken,
                data.investmentValue,
                (parseFloat(data.investmentValue) / data.unitValue),
                parseFloat(data.installmentsNumber),
                '',
                true
            ) */
            generateLeadInCRM(
                nameFromToken,
                emailFromToken,
                data.investmentValue,
                (parseFloat(data.investmentValue) / data.unitValue),
                parseFloat(data.installmentsNumber),
                '',
                true,
                ''
            )
            createContactInQuiiven(
                nameFromToken,
                emailFromToken,
                data.investmentValue,
                (parseFloat(data.investmentValue) / data.unitValue),
                parseFloat(data.installmentsNumber),
                '',
                true,
                '',
                UTM.utmSource,
                UTM.utmMedium,
                UTM.utmCampaign,
                UTM.utmTerm,
                UTM.utmContent
                
            )
            saveSimulation(
                data.project,
                parseFloat((parseFloat(data.investmentValue)/(watch('unitValue') === 0 ? simulationData.unitValue : watch('unitValue'))).toFixed(1)),
                parseFloat(data.investmentValue),
                parseInt(data.installmentsNumber),
                true,
                undefined,
                "new simulator",
                getNameEmail().email, 
                undefined,
                undefined,
                nameFromToken,
                UTM.utmSource,
                UTM.utmMedium,
                UTM.utmCampaign,
                UTM.utmTerm,
                UTM.utmContent
            )   
        }
    };
    const handleProjectChange = (val: any) => {
        setValue('project', val.value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        });
        setProjectSelected(val);
        setMinimumUnitsPurchaseSelected(val.minimumUnitsPurchase)
        setValue('minimumUnitsPurchase', val.minimumUnitsPurchase);
        setValue('unitValue', val.unitValue);
        clearErrors('project');
        const maxInvestmentQuota = val.maxInvestmentQuota;
        const installmentOptions = [{
            value: '0',
            label: 'Pago único'
        }]
        for (let i = 2; i <= maxInvestmentQuota; i++) {
            installmentOptions.push({
                value: i.toString(),
                label: i.toString() + ' meses'
            })
        }
        setInstallmentOptions(installmentOptions)
        const resetInstallment: any = { value: "0", label: "Pago único", disabled: false }
        if (installmentSelected) {
            setInstallmentSelected(resetInstallment)
            setValue('installmentsNumber', "0", {
                shouldDirty: true,
                shouldTouch: true,
                shouldValidate: true,
            });
        }
    };
    const handleInstallmentsChange = (val: any) => {
        setValue('installmentsNumber', val.value, {
            shouldDirty: true,
            shouldTouch: true,
            shouldValidate: true,
        });
        setInstallmentSelected(val);
        clearErrors('installmentsNumber');
    };

    useEffect(() => {
        const unitValue = (watch('unitValue') === 0 ? simulationData.unitValue : watch('unitValue'));
        const minimumUnitsPurchase = watch('minimumUnitsPurchase');

        setValue('investmentValue', unitValue * minimumUnitsPurchase, {
            shouldValidate: true,
            shouldTouch: true,
            shouldDirty: true
        });

        clearErrors('investmentValue'); 
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watch('unitValue'), watch('minimumUnitsPurchase')]);


    return (
        <form id='simulator-home-form' onSubmit={handleSubmit(onSubmit)} className='mt-14 w-full bg-[#DFDFFF] min-h-[230px] h-auto rounded-3xl px-[5%] pt-14 pb-10'>
            <div className='flex flex-col md:flex-row w-full justify-between'>
                <h1 className='primaryFont text-3xl md:text-4xl font-bold text-[#3533FF]'>SIMULADOR DE INVERSIONES</h1>
                <button className='hidden primaryFont text-xl text-white bg-[#221FEB] md:flex px-10 items-center justify-center rounded-full font-semibold h-16 md:h-auto'>
                    Calcular Retorno
                    <svg className='w-5 h-5 ml-3 fill-current text-white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-384c0-35.3-28.7-64-64-64L64 0zM96 64l192 0c17.7 0 32 14.3 32 32l0 32c0 17.7-14.3 32-32 32L96 160c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32zm32 160a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zM96 352a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM64 416c0-17.7 14.3-32 32-32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-96 0c-17.7 0-32-14.3-32-32zM192 256a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm32 64a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zm64-64a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm32 64a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zM288 448a32 32 0 1 1 0-64 32 32 0 1 1 0 64z" /></svg>
                </button>
            </div>
            <div className='flex  flex-col md:flex-row w-full justify-between mt-4'>
                <div className='md:min-h-fit min-h-[90px] w-full md:w-[32%]'>
                    <Select
                        classNames={{
                            menuButton: () =>
                                `secondaryFont flex justify-between h-[40px] border-[1px] text-[#4F4F4F] bg-white ${errors.project
                                    ? 'border-red-500'
                                    : !errors.project && touchedFields.project
                                        ? 'border-green-500'
                                        : 'border-gray-300'
                                } px-4 rounded-lg outline-none cursor-pointer focus:border-[#4743E0] ${projectSelected ? 'text-gray-800' : 'text-gray-400'
                                }`,
                            list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52',
                            listItem: ({ isSelected }) => `pl-[15px] list-none py-1 cursor-pointer ${isSelected && 'bg-[#4a4a4a] rounded-lg text-white py-2 px-3'}`,
                        }}
                        primaryColor="violet"
                        value={projectSelected}
                        onChange={handleProjectChange}
                        options={projects}
                        placeholder="Proyecto"
                    />
                    {errors.project && (
                        <div className="secondaryFont text-red-500 text-sm">* El proyecto es requerido.</div>
                    )}
                    <label className="secondaryFont block text-[#4F4F4F] text-sm">En que proyecto quisieras invertir?</label>
                    <input type="hidden" {...register('project', { required: true })} />
                </div>
                <div className='md:min-h-fit min-h-[90px] w-full md:w-[32%]'>
                    <Select
                        classNames={{
                            menuButton: () =>
                                `secondaryFont flex justify-between h-[40px] border-[1px] text-[#4F4F4F] bg-white ${errors.installmentsNumber
                                    ? 'border-red-500'
                                    : !errors.installmentsNumber && touchedFields.installmentsNumber
                                        ? 'border-green-500'
                                        : 'border-gray-300'
                                } px-4 rounded-lg outline-none cursor-pointer focus:border-[#4743E0] ${installmentSelected ? 'text-gray-800' : 'text-gray-400'
                                }`,
                            list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52',
                            listItem: ({ isSelected }) => `pl-[15px] list-none py-1 cursor-pointer ${isSelected && 'bg-[#4a4a4a] rounded-lg text-white py-2 px-3'}`,
                        }}
                        primaryColor="violet"
                        value={installmentSelected}
                        onChange={handleInstallmentsChange}
                        options={installmentOptions}
                        placeholder="Cuotas"
                    />
                    {errors.installmentsNumber && (
                        <div className="secondaryFont text-red-500 text-sm">* El número de cuotas es requerido.</div>
                    )}
                    <label className="secondaryFont block text-[#4F4F4F] text-sm">En cuantos meses quisieras pagar?</label>
                    <input type="hidden" {...register('installmentsNumber', { required: true })} />
                </div>
                <div className='md:min-h-fit min-h-[90px] w-full md:w-[32%]'>
                    <div className="flex text-sm text-[#4F4F4F] justify-between items-start h-min">
                        <span className='numberFont font-bold text-2xl -translate-y-1'>${formatNumberWithCommas(parseInt(watch('investmentValue')))}</span>
                        <div className='flex flex-col'>
                            <span className='numberFont leading-none font-bold'>{(parseInt(watch('investmentValue')) / parseInt((watch('unitValue') === 0 ? simulationData.unitValue : watch('unitValue')))).toFixed(0)}</span>
                            <span className='secondaryFont text-xxs leading-none' >Units</span>
                        </div>
                    </div>
                    <div className="flex justify-between items-center -translate-y-1">
                        <input
                            type="range"
                            min={(watch('minimumUnitsPurchase')) * (watch('unitValue') === 0 ? simulationData.unitValue : watch('unitValue'))}
                            max={MAXINVESTMENT}
                            step={1}
                            className={`w-full  ${errors.investmentValue
                                ? 'border-red-500'
                                : !errors.investmentValue && touchedFields.investmentValue
                                    ? 'border-green-500'
                                    : 'border-gray-300'
                                } rounded-lg outline-none focus:border-[#4743E0]`}
                            {...register('investmentValue', { required: true, min: (watch('minimumUnitsPurchase')) * (watch('unitValue') === 0 ? simulationData.unitValue : watch('unitValue')), max: MAXINVESTMENT })}
                            onChange={(e) => setValue('investmentValue', e.target.value)}
                        />
                    </div>
                    <label className="secondaryFont block text-[#4F4F4F] text-sm mb-2 -translate-y-2">¿Cuánto quieres invertir en total?</label>
                    {errors.investmentValue && (
                        <div className="secondaryFont text-red-500 text-sm">
                            * El valor de la inversión debe estar entre ${formatNumberWithCommas((watch('minimumUnitsPurchase')) * (watch('unitValue') === 0 ? simulationData.unitValue : watch('unitValue')))} y ${formatNumberWithCommas(MAXINVESTMENT, 0)}
                        </div>
                    )}
                </div>
                <button className='flex md:hidden primaryFont text-xl text-white bg-[#221FEB] px-10 items-center justify-center rounded-full font-semibold h-16 md:h-auto'>
                    Calcular Retorno
                    <svg className='w-5 h-5 ml-3 fill-current text-white' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path d="M64 0C28.7 0 0 28.7 0 64L0 448c0 35.3 28.7 64 64 64l256 0c35.3 0 64-28.7 64-64l0-384c0-35.3-28.7-64-64-64L64 0zM96 64l192 0c17.7 0 32 14.3 32 32l0 32c0 17.7-14.3 32-32 32L96 160c-17.7 0-32-14.3-32-32l0-32c0-17.7 14.3-32 32-32zm32 160a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zM96 352a32 32 0 1 1 0-64 32 32 0 1 1 0 64zM64 416c0-17.7 14.3-32 32-32l96 0c17.7 0 32 14.3 32 32s-14.3 32-32 32l-96 0c-17.7 0-32-14.3-32-32zM192 256a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm32 64a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zm64-64a32 32 0 1 1 0-64 32 32 0 1 1 0 64zm32 64a32 32 0 1 1 -64 0 32 32 0 1 1 64 0zM288 448a32 32 0 1 1 0-64 32 32 0 1 1 0 64z" /></svg>
                </button>
            </div>
        </form >
    )
}
