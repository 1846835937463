import React, { useState, useRef, useEffect } from 'react'
import Img_background_pop_up_ from "../../assets/img/pop-up-ambassadors/Img-backgorund-pop-up.jpg"
import pop_up_ambassador_icon_black_Facebook_pop_up_ from "../../assets/img/pop-up-ambassadors/icon-black-Facebook-pop-up.png"
import pop_up_ambassador_icon_black_Linkdin_pop_up_ from "../../assets/img/pop-up-ambassadors/icon-black-Linkdin-pop-up.png"
import pop_up_ambassador_icon_black_black_WhatsApp_pop_up_ from "../../assets/img/pop-up-ambassadors/icon-black-WhatsApp-pop-up.png"
import pop_up_ambassador_icon_black_black_X_pop_up from "../../assets/img/pop-up-ambassadors/icon-black-X-pop-up.png"
import pop_up_ambassador_icon_black_black_telegram_pop_up from "../../assets/img/pop-up-ambassadors/icon-black-telegram-pop-up.png"
import pop_up_ambassador_icon_black_black_instagram_pop_up from "../../assets/img/pop-up-ambassadors/icon-black-pop-up-instagram.png"
import closeIcon from '../../assets/img/home/close-white-2.svg'
import es from 'react-phone-number-input/locale/es.json';
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { ModalShareCodeUnitService } from '../../services/ReferCodeService';

import {
  FacebookShareButton,

  TwitterShareButton,

  WhatsappShareButton,

  TelegramShareButton,

  LinkedinShareButton,

} from 'react-share';
import { getUserData } from '../../services/ownerServices';
import jwt_decode from "jwt-decode";
import { checkSessionStorageToken } from '../../helpers/functions';
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form'
import toast from 'react-hot-toast'

const ModalShareCodeUnit = ({ setIsOpen }: any) => {


  const modalRef = useRef<HTMLDivElement>(null);
  const modalOverlayRef = useRef<HTMLDivElement>(null);
  const [code, setCode] = useState('');
  const [codeCopied, setCodeCopied] = useState(false);
  const [phone, setPhone] = useState<string | undefined>("");


  const getData = async (id: string) => {
    const { data } = await getUserData();
    const userCode = data.data.uniqueCode;
    setCode(userCode);
  }

  const copyTextToClipboard = () => {

    navigator.clipboard.writeText(`Hola me gustaría que conocieras este increíble proyecto. Usa mi link de referido para registrarte https://www.lokl.life?code=${code}`)
    setCodeCopied(true);
    setTimeout(() => {
      setCodeCopied(false);
    }, 3000);

  }



  useEffect(() => {

    const token = localStorage.getItem("token") ?? '';
    const decoded: any = jwt_decode(token);

    if (checkSessionStorageToken()) {
      getData(decoded.id);
    }



  }, []);

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    clearErrors,
    reset
  } = useForm<FieldValues>({
    defaultValues: {
      name: '',
      phone: '',
      email: ''
    },
    mode: 'onTouched',
  });

  const handlePhoneChange = (value: string | undefined) => {
    setValue('phone', value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
    setPhone(value);
    setValue('phone', value);
    clearErrors('phone');
  };

  const onSubmit: SubmitHandler<FieldValues> = async (data) => {
    try {
      const { name, phone, email } = data;
      const response = await ModalShareCodeUnitService(name, phone, email);

      console.log(response);


      if (response.success) {
        toast.success('Referido exitosamente');
        reset()
        setPhone('')

      } else {
        toast.error('Referido no válido');
      }
    } catch (error) {
      console.error('Error al enviar la invitación:', error);
      alert('Ocurrió un error. Por favor, inténtalo más tarde.');
    }
  };



  return (

    <div
      onClick={() => setIsOpen(false)}
      className={`fixed inset-0 z-50  bg-black/50 flex items-center justify-center pt-[60px] md:pt-[0px]  box-border`}

      ref={modalOverlayRef}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="bg-white w-full z-[9999] max-w-[90%] md:max-w-[70%] h-[90%] md:h-auto  max-h-[90%] lg:max-w-[50%] rounded-lg shadow-lg flex flex-col md:flex-row overflow-y-auto items-stretch"
        ref={modalRef}

      >

        {/* left */}
        <div className="bg-white flex flex-col items-center justify-between p-6 md:w-1/2  flex-1 h-[450px]">

          <img
            onClick={() => setIsOpen(false)}
            src={closeIcon}
            alt="Cerrar"
            className="md:hidden block absolute top-20 right-4 cursor-pointer w-5"
          />

          <div className="text-center">
            <h2 className="primaryFont text-2xl md:text-3xl font-light">
              No guardes <span className="font-bold">el secreto.</span>
            </h2>
            <p className="primaryFont text-base font-extralight my-2">
              <span className="font-bold">Comparte esta experiencia</span> con tus amigos y
            </p>
            <p className="text-[#504CFA] text-4xl  md:text-6xl font-extrabold mb-4">
              ¡crezcamos <br /> juntos!
            </p>
            <p className="primaryFont text-lg font-semibold">
              <span className="font-semibold">Refiere</span> a tus amigos
            </p>
            <p className="primaryFont text-xl font-extrabold">
              y si invierten gana hasta
            </p>
            <p className="primaryFont text-xl font-extrabold mb-4">
              <span className="numberFont">500.000</span> COP
            </p>
          </div>

          <div className="flex justify-between items-center border-2 p-2 rounded-full w-full mb-4">
            <span className="text-gray-400 text-sm truncate">
              www.lokl.life?code={code}
            </span>
            <button
              className="bg-[#504CFA] text-white text-xs md:text-sm font-bold rounded-full px-4 py-2"
              onClick={() => copyTextToClipboard()}
            >
              {codeCopied ? 'Copiado!' : 'Compartir'}
            </button>
          </div>

          <div className="flex justify-center gap-3">

            <FacebookShareButton
              url={`https://www.lokl.life?code=${code}`}
              quote={
                '¡Hola! Descubrí una plataforma increíble donde puedes invertir en proyectos inmobiliarios desde montos accesibles. 🚀 Échale un vistazo aquí:'
              }
              hashtag="#lokl"
            >
              <img
                src={pop_up_ambassador_icon_black_Facebook_pop_up_}
                alt="Facebook"
                className="w-12 h-w-12 cursor-pointer"
              />
            </FacebookShareButton>

            <TwitterShareButton
              url={`¡Hola! Descubrí una plataforma increíble donde puedes invertir en proyectos inmobiliarios desde montos accesibles. 🚀 Échale un vistazo aquí: https://www.lokl.life?code=${code}`}
            >
              <img
                src={pop_up_ambassador_icon_black_black_X_pop_up}
                alt="Twitter"
                className="w-12 h-w-12 cursor-pointer"
              />
            </TwitterShareButton>

            <WhatsappShareButton
              url={`¡Hola! Descubrí una plataforma increíble donde puedes invertir en proyectos inmobiliarios desde montos accesibles. 🚀 Échale un vistazo aquí: https://www.lokl.life?code=${code}`}
            >
              <img
                src={pop_up_ambassador_icon_black_black_WhatsApp_pop_up_}
                alt="WhatsApp"
                className="w-12 h-w-12 cursor-pointer"
              />
            </WhatsappShareButton>

            <TelegramShareButton
              url={`¡Hola! Descubrí una plataforma increíble donde puedes invertir en proyectos inmobiliarios desde montos accesibles. 🚀 Échale un vistazo aquí: https://www.lokl.life?code=${code}`}
            >
              <img
                src={pop_up_ambassador_icon_black_black_telegram_pop_up}
                alt="Telegram"
                className="w-12 h-w-12 cursor-pointer"
              />
            </TelegramShareButton>

            <LinkedinShareButton
              url={`¡Hola! Descubrí una plataforma increíble donde puedes invertir en proyectos inmobiliarios desde montos accesibles. 🚀 Échale un vistazo aquí: https://www.lokl.life?code=${code}`}
            >
              <img
                src={pop_up_ambassador_icon_black_Linkdin_pop_up_}
                alt="LinkedIn"
                className="w-12 h-w-12 cursor-pointer"
              />
            </LinkedinShareButton>

            <div
              className="relative h-[48px] w-[48px] flex items-center justify-center cursor-pointer"
              onClick={() => {
                navigator.clipboard.writeText(
                  `¡Hola! Descubrí una plataforma increíble donde puedes invertir en proyectos inmobiliarios desde montos accesibles. Usa mi link de referido para registrarte https://www.lokl.life?code=${code}`
                );
                window.open('https://www.instagram.com/lokl.life/', '_blank');
              }}
            >
              <img src={pop_up_ambassador_icon_black_black_instagram_pop_up} alt="Instagram" className="w-12 h-w-12 cursor-pointer" />
            </div>
          </div>
        </div>

        <img
          src={Img_background_pop_up_}
          alt="Background"
          className="w-full h-[150px] object-cover block sm:hidden"
        />


        {/*  right*/}
        <div className="flex flex-col items-center justify-between p-6 md:w-1/2 bg-[#232323]  relative  flex-1 h-[450px]">

          <img
            onClick={() => setIsOpen(false)}
            src={closeIcon}
            alt="Cerrar"
            className="hidden md:block absolute top-4 right-4 cursor-pointer w-5"
          />

          <div className="text-center text-white">
            <p className="text-sm mb-2">#CreceConLokl</p>
            <h2 className="text-3xl font-light">
              ¡Refiere a un amigo <br />
              <span className="font-bold">ahora!</span>
            </h2>
          </div>

          <form
            className="flex flex-col gap-4 w-full px-4 mt-6"
            onSubmit={handleSubmit(onSubmit)}
          >

            <div>
              <input
                type="text"
                placeholder="Nombre y apellido del referido"
                className={`w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-primary ${errors.name
                  ? 'border-red-500'
                  : touchedFields.name
                    ? 'border-green-500'
                    : 'border-gray-300'
                  } py-2 px-4 rounded-lg outline-none focus:border-[#4743E0]`}
                {...register('name', { required: true })}
              />
              {errors.name && (
                <div className="text-right secondaryFont text-red-500 text-sm">
                  * El nombre es requerido
                </div>
              )}
            </div>

            <div className='md:min-h-fit min-h-[40px]  md:w-full'>
              <PhoneInput
                className={`rounded-lg numberFont w-full h-[40px] text-black border-[1px] px-4 bg-white focus:border-[#4743E0] ${errors.phone?.message ? 'border-red-500' : 'border-gray-300'}`}
                international
                countryCallingCodeEditable={true}
                defaultCountry='CO'
                labels={es}
                placeholder="Teléfono"
                value={phone}
                onChange={handlePhoneChange}
              />
              <input type="hidden" {...register('phone', {
                required: 'El teléfono es requerido',
                minLength: { value: 8, message: 'El teléfono no es valido' }

              })} />
              {errors.phone && (
                <div className="text-right secondaryFont text-red-500 text-sm">
                  * {errors.phone?.message?.toString()}
                </div>
              )}
            </div>


            <div>
              <input
                type="email"

                placeholder="Correo electrónico del referido"
                className={`w-full px-4 py-2 border border-black rounded-lg focus:outline-none focus:ring-2 focus:ring-primary ${errors.email
                  ? 'border-red-500'
                  : touchedFields.email
                    ? 'border-green-500'
                    : 'border-gray-300'
                  } py-2 px-4 rounded-lg outline-none focus:border-[#4743E0]`}
                {...register('email', { required: true })}
              />
              {errors.email && (
                <div className="text-right secondaryFont text-red-500 text-sm">
                  * El correo es requerido
                </div>
              )}
            </div>

            <a href='https://drive.google.com/file/d/1Y8nyaVvniHKqP-I9y6y_IHp0Pl8DWpOu/view?usp=sharing' target='_blank' className="text-sm mt-2 text-left text-white cursor-pointer hover:!text-[#504CFA] no-underline" rel="Terms_and_conditions_ambassadors noreferrer">
              * Ver términos y condiciones
            </a>
            <button
              type="submit"
              className="bg-[#504CFA] text-white py-2 rounded-xl hover:bg-[#403CC0] transition w-40 mx-auto"
            >
              Enviar invitación
            </button>
          </form>
        </div>
      </div>
    </div>
  )
}

export default ModalShareCodeUnit;