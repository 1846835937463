interface StarRatingProps {
    rating: number;
  }
  
  const StarRating: React.FC<StarRatingProps> = ({ rating }) => {
    const MAX_STARS = 5;
  
    return (
      <div style={{ display: 'flex', gap: '4px' }}>
        {[...Array(MAX_STARS)].map((_, index) => (
          <svg
            key={index}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill={index < rating ? 'gold' : 'lightgray'}
            width="15"
            height="15"
          >
            <path d="M12 .587l3.668 7.568L24 9.423l-6 5.841 1.415 8.231L12 18.896l-7.415 4.599L6 15.264 0 9.423l8.332-1.268L12 .587z" />
          </svg>
        ))}
      </div>
    );
  };
  
  export default StarRating;