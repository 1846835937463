import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { checkSessionStorageToken, numberWithCommas } from '../../helpers/functions';
import moment from 'moment';
import { getDividendReinvestmentByDividendReinvestmentId, getDividendReinvestmentByGatewayTransactionId } from '../../services/paymentDataServices';
import { useEffectOnce } from '../../helpers/hooks';
import { getProjectCards } from '../../services/projectService';
import { ProjectCardDataInterface, ProjectCardResponseInterface } from '../NewHome/interfaces/ProjectCardDataInterface';
import { CapitalizeFirstLetter } from '../../utils/otherFormat';


export const Approved = () => {

    const [dividendReinvestment, setDividendReinvestment] = useState<any>(undefined);
    const [project, setProject] = useState<ProjectCardDataInterface>({
        id: "",
        projectCode: "",
        minRent: 1,
        maxRent: 1,
        unitPrice: 1,
        partners: 1,
        name: "",
        phase: "",
        country: "",
        city: "",
        accommodations: 1,
        squareMeters: 1,
        amenities: [],
        minInvestmentUnits: 1,
        maxInvestmentQuota: 1,
        availableSpots: 1,
        totalSpots: 1,
        videoURL: "",
        imageURL: ""
    })
    const useQuery = () => new URLSearchParams(useLocation().search);
    const idTransaction = useQuery().get('id');
    const [conditionDividend, setConditionDividend] = useState(false)
    const dividendReinvestmentTransactionId = useQuery().get('dividendReinvestmentTransactionId');
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const getData = async () => {
        if (!checkSessionStorageToken()) {
            goToRegister();
            return;
        }
        try {
            if (idTransaction) {
                getDividendReinvestmentByGatewayTransactionId(idTransaction).then(async (resp) => {

                    setDividendReinvestment(resp?.data);
                    const projectId = resp?.data.investment.projectId;

                    getProjectCards().then((data: ProjectCardResponseInterface) => {
                        const filteredProject = data.data.find((project: ProjectCardDataInterface) => (project.id === projectId));
                        if (filteredProject) {
                            setProject(filteredProject)
                            setConditionDividend(resp?.data.dividendReinvestmentTransaction && resp?.data.dividendReinvestmentTransaction.amount && resp?.data.dividendReinvestmentTransaction.amount > 0)
                        }
                    })
                })
            }
            else if (dividendReinvestmentTransactionId) {
                getDividendReinvestmentByDividendReinvestmentId(dividendReinvestmentTransactionId).then(async (resp) => {
                    setDividendReinvestment(resp?.data);
                    const projectId = resp?.data.investment.projectId;

                    getProjectCards().then((data: ProjectCardResponseInterface) => {
                        const filteredProject = data.data.find((project: ProjectCardDataInterface) => (project.id === projectId));
                        if (filteredProject) {
                            setProject(filteredProject)
                            setConditionDividend(true)
                        }
                    })
                })
            }
        } catch (error: any) {

            navigate('/payment/fail');

        }

    }

    useEffectOnce(() => {

        getData();

    });

    const goToRegister = async () => {
        const url = new URL(window.location.href);
        const baseUrl = new URL(url).origin + new URL(url).pathname;
        const urlParams = new URLSearchParams(new URL(url).search);
        const parameters = urlParams.toString();
        const urlToRedirect = `/register?redirect_to=${baseUrl}?${parameters}`;
        navigate(urlToRedirect);
    }

    return (
        <>
            <div className='flex flex-col w-full lg:w-[1024px] lg:flex-row lg:space-x-16 py-16 md:py-32'>
                <div className="relative lg:w-1/2 h-fit bg-current rounded-3xl">
                    {project && project.imageURL && (
                        <img className="rounded-3xl w-full h-auto aspect-square object-cover object-right" src={project.imageURL} alt="" />
                    )}
                    <div className="rounded-3xl flex flex-col justify-end items-center absolute w-full h-full top-0 left-0  bg-[#00000045]">
                        {dividendReinvestment && dividendReinvestment.user && (
                            <div className="text-white font-extrabold text-4xl text-center">
                                ¡Hola {CapitalizeFirstLetter(dividendReinvestment.user.firstName)}!
                            </div>
                        )}
                        <div className="text-white text-xl text-center mb-4">
                            Es un placer tenerte de vuelta
                            <br />
                            ¡Gracias por seguir confiando en nosotros!
                        </div>
                    </div>
                </div>

                <div className="w-full lg:w-1/2 lg:space-y-7 mt-5 md:mt-[0px]">

                    <div className="text-xl text-green-400 font-medium">
                        Inversión aprobada <br />
                    </div>

                    {dividendReinvestment && dividendReinvestment.investment &&
                        <div className="text-gray-500 text-sm">
                            {moment(dividendReinvestment.investment.createAt).format('LL')}
                            <br />
                            {moment(dividendReinvestment.investment.createAt).format('hh:mm a')}
                        </div>
                    }

                    <div className="flex items-end">

                        <div className="">

                            <div className="font-extrabold font-lato text-3xl">
                                {CapitalizeFirstLetter(project.name)}
                            </div>
                            {
                                dividendReinvestment && dividendReinvestment.transaction &&
                                <>
                                    <div className="font-extrabold font-lato text-3xl mt-3">
                                        ${numberWithCommas(dividendReinvestment.transaction.value)}
                                    </div>
                                    {
                                        conditionDividend ?
                                            <div className="text-gray-500 text-sm">
                                                Pago extra
                                            </div> :
                                            <div className="text-gray-500 text-sm">
                                                Valor pagado
                                            </div>
                                    }
                                </>
                            }
                            {dividendReinvestment && dividendReinvestment.investment &&
                                <>
                                    {
                                        conditionDividend &&
                                        <>
                                            <div className="font-extrabold font-lato text-3xl">
                                                ${numberWithCommas(dividendReinvestment.dividendReinvestmentTransaction.amount || 0)}
                                            </div>
                                            <div className="text-gray-500 text-sm">
                                                Pago a través de utilidades
                                            </div>
                                        </>
                                    }
                                    <div className="font-extrabold font-lato text-3xl text-green-400 mt-10">
                                        Inversión: ${numberWithCommas(dividendReinvestment.investment.investmentValue || 0)}
                                    </div>
                                    {
                                        conditionDividend &&
                                        <div className="text-gray-500 text-base mt-3">
                                            El pago a través de utilidades estara en proceso de validacíon, pronto se encontrara reflejado en tu reinversión.
                                        </div>
                                    }
                                </>
                            }
                        </div>

                    </div>

                    <div className="flex divide-x-2 space-x-2 text-sm leading-tight mt-5 md:mt-[0px]">

                        <div className="">{project.phase}</div>
                        {dividendReinvestment && dividendReinvestment.investment &&
                            <>
                                <div className="pl-2">Número de Units: {numberWithCommas(dividendReinvestment.investment.unitsQuantity)}</div>
                                <div className="pl-2">Costo por Unit: ${numberWithCommas(dividendReinvestment.investment.unitValue)}</div>
                            </>
                        }

                    </div>

                    <div className="mt-5 md:mt-[0px] flex justify-center md:justify-start">

                        <button
                            className="bg-gray-900 text-white hover:bg-gray-700 w-60 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out"
                            onClick={() => navigate(`/dashboard`)}
                        >
                            Continuar
                        </button>

                    </div>

                </div>

            </div>

        </>
    )

}