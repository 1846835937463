import React from 'react'
import img from '../../../assets/img/about-us/img-lets-growth.jpg'


export const LetsGrowthTogether = () => {
  return (
    <header className="relative w-full   h-[100vh] max-h-[100vh] md:max-h-[740px] md:h-[100vh]  overflow-hidden">
    <img src={img} alt="Somos la plataforma inmobiliaria de las nuevas generaciones | LOKAL." className="w-full h-full md:h-auto object-cover" />

  
    <div className="absolute inset-0 bg-gradient-to-t from-black/95 to-transparent"></div>

  
  <div className="absolute inset-0 flex items-center justify-center primaryFont">
  <h1 className="text-white text-center primaryFont font-light text-2xl md:text-3xl lg:text-5xl leading-snug p-4">
        Somos <br />
        <span className="font-extralight italic  primaryFont first-letter:font-roboto">la <span className=' primaryFont font-semibold'>plataforma</span> </span> <br />
        inmobiliaria de las  <br />
        nuevas <span className="italic primaryFont font-thin"> generaciones.</span>
    </h1>
  </div>

  
    <div className="absolute secondaryFont top-24 left-8 text-white text-xl md:text-3xl lg:text-4xl ">
    CRECE
  </div>
  <div className="absolute  secondaryFont top-24 right-8 text-white text-xl md:text-3xl lg:text-4xl ">
    JUNTO
  </div>
  <div className="absolute secondaryFont bottom-8 left-8 text-white text-xl md:text-3xl lg:text-4xl ">
    A
  </div>
  <div className="absolute secondaryFont bottom-8 right-8 text-white text-xl md:text-3xl lg:text-4xl ">
    NOSOTROS
  </div>

  </header>


  )
}
