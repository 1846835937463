import React from "react";
import PhoneInput from "react-phone-number-input";

import "react-phone-number-input/style.css";
import es from "react-phone-number-input/locale/es.json";

interface PhoneInputProps {
  value: { phone: string; countryCode: string };
  label: string;
  onChange: (value: { phone: string; countryCode: string }) => void;
  error?: string;
  id: string;
}


export const PhoneInputComponent: React.FC<PhoneInputProps> = ({
  id,
  value,
  onChange,
  error,
  label,
  
}) => {

 

  return (
    <div className="md:min-h-fit min-h-[40px] md:w-full">
      <label className="block text-sm font-medium text-gray-700 mb-1">{label}</label>
      
      <PhoneInput
      id={id}
  className="rounded-lg numberFont w-full h-[40px] text-black border-[1px] px-2 bg-white focus:border-[#4743E0]"
  international
  countryCallingCodeEditable={true}
  defaultCountry="CO"
  labels={es}
  placeholder="Teléfono"
  value={value.phone} 
  onChange={(newValue) =>
    onChange({ phone: newValue || "", countryCode: value.countryCode }) 
  }
/>


     
    </div>
  );
};
