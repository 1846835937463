import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

import starIcon from '../../../assets/img/dashboard/star-yellow.svg';
import ModalShareCodeUnit from '../../../components/PopUpAmbassadors/ModalShareCodeUnits';
import { on } from '../../../helpers/events';
import { numberWithCommas } from '../../../helpers/functions';
import { getOwnersInvestment } from '../../../services/dashboardServices';

function DashboardProjects() {

  const [statusProjects, setStatusProjects] = useState<any>('pending');
  const [myProjects, setMyProjects] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
    const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
       
    const timer = setTimeout(() => {
      setShowModal(true); 
      setIsOpen(true);  
    }, 1500); 

    
    return () => clearTimeout(timer);
      }, []);

  /**
   * Funcion para obtener información sobre los proyectos 
   * en los que ha invertidon el usuario
   */
  const getDataDashborad = async () => {

    sessionStorage.removeItem("projects");

    // const projectsStored = sessionStorage.getItem("projects");
   
    // if(projectsStored){

    //   const project = JSON.parse(projectsStored);

    //   setMyProjects(project);
    //   setStatusProjects("success");      

    // }else{

      try {
        
        const { data, status } = await getOwnersInvestment();
  
        // const projects = data.filter((p: any) => p.property !== null);
  
        // sessionStorage.setItem("projects", JSON.stringify(projects));
        
        setMyProjects(data);
        setStatusProjects(status);
        
      } catch (error) {
        
        setStatusProjects('error');
  
      }

    // }

  }

  /**
   * Funcion que manda a la vista de detalle
   */
  const seeDetails = (project: any) => {
    navigate(project.projectId, {
      state: {
        projectId: project.projectId,
        title: project.projectName,
        description: project.projectDescription,
        investment: project.totalInvestmentValue,
        shares: parseFloat(project.units.toFixed(2)),
        unitsByReferral: project.unitsByReferral,
        location: '',
        roi: project.projectName.includes('nido') ? 15 : 14,
        rate: '5',
        min_invested: parseInt(project.projectValuation),
        data: [
          {
            name: 'Valor del Unit',
            value: numberWithCommas(project.averageUnitValue),
          },
          {
            name: 'Etapa',
            value: 'Finalizado',
          },
          {
            name: 'Cupo de inversión',
            value: numberWithCommas(parseInt(project.projectValuation)),
          },
          {
            name: 'Estado del proyecto',
            value: project.projectName === "nido de agua" ? 'En proceso' : 'Completado',
          },
          {
            name: 'Detalles de pago',
            value: 'Aprobado',
          },
        ]
      }
    });
  }

  useEffect(() => {

    getDataDashborad();
    
    on('reload:dashboard', () => {
      getDataDashborad();
    });

  }, []);

  return (
    <>

      <h2 className="text-[23px] font-lato font-black mb-[17px] mt-44 lg:mt-0">Mis inversiones</h2>

      <div className="flex items-start flex-wrap space-y-5 lg:space-y-0">

        {
          statusProjects === 'pending' ? 
            <h2>Cargando inversiones...</h2>
          : 
          myProjects.length > 0 ? myProjects.map((project: any, index) => 
          
            <div key={index} className="lg:w-[330px] h-auto bg-white rounded-md lg:mr-8 lg:mb-8 p-[30px]">

              {/* <div className="flex items-start mb-2">

                <div className="text-xs px-4 py-1.5 bg-[#FFE8FD]">Etapa 1</div>

              </div> */}

              <div className="mb-3 flex justify-between items-center">
                
                <h2 className="text-[21px] font-lato font-black capitalize">{project?.projectName}</h2>

                <div className="flex">

                  <img className="w-[16px]" src={starIcon} alt="star" />
                  <div className="text-[15px] ml-2">5</div>
                
                </div>

              </div>

              <p className={`text-[11px] w-[95%] ${ project?.projectName.includes('indie') ? 'mb-[15px]' : 'mb-[30px]' }`}>
                
                {project.projectDescription}

              </p>

              { 
                project?.projectName.includes('indie') &&
                <div className='my-[15px]'>
                  <a 
                    className='text-[#3533FF] text-base leading-5'
                    target="_blank" 
                    rel="noreferrer"
                    href="https://wa.link/mheirj"
                  >
                    Reserva en Indie
                  </a> 
                </div> 
              }

              <div className="flex flex-row justify-between items-end">

                <div className="">

                  <h2 className="text-[18px] font-bold">${numberWithCommas(project?.totalInvestmentValue)}</h2>
                  <h2 className="text-[12px] font-normal">Monto invertido</h2>

                </div>

                <div className="">

                  <h2 className="text-[18px] font-bold">{numberWithCommas(project?.units + project?.unitsByReferral)}</h2>
                  <h2 className="text-[12px] font-normal">Units</h2>

                </div>

                <button 
                  className="h-9 bg-black text-white text-[10px] px-3 rounded-full"
                  onClick={() => seeDetails(project)}
                >
                  Ver inversión
                </button>

              </div>

            </div>

          ) : <h2>No posees inversiones</h2>

        }

      </div>

      {showModal && isOpen && <ModalShareCodeUnit setIsOpen={setIsOpen} />}

    </>
  )

}

export default DashboardProjects;