export const SkeletonProperty = () => {
    return (
        <div className="flex flex-col items-start container mx-auto xl:max-w-7xl pt-52 pb-40 space-y-5 md:space-y-20 lg:space-y-16">

            <div className="w-full flex flex-col items-start space-y-7">
                <div className="skeleton w-[120px]  h-[40px] rounded-lg"></div>
                <div className="h-[500px] md:h-[600px] flex justify-between w-full">
                    <div className="h-full skeleton w-full md:w-[59%] rounded-lg"></div>
                    <div className="h-full skeleton w-[37%] rounded-lg hidden md:flex"></div>
                </div>
            </div>


            <div className="skeleton w-full h-16 rounded-lg"></div>


            <div className="grid w-full h-full grid-cols-12 gap-8">

                <div className="col-span-12 lg:col-span-8 space-y-12">

                    <div className="skeleton w-full h-96 md:h-48 rounded-lg"></div>
                    <hr className="hidden md:block" />

                    <div className="skeleton w-full h-44 md:h-96 rounded-lg"></div>
                    <hr className="hidden md:block" />


                    <div className="skeleton w-full h-96 rounded-lg"></div>
                    <hr className="hidden md:block" />


                    <div className="skeleton w-full h-96 rounded-lg"></div>
                </div>


                <div className="col-span-12 w-full lg:col-span-4 space-y-6">

                    <div className="skeleton w-full h-96 rounded-lg"></div>
                    <div className="skeleton w-full h-96 rounded-lg"></div>

                    <div className="skeleton w-full h-96 rounded-lg"></div>
                </div>

            </div>


            <div className="w-full space-y-8">
                <hr />
                <div className="skeleton w-full h-96 rounded-lg"></div>
            </div>

            <div className="w-full space-y-8">
                <hr />
                <div className="skeleton w-full h-64 rounded-lg"></div>
            </div>

            <div className="w-full space-y-8">
                <hr />
                <div className="skeleton w-full h-96 rounded-lg"></div>
            </div>
        </div>

    )
}
