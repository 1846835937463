import axios from "axios";
import { urls } from "../helpers/urls";

export interface ContactDataAc {
    email: string;
    firstName: string;
    lastName: string;
    phone: string;
    source: string;
    medium: string;
    howDidYouMeetUs: string;
    campaign?: string;
    term?: string;
    content?: string;
    tag?: string;
}

export const CreateContactInAC = async (data: ContactDataAc): Promise<any> => {
    const body = {
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,    
        phone: data.phone,
        source: data.source,
        medium: data.medium,
        howDidYouMeetUs: data.howDidYouMeetUs,
        campaign: data.campaign || null,
        term: data.term || null,
        content: data.content || null,
        tag: data.tag
    };

    try {
        const response = await axios.post(`${urls.NEW_API_URL}lead/createContact`, body);
        return response;
    } catch (error) {
        if (axios.isAxiosError(error) && error.response) {
            throw error.response.data;
        } else {
            throw error;
        }
    }
    
};