import React, { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";

export default function AmbassadorHowItsWorks() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentRef = sectionRef.current;

    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.2 }
    );

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <div
      id="howitworks"
      ref={sectionRef}
      className="flex flex-col relative items-center text-center mt-16 md:mt-24 h-[100vh] w-full px-4 md:justify-start justify-center"
    >
      <motion.p
        className="primaryFont text-[#5352F6] text-xl md:text-3xl mt-10 md:mt-20 font-light mb-4"
        initial={{ opacity: 0, y: 20 }}
        animate={isVisible ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8 }}
      >
        #CreceConLokI
      </motion.p>

      <motion.h2
        className="primaryFont text-[#5352F6] text-5xl md:my-9 mt-10 md:text-8xl font-bold mb-8"
        initial={{ opacity: 0, y: 20 }}
        animate={isVisible ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8, delay: 0.2 }}
      >
        ¿Cómo funciona?
      </motion.h2>

      <motion.div
        className="flex flex-col md:flex-row items-center justify-center gap-4 md:gap-8 w-full"
        initial={{ opacity: 0, scale: 0.9 }}
        animate={isVisible ? { opacity: 1, scale: 1 } : {}}
        transition={{ duration: 0.8, delay: 0.4 }}
      >
        <div className="flex items-center border-[1px] border-[#5352F6] rounded-2xl px-6 mx-6 py-4 md:w-[720px] primaryFont text-[#5352F6] text-sm md:text-2xl font-light relative">
          <span className="font-bold text-base md:text-4xl mr-4">HTTP</span>
          <p className="relative primaryFont border-l-[1px] md:border-l-0 border-[#5352F6] font-normal text-base md:text-2xl text-left pl-8">
            <div className="absolute hidden md:block md:-translate-y-7 -translate-y-7 left-[10px] h-[145%] md:h-[190%] w-[1px] bg-[#5352F6] md:rotate-[25deg]"></div>
            Cada embajador recibe un código y URL único para <br /> invitar a sus contactos. Por cada referido que invierta...
          </p>
        </div>
      </motion.div>

      <motion.p
        className="primaryFont text-[#5352F6] md:mt-16 text-5xl md:text-8xl font-bold mt-12"
        initial={{ opacity: 0, y: 20 }}
        animate={isVisible ? { opacity: 1, y: 0 } : {}}
        transition={{ duration: 0.8, delay: 0.6 }}
      >
        ¡Tu ganas!
      </motion.p>
    </div>
  );
}
