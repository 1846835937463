import React, { useEffect, useRef, useState } from 'react'

import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { scrollToSection } from '../../helpers/functions';
import VideoComponent from '../../components/VideoComponent';
// import UseAnimations from 'react-useanimations';
// import playPause from 'react-useanimations/lib/playPause';
import { motion } from "framer-motion";

import playIcon from "../../assets/img/blog/play.svg";
import img1 from "../../assets/img/blog/card-1.png";
import img2 from "../../assets/img/blog/card-2.png";
import descubreSectores from "../../assets/img/blog/descubre-sectores.jpg";
import ahorroInversion from "../../assets/img/blog/ahorro-inversion.jpg";
/* import nidoImg from "../../assets/img/blog/card-nido.png"; */
import imgCamiloKarem from "../../assets/img/blog/lokl-camilo-karem.jpeg";
import imgCamilo from "../../assets/img/blog/lokl-camilo.jpeg";
import imgExito from "../../assets/img/blog/exito-financiero-lokl.jpg";
import imgColectivo from "../../assets/img/blog/colectivo-milenial-lokl.jpg";
import imgColectivo2 from "../../assets/img/blog/colectivo-milenial-lokl2.jpg";
import imgUser from "../../assets/img/blog/user.png";
import wradioAudio from "../../assets/img/blog/audio-wradio-camilo-lokl.mp3";
import WritingEffect from '../../components/WrittingEffect';
import { articles } from './Articles';
import useTitle, { useDescription, useKeywords } from '../../hooks/useSEO';
// import WritingEffect from '../../components/WrittingEffect';
// import PausePlayButton from '../../components/PlayPauseButton';

export const PlaySVG = () => (
  <svg width="40" height="39" viewBox="0 0 40 39" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M20 0.40625C9.24781 0.40625 0.5 8.97182 0.5 19.5C0.5 30.0282 9.24781 38.5938 20 38.5938C30.7522 38.5938 39.5 30.0282 39.5 19.5C39.5 8.97182 30.7522 0.40625 20 0.40625ZM27.0097 20.3537L16.28 26.7005C16.1262 26.7908 15.9507 26.8393 15.7714 26.8412C15.5921 26.843 15.4156 26.7982 15.2598 26.7112C15.1041 26.6242 14.9748 26.4982 14.8852 26.3462C14.7956 26.1941 14.749 26.0215 14.75 25.8459V13.1541C14.749 12.9785 14.7956 12.8059 14.8852 12.6538C14.9748 12.5018 15.1041 12.3758 15.2598 12.2888C15.4156 12.2018 15.5921 12.1569 15.7714 12.1588C15.9507 12.1607 16.1262 12.2092 16.28 12.2995L27.0097 18.6463C27.1592 18.7355 27.2829 18.8609 27.3686 19.0104C27.4544 19.1599 27.4995 19.3285 27.4995 19.5C27.4995 19.6715 27.4544 19.8401 27.3686 19.9896C27.2829 20.1391 27.1592 20.2645 27.0097 20.3537Z" fill="url(#paint0_linear_3245_176)" fill-opacity="0.8" />
    <defs>
      <linearGradient id="paint0_linear_3245_176" x1="0.5" y1="19.5" x2="39.5" y2="19.5" gradientUnits="userSpaceOnUse">
        <stop stop-color="#FDFBFB" />
        <stop offset="1" stop-color="#EBEDEE" />
      </linearGradient>
    </defs>
  </svg>
)

export const PlaySVGBlack = () => (
  <svg width="31" height="31" viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.3214 0C6.87328 0 0 6.87328 0 15.3214C0 23.7696 6.87328 30.6429 15.3214 30.6429C23.7696 30.6429 30.6429 23.7696 30.6429 15.3214C30.6429 6.87328 23.7696 0 15.3214 0ZM20.829 16.0065L12.3986 21.0994C12.2777 21.1718 12.1398 21.2107 11.999 21.2122C11.8581 21.2137 11.7194 21.1777 11.597 21.1079C11.4747 21.0381 11.3731 20.937 11.3027 20.815C11.2323 20.693 11.1956 20.5545 11.1964 20.4136V10.2293C11.1956 10.0884 11.2323 9.94985 11.3027 9.82784C11.3731 9.70582 11.4747 9.60473 11.597 9.53493C11.7194 9.46513 11.8581 9.42913 11.999 9.43064C12.1398 9.43214 12.2777 9.47109 12.3986 9.54348L20.829 14.6364C20.9466 14.708 21.0437 14.8086 21.1111 14.9286C21.1785 15.0485 21.2139 15.1838 21.2139 15.3214C21.2139 15.459 21.1785 15.5943 21.1111 15.7143C21.0437 15.8343 20.9466 15.9349 20.829 16.0065Z" fill="url(#paint0_linear_3249_106)" fill-opacity="0.9" />
    <defs>
      <linearGradient id="paint0_linear_3249_106" x1="0" y1="15.3214" x2="30.6429" y2="15.3214" gradientUnits="userSpaceOnUse">
        <stop />
        <stop offset="1" />
      </linearGradient>
    </defs>
  </svg>
)

function Blog() {
  useTitle("Blog de Lokl | Ideas, Consejos y Tendencias en Inversión Inmobiliaria Digital")
  useDescription("Descubre las mejores estrategias de inversión, oportunidades de crecimiento y rentabilidad en el mercado actual. Claves para hacer que tu dinero trabaje para ti y alcanzar tus metas financieras con nuestras estrategias de inversión probadas.")
  useKeywords("blog, inversiones, inmobiliarias, rentabilidad, crecimiento, mercado, estrategias, dinero, metas, financieras, oportunidades, crecimiento, rentabilidad, mercado, estrategias, inversion, inmobiliaria, digital, lokl")
  // TODO: Verify if is possible to improve this with react-router-dom config
  // Code to scroll to top when change page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const optionsList = [
    "Episodios Podcast",
    "Blog",
    "Academia LOKL",
  ];
  
  const optionsAcademy = [
    "Colectivo millenial",
    "Éxito Financiero",
    "Karem Suarez",
  ];

  const videos = {
    nido: 'VogBkJMkJhY',
    colectivo: 't-3dbgPqKTs',
    sebas: 'srbFIv7cgvE',
    karem: '1o7WWfcSZ94',
    camilo: 'CQDtTMMa2mQ',
  }

  const [tabOptions] = useState(optionsList);
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [activeTabAcademy, setActiveTabAcamedy] = useState(0);

  let audioWradio = useRef(new Audio(wradioAudio));

  const reproduceAudio = (audioRef: any) => {

    if (audioRef.current.paused === false) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }

  };

  return (
    <main className='w-full max-w-full overflow-hidden'>
    <article className='pt-48 pb-40 bg-[#F9F9F9]'>

      <div className="wrapper-lokl space-y-5">

        <div className="flex flex-col md:flex-row mb-12 w-[90%] md:w-full ml-[5%] md:ml-0">

          <div className="w-full md:w-1/2 space-y-5">

            <h1 className="text-[#52414180]">BLOG LOKL</h1>
            <h2 className="font-lato text-3xl font-extrabold">Inversiones inteligentes</h2>
            <p className="text-sm w-4/5">
              Descubre las mejores estrategias de inversión, oportunidades de crecimiento y rentabilidad en el mercado actual.
              Claves para hacer que tu dinero trabaje para ti y alcanzar tus metas financieras con nuestras estrategias de inversión probadas.
            </p>

          </div>

          <div className="w-full md:w-1/2 mt-4 flex items-start justify-start">

            <motion.img
              className='mr-3 cursor-pointer'
              src={playIcon}
              alt="play--lokl"
              initial={{ x: -10, rotate: -90 }}
              animate={{ x: 0, rotate: 0 }}
              transition={{ ease: "easeOut", duration: 2 }}
              onClick={() => {
                reproduceAudio(audioWradio);
              }}
            />
            {/* <div className="">

              <UseAnimations reverse={audioWradio.current.paused} animation={playPause} speed={3} size={40} style={{ paddingLeft: 10 }} onClick={() => {reproduceAudio(audioWradio); }}  />

            </div> */}

            <div className="">
              <div className="font-extrabold">WRADIO</div>
              <div className="font-lato font-extrabold">Entrevista Camilo Olarte CEO de LOKL</div>
            </div>

          </div>

        </div>

        <div className="">

          <TabList className="flex space-x-6 font-bold text-lg list-none mb-10">

            {
              tabOptions.map((tab, key) =>
                <Tab key={key} className={`min-w-[100px] cursor-pointer outline-none text-center font-roboto ${key === activeTabIndex ? ' text-black ' : 'text-gray-400'}`}
                  onClick={() => {
                    let section = "";
                    if (tab === "Episodios Podcast") {
                      section = "podcast"
                    }
                    if (tab === "Blog") {
                      section = "blog"
                    }
                    if (tab === "Academia LOKL") {
                      section = "academia"
                    }
                    scrollToSection(section, 150);

                    setActiveTabIndex(key)
                  }}
                >
                  {tab}
                  {
                    key === activeTabIndex &&
                    <motion.div
                      initial={{ x: -10, scale: 0 }}
                      animate={{ x: 0, scale: 1 }}
                      transition={{ ease: "easeOut", duration: 1.2 }}
                      className='px-2 h-1 bg-black mt-2'

                    ></motion.div>
                  }
                </Tab>
              )
            }

          </TabList>

          <div className="flex mb-4 text-sm md:text-xl">

            <div className="w-1/2 text-sm md:text-xl">
              <WritingEffect text="<strong>¡No dejes que tus finanzas</strong> sean un misterio!" fontSize="17px" fontWeight="normal" />
            </div>

            <div className="w-1/2 text-sm md:text-xl">
              <WritingEffect text="<strong>¡Descubre cómo hacer que tu dinero</strong> trabaje para ti!" fontSize="17px" fontWeight="normal" />
            </div>

          </div>

        </div>

      </div>

      <div id='blog' className="wrapper-lokl">

        <div className="w-full inline-flex overflow-x-auto scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 space-x-4 pb-3 mb-5">

          {/* <motion.div 
            initial={{ scale: 0 }}
            whileInView={{ scale: 1 }}
            className="flex flex-col items-start flex-shrink-0 justify-between p-12 text-[#0A180E] bg-white rounded-xl"
          >
            
            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-gray/50">EDUCACIÓN</div>

              <div className='font-lato font-extrabold text-2xl'>
                ¿Qué son inversiones? <br /> y cuales son las más <br /> rentables.
              </div>

            </div>

            <div className="bg-[#FF7171] text-[#010067] hover:bg-[#FF7171]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline">
              Ver nota
            </div>

          </motion.div> */}

          {
            articles.map((article) => {
              return (
                <motion.div
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ delay: 1 }}
                  className="flex items-center flex-shrink-0 justify-between w-[700px] p-12 text-white bg-[#0A180E] rounded-xl"
                >

                  <div className="flex flex-col items-start space-y-7 mr-5">

                    <div className="text-white/50">INVERSIÓN</div>

                    <div className='font-lato font-extrabold text-3xl'>{article.title}<br /></div>

                    <div className="bg-[#7271FF] text-black hover:bg-[#7271FF]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                      onClick={() => {
                        window.location.href = `/blog/${article.urlRouter}`
                      }}
                    >
                      Ver nota
                    </div>

                  </div>

                  <img src={img1} alt="banner-card-lokl" />

                </motion.div>
              )
            })
          }

          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
            className="flex items-center flex-shrink-0 justify-between w-[700px] p-12 text-white bg-[#0A180E] rounded-xl"
          >

            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">INVERSIÓN</div>

              <div className='font-lato font-extrabold text-3xl'>¿Cómo organizo mis finanzas? <br /></div>

              <div className="bg-[#7271FF] text-black hover:bg-[#7271FF]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/organizar-mis-finanzas"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img1} alt="banner-card-lokl" />

          </motion.div>

          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
            className="flex items-center flex-shrink-0 justify-between w-[700px] p-12 text-black bg-[#CACAC8] rounded-xl"
          >

            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-black/50">INVERSIÓN</div>

              <div className='font-lato font-extrabold text-3xl'>Invertir en bienes raíces con poco dinero y obtén grandes beneficios</div>

              {/* <p className='text-sm w-[350px]'>
                Muchos inversionistas buscan oportunidades para hacer crecer su dinero. Con los mercados financieros en constante evolución, es esencial investigar y considerar cuidadosamente las opciones de inversión disponibles.
              </p> */}

              <div className="bg-black text-white hover:bg-black/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/invertir-en-bienes-raices"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img1} alt="banner-card-lokl" />

          </motion.div>

          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
            className="flex items-center flex-shrink-0 justify-between w-[700px] p-12 text-white bg-[#0A180E] rounded-xl"
          >

            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">EDUCACIÓN</div>

              <div className='font-lato font-extrabold text-3xl'>5 Conceptos básicos de inversión inmobiliaria que debes conocer</div>

              <div className="bg-[#7271FF] text-black hover:bg-[#7271FF]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/tips-inversion-finanzas/conceptos-basicos"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img1} alt="banner-card-lokl" />

          </motion.div>

          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
            className="flex items-center flex-shrink-0 justify-between w-[700px] p-12 text-black bg-[#CACAC8] rounded-xl"
          >

            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">INVERSIÓN</div>

              <div className='font-lato font-extrabold text-3xl'>¿Dónde invertir en <br /> Colombia en el 2023?</div>

              <p className='text-sm w-[350px]'>
                Muchos inversionistas buscan oportunidades para hacer crecer su dinero. Con los mercados financieros en constante evolución, es esencial investigar y considerar cuidadosamente las opciones de inversión disponibles.
              </p>

              <div className="bg-black text-white hover:bg-black/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/donde-invertir-colombia-2023"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img1} alt="banner-card-lokl" />

          </motion.div>

          <motion.div
            className="flex flex-col items-start flex-shrink-0 justify-between p-12 text-white bg-[#0A180E] rounded-xl"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
          >

            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">EDUCACIÓN</div>

              <div className='font-lato font-extrabold text-2xl'>
                5 secretos para usar el <br /> dinero adecuadamente, <br /> planifica e invierte a largo <br /> plazo.
              </div>

            </div>

            <div
              className="bg-[#FF7171] text-[#010067] hover:bg-[#FF7171]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
              onClick={() => {
                window.location.href = "/blog/planificar-presupuesto-largo-plazo"
              }}
            >
              Ver nota
            </div>

          </motion.div>

          <motion.div
            className="flex items-center flex-shrink-0 justify-between p-12 text-black bg-[#CACAC8] rounded-xl"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
          >

            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">INVERSIÓN</div>

              <div className='font-lato font-extrabold text-3xl'>
                Inversiones colaborativas en <br /> hospitality: una oportunidad <br /> para las nuevas generaciones

              </div>

              <p className='text-sm w-[350px]'>
                Las nuevas generaciones tienen un enfoque diferente en cuanto a su relación con el dinero. Muchos jóvenes buscan opciones de inversión que les permitan obtener ganancias, pero también que tengan un impacto positivo en el mundo.
              </p>

              <div
                className="bg-black text-white hover:bg-black/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/inversiones-colaborativas-hospitality"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img2} alt="banner-card2-lokl" />

          </motion.div>

          <motion.div
            className="flex flex-col items-start flex-shrink-0 justify-between p-12 text-white bg-[#0A180E] rounded-xl"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
          >

            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">EDUCACIÓN</div>

              <div className='font-lato font-extrabold text-2xl'>
                Descubre los mejores sectores <br /> para invertir en Colombia.
              </div>

            </div>

            <div
              className="bg-[#FF7171] text-[#010067] hover:bg-[#FF7171]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
              onClick={() => {
                window.location.href = "/blog/planificar-presupuesto-largo-plazo"
              }}
            >
              Ver nota
            </div>

          </motion.div>

          <motion.div
            className="flex items-center flex-shrink-0 justify-between p-12 text-black bg-[#CACAC8] rounded-xl"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
          >

            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">INVERSIÓN</div>

              <div className='font-lato font-extrabold text-3xl'>
                Descubriendo las opciones <br /> de inversión más rentables
              </div>

              <p className='text-sm w-[350px]'>
                En un mundo cada vez más globalizado y lleno de oportunidades, el tema de las inversiones se ha vuelto relevante para muchas personas. Si alguna vez te has preguntado qué son las inversiones y cuáles son las más rentables, estás en el lugar correcto.
              </p>

              <div
                className="bg-black text-white hover:bg-black/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/opciones-de-inversion-colombia"
                }}
              >
                Ver nota
              </div>

            </div>

            <img className='rounded-lg' src={descubreSectores} alt="opciones-inversiones-rentables" />

          </motion.div>

          <motion.div
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
            className="flex items-center flex-shrink-0 justify-between w-[700px] p-12 text-white bg-[#0A180E] rounded-xl"
          >

            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">EDUCACIÓN</div>

              <div className='font-lato font-extrabold text-3xl'>
                Inversión y ahorro: <br /> dos pilares fundamentales <br /> para el crecimiento financiero
              </div>

              <p className='text-sm w-[350px]'>
                Muchos inversionistas buscan oportunidades para hacer crecer su dinero. Con los mercados financieros en constante evolución, es esencial investigar y considerar cuidadosamente las opciones de inversión disponibles.
              </p>

              <div className="bg-[#7271FF] text-black hover:bg-[#7271FF]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/inversion-ahorro-crecimiento-financiero"
                }}
              >
                Ver nota
              </div>

            </div>

            <img className='rounded-lg' src={ahorroInversion} alt="ahorro-invesion-lokl" />

          </motion.div>

        </div>

      </div>

      <div className="wrapper-lokl w-full space-y-5 mb-20">

        <div className=" w-full   items-center">

          <motion.div>

            <motion.h2 className='font-lato font-extrabold text-2xl mb-10 ml-2 md:ml-0'>Nuestras notas</motion.h2>

            <motion.ul className='list-none space-y-1 font-roboto max-h-96 overflow-y-auto'>
              
              

              {/* <motion.li
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 2 }}
                className='cursor-pointer pl-3 py-2.5 hover:bg-[#EEEEEE] rounded-lg'
                onClick={() => {
                  window.location.href = "/blog/donde-invertir-colombia-2023"
                }}
              >¿Dónde invertir en Colombia en el 2023?</motion.li> */}
              
              {
                articles.map((article) => {
                  return (
                    <motion.li
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 1 }}
                      transition={{ duration: 2 }}
                      className='cursor-pointer pl-3 py-2.5 hover:bg-[#EEEEEE] rounded-lg'
                      onClick={() => {
                        window.location.href = `/blog/${article.urlRouter}`
                      }}
                    >{article.title}</motion.li>
                  )
                })
              }
              <motion.li
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 4 }}
                className='cursor-pointer pl-3 py-2.5 hover:bg-[#EEEEEE] rounded-lg'
                onClick={() => {
                  window.location.href = "/blog/inversiones-colaborativas-hospitality"
                }}
              >Inversiones colaborativas en hospitality</motion.li>
              <motion.li
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 3 }}
                className='cursor-pointer pl-3 py-2.5 hover:bg-[#EEEEEE] rounded-lg'
                onClick={() => {
                  window.location.href = "/blog/planificar-presupuesto-largo-plazo"
                }}
              >5 secretos para usar el dinero adecuadamente</motion.li>

              {/* <motion.li
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 2 }}
                className='cursor-pointer pl-3 py-2.5 hover:bg-[#EEEEEE] rounded-lg'
                onClick={() => {
                  window.location.href = "/blog/sectores-invertir-colombia-2023"
                }}
              >Descubre los mejores sectores para invertir en Colombia</motion.li> */}
              <motion.li
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 3 }}
                className='cursor-pointer pl-3 py-2.5 hover:bg-[#EEEEEE] rounded-lg'
                onClick={() => {
                  window.location.href = "/blog/opciones-de-inversion-colombia"
                }}
              >Las opciones de inversión más rentables</motion.li>
              <motion.li
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 4 }}
                className='cursor-pointer pl-3 py-2.5 hover:bg-[#EEEEEE] rounded-lg'
                onClick={() => {
                  window.location.href = "/blog/inversion-ahorro-crecimiento-financiero"
                }}
              >Inversión y ahorro: dos pilares fundamentales</motion.li>

            </motion.ul>

          </motion.div>
                
          <motion.div
            className="relative"
            initial={{
              x: 50,
              opacity: 0,
            }}
            whileInView={{
              x: 0,
              opacity: 1,
            }}
          >
            <div className='my-10 md:my-0'></div>
            {/* <VideoComponent
              thumbnailSrc={nidoImg} // Replace with the actual path to your thumbnail image
              videoId={videos.nido} // Replace with the actual YouTube video ID
              design={
                <>

                  <div className="absolute top-20 left-20 flex flex-col w-full h-full text-white">

                    <div className="text-white/50 ml-5">PROYECTO</div>
                    <div className="font-lato uppercase text-3xl font-extrabold">NIDO DE AGUA</div>
                    <div className="w-64">
                      Creemos que la vida es una sola como para vivirla únicamente los fines de semana.
                    </div>

                  </div>

                  <div className="absolute top-0 left-0 flex justify-center items-center w-full h-full cursor-pointer">
                    <PlaySVG />
                  </div>

                </>
              }
            /> */}

          </motion.div>

        </div>

      </div>

      <div id="podcast" className="flex flex-col items-center text-center mb-5">

        <motion.h2
          initial={{ x: -50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          className="w-full font-extrabold font-lato text-2xl mb-4">
          El crecimiento comienza cuando sales de tu zona de confort y te atreves a explorar nuevas posibilidades
        </motion.h2>

        <motion.h3
          initial={{ x: -50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
          className='font-roboto text-lg'>
          Secretos de Inversiones Inmobiliarias
        </motion.h3>

      </div>

      <div className="wrapper-lokl">

        <div className="w-full inline-flex justify-between items-center overflow-x-auto scrollbar-thin scrollbar-thumb-gray-100 scrollbar-track-gray-100 space-x-4 mb-20 pb-3">

          <div className="space-y-4 flex-shrink-0">

            <motion.div
              initial={{ y: -50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
            >

              <iframe title="podcast" src="https://open.spotify.com/embed/episode/5xIL9Hg29BVSZZ3iYRyBkQ?utm_source=generator" width="288" height="235" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>

            </motion.div>

            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              className=""
            >

              <iframe title="podcast" src="https://open.spotify.com/embed/episode/19znDQsNfQDpYPt2U8WLjX?utm_source=generator" width="288" height="235" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>

            </motion.div>

          </div>

          <div className="space-y-4 flex-shrink-0">

            <motion.div
              initial={{ y: -50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
            >

              <iframe title="podcast" src="https://open.spotify.com/embed/episode/2zXwbyStCQ05NC9F2Kylvw?utm_source=generator" width="288" height="235" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>

            </motion.div>

            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              className=""
            >

              <iframe title="podcast" src="https://open.spotify.com/embed/episode/5unq7fATjE0QSSj8Gg6NbT?utm_source=generator" width="288" height="235" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>

            </motion.div>

          </div>

          <div className="space-y-4 flex-shrink-0">

            <motion.div
              initial={{ y: -50, opacity: 0 }}
              whileInView={{ y: 0, opacity: 1 }}
            >

              <iframe title="podcast" src="https://open.spotify.com/embed/episode/2j2zmcmQ8jWZv9vkzVdio7?utm_source=generator" width="288" height="235" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>

            </motion.div>

            <motion.div
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              className=""
            >

              <iframe title="podcast" src="https://open.spotify.com/embed/episode/3Cbo92mOD3g1tCh7OdGTRw?utm_source=generator" width="288" height="235" frameBorder="0" allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>

            </motion.div>

          </div>

        </div>

      </div>

      <div className="w-full block md:hidden mb-10">
        <motion.h2
          initial={{ x: -50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          className='font-lato font-extrabold text-2xl mb-3'>
          Academia LOKL
        </motion.h2>

        <motion.div className="font-roboto"
          initial={{ x: -50, opacity: 0 }}
          whileInView={{ x: 0, opacity: 1 }}
          transition={{ duration: 1 }}
        >
          Secretos de Inversiones Inmobiliarias  / <span className='text-gray-400'>6 episodios</span>
        </motion.div>
      </div>

      <Tabs id='academia' className="space-y-5 w-full max-w-[1152px] mx-auto">

        <div className="flex justify-between items-end mb-24">

          <div className="w-full hidden md:block">

            <motion.h2
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              className='font-lato font-extrabold text-2xl mb-3'>
              Academia LOKL
            </motion.h2>

            <motion.div className="font-roboto"
              initial={{ x: -50, opacity: 0 }}
              whileInView={{ x: 0, opacity: 1 }}
              transition={{ duration: 1 }}
            >
              Secretos de Inversiones Inmobiliarias  / <span className='text-gray-400'>6 episodios</span>
            </motion.div>

          </div>

          <motion.div
            initial={{ x: 50, opacity: 0 }}
            whileInView={{ x: 0, opacity: 1 }}
            transition={{ delay: 1 }}
          >
            <TabList className="flex space-x-12 font-bold md:text-lg list-none -mb-4 text-sm">

              {
                optionsAcademy.map((tab, key) =>
                  <Tab
                    key={key}
                    className={`min-w-[100px] cursor-pointer outline-none text-center font-roboto ${key === activeTabAcademy ? ' text-black ' : 'text-gray-400'}`}
                    onClick={() => setActiveTabAcamedy(key)}
                  >
                    {tab}
                    {
                      key === activeTabAcademy &&
                      <motion.div
                        initial={{ x: -10, scale: 0 }}
                        animate={{ x: 0, scale: 1 }}
                        transition={{ ease: "easeOut", duration: 1.2 }}
                        className='w-full h-1.5 bg-black mt-2'
                      ></motion.div>
                    }
                  </Tab>
                )

              }

            </TabList>
          </motion.div>

        </div>

        <TabPanel className="flex flex-col md:flex-row ">

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 1 }}
            className="w-3/5 mr-8 space-y-4"
          >

            <VideoComponent
              thumbnailSrc={imgColectivo}
              videoId={videos.colectivo}
            />

            <VideoComponent
              thumbnailSrc={imgColectivo2}
              videoId={videos.colectivo}
            />

          </motion.div>

          <div className="w-full md:w-2/5 mt-10 md:mt-0">

            <div className="w-full h-[560px] bg-white rounded-2xl p-10 divide-y-2 space-y-8">

              <div className="flex justify-between font-bold text-xl">

                <p className="font-roboto">Comentarios</p>
                <p className="font-roboto">201</p>

              </div>

              <div className="flex items-start pt-8">

                <img className='mr-4' src={imgUser} alt="user-avatar" />

                <div className=" flex flex-col items-end space-y-2">

                  <div className="w-full flex justify-between">

                    <p className='text-[#686868] font-bold text-lg font-roboto'>Elizabeth</p>

                    <p className='text-[#8F8F8F] font-roboto'>13/04/2023</p>

                  </div>

                  <div className="text-[#8F8F8F] text-base font-roboto">
                    LOKL ha sido la plataforma que piensa en mi futuro y logre invertir con poco presupuesto en proyectos muy grandes.
                  </div>

                  <div className="text-[#494949] underline font-roboto">Responder</div>

                </div>

              </div>

              <div className="flex items-start pt-8">

                <img className='mr-4' src={imgUser} alt="user-avatar" />

                <div className=" flex flex-col items-end space-y-2">

                  <div className="w-full flex justify-between">

                    <p className='text-[#686868] font-bold text-lg font-roboto'>Elizabeth</p>

                    <p className='text-[#8F8F8F] font-roboto'>13/04/2023</p>

                  </div>

                  <div className="text-[#8F8F8F] text-base font-roboto">
                    LOKL ha sido la plataforma que piensa en mi futuro y logre invertir con poco presupuesto en proyectos muy grandes.
                  </div>

                  <div className="text-[#494949] underline font-roboto">Responder</div>

                </div>

              </div>

              <div className="btn-black-full">
                Regístrate
              </div>

            </div>

          </div>

        </TabPanel>

        <TabPanel className="flex flex-col md:flex-row">

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 1 }}
            className="w-3/5 mr-8 space-y-4"
          >

            <VideoComponent
              thumbnailSrc={imgExito}
              videoId={videos.sebas}
              width={588}
            />

          </motion.div>

          <div className="w-full md:w-2/5 mt-10 md:mt-0">

            <div className="w-full h-[560px] bg-white rounded-2xl p-10 divide-y-2 space-y-8">

              <div className="flex justify-between font-bold text-xl">

                <p className="font-roboto">Comentarios</p>
                <p className="font-roboto">201</p>

              </div>

              <div className="flex items-start pt-8">

                <img className='mr-4' src={imgUser} alt="user-avatar" />

                <div className=" flex flex-col items-end space-y-2">

                  <div className="w-full flex justify-between">

                    <p className='text-[#686868] font-bold text-lg font-roboto'>Elizabeth</p>

                    <p className='text-[#8F8F8F] font-roboto'>13/04/2023</p>

                  </div>

                  <div className="text-[#8F8F8F] text-base font-roboto">
                    LOKL ha sido la plataforma que piensa en mi futuro y logre invertir con poco presupuesto en proyectos muy grandes.
                  </div>

                  <div className="text-[#494949] underline font-roboto">Responder</div>

                </div>

              </div>

              <div className="flex items-start pt-8">

                <img className='mr-4' src={imgUser} alt="user-avatar" />

                <div className=" flex flex-col items-end space-y-2">

                  <div className="w-full flex justify-between">

                    <p className='text-[#686868] font-bold text-lg font-roboto'>Elizabeth</p>

                    <p className='text-[#8F8F8F] font-roboto'>13/04/2023</p>

                  </div>

                  <div className="text-[#8F8F8F] text-base font-roboto">
                    LOKL ha sido la plataforma que piensa en mi futuro y logre invertir con poco presupuesto en proyectos muy grandes.
                  </div>

                  <div className="text-[#494949] underline font-roboto">Responder</div>

                </div>

              </div>

              <div className="btn-black-full">
                Regístrate
              </div>

            </div>

          </div>

        </TabPanel>

        <TabPanel className="flex flex-col md:flex-row">

          <motion.div
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ delay: 1 }}
            className="w-3/5 mr-8 space-y-4"
          >

            <VideoComponent
              thumbnailSrc={imgCamiloKarem}
              videoId={videos.karem}
              width={588}
            />

            <VideoComponent
              thumbnailSrc={imgCamilo}
              videoId={videos.camilo}
              width={588}
            />

          </motion.div>

          <div className="w-full md:w-2/5 mt-10 md:mt-0">

            <div className="w-full h-[560px] bg-white rounded-2xl p-10 divide-y-2 space-y-8">

              <div className="flex justify-between font-bold text-xl">

                <p className="font-roboto">Comentarios</p>
                <p className="font-roboto">201</p>

              </div>

              <div className="flex items-start pt-8">

                <img className='mr-4' src={imgUser} alt="user-avatar" />

                <div className=" flex flex-col items-end space-y-2">

                  <div className="w-full flex justify-between">

                    <p className='text-[#686868] font-bold text-lg font-roboto'>Elizabeth</p>

                    <p className='text-[#8F8F8F] font-roboto'>13/04/2023</p>

                  </div>

                  <div className="text-[#8F8F8F] text-base font-roboto">
                    LOKL ha sido la plataforma que piensa en mi futuro y logre invertir con poco presupuesto en proyectos muy grandes.
                  </div>

                  <div className="text-[#494949] underline font-roboto">Responder</div>

                </div>

              </div>

              <div className="flex items-start pt-8">

                <img className='mr-4' src={imgUser} alt="user-avatar" />

                <div className=" flex flex-col items-end space-y-2">

                  <div className="w-full flex justify-between">

                    <p className='text-[#686868] font-bold text-lg font-roboto'>Elizabeth</p>

                    <p className='text-[#8F8F8F] font-roboto'>13/04/2023</p>

                  </div>

                  <div className="text-[#8F8F8F] text-base font-roboto">
                    LOKL ha sido la plataforma que piensa en mi futuro y logre invertir con poco presupuesto en proyectos muy grandes.
                  </div>

                  <div className="text-[#494949] underline font-roboto">Responder</div>

                </div>

              </div>

              <div className="btn-black-full">
                Regístrate
              </div>

            </div>

          </div>

        </TabPanel>

      </Tabs>

    </article>
    </main>
  )
}

export default Blog;