import React from 'react'
import loklProfile from '../../assets/img/lokl-profile.png';

interface MessageSentProps {
    isOpen: boolean;
    setIsOpen: (arg: boolean) => void;
}

export const MessageSent: React.FC<MessageSentProps> = ({
    isOpen,
    setIsOpen,
}) => {

    const handleSubmit = () => {
        setIsOpen(false)
    }

    return (

        isOpen === true ?

            <div className={`fixed top-0 left-0 right-0 bottom-0 z-[100] bg-[#A7A6FF]/75 flex items-center justify-center`}>
                <div className="relative w-96 md:w-[30%] py-12 bg-white rounded-xl p-6 flex flex-col justify-center items-center">

                    <div className="flex flex-col justify-center items-center gap-2">
                        <img className='mb-3' src={loklProfile} alt="lokl profile" />
                        <div className="flex gap-2 items-center relative">
                            <h3 className="font-bold text-lg">¡Tu simulación detallada ha sido enviada!</h3>
                            <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M9.7345 2.26212C10.288 1.27264 11.712 1.27264 12.2655 2.26212C12.664 2.97446 13.5717 3.21767 14.273 2.80002C15.2471 2.21988 16.4803 2.93184 16.4649 4.06553C16.4538 4.88168 17.1183 5.54615 17.9345 5.5351C19.0682 5.51975 19.7801 6.75289 19.2 7.72702C18.7823 8.42831 19.0255 9.33599 19.7379 9.7345C20.7274 10.288 20.7274 11.712 19.7379 12.2655C19.0255 12.664 18.7823 13.5717 19.2 14.273C19.7801 15.2471 19.0682 16.4803 17.9345 16.4649C17.1183 16.4538 16.4538 17.1183 16.4649 17.9345C16.4803 19.0682 15.2471 19.7801 14.273 19.2C13.5717 18.7823 12.664 19.0255 12.2655 19.7379C11.712 20.7274 10.288 20.7274 9.7345 19.7379C9.33599 19.0255 8.42831 18.7823 7.72702 19.2C6.75289 19.7801 5.51975 19.0682 5.5351 17.9345C5.54615 17.1183 4.88168 16.4538 4.06553 16.4649C2.93184 16.4803 2.21988 15.2471 2.80002 14.273C3.21767 13.5717 2.97446 12.664 2.26212 12.2655C1.27264 11.712 1.27264 10.288 2.26212 9.7345C2.97446 9.33599 3.21767 8.42831 2.80002 7.72702C2.21988 6.75289 2.93184 5.51975 4.06553 5.5351C4.88168 5.54615 5.54615 4.88168 5.5351 4.06553C5.51975 2.93184 6.75289 2.21988 7.72702 2.80002C8.42831 3.21767 9.33599 2.97446 9.7345 2.26212Z" fill="#6DECB9" />
                                <path d="M14.125 8.5L9.75 13.5L7.875 11.625" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                            </svg>
                        </div>
                        <p className="font-medium text-center">Hemos enviado un modelo detallado de tu simulación a tu correo electrónico. ¡Gracias por confiar en nosotros!</p>
                        <div className="mt-3">
                            <button
                                onClick={handleSubmit}
                                className="rounded-full bg-black text-white py-2 px-6 font-medium text-lg"
                            >
                                Continuar
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            : null

    )
}
