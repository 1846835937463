import React from 'react'
import img1 from "../../assets/img/blog/card-1.png";
import img2 from "../../assets/img/blog/card-2.png";
import { motion } from "framer-motion";
import useTitle, { useDescription, useKeywords } from '../../hooks/useSEO';

// import image from "../../assets/img/blog/manage-money.png";
// import llaves from "../../assets/img/blog/llaves.png";
// import mapa from "../../assets/img/blog/mapa.png";
// import planta from "../../assets/img/blog/planta.png";
// import image2 from "../../assets/img/blog/image-2-article.png";
// import vecindario from "../../assets/img/blog/vecindario.svg";
// import lokl from "../../assets/img/blog/lokl-black.svg";
// import forbes from "../../assets/img/blog/forbes.svg";
// import republica from "../../assets/img/blog/republica.svg";
// import semana from "../../assets/img/blog/semana.svg";
// import { Tab, TabList } from 'react-tabs';
// import { scrollToSection } from '../../helpers/functions';

function ArticleOpcionesInversion() {
  useTitle("Descubriendo las opciones de inversión más rentables | LOKL");
  useDescription("En esta nota de blog, exploraremos los fundamentos de las inversiones y destacaremos algunas opciones rentables que podrían ayudarte a alcanzar tus objetivos financieros.");
  useKeywords("inversiones, opciones de inversión, inversiones rentables, acciones, bienes raíces, fondos mutuos, criptomonedas");
  // const optionsList = [
  //   "Dejanos tus comentario",
  //   "Comentarios",
  // ];

  // const [tabOptions /*, setTabOptions*/] = useState(optionsList);
  // const [activeTabIndex /*, setActiveTabIndex */] = useState(0);

  return (
    <main id='article' className='pt-32 bg-[#F9F9F9]'>
      
      <header className="bg-note-img bg-cover bg-center h-[432px] w-full flex items-center justify-center">

        <div className="wrapper-lokl text-white ">

          <div className="w-[90%] md:w-1/2 ml-[5%] md:ml-0">

            <h1 className="font-lato font-extrabold text-[38px] mb-3">
              Descubriendo las opciones de inversión más rentables
            </h1>
          
          </div>

        </div>

      </header>

      <article className="wrapper-lokl space-y-10 py-16">

        {/* <div className="w-full flex justify-end">

          <div className="flex space-x-2 mr-3">

            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>

          </div>

          <div className="underline cursor-pointer" onClick={() => scrollToSection('comments', 150)}>35 Comentarios</div>

        </div> */}

        <article className="w-full flex justify-between">

          <section className="w-[90%] md:w-[67%] mr-0 md:mr-10 ml-[5%] md:ml-0">

            <p className="text-base font-roboto text-justify">
              En un mundo cada vez más globalizado y lleno de oportunidades, el tema de las inversiones se ha vuelto relevante para muchas personas. Si alguna vez te has preguntado qué son las inversiones y cuáles son las más rentables, estás en el lugar correcto. En esta nota de blog, exploraremos los fundamentos de las inversiones y destacaremos algunas opciones rentables que podrían ayudarte a alcanzar tus objetivos financieros.
            </p>

          </section>

          <div className="h-auto hidden md:flex justify-end items-start">

            <div className="flex space-x-2 mr-3">

              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>
              <div className="">
                <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
                </svg>
              </div>

            </div>

            <div className="underline cursor-pointer">5</div>

          </div>

        </article>

        <section className="w-[90%] md:w-[67%] mr-0 md:mr-10 ml-[5%] md:ml-0">

          <h2 className="blog-title-md mb-4">
            ¿Qué son las inversiones?
          </h2>

          <p className="text-base font-roboto text-justify">

            Las inversiones son una forma de utilizar el dinero con el objetivo de generar ganancias a largo plazo. En lugar de gastar el dinero en bienes o servicios inmediatos, las inversiones permiten que el dinero se coloque en diferentes activos con la esperanza de obtener un retorno financiero en el futuro.
            
            <br />
            <br />
            
            Existen diversas formas de invertir, y cada una conlleva sus propios riesgos y beneficios. Algunas de las opciones más comunes incluyen acciones, bonos, bienes raíces, fondos mutuos, divisas extranjeras y criptomonedas. La elección de una u otra dependerá de tus metas financieras, tolerancia al riesgo y nivel de conocimiento en cada área.

          </p>

        </section>

        <section className="w-[90%] md:w-[67%] ml-[5%] md:ml-0 flex justify-center mdj:ustify-between items-start">

          <div className="flex flex-col justify-center">

            <div className="flex flex-col space-y-6">
              
              <div className="flex flex-col justify-center">

                <h2 className='blog-title-sm mb-3'>1. Acciones</h2>

                <div className="flex flex-col justify-between">

                  <p className="text-base font-roboto text-justify">
                    Invertir en acciones implica comprar una parte de propiedad de una empresa. Las acciones pueden generar beneficios a través de dividendos y apreciación del precio de las acciones. Sin embargo, el mercado de acciones puede ser volátil y requerir un análisis cuidadoso de las empresas antes de invertir.
                  </p>

                </div>
              
              </div>
              
              <div className="flex flex-col justify-start">

                <h2 className='blog-title-sm mb-3'>2. Bienes raíces</h2>

                <div className="flex flex-col justify-between">

                  <p className="text-base font-roboto text-justify">
                    La inversión en bienes raíces ha sido una opción confiable a lo largo del tiempo. Comprar propiedades para alquilar o vender puede generar ingresos pasivos y apreciación del valor a largo plazo.
                  </p>

                </div>

              </div>

            </div>

            <div className="my-10">
            
              <div className="mb-8">
                Lee también: <a className='hover:text-[#17008A]' href="/blog/donde-invertir-colombia-2023"> <b> ¿Dónde invertir en Colombia en el 2023? </b> </a>
              </div>

              <hr className="border-t border-gray-400"/>
            
            </div>

            <div className="flex flex-col space-y-6">

              <div className="flex flex-col justify-start">

                <h2 className='blog-title-sm mb-3'>3. Fondos mutuos</h2>

                <div className="flex flex-col justify-between">

                  <p className="text-base font-roboto text-justify">
                    Los fondos mutuos son una opción popular para aquellos que desean diversificar sus inversiones. Estos fondos reúnen el dinero de varios inversionistas y lo invierten en una cartera diversificada de acciones, bonos y otros activos. Los fondos mutuos son administrados por profesionales y pueden brindar una mayor seguridad y potencial de crecimiento a largo plazo.
                  </p>

                </div>

              </div>

              <div className="flex flex-col justify-start">

                <h2 className='blog-title-sm mb-3'>4. Criptomonedas</h2>

                <div className="flex flex-col justify-between">

                  <p className="text-base font-roboto text-justify">
                    En los últimos años, las criptomonedas han ganado popularidad como una opción de inversión de alto rendimiento. Bitcoin y otras criptomonedas han experimentado un crecimiento significativo en su valor, pero también conllevan un alto nivel de riesgo debido a su volatilidad y la falta de regulación.
                  </p>

                </div>

              </div>

              <div className="flex flex-col justify-start">

                <div className="flex flex-col justify-between mb-1">

                  <p className="text-base font-roboto text-justify">
                    Es importante tener en cuenta que ninguna inversión está exenta de riesgos. Antes de invertir, es esencial investigar y comprender completamente las oportunidades y los riesgos asociados. Además, siempre es recomendable buscar asesoramiento financiero profesional antes de tomar decisiones de inversión importantes.
                  </p>

                </div>
              
              </div>

              <div className="flex flex-col justify-start">

                <h3 className='blog-title-sm mb-3'>Conclusión</h3>

                <div className="flex flex-col justify-between mb-4">

                  <p className="text-base font-roboto text-justify">
                    Las inversiones son una herramienta poderosa para hacer crecer tu riqueza y alcanzar tus metas financieras a largo plazo. Existen numerosas opciones de inversión disponibles, y las más rentables dependerán de tus necesidades y objetivos personales. Ya sea que decidas invertir en acciones, bienes raíces, fondos mutuos o criptomonedas, recuerda que la investigación y el asesoramiento adecuado son fundamentales para tomar decisiones informadas. La diversificación también es clave para minimizar riesgos y aprovechar oportunidades en diferentes mercados. <br /> <br />
                    Recuerda que invertir implica un horizonte a largo plazo y requiere paciencia, disciplina y la capacidad de adaptarse a los cambios del mercado. A medida que te adentres en el mundo de las inversiones, es esencial educarte continuamente, seguir aprendiendo y estar al tanto de las últimas tendencias y noticias financieras. <br /> <br />
                    En resumen, las inversiones ofrecen la posibilidad de hacer crecer tu patrimonio y asegurar tu futuro financiero. Identificar las opciones más rentables implica evaluar cuidadosamente tus metas, tolerancia al riesgo y horizonte de tiempo. Con el conocimiento adecuado y el enfoque correcto, puedes aprovechar las oportunidades y alcanzar tus objetivos financieros a largo plazo. ¡Empieza a explorar las opciones de inversión y da el primer paso hacia un futuro financiero próspero! 
                  </p>

                </div>

                <div className="mb-4">

                  <hr className="border-t border-gray-400"/>
                
                </div>

                <div className="text-xl">
                  Recuerda <a className='hover:text-[#17008A]' href="/register"><b>registrarte</b></a> para recibir información de primera mano, sobre nuestros proyectos.
                </div>

              </div>

              <div className="flex flex-col justify-start space-y-2 ml-4">

                <div className="">
                  Empieza a invertir en el futuro que quieres vivir. <br /> <a className='hover:text-[#17008A]' href="/#projects"><b>Invierte con LOKL</b></a>.
                </div>

                <div className="">
                  Escrito por Veronica Porras.
                </div>

              </div>

            </div>

          </div>

        </section>

        {/* <div id='comments'>

          <TabList className="flex space-x-6 font-bold text-lg list-none mb-8">

            {
              tabOptions.map((tab, key) => 
                <Tab key={key} className={ `min-w-[100px] cursor-pointer outline-none text-center font-roboto ${ key === activeTabIndex ? ' text-black ' : 'text-gray-400' }` }>
                  {tab}
                  { key === activeTabIndex && <div className='w-full h-1 bg-black mt-2'></div> }
                </Tab>
              )
            }

          </TabList>

          <div className="bg-white w-2/3 p-12 flex flex-col items-center rounded-xl">

            <div className="flex flex-col w-full">

              <label htmlFor="commentInput">¿Tienes preguntas?</label>
              <textarea className='outline-none' name="" id="commentInput" cols={30} rows={10}></textarea>

            </div>

            <button className="btn-primary">
              Comentar
            </button>

          </div>

        </div> */}


        <section className="w-full inline-flex overflow-x-auto space-x-4 mb-5">

          <motion.div 
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
            className="flex items-center flex-shrink-0 justify-between w-[700px] p-12 text-white bg-[#0A180E] rounded-xl"
          >
            
            <div className="flex flex-col items-start space-y-7 mr-5">

              <h3 className="text-white/50">INVERSIÓN</h3>

              <h2 className='font-lato font-extrabold text-3xl'>¿Dónde invertir en <br /> Colombia en el 2023?</h2>

              <p className='text-sm w-[350px]'>
                Muchos inversionistas buscan oportunidades para hacer crecer su dinero. Con los mercados financieros en constante evolución, es esencial investigar y considerar cuidadosamente las opciones de inversión disponibles.
              </p>

              <div className="bg-[#7271FF] text-black hover:bg-[#7271FF]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/donde-invertir-colombia-2023"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img1} alt="banner-card-lokl" /> 

          </motion.div>

          <motion.div 
            className="flex items-center flex-shrink-0 justify-between p-12 text-black bg-[#CACAC8] rounded-xl"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
          >
            
            <div className="flex flex-col items-start space-y-7 mr-5">

              <h3 className="text-white/50">INVERSIÓN</h3>

              <h2 className='font-lato font-extrabold text-3xl'>
                Inversiones colaborativas en <br /> hospitality: una oportunidad <br /> para las nuevas generaciones
              </h2>

              <p className='text-sm w-[350px]'>
                Las nuevas generaciones tienen un enfoque diferente en cuanto a su relación con el dinero. Muchos jóvenes buscan opciones de inversión que les permitan obtener ganancias, pero también que tengan un impacto positivo en el mundo.
              </p>

              <div 
                className="bg-black text-white hover:bg-black/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/inversiones-colaborativas-hospitality"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img2} alt="banner-card2-lokl" /> 

          </motion.div>

        </section>

      </article>

    </main>
  )
}

export default ArticleOpcionesInversion;