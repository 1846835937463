import React from "react";

interface Option {
  id: string;
  label: string;
  value: string;
}

interface SelectComponentProps {
  id: string;
  label: string;
  selectedValue: string;
  options: Option[];
  onChange: (value: string) => void;
  disabled?: boolean;
}

export const SelectComponent: React.FC<SelectComponentProps> = ({
  id,
  label,
  selectedValue,
  options,
  onChange,
  disabled = false,
}) => {
  return (
    <div className="mb-4">
      <label htmlFor={id} className={`text-gray-700 font-medium primaryFont ${disabled ? "text-gray-400" : ""}`}>
        {label}
      </label>
      <select
        id={id}
        value={selectedValue}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        className={`mt-2 rounded-lg numberFont w-full h-[40px] text-black border-[1px] px-2 bg-white focus:border-[#4743E0] ${
          disabled ? "cursor-not-allowed" : ""
        }`}
      >
        {/* 🔹 Opción por defecto que obliga a seleccionar algo */}
        <option value="" disabled hidden>
          Selecciona una opción
        </option>

        {/* 🔹 Mapea las opciones disponibles */}
        {options.map((option) => (
          <option key={option.id} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};
