import { useRef, useEffect } from "react";
import { createPopper } from "@popperjs/core";

export function PopupSecondaryMarket({ triggerRef, isVisible }: any) {
  const popoverRef = useRef<HTMLDivElement>(null); // Cambiar a useRef para mantener la referencia

  useEffect(() => {
    if (triggerRef.current && popoverRef.current) {
      createPopper(triggerRef.current, popoverRef.current, {
        placement: "top",
        modifiers: [
          {
            name: "preventOverflow",
            options: {
              boundary: "viewport",
            },
          },
          {
            name: "offset",
            options: {
              offset: [0, 10],
            },
          },
        ],
      });
    }
  }, [triggerRef, isVisible]);

  return (
    <div
      ref={popoverRef}
      className={`${
        isVisible ? "opacity-100 visible" : "opacity-0 invisible"
      } w-72 bg-white z-10 text-sm text-[#928C8C] p-3 border mb-2 rounded transition-opacity duration-200`}
    >
      * El mercado secundario de LOKL te permite comprar participaciones de otros inversionistas en proyectos ya en curso. Es una oportunidad única para sumarte a un proyecto avanzado, aprovechar su valorización y obtener rendimientos en menor tiempo.
    </div>
  );
}
