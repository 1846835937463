import React, { useEffect, useState } from 'react';
import { LetsGrowthTogether, WeReDifferent, TimeLine, OurTeam, FrecuentlyQs, Supplierbar } from "./sections/index";
import useTitle, { useDescription, useKeywords } from '../../hooks/useSEO';
import { BlackBar } from './components/componetsTimeLine';
import { getHomeIndicators } from '../../services/globalServices';

export const AboutUs = () => {
  const [indicators, setIndicators] = useState({
      investors: 0, 
      totalInvestmentValue: 0,
    });
  
    useEffect(() => {
      const getData = async () => {
        const { data } = await getHomeIndicators();
        setIndicators({
          investors: Number(data?.data?.totalInvestors) || 0, 
          totalInvestmentValue: Number(data?.data?.totalInvested) || 0,
        });
      };
      getData();
    }, []);

  useTitle('Sobre Nosotros | inversiones inmobiliarias | Lokl');
  useDescription("Descubre cómo Lokl permite a las nuevas generaciones invertir en proyectos que les apasionan desde $1,000,000 COP en el sector inmobiliario, todo de manera 100% digital.");
  useKeywords('Inversión inmobiliaria digital, proyectos apasionantes, inversión desde $1,000,000 COP, propiedad digital, nuevas generaciones inversión, Lokl proyectos inmobiliarios, oportunidades de inversión, inversión 100% digital, ser dueño de proyectos, inversión en bienes raíces.')
  return (
    <main > 

      
     
      <LetsGrowthTogether />
      
      
      
      
      <section >
        <TimeLine />
      </section>
       <section className="w-full">
              <BlackBar 
                investors={indicators.investors} 
                totalInvestmentValue={parseFloat((indicators.totalInvestmentValue / 1000000).toFixed(0))} 
              />
        </section>
      <article className=" md:mt-0">
        <WeReDifferent />
      </article>
      
      <section>
      <OurTeam />
      </section>
     
      <section>
      <FrecuentlyQs />
      </section>
      <aside className="mt-16">
        <Supplierbar />
      </aside>
    </main>
  );
};
