import React, { useEffect, useState } from 'react'
import { ReinvestForm } from '../components/ReinvestForm'
import { ReinvestmentSummary } from '../components/ReinvestmentSummary'
import { useInvestmentSummary } from '../../../../hooks/useInvestmentSummary';
import { getProjectCards } from '../../../../services/projectService';
import toast from 'react-hot-toast';
import { Reinvestment } from '../interfaces/interfaces';
import { createOrUpdateDividendReinvestment } from '../../../../services/cartService';
import { formatNumberWithCommas } from '../../../../utils';

interface InvestProps {
  goNextStep: any;
  totalNoRequestAmount: number
  setReinvestment: React.Dispatch<React.SetStateAction<Reinvestment | undefined>>;
}

export const Invest: React.FC<InvestProps> = ({
  goNextStep, totalNoRequestAmount, setReinvestment
}) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [paymentMethod, setPaymentMethod] = useState<number>(2);
  const [project, setProject] = useState({
    value: "",
    label: "",
    phase: "",
    unitValue: 1,
    minimumUnitsPurchase: 1,
    subscriptionFeePercentage: 0,
    imageURL: '',
  });
  const [projects, setProjects] = useState([]);
  // FIX THIS
  const [NDAUnitValue, setNDAUnitValue] = useState(0)
  const [defaultProject, setDefalutProjects] = useState({
    value: "",
    label: "",
    phase: "",
    unitValue: 1,
    minimumUnitsPurchase: 1,
    subscriptionFeePercentage: 0,
    imageURL: '',
  });
  const [investUnitAmount, setInvestUnitAmount] = useState<string>('1');
  const [coupon, setCoupon] = useState({
    code: undefined,
    couponUnitPrice: 0,
    couponSubscriptionFeeFree: false
  });
  const {
    totalInvestment,
    subtotal,
    paymentMethodFee,
    installments,
    total
  } = useInvestmentSummary(
    investUnitAmount,
    '1',
    paymentMethod,
    project,
    coupon.couponUnitPrice,
    coupon.couponSubscriptionFeeFree,
    totalNoRequestAmount)


  useEffect(() => {
    getProjectCards().then((data) => {
      const filteredProjects = data.data.filter((project: any) => (project.phase === 'Etapa 2' || project.phase === 'Etapa 1' || project.phase === 'Etapa 3'));
      const projectNDA = filteredProjects.find((project: any) =>  project.name.trim().toLowerCase().includes("nido"));
      setNDAUnitValue(projectNDA?.unitPrice || 0);
      setProjects(filteredProjects.map((project: any) => ({
        value: project.id,
        label: project.name,
        phase: project.phase,
        unitValue: project.unitPrice,
        minimumUnitsPurchase: project.minInvestmentUnits,
        subscriptionFeePercentage: 0,
        imageURL: project.imageURL
      })))
      if (project.value === '') {
        setProject({
          value: filteredProjects[0].id,
          label: filteredProjects[0].name,
          phase: filteredProjects[0].phase,
          unitValue: filteredProjects[0].unitPrice,
          minimumUnitsPurchase: filteredProjects[0].minInvestmentUnits,
          subscriptionFeePercentage: 0,
          imageURL: filteredProjects[0].imageURL
        })
        setDefalutProjects({
          value: filteredProjects[0].id,
          label: filteredProjects[0].name,
          phase: filteredProjects[0].phase,
          unitValue: filteredProjects[0].unitPrice,
          minimumUnitsPurchase: filteredProjects[0].minInvestmentUnits,
          subscriptionFeePercentage: 0,
          imageURL: filteredProjects[0].imageURL
        })
      }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleNextStep = async () => {
    if (!(investUnitAmount.length > 0 && parseFloat(investUnitAmount) > 0)) toast.error('Ingrese la cantidad de units')
    else if (project.value === '') toast.error('Seleccione un proyecto')
    else {
      // Benefits
      if (coupon && coupon.code && /^DESCUENTO.*/.test(coupon.code) && totalNoRequestAmount < 1000) {
        toast.error('Para obtener este beneficio debes invertir más de $1000 por dividendos')
        return
      }
      if (coupon.code === 'DESCUENTO_5_PORCIENTO_NDA' && totalInvestment < totalNoRequestAmount) {
        toast.error('Para este beneficio, invierte todos tus dividendos para este beneficio. Selecciona más units.')
        return
      }
      if (coupon.code === 'DESCUENTO_7_5_PORCIENTO_NDA' && subtotal < 8 * NDAUnitValue) {
        toast.error(`Para este beneficio, invierte todos tus dividendos y al menos $${formatNumberWithCommas(8 * NDAUnitValue)} extra. Selecciona más units`)
        return
      }
      if (coupon.code === 'DESCUENTO_10_PORCIENTO_NDA' && subtotal < 16 * NDAUnitValue) {
        toast.error(`Para este beneficio, invierte todos tus dividendos y al menos $${formatNumberWithCommas(16 * NDAUnitValue)} extra. Selecciona más units.`)
        return
      }
      // end benefits 
      try {
        const response = await createOrUpdateDividendReinvestment({
          projectId: project.value,
          unitsQuantity: investUnitAmount,
          paymentMethod: paymentMethod === 1 ? 'CARD' : paymentMethod === 2 ? 'PSE' : null,
          installmentsQuantity: installments,
          couponCode: coupon.code || ''
        })

        if (response.success) {
          localStorage.setItem('cartId', response.data.id)
          goNextStep()
          setReinvestment({
            project,
            coupon: coupon.code ? coupon : undefined,
            totalInvestment,
            subtotal,
            paymentMethodFee,
            total,
            investUnitAmount,
            paymentMethod
          })
        } else {
          toast.error('Error con la compra, intentelo de nuevo.')
        }
      }
      catch (error: any) {
        if (error?.response.status === 403 || error?.response.status === 401) {
          toast.error('Tu sesión ha expirado, por favor inicia sesión nuevamente')
        }
      }
    }
  }

  return (
    <>
      <ReinvestForm
        NDAUnitValue={NDAUnitValue}
        setUnitAmount={setInvestUnitAmount}
        unitAmount={investUnitAmount}
        totalInvestment={totalInvestment}
        projects={projects}
        project={project}
        setProject={setProject}
        setCoupon={setCoupon}
        defaultProject={defaultProject}
      />
      <ReinvestmentSummary
        onSubmit={handleNextStep}
        isLoading={false}
        project={project}
        totalInvestment={totalInvestment}
        subtotal={subtotal}
        paymentMethodFee={paymentMethodFee}
        total={total}
        totalNoRequestAmount={totalNoRequestAmount}
      />
    </>
  )

}
