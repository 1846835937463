import { useOutletContext } from "react-router-dom";
import { InvestmentDetailsInterface } from "../interfaces";
import { motion } from 'framer-motion';
import { GanttChart, GalleryProject, InfoProject, HelpYourProject, CloudbedsDashboard, GalleryProjectSkeleton, InfoProjectSkeleton, HelpYourProjectSkeleton, MainList } from "./ProjectDetail/";


interface ProjectDetailDashboardOutletContext {
    investmentDetails: InvestmentDetailsInterface;
    isLoading: boolean;
}


const ProjectDetailDashboard = () => {
    const { investmentDetails, isLoading } = useOutletContext<ProjectDetailDashboardOutletContext>();
    const featureFlags = (investmentDetails?.features || []).reduce<Record<string, boolean>>((flags, feature) => {
        if (feature.enabled) {
            flags[feature.name] = true;
        }
        return flags;
    }, {});

    const cloudbedsDashboardEnabled = !!featureFlags['CLOUDBEDS_DASHBOARD'];
    const ganttChartEnabled = !!featureFlags['PROGRESS_TIMELINE'];

    

    
    return (
<div className='w-full h-auto space-y-8'>

  {cloudbedsDashboardEnabled && (
    <motion.div
      initial={{ opacity: 0, x: -100, scale: 0.95 }} 
      animate={{ opacity: 1, x: 0, scale: 1 }} 
      exit={{ opacity: 0, x: 100, scale: 0.95 }} 
      transition={{ duration: 0.6, ease: "easeOut" }} 
    >
      <CloudbedsDashboard />
    </motion.div>
  )}

{ganttChartEnabled && (
    <motion.div
      initial={{ opacity: 0, y: 50 }} 
      animate={{ opacity: 1, y: 0 }} 
      exit={{ opacity: 0, y: -50 }} 
      transition={{ duration: 0.5, ease: "easeOut" }} 
    >  
       
      <GanttChart tasks={investmentDetails.activities }  />
    </motion.div>
  )}

  <motion.div
    initial={{ opacity: 0, x: -50, scale: 0.9 }} 
    animate={{ opacity: 1, x: 0, scale: 1 }} 
    exit={{ opacity: 0, x: 50, scale: 0.9 }} 
    transition={{ duration: 0.6, ease: "easeInOut" }} 
  >
    <MainList projectName={investmentDetails?.name} cloudbedsDashboardEnabled={cloudbedsDashboardEnabled} />
  </motion.div>

   
  
  <motion.div
    className="relative w-full h-auto flex md:flex-row flex-col justify-between space-y-4 md:space-y-[0px]"
    initial={{ opacity: 0, x: -30 }} 
    animate={{ opacity: 1, x: 0 }} 
    exit={{ opacity: 0, x: 30 }} 
    transition={{ duration: 0.6, ease: "easeInOut" }} 
  >
    {isLoading ? <GalleryProjectSkeleton /> : <GalleryProject />}
    {isLoading ? <InfoProjectSkeleton /> : <InfoProject />}
  </motion.div>

  
  <motion.div
    className="w-full"
    initial={{ opacity: 0, scale: 0.8 }} 
    animate={{ opacity: 1, scale: 1 }} 
    exit={{ opacity: 0, scale: 0.8 }} 
    transition={{ duration: 0.7, ease: "easeInOut" }} 
  >
    {isLoading ? <HelpYourProjectSkeleton /> : <HelpYourProject />}
  </motion.div>
</div>
    )
}

export default ProjectDetailDashboard
