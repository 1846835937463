import React, { createRef, useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { options } from './constants/chart-options';
import { Button } from '../../components/buttons/Button';

import { getInstallmentsValue, getInvestorProfile, getNameEmail, isAuthenticated, scrollToSection, unitPrice, unitPriceMulplicated } from '../../helpers/functions';
import { useAuth } from '../../hooks/useAuth';
import iconInfo from "../../assets/img/infocircle.svg";
import { createPopper } from '@popperjs/core';
import { generateLead, generateLeadInCRM } from '../../services/globalServices';
import { useNavigate } from 'react-router-dom';
import Select from 'react-tailwindcss-select';
import PhoneInput from 'react-phone-number-input';
import es from 'react-phone-number-input/locale/es.json';
import Checkbox from '../../components/Checkbox';
import { HowDidYouHearAboutUs } from '../Auth/constants/register-constants';


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartDataLabels
);

const labels = ['2025', '2026', '2027', '2028', '2029', '2030'];

export const initialData = {
  labels,
  datasets: [
    {
      label: 'Rentabilidad',
      barThickness: 20,
      data: [0, 0, 0, 0, 0, 0],
      backgroundColor: '#6DECB9',
      borderColor: '#1311CD',
      borderWidth: 2,
      borderSkipped: false,
      // borderRadius: [
      //   { topLeft: 1000, topRight: 1000, bottomLeft: 1000, bottomRight: 1000 },
      // ]
    },
    {
      label: 'Valorización',
      barThickness: 20,
      data: [0, 0, 0, 0, 0, 0],
      backgroundColor: '#A9D0FC',
      borderColor:'#5452F6',
      borderWidth: 2,
      borderRadius: [
        { topLeft: 1000, topRight: 1000, bottomLeft: 0, bottomRight: 0 },
      ],
    },
  ],
};

interface ChartProps {
  dataProp: any
}

export const SimulatorChart: React.FC<ChartProps> = ({dataProp}) => {

  const [ data, setData ] = useState(initialData)

  useEffect(() => {
    if(initialData) {
      const test = {
        labels: dataProp?.simulation.map((value: any) => value.year),
        datasets: [
          {
            label: 'Rentabilidad',
            barThickness: 20,
            data: dataProp?.simulation.map((value: any) => value.return),
            backgroundColor: '#8972FF',
            borderColor: '#0401DD',
            borderWidth: 2,
            borderSkipped: false,
            // borderRadius: [
            //   { topLeft: 0, topRight: 0, bottomLeft: 1000, bottomRight: 1000 },
            // ]
          },
          {
            label: 'Valorización',
            barThickness: 20,
            data: dataProp?.simulation.map((value: any) => value.appreciation),
            backgroundColor: '#4CF2C3',
            borderColor:'#0401DD',
            borderWidth: 2,
            borderRadius: [
              { topLeft: 1000, topRight: 1000, bottomLeft: 0, bottomRight: 0 },
            ],
          },
        ],
      };
      setData(test)
    }

  }, [dataProp])
  

  return (
    <>
      <Bar options={options} data={data} />
      <div className="px-[30px] mt-[-1px] opacity-100" >
        <hr style={{borderWidth: '2px'}}/>
      </div>
    </>
  );
}

const testData = {
  simulation: [
    {
      year: 'Año 1',
      return: 9.61,
      appreciation: 0,
    },
    {
      year: 'Año 2',
      return: 8.77,
      appreciation: 0,
    },
    {
      year: 'Año 3',
      return: 12.63,
      appreciation: 8.12,
    },
    {
      year: 'Año 4',
      return: 11.76,
      appreciation: 9.27,
    },
    {
      year: 'Año 5',
      return: 6,
      appreciation: 10.48,
    }
  ]
}

function PopupSimulatorInfo({ triggerRef, isVisible }: any) {

  const popoverRef: any = createRef();

  useEffect(() => {
    // if(isVisible){
    // }
    createPopper(triggerRef.current, popoverRef.current, {
      placement: "top"
    });

    // eslint-disable-next-line
  }, []);

  return (
    <div 
      ref={popoverRef} 
      className={`${ isVisible === true ? 'block' : 'hidden' } w-72 bg-white z-10 text-sm text-[#928C8C] float-left p-3 border mb-2 rounded`}
    >
      <ul>
        <li>Rentas = Dinero recibido por la utilidad de la operación.</li>
        <li>Valorización = Valor que gana tu participación en el tiempo.</li>
        <li>ROI = Retorno sobre la inversión.</li>
      </ul>
    </div>
  )
}

interface SimulatorProps {
  showInputs?: boolean;
  showButton?: boolean;
  unitsAlt?: string;
  installmentsAlt?: string;
  nameAlt?: string;
  phoneAlt?: string;
  numberInstallmentsAlt?: number;
  leadOriginAlt?: any;
  reference?: any
  secondaryButtonPath?: string
}

const installmentsOptionsConst = [
  {
    value: '1',
    label: '1'
  },
  {
    value: '3',
    label: '3'
  },
  {
    value: '6',
    label: '6'
  },
  {
    value: '9',
    label: '9'
  },
  {
    value: '12',
    label: '12'
  }
]

const initialInstallmentsValue = installmentsOptionsConst.find((item) => item.value === localStorage.getItem('simulationInstallments')) || installmentsOptionsConst[0]

export const Simulator: React.FC<SimulatorProps> = ({
  showInputs = true,
  showButton = true,
  unitsAlt = '100',
  installmentsAlt = '1',
  nameAlt= '',
  phoneAlt= '',
  numberInstallmentsAlt = 1,
  leadOriginAlt = '',
  reference,
  secondaryButtonPath
}) => {

  const [terms, setTerms] = useState(true);
  const [units, setUnits] = useState(100);
  const [investmentValue, setInvestmentValue] = useState('12.300.000');

  const [installmentsValue, setInstallmentsValue] = useState<string | null>('-');
  const [investorProfile, setInvestorProfile] = useState<any>({title: '-', icon: ''});
  const [popoverShow, setPopoverShow] = useState(false);
  const btnTriggerRef: any = createRef();

  const [installmentsOptions] = useState<any>(installmentsOptionsConst);
  const [numberInstallments, setNumberInstallments] = useState<any>(initialInstallmentsValue);
  const [phone, setPhone] = useState<string>();
  const [nameSimulator, setNameSimulator] = useState<string>();
  const [nameSimulatorError, setNameSimulatorError] = useState<string>('');
  const [phoneError, setPhoneError] = useState<string>('');
  const [showSimulator, setShowSimulator] = useState<boolean>(false);
  const [leadOrigin, setLeadOrigin] = useState<any>(null);
  const {isAuth} = useAuth()
  const [originError, setOriginError] = useState<string>();

  const handleUnitsChange = (e: any) => {
    setUnits(e.target.value);
    const invest = unitPriceMulplicated(e.target.value);
    setInvestmentValue(invest);
  }

  const submitSimulator = () => {
    if(isAuth) {
      const result = getInstallmentsValue(parseInt(numberInstallments.value), units);
      setInstallmentsValue(result);
      const investorProfile = getInvestorProfile(units * unitPrice());
      setInvestorProfile(investorProfile);
      const emailFromToken = getNameEmail().email;
      const nameFromToken = getNameEmail().name;
      sendSimulationLead(emailFromToken, nameFromToken, '')
      setPhoneError('')
      setNameSimulatorError('')
    } else {
      if(!nameSimulator) {
        setNameSimulatorError('Por favor ingresa un nombre')
        return;
      }
      setNameSimulatorError('')
      if(!phone || phone.length < 13) {
        setPhoneError('Por favor ingresa un número de teléfono')
        return;
      }
      setPhoneError('')
      localStorage.setItem('simulationUnits', units.toString());
      localStorage.setItem('simulationInstallments', numberInstallments.value);
      localStorage.setItem('nameSimulator', nameSimulator);
      localStorage.setItem('phone', phone);
      const result = getInstallmentsValue(parseInt(numberInstallments.value), units);
      setInstallmentsValue(result);
      const investorProfile = getInvestorProfile(units * unitPrice());
      setInvestorProfile(investorProfile);
      sendSimulationLead('', nameSimulator, phone);
      setShowSimulator(true)
    }
  }

  const navigate = useNavigate()
  const goToBenefits = () => {
    navigate('/nido')
    setTimeout(() => {
      scrollToSection('investor-profile' || '', 100);
    }, 10)
  }

  const sendSimulationLead = (email: string, name: string, phone = '') => {
    var leadOriginVar = ""
    if(leadOriginAlt !== undefined && leadOriginAlt !== null && leadOriginAlt !== '')
      {
        leadOriginVar = leadOriginAlt.value 
      }
    if(leadOrigin !== null && leadOrigin !== undefined){
      leadOriginVar = leadOrigin.value
    }

    if (!leadOriginVar && !isAuth){
      setOriginError("Por favor ingresa cómo nos conociste");
      return
    }
    setOriginError('')
    generateLead(
      name,
      email,
      investmentValue,
      units,
      numberInstallmentsAlt,
      phone,
      terms
    );
    generateLeadInCRM(
      name,
      email,
      investmentValue,
      units,
      numberInstallmentsAlt,
      phone,
      terms,
      leadOriginVar
    );
  }

  useEffect(() => {
    setUnits(parseInt(unitsAlt));
    setNumberInstallments(installmentsOptionsConst.find((item) => item.value === installmentsAlt) || installmentsOptionsConst[0]);
    setNameSimulator(nameAlt);
    setPhone(phoneAlt);
    setInvestmentValue(unitPriceMulplicated(parseInt(unitsAlt)));
  }, [unitsAlt, installmentsAlt, nameAlt, phoneAlt])


  // FIXME: This is a temporal solution
  // This useEffect is to get simulation data from localStorage in a non-authenticated flow
  useEffect(() => {
    const units = localStorage.getItem('simulationUnits') || '100';
    setUnits(parseInt(units));
    setNumberInstallments(installmentsOptionsConst.find((item) => item.value === localStorage.getItem('simulationInstallments')) || installmentsOptionsConst[0]);
    setInvestmentValue(unitPriceMulplicated(parseInt(units)));
    setNameSimulator(localStorage.getItem('nameSimulator') || '');
    setPhone(localStorage.getItem('phone') || '');
    if(localStorage.getItem('nameSimulator') && localStorage.getItem('phone')) {
      setShowSimulator(true)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleInstallments = (val: any) => {
    setNumberInstallments(val);
  }

  const onHandleSecondaryButton = () => {
    if(secondaryButtonPath) {
      navigate(secondaryButtonPath)
    } else {
      isAuthenticated(true);
    }
  }

  const handleName = (event: any) => {
    setNameSimulator(event.target.value);
  }

  const handlePhoneChange = (value: any) =>  {
    if (value?.length >= 13) {
      setPhoneError('');
    }
    setPhone(value);
  }

  return (
    <div className="rounded-xl overflow-hidden border border-[#E8E6E6] shadow-md max-w-md min-w-[366px] w-full">
      <div className="bg-[#BEC5FF] px-8 py-10">
        <div className="mb-4">
          <h4 className="font-epilogue text-xl font-bold">Simula tu inversión</h4>
          <p className="font-noto text-sm">*Proyecciones estimadas sujetas a cambios. El pago a cuotas tiene un costo de financiación</p>
        </div>
        {
          showInputs &&
          <div className="grid grid-cols-12 gap-2">
            <div className="col-span-3 text-center">
              <input
                className="w-full rounded-md py-[10px] text-lg px-2 block font-epilogue font-bold text-center leading-none"
                type="text"
                id="units"
                value={units}
                onChange={handleUnitsChange}
                placeholder='units'
              />
              <label htmlFor="units" className="text-[#4F4F4F] font-syne text-sm"># Units</label>
            </div>
            <div className="col-span-3 text-center">
              <Select
                classNames={{
                  menuButton: ({ isDisabled }) => `flex justify-around border-[1px] rounded-lg bg-white border-gray-300 px-4 rounded-lg outline-none cursor-pointer focus:border-black focus:border-2 font-bold`,
                  ChevronIcon: ({ open }) => (open ? 'rotate-90' : 'rotate-180'),
                  list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52'
                }}
                primaryColor='violet'
                value={numberInstallments}
                loading={installmentsOptions.length <= 0}
                options={installmentsOptions.map((value: any) => { return { value: value.value, label: value.label } })}
                placeholder="Numero de cuotas"
                onChange={(val: any) => {
                  handleInstallments(val)
                }}
              />
              <label htmlFor="installments" className="text-[#4F4F4F] font-syne text-sm">Cuotas</label>
            </div>
            <div className="col-span-6 text-center">
              <input
                disabled
                id="price"
                className="w-full block rounded-md py-[10px] text-lg px-2 font-epilogue font-bold text-center leading-none"
                type="text"
                value={`$${investmentValue}`}
              />
              <label htmlFor="price" className="text-[#4F4F4F] font-syne text-sm">Total a invertir</label>
            </div>
            {
              !isAuth && 
              <>
                <div className="col-span-12">
                  <input
                    className={`
                      w-full rounded-md py-[10px] text-lg px-4 block font-epilogue font-bold leading-none
                      ${ nameSimulatorError ? 'border-red-500 border-[2px]' : 'border' }
                      `}
                    type="text"
                    id="name"
                    value={nameSimulator}
                    onChange={handleName}
                    placeholder='Nombre'
                  />
                  <label htmlFor="units" className="text-[#4F4F4F] font-syne text-sm">Nombre</label>
                </div>
                <div className="col-span-12">
                  <div className="">

                  <PhoneInput
                    className={`
                      h-[40px] border-[1px]
                      px-4
                      bg-white
                      focus:border-[#4743E0]
                      font-semibold
                      rounded-lg
                      ${ phoneError ? 'border-red-500' : ''}
                    `}
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry='CO'
                    labels={es}
                    placeholder="Teléfono"
                    value={phone}
                    onChange={(value: any) => {
                      handlePhoneChange(value);
                    }}
                  />
                  </div>
                  <label htmlFor="units" className="text-[#4F4F4F] font-syne text-sm">Número de teléfono</label>
                  <div className="col-span-12 mt-2">
                    <Select
                      classNames={{
                        menuButton: () => `font-semibold flex justify-between border-[1px] rounded-lg bg-white  py-1 px-4 rounded-lg outline-none cursor-pointer focus:border-violet-600 ${originError ? 'border-red-500': 'border-gray-300'}`,
                        ChevronIcon: ({ open }) => ( open ? 'rotate-90' : 'rotate-180' ),
                        list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52',
                        listGroupLabel: 'pl-[10px] pb-[5px] text-black font-bold',
                        listItem: ({ isSelected }) => `pl-[15px] list-none py-1 cursor-pointer ${ isSelected && 'bg-[#8B5CF6] text-white py-2 px-3 rounded-lg' } `,
                      }}
                      primaryColor='violet'
                      value={leadOrigin}
                      onChange={(val: any) => {
                        setLeadOrigin(val);
                      }}
                      options={HowDidYouHearAboutUs}
                      loading={HowDidYouHearAboutUs.length <= 0}
                      placeholder="¿Cómo nos conociste?"
                    />
                    <label htmlFor="units" className="text-[#4F4F4F] font-syne text-sm">Selecciona tu origen</label>
                  </div>
                  <div className='mt-2'>
                    <Checkbox setTerms={setTerms} terms={terms} name={<div className="flex">Acepto <a target='_blank' rel='noreferrer' href='https://drive.google.com/file/d/1R6aOvsRjYVo-d398PskWJjwL4_WrY9PP/view?usp=drive_link' className="ml-1 font-roboto font-bold text-[#4743E0] no-underline">términos y condiciones</a></div>} />
                  </div>
                </div>
              </>
            }
          </div>
        }

        <div className="flex justify-between mt-10">
          <div>
            <p className="text-[12px] text-[#353535]">Tipo de membrecía</p>
            <div className="flex items-center gap-2 cursor-pointer" onClick={goToBenefits}>
              <p className="font-lato font-extrabold text-[20px] text-[#1A1919]">{investorProfile.title}</p>
              {
                investorProfile.icon !== '' && 
                <img src={investorProfile.icon} alt="Imagen de tipo de inversionista" />
              }
            </div>
          </div>
          <div>
            <p className="text-[12px] text-[#353535]">Cuotas mensuales de</p>
            <p className="font-lato font-extrabold text-[20px] text-[#1A1919]">{installmentsValue}</p>
          </div>
        </div>
      </div>
      <div className="relative pb-2">
        <div className={`${isAuth || showSimulator ? '' : 'blur-sm'}`}>
          <div className="bg-white p-6">
            <div className="flex gap-4 justify-center relative">
              <div className="flex items-center gap-2">
                <div className="bg-[#4CF2C3] rounded-full w-2 h-2 flex-shrink-0"></div>
                <p className="font-extrabold text-sm font-lato">Rentas</p>
              </div>
              <div className="flex items-center gap-2">
                <div className="bg-[#5452F6] rounded-full w-2 h-2 flex-shrink-0"></div>
                <p className="font-extrabold text-sm font-lato">Valorización</p>
              </div>
              <div className="flex items-center gap-2">
                <div className="bg-[#F82FF8] rounded-full w-2 h-2 flex-shrink-0"></div>
                <p className="font-extrabold text-sm font-lato">ROI anual</p>
              </div>
              <div
                className="absolute top-[-4px] right-4"
                ref={btnTriggerRef}
                onClick={() => {
                  setPopoverShow(!popoverShow)
                }}
              >
                <img width={13} height={13} src={iconInfo} alt="" className="cursor-pointer" />
              </div>
              <PopupSimulatorInfo triggerRef={btnTriggerRef} isVisible={popoverShow} />
            </div>
          </div>

          <div className="px-6 ml-[-20px]">
            <SimulatorChart dataProp={testData} />
            <div className="my-2">
              <div className="flex items-center justify-between px-9">
                <div className="bg-[#ffffff] rounded-full w-2 h-2 flex-shrink-0"></div>
                <div className="border-b w-full border-[#ffffff]"></div>
                <div className="bg-[#ffffff] rounded-full w-2 h-2 flex-shrink-0"></div>
                <div className="border-b w-full border-[#ffffff]"></div>
                <div className="bg-[#F82FF8] rounded-full w-2 h-2 flex-shrink-0"></div>
                <div className="border-b w-full border-[#F82FF8]"></div>
                <div className="bg-[#F82FF8] rounded-full w-2 h-2 flex-shrink-0"></div>
                <div className="border-b w-full border-[#F82FF8]"></div>
                <div className="bg-[#F82FF8] rounded-full w-2 h-2 flex-shrink-0"></div>
              </div>
              <div className="flex item-center justify-between px-[12px] mt-2">
                <p className="text-transparent text-sm font-epilogue font-bold">13.29%</p>
                <p className="text-transparent text-sm font-epilogue font-bold">13.29%</p>
                <p className="text-[#F82FF8] text-sm font-epilogue font-bold">12.79%</p>
                <p className="text-[#F82FF8] text-sm font-epilogue font-bold">15.74%</p>
                <p className="text-[#F82FF8] text-sm font-epilogue font-bold">16.44%</p>
              </div>
            </div>
          </div>
        </div>
        <div className={`
          px-6 my-6 
          ${isAuth || showSimulator ? 'block' : 'w-4/6 absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%]'}
          ${!showButton ? 'hidden' : 'block'}
        `}>
          <Button disabled={!terms} reference={reference} id="btnSimulator" label='Calcular inversión' type='button' onButtonClick={submitSimulator} />
        </div>
      </div>

      <div className='mx-4'>
        <p className='text-sm'>Todos estos valores son estimados y dependen de los resultados del proyecto para cumplirse, los cuales están por fuera del control de LOKL, por lo cual LOKL no garantiza la rentabilidad.</p>
      </div> 

      <div className="flex px-4 justify-between items-center my-8 gap-4">
        
        <div>
          <button className="
              min-w-fit
              w-full
              md:w-1/2
              rounded-full
              bg-black
              text-white
              font-semibold
              text-sm
              px-2.5
              py-2
              text-center
              relative
              hover:opacity-80
              transition
            "
            onClick={onHandleSecondaryButton}>
            Quiero participar
          </button>
        </div>
      </div>
    </div>
  )
}