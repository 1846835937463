import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import amabassador_question_mark from "../../../assets/img/ambassadors/ambassadors-question-mark.png";
import ambassador_forest_image from "../../../assets/img/ambassadors/ambassador-forest-image.png";
import img_camilo_ambassador from "../../../assets/img/ambassadors/img-camilo-amabassador.png";
import iconPlay from "../../../assets/img/nido/header/icon-play.svg";

export default function AmbassadorWhoWeAre() {
  const [isVisible, setIsVisible] = useState(false);
  const sectionRef = useRef(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const handlePlayClick = () => {
    setIsVideoPlaying(true);
  };

  useEffect(() => {
    const currentSection = sectionRef.current; 
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true);
          }
        });
      },
      { threshold: 0.2 }
    );
  
    if (currentSection) {  // Use the local variable
      observer.observe(currentSection);
    }
  
    return () => {
      if (currentSection) {  
        observer.unobserve(currentSection);
      }
    };
  }, []);

  return (
    <div ref={sectionRef} className="flex flex-col items-center bg-[#E0E0E0] text-gray-800 min-h-[100vh]">
      <div className="relative w-full h-[35vh] md:h-[50vh]">
        <motion.div
          className="w-full h-full bg-center bg-cover"
          style={{ backgroundImage: `url(${ambassador_forest_image})` }}
          initial={{ opacity: 0 }}
          animate={isVisible ? { opacity: 1 } : {}}
          transition={{ duration: 1 }}
        ></motion.div>
      </div>

      <div className="flex flex-col lg:flex-row items-center justify-between w-full lg:max-w-6xl max-w-3xl mt-20 md:mt-40 md:mb-28">
        <div className="lg:w-1/2 flex flex-col items-center lg:items-end text-center md:text-right">
          <motion.h2
            className="text-7xl lg:text-9xl font-thin md:translate-y-12 lg:mr-20 tracking-tight primaryFont"
            initial={{ opacity: 0, y: 50 }}
            animate={isVisible ? { opacity: 1, y: 0 } : {}}
            transition={{ duration: 1 }}
          >
            ¿Quiénes <br />
            <span className="text-[#333333] font-semibold">somos?</span>
          </motion.h2>
        </div>

        <div className="hidden lg:block h-80 md:translate-y-10 w-[1px] bg-black lg:mx-6"></div>

        <div className="lg:w-[45%]  xl:w-[90%] w-[80%] flex flex-col  xl:translate-x-20 items-center lg:items-start text-center md:text-left">
          <motion.img
            src={amabassador_question_mark}
            alt="icono signo pregunta lokl"
            className="w-10 mt-12 h-10 md:w-20 md:h-20 object-contain mb-10"
            initial={{ opacity: 0 }}
            animate={isVisible ? { opacity: 1 } : {}}
            transition={{ duration: 1, delay: 0.3 }}
            loading="lazy"
          />
          <motion.p
            className="text-lg md:text-2xl font-bold text-black primaryFont mb-10 md:mb-12"
            initial={{ opacity: 0 }}
            animate={isVisible ? { opacity: 1 } : {}}
            transition={{ duration: 1, delay: 0.5 }}
          >
            #CreceConLokl
          </motion.p>
          <motion.p
            className="text-base lg:text-2xl  font-normal text-left leading-6 primaryFont mb-4"
            initial={{ opacity: 0, x: -50 }}
            animate={isVisible ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1, delay: 0.7 }}
          >
            Lokl es una plataforma de inversión <br />
            inmobiliaria; aunque más que eso, es <br />
            una comunidad que conecta a personas <br />
            y proyectos únicos.
          </motion.p>
          <motion.p
            className="text-base  lg:text-2xl  font-normal text-left leading-6 primaryFont"
            initial={{ opacity: 0, x: -50 }}
            animate={isVisible ? { opacity: 1, x: 0 } : {}}
            transition={{ duration: 1, delay: 0.9 }}
          >
            A través de un ecosistema 100% digital <br />
            democratizamos el acceso a la inversión <br />
            y a los bienes raíces de manera accesible, <br />
            transparente y con gran impacto social.
          </motion.p>
        </div>
      </div>

      <div className="flex flex-col items-center w-full mt-16 mb-16">
        {isVideoPlaying ? (
          <div className="w-full max-w-[320px] sm:max-w-[480px] md:max-w-6xl aspect-video bg-black rounded-lg overflow-hidden">
            <iframe
              className="w-full h-full"
              src="https://www.youtube.com/embed/GcQSVdwwnKI?autoplay=1&mute=0"
              title="Lokl Video"
              allow="autoplay; encrypted-media"
              allowFullScreen
              loading="lazy"
            ></iframe>
          </div>
        ) : (
          <div className="relative w-full max-w-[320px] sm:max-w-[480px] md:max-w-6xl">
            <img
              src={img_camilo_ambassador}
              alt="Video Camilo Ambassador"
              className="w-full h-full object-cover rounded-lg"
              loading="lazy"
            />
            <button
              onClick={handlePlayClick}
              className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg"
              id="btn-play-video-who-we-are"
            >
              <img
                src={iconPlay}
                alt="Reproducir video"
                className="w-16 h-16 md:w-20 md:h-20"
              />
            </button>
          </div>
        )}
        <motion.p
          className="text-lg md:text-4xl font-light text-black primaryFont mt-7 md:my-20"
          initial={{ opacity: 0 }}
          animate={isVisible ? { opacity: 1 } : {}}
          transition={{ duration: 1, delay: 1.1 }}
        >
          #CreceConLokl
        </motion.p>
      </div>
    </div>
  );
}
