import { useState } from "react";
import { useOutletContext } from "react-router-dom";
import { InvestmentDetailsInterface } from "../../interfaces";
import playIcon from "../../../../assets/img/nido/arrow-next.svg";
import { trigger } from '../../../../helpers/events';
import GalleryAll from "./GalleryAll";

interface GalleryProjectOutletContext {
  investmentDetails: InvestmentDetailsInterface;
}

export const GalleryProject = () => {
  const { investmentDetails } = useOutletContext<GalleryProjectOutletContext>();
  const [videoActived, setVideoActived] = useState(false);
  const [activeGallery, setActiveGallery] = useState(false);

  if (!investmentDetails?.gallery || investmentDetails.gallery.length === 0) {
    return (
      <div className="w-full md:w-[60%] h-[300px] flex justify-center items-center">
        No se encontraron imágenes
      </div>
    );
  }

  const mainImage = investmentDetails.gallery.find((img) => img.format === "image" && img.type === "main");
  const videoImage = investmentDetails.gallery.find((img) => img.format === "video");
  const horizontalImage = investmentDetails.gallery.find((img) => img.type === "horizontal");
  const galleryImages = investmentDetails.gallery.filter((img) => img.type === "gallery");

  const openGallery = () => {
    setActiveGallery((prevState) => !prevState);
    trigger('closeGalleryNido');
  };

  return (
    <div className="block md:relative w-full md:w-[60%]">

      <div className="flex flex-col gap-2">
        <div className="relative w-full cursor-pointer" style={{ paddingBottom: '56.25%' }}>
          {videoActived ? (
            <iframe
              width="100%"
              height="100%"
              src={`${videoImage?.url}?autoplay=1`}
              title="YouTube video player"
              className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : (
            <>
              <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center cursor-pointer">
                <img
                  className="opacity-60 z-10 transform transition-transform duration-300 hover:scale-110"
                  width={50}
                  height={50}
                  src={playIcon}
                  alt="play-video-indie-universe"
                  onClick={() => {
                    setVideoActived(true);
                    console.log("Activando video desde la imagen");
                  }}
                />
              </div>

              <img
                className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
                src={mainImage?.url}
                alt="indie-universe-video"
                onClick={() => {
                  setVideoActived(true);
                  console.log("Activando video desde la imagen");
                }}
                loading="lazy"
              />
            </>
          )}
        </div>

        <div className="grid grid-cols-3 gap-2 h-[150px]">

          {horizontalImage && (
            <div className="col-span-2">
              <img
                src={horizontalImage.url}
                alt="Imagen horizontal"
                className="w-full h-[150px] object-cover rounded-lg"
                loading="lazy"
              />
            </div>
          )}

          {galleryImages[0] && (
            <div className="col-span-1 relative">
              <div
                className="cursor-pointer absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center text-white p-8 bg-black bg-opacity-50 rounded-lg shadow-lg"
                onClick={openGallery}
              >
                <div className="text-4xl font-extrabold">+8</div>
                <div className="font-light underline">Ver más fotos</div>
              </div>
              <img
                src={galleryImages[0].url}
                alt="Imagen de la galería"
                className="w-full h-[150px] object-cover rounded-lg cursor-pointer"
                loading="lazy"
              />
            </div>

          )}
        </div>

        {activeGallery && <GalleryAll images={galleryImages} />}
      </div>
    </div>
  );
};
