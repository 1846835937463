
interface CollaboratorsPropertyprops {
    collaborator : Array<{name : string , roll : string , collaboratorLink : string, imageLink : string}>
}


export const CollaboratorsPropertyPage:React.FC<CollaboratorsPropertyprops> = ({
    collaborator = []
  }) => {
  return (
    <section>
      <h2 className="text-gray-400 text-2xl font-bold primaryFont mb-5">Equipo del proyecto</h2>
      <div className="grid grid-cols-6 gap-x-0 gap-y-6 lg:gap-x-5 lg:gap-y-16">
        {
          collaborator.map((team) => (
            <div className="flex items-end justify-center secondaryFont col-span-3 lg:col-span-2">
              <div className="text-center">
                <div className="flex justify-center mb-4 ">
                  <img className="w-[55%] h-[55%]" src={team.imageLink} alt={team.roll} />
                </div>
                <p className="primaryFont font-bold text-[#7C786C]">{team.name}</p>
                <a href={team.collaboratorLink} className="secondaryFont text-[#5452F6] text-sm" target='_blank' rel="noreferrer">Conoce más aquí</a>
              </div>
            </div>
          ))
        }
      </div>
    </section>
  )
}

