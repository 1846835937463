export const SkeletonOperation = () => {
    return (
        <div className="w-full">
            <div className='skeleton rounded-lg w-[80%] h-[11px] mb-1'></div>
            <div className='skeleton rounded-lg w-[80%] h-[11px] mb-1'></div>
            <div className='skeleton rounded-lg w-[80%] h-[11px] mb-1'></div>
            <div className='skeleton rounded-lg w-[80%] h-[11px] mb-1'></div>
        </div>
    )
}
