import axios from "axios"
import { urls } from "../helpers/urls";

export const createSimulation = async (data: any) => {
    try {
        const response = await axios.post(`${urls.NEW_API_URL}simulator/createSimulation`, data, {
        })
        return response;
    } catch (error) {
        throw error;
    }
}
export const sendSimulationScenaries = async (data: { to: string, userName: string }) => {
    try {
        const response = await axios.post(`${urls.NEW_API_URL}simulator/sendSimulationScenaries`, data, {
        })
        return response;
    } catch (error) {
        throw error;
    }
}