import React from "react";
import { useLocation } from "react-router-dom";

interface InvestorFormHeaderProps {
  urlImg: string | undefined;
  name?: string;
  isSubmitted: boolean;
  setIsSubmitted: (value: boolean) => void;
}

export const InvestorFormHeader: React.FC<InvestorFormHeaderProps> = ({ 
  urlImg, 
  name, 
  isSubmitted, 
  setIsSubmitted 
}) => {
  const location = useLocation();
  const isLeadForm = location.pathname.startsWith("/project-signup");
  const isInvestorForm = location.pathname === "/project-signup";

  return (
    <section className="h-auto flex flex-col items-center text-black mb-4 rounded-t-2xl">
      <div
        className="w-full flex flex-col justify-center items-center p-8 rounded-t-lg h-60 relative"
        style={{
          backgroundImage: urlImg && !isInvestorForm ? `url(${urlImg}), linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 1))` : "none",
          backgroundColor: isInvestorForm ? "black" : "transparent",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {isInvestorForm && (
          <img
            src={"https://lokl-assets.s3.us-east-1.amazonaws.com/lokl/img-lokl-green.png"}
            alt="TESTIMONIO INVERSIONISTA 1 LOKL S A S"
            className="w-[50%] h-auto object-contain"
          />
        )}
        <div className="absolute inset-0 bg-black opacity-5"></div>
        <h1 className={`text-2xl font-bold primaryFont text-center ${isInvestorForm ? "text-white" : "text-white"}`}>
          {isInvestorForm ? "INSCRIPCIÓN A PROYECTOS LOKL" : isLeadForm ? `INSCRIPCIÓN AL PROYECTO ${name?.toUpperCase()}` : "COMPLETA TU PERFIL DE INVERSIONISTA"}
        </h1>
      </div>

      
      {!isSubmitted && (
        <p className="text-base mt-3 text-center secondaryFont">
          {isLeadForm
            ? "Por favor, completa la siguiente información para inscribirte en nuestros próximos proyectos. Asegúrate de proporcionar todos los datos correctamente para que podamos contactarte."
            : "Queremos seguir mejorando la experiencia para brindarte un mejor servicio. Realizar esta encuesta no te llevará más de 2 minutos."
          }
        </p>
      )}

    </section>
  );
};
