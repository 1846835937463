import { useEffect, useState } from "react";
import { Link, useNavigate, useOutletContext } from "react-router-dom";
import starIcon from "../../../assets/img/dashboard/star-yellow.svg";
/* import ModalShareCodeUnit from "../../../components/PopUpAmbassadors/ModalShareCodeUnits"; */
import { on } from "../../../helpers/events";
import { numberWithCommas } from "../../../helpers/functions";
import { getOwnersInvestment } from "../../../services/dashboardServices";
import { AmenitiesCard } from "../../NewHome/components";
import bannerIndie from "../../../assets/img/dashboard/banner-indie.jpg";
import bannerNido from "../../../assets/img/dashboard/banner-nido-de-agua.jpg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation, Pagination, Scrollbar } from "swiper";

const InvestedProjectCard = () => {
  const { setIsInvestor, isInvestor } = useOutletContext<any>();
  const [statusProjects, setStatusProjects] = useState<any>("pending");
  const [myProjects, setMyProjects] = useState<any[]>([]);
  /* const [isOpen, setIsOpen] = useState(false);
  const [showModal, setShowModal] = useState(false); */
  const navigate = useNavigate();

/*   useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
      setIsOpen(true);
    }, 1500);

    return () => clearTimeout(timer);
  }, []); */

  const getDataDashborad = async () => {
    sessionStorage.removeItem("projects");

    try {
      const { data, status } = await getOwnersInvestment();
      setMyProjects(data);
      setStatusProjects(status);
      if (data.length > 0) {
        setIsInvestor(data[0].projectId);
      }
    } catch (error) {
      setStatusProjects("error");
    }
  };

  const goToProjectDetail = (project: any) => {
    navigate(`project-detail/${project.projectId}`);
  };

  useEffect(() => {
    getDataDashborad();

    on("reload:dashboard", () => {
      getDataDashborad();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="w-full flex flex-col items-center justify-center bg-[#F8F8F8] rounded-lg p-4 md:p-6 mb-4">
      {isInvestor && (
        <Link
          to={`/dashboard/dividend/${isInvestor}/reinvestment`}
          className="mt-5 flex md:hidden justify-start items-center bg-white w-full rounded-lg py-4 px-4 font-roboto text-[23px] font-medium no-underline text-black hover:text-black"
        >
          <svg
            className="w-[24px] h-[24px] mr-[20px]"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 512 512"
          >
            <path d="M512 32c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 46.3 364 0 448 0l32 0c17.7 0 32 14.3 32 32zM0 96C0 78.3 14.3 64 32 64l32 0c123.7 0 224 100.3 224 224l0 32 0 160c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-160C100.3 320 0 219.7 0 96z" />
          </svg>
          Reinvertir
        </Link>
      )}
      <h2 className="text-[23px] font-lato font-black mb-[17px] mt-[20px] md:mt-[0px]">
        Mis inversiones
      </h2>
      
      {myProjects.some((project) => project.projectId) && (
        <Swiper
            spaceBetween={40}
            slidesPerView={1}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            modules={[Navigation, Pagination, Scrollbar, Autoplay]}
            className="w-full min-w-[240px] min-h-[100px] max-w-full mb-4 items-center justify-center"
        >
            
            {myProjects.some((project) => project.projectId === "c3f50b31-1e1b-4ebe-881e-0d390458f471") && (
            <SwiperSlide>
            <div className="h-full  rounded-md mb-4 flex items-center justify-center relative">
              <img
                src={bannerIndie}
                alt="Banner Indie"
                className="w-full h-auto object-cover rounded-md"
              />
              <a 
                href="https://docs.google.com/forms/d/e/1FAIpQLScqwKr8tSdAILLcOYSyDuDpAF-TfEw2smqesJzr892P-X-jTQ/viewform"
                target="_blank" 
                rel="noopener noreferrer"
                className="no-underline absolute bottom-2 md:bottom-4 text-xs right-5 bg-white text-black px-6 md:py-2 py-1.5 rounded-full text-center shadow-lg hover:bg-gray-200 transition-all"
              >
                Inscribite a la asamblea
              </a>
            </div>
          </SwiperSlide>
            )}

            
            {myProjects.some((project) => project.projectId === "a6775860-635a-4622-80f8-7d0de0c3eef0") && (
            <SwiperSlide>
                <div className="h-full w-full rounded-md mb-4 flex items-center justify-center">
                <img
                    src={bannerNido}
                    alt="Banner Nido"
                    className="w-full h-auto object-cover rounded-md"
                />
                 <a 
                href="https://docs.google.com/forms/d/e/1FAIpQLSe-5aFmw4OAhUEcMEgOrpRrk1g8V1ESjaQEMZPq41o0-jFY9A/viewform"
                target="_blank" 
                rel="noopener noreferrer"
                className="no-underline absolute bottom-8 md:bottom-10 text-xs right-5 bg-white text-black px-6 md:py-2 py-1.5 rounded-full text-center shadow-lg hover:bg-gray-200 transition-all"
              >
                Inscribite a la asamblea
              </a>
                </div>
            </SwiperSlide>
            )}
        </Swiper>
        )}


      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 pr-[0px] md:pr-3">
        {statusProjects === "pending" ? (
          <h2>Cargando inversiones...</h2>
        ) : myProjects.length > 0 ? (
          myProjects.map((project) => (
            <div
              key={project.projectId}
              className="bg-white rounded-md p-4 mb-4 "
            >
              <div className="flex flex-col md:flex-row  items-start md:space-x-4">
                <div className="w-full md:w-auto flex flex-col items-center justify-center  mb-4 md:mb-[0px]">
                  {project.projectImageUrl && (
                    <img
                      src={project.projectImageUrl}
                      alt={project.projectName}
                      className="aspect-video md:aspect-square  w-full md:w-24 h-auto  md:h-24 object-cover rounded-md"
                    />
                  )}
                  {project.projectBookingUrl && (
                    <a
                      className="mt-1 text-[#3533FF] text-base leading-5"
                      href={project.projectBookingUrl}
                      target="blank"
                      rel="noreferrer"
                    >
                      Reservar
                    </a>
                  )}
                </div>
                <div className="flex-1">
                  <div className="mb-3 flex justify-between items-center">
                    <h2 className="text-[21px] font-lato font-black capitalize">
                      {project?.projectName}
                    </h2>
                    <div className="flex">
                      <img className="w-[16px]" src={starIcon} alt="star" />
                      <div className="text-[15px] ml-2">5</div>
                    </div>
                  </div>
                  <div className="flex flex-wrap mt-2 -translate-x-1">
                    <AmenitiesCard
                      amenitie={`${project.accommodations} Alojamientos`}
                    />
                    <AmenitiesCard amenitie={`${project.squareMeters} m²`} />
                    {project.amenities.map((amenity: any) => (
                      <AmenitiesCard key={amenity} amenitie={amenity} />
                    ))}
                  </div>
                  <div className="h-24 overflow-y-auto">
                    <p className={`text-xs mt-2`}>
                      {project.projectDescription}
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex md:flex-row flex-col flex-wrap justify-between items-center">
                <div className="w-full md:w-auto flex flex-row flex-wrap justify-between items-end mt-4 ml-[0px] md:ml-28">
                  <div className="flex flex-col justify-center items-center">
                    <h2 className="text-[18px] font-bold">
                      ${numberWithCommas(project?.totalInvestmentValue)}
                    </h2>
                    <h2 className="text-[12px] font-normal">Monto invertido</h2>
                  </div>
                  <div className="flex flex-col justify-center items-center mx-[0px] md:mx-4">
                    <h2 className="text-[18px] font-bold">
                      {Math.round(project?.units)}
                    </h2>
                    <h2 className="text-[12px] font-normal">Units</h2>
                  </div>
                  <div className="flex flex-col justify-center items-center">
                    <h2 className="text-[18px] font-bold">
                      {Math.round(project?.unitsByReferral)}
                    </h2>
                    <h2 className="text-[12px] font-normal">
                      Units x referidos
                    </h2>
                  </div>
                </div>
                <button
                  className="w-full md:w-auto h-9 bg-black text-white text-[10px] px-3 rounded-full mt-4 md:mt-[0px] ml-auto"
                  onClick={() => goToProjectDetail(project)}
                >
                  Ver inversión
                </button>
              </div>
            </div>
          ))
        ) : (
          // TODO: Recommend projects
          <h2>No posees inversiones</h2>
        )}
      </div>

      {/* {showModal && isOpen && <ModalShareCodeUnit setIsOpen={setIsOpen} />} */}
    </div>
  );
};

export default InvestedProjectCard;
