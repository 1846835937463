import { Form } from "../Interfaces/FormInterfaces";


export const ProjectsFormData: Form = {
    id: "investor-form",
    name: "Formulario de Inversionista",
    questions: [
      {
        id: "firstName",
        text: "¿Cuáles son tus nombres?",
        type: "text",
        required: true,
      },
      {
        id: "lastName",
        text: "¿Cuáles son tus apellidos?",
        type: "text",
        required: true,
      },
      {
        id: "phone",
        text: "¿Cuál es tu número de teléfono?",
        type: "phone", 
        required: true,
      },
      {
        id: "email",
        text: "¿Cuál es tu correo electrónico?",
        type: "text",
        required: true,
      },
      {
        id: "leadOrigin",
        text: "¿Dónde nos conociste?",
        type: "select",
        required: false,
        options: [
          { id: "facebook", label: "Facebook", value: "Facebook" },
          { id: "instagram", label: "Instagram", value: "Instagram" },
          { id: "google", label: "Google", value: "Google" },
          { id: "finances", label: "Mis propias finanzas", value: "Mis propias finanzas" },
          { id: "ads", label: "Anuncio o publicidad en redes sociales", value: "Anuncio o publicidad en redes sociales" },
          { id: "contentCreator", label: "Creador@ de contenido", value: "Creador@ de contenido" },
          { id: "ambassadorProgram", label: "Programa de embajadores", value: "Programa de embajadores" },
          { id: "finzi", label: "Finzi", value: "Finzi" },
          { id: "news", label: "Noticias", value: "Noticias" },
          { id: "email", label: "Email", value: "Email" },
          { id: "google", label: "Google", value: "Google" },
          { id: "partnerships", label: "Alianzas", value: "Alianzas" },
          { id: "familyFriend", label: "Familiar, amigo o conocido", value: "Familiar, amigo o conocido" },
          { id: "events", label: "Eventos", value: "Eventos" },
          { id: "youtube", label: "Youtube", value: "Youtube" },
          { id: "instagram", label: "Instagram", value: "Instagram" },
          { id: "tiktok", label: "Tiktok", value: "Tiktok" },
          { id: "linkedin", label: "LinkedIn", value: "LinkedIn" },
          { id: "other", label: "Otro", value: "Otro" },
        ],
      },


      {
        id: "projectIds",
        text: "¿Cuales proyectos te interesan?",
        type: "checkbox",
        required: true,
        options: [
          { id: "proj1",url:"https://lokl-assets.s3.amazonaws.com/patito-feo/patito-feo.jpg" , label: "Patito feo", value: "d4f50b31-1e1b-4ebe-881e-0d390458f471" },
          { id: "proj2",url:"https://lokl-assets.s3.amazonaws.com/nido-de-agua/AEREA+NOCHE.jpg" ,label: "Nido de Agua", value: "a6775860-635a-4622-80f8-7d0de0c3eef0" },
          { id: "proj3",url:"https://lokl-assets.s3.us-east-1.amazonaws.com/indie-universe/indie_universe.jpg" ,label: "Indie Universe", value: "c3f50b31-1e1b-4ebe-881e-0d390458f471" },
          { id: "proj4",url:"https://lokl-assets.s3.us-east-1.amazonaws.com/aldea/aldea.jpg" ,label: "Aldea", value: "bc47c0a3-0d7d-40d8-a1db-128015f1ac9c" },
        ],
      },
    
      
    ],
  };
  
