import React, { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import icon from "../../../../assets/img/about-us/img-boton-plus.png";
import info from "../../../../assets/img/about-us/img-info.png";
import useCountUp from "../../../../hooks/useCountUp";
import { createPopper } from "@popperjs/core";

interface IndicatorsProps {
  investors: number
  totalInvestmentValue: number;
}

const PopoverDisclaimer = ({ triggerRef, isVisible }: any) => {
  const popoverRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (triggerRef.current && popoverRef.current) {
      createPopper(triggerRef.current, popoverRef.current, { placement: "top" });
    }
  }, [triggerRef]);

  return (
    <div
      ref={popoverRef}
      className={`${isVisible ? "block" : "hidden"} primaryFont w-72 bg-white z-10 text-sm text-[#928C8C] float-left p-4 border mb-2 rounded`}
    >
      <span className="font-bold primaryFont">Las estimaciones de retorno</span> dependen netamente de la operación y pueden variar acorde a los resultados de la misma.
    </div>
  );
};

export const BlackBar = ({ investors, totalInvestmentValue }: IndicatorsProps) => {
  const [popoverShow, setPopoverShow] = useState(false);
  const btnTriggerRef = useRef<HTMLDivElement>(null);
  const [isVisible, setIsVisible] = useState(false);
  const blackBarRef = useRef<HTMLDivElement>(null);

  
  const animatedInvestors = useCountUp(investors, 1700); 
  const animatedInvestmentValue = useCountUp(totalInvestmentValue, 1700);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.1 }
    );

    if (blackBarRef.current) observer.observe(blackBarRef.current);

    return () => observer.disconnect();
  }, []);

  return (
    <div
      ref={blackBarRef}
      className="relative px-[23px] w-full gap-7 h-[130px] md:h-[174px] py-2.5 bottom-0 md:px-[200px] bg-black flex justify-between items-center"
    >
      {[
        { value: animatedInvestors, label: "Total inversionistas" },
        { value: "12-14% E.A", label: "Retorno estimado", icon: true },
        { value: `${animatedInvestmentValue.toLocaleString("es-ES")}M`, label: "Inversión a la fecha" },
      ].map((item, index) => (
        <motion.div
          key={index}
          className="flex items-center space-x-2"
          initial={{ opacity: 0, y: -20 }}
          animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: -20 }}
          transition={{ duration: 0.8, delay: index * 0.3 }}
        >
          <img className="w-[35px] h-[25px] md:w-[56px] md:h-[46px]" src={icon} alt="icon" />
          <div
            className={`text-white text-xs md:text-xl text-center ${
              item.label === "Inversión a la fecha" ? "primaryFont" : ""
            }`}
          >
            <div className="text-sm text-center md:text-left md:text-[40px] numberFont font-bold relative">
              {item.value}
              {item.icon && (
                <div
                  ref={btnTriggerRef}
                  onClick={() => setPopoverShow(!popoverShow)}
                  className="absolute top-0 -right-4 cursor-pointer mt-1"
                >
                  <motion.img
                    className="w-4 h-4"
                    src={info}
                    alt="info icon"
                    initial={{ opacity: 0, y: -10 }}
                    animate={isVisible ? { opacity: 1, y: 0 } : { opacity: 0, y: -10 }}
                    transition={{ duration: 0.5, delay: index * 0.3 + 0.3 }}
                  />
                </div>
              )}
            </div>
            <div className="text-xs mt-2 md:text-lg">{item.label}</div>
          </div>
        </motion.div>
      ))}
      <PopoverDisclaimer triggerRef={btnTriggerRef} isVisible={popoverShow} />
    </div>
  );
};
