import React, { useContext, useEffect, useState } from "react";
import { useForm, FieldValues, SubmitHandler } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import es from "react-phone-number-input/locale/es.json";
import "react-phone-number-input/style.css";
import Select from "react-tailwindcss-select";
import { HowDidYouHearAboutUs } from "../../Pages/Auth/constants/register-constants";
import {
  SimulationResponseAPIInterface,
  SimulationResponseInterface,
} from "./simulationInterface";
import { createSimulation } from "../../services/SimulatorService";
import { AxiosResponse } from "axios";
import {
  generateLeadInCRM,
  saveSimulation,
  createContactInQuiiven,
} from "../../services/globalServices";
import { getNameEmail } from "../../helpers/functions";
import { useLocation } from "react-router-dom";
import { UTMContext } from "../../contexts";
import { CreateContactInAC } from "../../services/acService";

interface LeadCardProps {
  simulationData: {
    project: string;
    investmentValue: string;
    installmentsNumber: string;
    name: string;
    phone: string;
    email: string;
    leadOrigin: string;
    unitValue: number;
    minimumUnitsPurchase: number;
    installmentOptions: any;
  };
  setSimulationData: React.Dispatch<
    React.SetStateAction<{
      project: string;
      investmentValue: string;
      installmentsNumber: string;
      name: string;
      phone: string;
      email: string;
      leadOrigin: string;
      unitValue: number;
      minimumUnitsPurchase: number;
      installmentOptions: any;
    }>
  >;
  setSimulationResponse: React.Dispatch<
    React.SetStateAction<SimulationResponseInterface | undefined>
  >;
}

function splitFullName(fullName: string) {
  if (!fullName || typeof fullName !== "string") {
    return { firstName: "", lastName: "" };
  }

  const nameParts = fullName.trim().split(/\s+/);

  if (nameParts.length === 1) {
    return { firstName: nameParts[0], lastName: "" };
  } else if (nameParts.length === 2) {
    return { firstName: nameParts[0], lastName: nameParts[1] };
  } else {
    const firstName = nameParts.slice(0, 2).join(" ");
    const lastName = nameParts.slice(2).join(" ");
    return { firstName, lastName };
  }
}

export const VerticalLeadCard: React.FC<LeadCardProps> = ({
  simulationData,
  setSimulationData,
  setSimulationResponse,
}) => {
  const [phone, setPhone] = useState<string | undefined>("");
  const [leadOriginSelected, setLeadOriginSelected] = useState<any>(null);
  const [, setIsOtherSelected] = useState(false);
  const context = useContext(UTMContext);
  const { UTM } = context;
  const [, setCode] = useState<string | undefined>(undefined);

  const location = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors, touchedFields },
    setValue,
    clearErrors,
    watch,
  } = useForm<FieldValues>({
    defaultValues: {
      name: simulationData.name,
      phone: simulationData.phone,
      leadOrigin: simulationData.leadOrigin,
      email: simulationData.email,
      terms: false,
    },
    mode: "onTouched",
  });

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const code = searchParams.get("code");

    if (code) {
      setCode(code);
      setValue("leadOrigin", code);
      setLeadOriginSelected({
        label: code,
        value: code,
        disabled: false,
      });
    }
  }, [location.search, setValue]);

  const onSubmit: SubmitHandler<FieldValues> = (data) => {
    console.log(data);
    createSimulation({
      investmentValue: parseFloat(simulationData.investmentValue),
      installmentsNumber: parseFloat(simulationData.installmentsNumber),
      projectId: simulationData.project,
    }).then((response: AxiosResponse<SimulationResponseAPIInterface>) => {
      setSimulationResponse(response.data.data);
    });
    setSimulationData((prevData: typeof simulationData) => ({
      ...prevData,
      name: data.name,
      phone: data.phone,
      email: data.email,
      leadOrigin:
        data.otherLeadOrigin && data.otherLeadOrigin !== ""
          ? data.otherLeadOrigin
          : data.leadOrigin,
    }));
    /* generateLead(
            data.name,
            data.email,
            simulationData.investmentValue,
            (parseFloat(simulationData.investmentValue) / simulationData.unitValue),
            parseFloat(simulationData.installmentsNumber),
            data.phone,
            true
        ); */
    generateLeadInCRM(
      data.name,
      data.email,
      simulationData.investmentValue,
      parseFloat(simulationData.investmentValue) / simulationData.unitValue,
      parseFloat(simulationData.installmentsNumber),
      data.phone,
      true,
      data.leadOrigin === "otro" ? data.otherLeadOrigin : data.leadOrigin
    );
    createContactInQuiiven(
      data.name,
      data.email,
      simulationData.investmentValue,
      parseFloat(simulationData.investmentValue) / simulationData.unitValue,
      parseFloat(simulationData.installmentsNumber),
      data.phone,
      true,
      data.leadOrigin === "otro" ? data.otherLeadOrigin : data.leadOrigin,
      UTM.utmSource,
      UTM.utmMedium,
      UTM.utmCampaign,
      UTM.utmTerm,
      UTM.utmContent
    );
    const { firstName, lastName } = splitFullName(data.name);
    const getBrowserName = () => {
      const userAgent = navigator.userAgent;

      if (userAgent.includes("Firefox")) return "Firefox";
      if (userAgent.includes("Edg")) return "Microsoft Edge";
      if (
        userAgent.includes("Chrome") &&
        !userAgent.includes("Edg") &&
        !userAgent.includes("OPR")
      )
        return "Chrome";
      if (userAgent.includes("Safari") && !userAgent.includes("Chrome"))
        return "Safari";
      if (userAgent.includes("Opera") || userAgent.includes("OPR"))
        return "Opera";
      return "Unknown Browser";
    };
    CreateContactInAC({
      email: data.email,
      firstName: firstName,
      lastName: lastName,
      phone: data.phone,
      source: UTM.utmSource || getBrowserName() || "Google",
      medium: UTM.utmMedium || "Directo",
      howDidYouMeetUs:
        data.leadOrigin === "otro" ? data.otherLeadOrigin : data.leadOrigin,
      campaign: UTM.utmCampaign,
      term: UTM.utmTerm,
      content: UTM.utmContent,
      tag: "SIMULADOR",
    });

    saveSimulation(
      simulationData.project,
      parseFloat(
        (
          parseFloat(simulationData.investmentValue) / simulationData.unitValue
        ).toFixed(1)
      ),
      parseFloat(simulationData.investmentValue),
      parseInt(simulationData.installmentsNumber),
      true,
      data.leadOrigin === "otro" ? data.otherLeadOrigin : data.leadOrigin,
      "new simulator",
      getNameEmail() ? getNameEmail().email : data.email,
      data.phone,
      "",
      data.name,
      UTM.utmSource,
      UTM.utmMedium,
      UTM.utmCampaign,
      UTM.utmTerm,
      UTM.utmContent
    );
  };

  const handlePhoneChange = (value: string | undefined) => {
    setValue("phone", value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
    setPhone(value);
    setValue("phone", value);
    clearErrors("phone");
  };

  const handleLeadOriginChange = (val: any) => {
    setValue("leadOrigin", val.value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true,
    });
    setLeadOriginSelected(val);
    if (val.value === "otro") {
      setIsOtherSelected(true);
    } else {
      setIsOtherSelected(false);
    }
    clearErrors("leadOrigin");
  };

  return (
    <form
      id="sendLeadNewSimulator"
      onSubmit={handleSubmit(onSubmit)}
      className="w-full bg-[#DFDFFF] min-h-[230px] h-auto rounded-3xl px-[5%] pt-14 pb-10"
    >
      <div className="flex flex-col w-full justify-between">
        <h1 className="primaryFont text-3xl  font-bold text-[#3533FF]">
          ¡ESTÁS A UN PASO DE SABER!
        </h1>
        <button
          type="submit"
          className="hidden primaryFont text-xl text-white bg-[#221FEB] px-10 items-center rounded-full font-semibold"
        >
          Ver Resultados
        </button>
      </div>
      <div className="flex flex-col w-full justify-between mt-4">
        <div className="min-h-[70px] w-full">
          <input
            type="text"
            placeholder="Nombre completo"
            className={`secondaryFont w-full placeholder:text-[#4F4F4F] h-[40px] border-[1px] ${
              errors.name
                ? "border-red-500"
                : touchedFields.name
                ? "border-green-500"
                : "border-gray-300"
            } py-2 px-4 rounded-lg outline-none focus:border-[#4743E0]`}
            {...register("name", { required: true })}
          />
          {errors.name && (
            <div className="secondaryFont text-red-500 text-sm">
              * El nombre es requerido
            </div>
          )}
        </div>

        <div className="min-h-[70px] w-full">
          <PhoneInput
            className={`
                          rounded-lg
                          numberFont w-full h-[40px] border-[1px]
                          px-4
                          bg-white
                          focus:border-[#4743E0]
                          ${
                            errors.phone?.message
                              ? "border-red-500"
                              : "border-gray-300"
                          }
                        `}
            international
            countryCallingCodeEditable={false}
            defaultCountry="CO"
            labels={es}
            placeholder="Teléfono"
            value={phone}
            onChange={handlePhoneChange}
          />
          <input
            type="hidden"
            {...register("phone", {
              required: "El teléfono es requerido",
              minLength: { value: 8, message: "El teléfono no es valido" },
            })}
          />
          {errors.phone && (
            <div className="secondaryFont text-red-500 text-sm">
              * {errors.phone?.message?.toString()}
            </div>
          )}
        </div>

        <div className="min-h-[70px] w-full">
          <input
            type="email"
            placeholder="Correo"
            className={`secondaryFont w-full placeholder:text-[#4F4F4F] h-[40px] border-[1px] ${
              errors.email
                ? "border-red-500"
                : touchedFields.email
                ? "border-green-500"
                : "border-gray-300"
            } py-2 px-4 rounded-lg outline-none focus:border-[#4743E0]`}
            {...register("email", { required: true })}
          />
          {errors.email && (
            <div className="secondaryFont text-red-500 text-sm">
              * El correo es requerido
            </div>
          )}
        </div>
      </div>
      <div className="w-full mx-auto flex flex-col justify-between">
        <div className="min-h-[70px] w-full">
          <Select
            classNames={{
              menuButton: () =>
                `secondaryFont flex justify-between h-[40px] border-[1px] text-[#4F4F4F] bg-white ${
                  errors.leadOrigin
                    ? "border-red-500"
                    : !errors.leadOrigin && touchedFields.leadOrigin
                    ? "border-green-500"
                    : "border-gray-300"
                } px-4 rounded-lg outline-none cursor-pointer focus:border-[#4743E0] ${
                  leadOriginSelected ? "text-gray-800" : "text-gray-400"
                } overflow-hidden whitespace-nowrap text-ellipsis`,
              list: "scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52",
              listItem: ({ isSelected }) =>
                `pl-[15px] list-none py-1 cursor-pointer ${
                  isSelected && "bg-[#4a4a4a] rounded-lg text-white py-2 px-3"
                }`,
            }}
            primaryColor="violet"
            value={leadOriginSelected}
            onChange={handleLeadOriginChange}
            options={[
              ...HowDidYouHearAboutUs,
              { value: "otro", label: "Otro" },
            ]}
            placeholder="¿Cómo nos conociste?"
          />

          {errors.leadOrigin && (
            <div className="secondaryFont text-red-500 text-sm">
              * Selecciona tu origen.
            </div>
          )}

          {watch("leadOrigin") === "otro" && (
            <div className="mt-8 mb-4 md-0">
              <input
                type="text"
                className="w-full border border-gray-300 rounded-lg px-4 py-2 focus:border-[#4743E0] focus:outline-none"
                placeholder="Especifica cómo nos conociste"
                {...register("otherLeadOrigin", { required: true })}
              />
              {errors.otherLeadOrigin && (
                <div className="secondaryFont text-red-500 text-sm">
                  * Por favor, especifica cómo nos conociste.
                </div>
              )}
            </div>
          )}

          <input
            type="hidden"
            {...register("leadOrigin", { required: true })}
          />
        </div>
        <div className=" w-full flex flex-col">
          <div className="flex h-fit">
            <input
              type="checkbox"
              id="terms"
              {...register("terms", { required: true })}
              className="secondaryFont mr-2"
            />
            <label htmlFor="terms" className="secondaryFont text-[#848484]">
              Acepto los{" "}
              <a
                className="text-[#292828]"
                href="https://drive.google.com/file/d/1R6aOvsRjYVo-d398PskWJjwL4_WrY9PP/view"
                target="_blank"
                rel="noreferrer noopener"
              >
                términos y condiciones
              </a>{" "}
              de la política de tratamiento de datos de Lokl s.a.s
            </label>
          </div>
          {errors.terms && (
            <div className="secondaryFont text-red-500 text-sm">
              * Debes aceptar los términos y condiciones.
            </div>
          )}
        </div>
      </div>
      <button
        type="submit"
        className="w-full justify-center mt-3 primaryFont text-xl text-white bg-[#221FEB] flex px-10 items-center rounded-full font-semibold h-16"
      >
        Ver Resultados
      </button>
    </form>
  );
};
