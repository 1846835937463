import React from 'react';

interface CardsteamProps {
    src: string;
    name: string;
    role: string;
    url: string;
}

export const Cardsteam: React.FC<CardsteamProps> = ({ src, name, role, url }) => {
    return (
        <a href={url} target='_blank' rel="noreferrer" className="flex flex-col items-center cursor-pointer no-underline text-center p-4">
            <img 
                className="w-80 h-80 rounded-full border-3 border-[#4F4CF1]" 
                src={src} 
                alt={name} 
            />
            <h3 className="mt-2 font-bold primaryFont text-4xl">{name}</h3>
            <p className="text-2xl font-light italic text-gray-600 secondaryFont">{role}</p>
        </a>
    );
};
