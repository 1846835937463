import React, { useRef, useState, useEffect } from "react";
import iconPlay from "../../../../assets/img/projects/play-transparente.svg";
import { ProjectStatusCard } from "./";
import { useVideoContext } from "../../../../contexts/VideoContext";
import { usePageVisits } from "../../../../hooks/usePageVisits";

interface VideoCardInterface {
    imageURL: string;
    videoURL: string;
    phase: string;
    name: string;

}

export const VideoCard: React.FC<VideoCardInterface> = ({ imageURL, videoURL, phase ,  name }) => {
    const { activeVideo, setActiveVideo } = useVideoContext();
    const [showVideo, setShowVideo] = useState(false);
    const iframeRef = useRef<any>(null);
    const { visits } = usePageVisits()
    const playVideo = () => {
        setActiveVideo(videoURL);
        setShowVideo(true);
    };

    useEffect(() => {
        if (activeVideo !== videoURL && iframeRef.current) {
            iframeRef.current.contentWindow.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            setShowVideo(false);
        }
    }, [activeVideo, videoURL]);

    return (
        <div className="w-full relative mt-9 mx-auto">
            <div className="secondaryFont absolute z-20  top-0 right-0 flex justify-between items-start mt-6 mr-7">
                <ProjectStatusCard phase={phase} />
            </div>
            {showVideo && phase !== 'Etapa 0' ? (
                <div className="w-full max-h-[250px]">
                    <iframe
                        ref={iframeRef}
                        width="100%"
                        className="aspect-[1.2/1] rounded-t-3xl max-h-[250px]"
                        src={`${videoURL}?si=X_uG8FnQXvOfZY-T&amp;controls=0&autoplay=1;start=2`}
                        title={` ${name} proyecto de inversion lokl`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        id={imageURL}
                    ></iframe>
                </div>
            ) : (
                <div className="w-full aspect-[1.2/1] max-h-[250px] relative cursor-pointer" onClick={playVideo}>
                    <img className="w-full rounded-t-3xl h-full object-cover" src={imageURL} alt={`PROYECTO DE INVERSIÓN ${name.toUpperCase()} LOKL`} />
                    <div className="absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]">
                        {phase !== 'Etapa 0' && <img src={iconPlay} alt="Play icon" />}
                    </div>
                </div>
            )}
           
            <div className="w-full text-white bg-black flex items-center justify-center py-1">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_6031_2108)">
                        <path d="M9.9848 4.375C6.94027 4.375 3.81722 6.13711 1.35863 9.66133C1.28845 9.76303 1.25 9.88325 1.24812 10.0068C1.24624 10.1303 1.28103 10.2517 1.34808 10.3555C3.23715 13.3125 6.3184 15.625 9.9848 15.625C13.6114 15.625 16.7559 13.3055 18.6485 10.3418C18.714 10.24 18.7488 10.1216 18.7488 10.0006C18.7488 9.87958 18.714 9.76113 18.6485 9.65937C16.7516 6.72969 13.584 4.375 9.9848 4.375Z" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
                        <path d="M9.99805 13.125C11.7239 13.125 13.123 11.7259 13.123 10C13.123 8.27411 11.7239 6.875 9.99805 6.875C8.27216 6.875 6.87305 8.27411 6.87305 10C6.87305 11.7259 8.27216 13.125 9.99805 13.125Z" stroke="white" strokeMiterlimit="10" />
                    </g>
                    <defs>
                        <clipPath id={imageURL}>
                            <rect width="20" height="20" fill="white" transform="translate(-0.00195312)" />
                        </clipPath>
                    </defs>
                </svg>
                
                    <p className="ml-3"><span className="secondaryFont font-extrabold">{visits}</span> personas viendo este proyecto</p>
                
            </div>
            
        </div>
    );
};
