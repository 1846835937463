import React, { useEffect, useState } from 'react'
import { numberWithCommas } from '../../../../helpers/functions'
import { Project } from '../interfaces/interfaces'
import Select from 'react-tailwindcss-select'
import { validateCoupon } from '../../../../services/couponService'
import toast from 'react-hot-toast'
import BenefitItem from './BenefitItem'
import { formatNumberWithCommas } from '../../../../utils'

interface ReinvestFormProps {
  NDAUnitValue: number
  unitAmount: string
  setUnitAmount: (amount: string) => void,
  totalInvestment: number,
  projects: Project[],
  project: Project,
  setProject: any,
  setCoupon: any,
  defaultProject: Project
}


export const ReinvestForm: React.FC<ReinvestFormProps> = ({
  NDAUnitValue,
  unitAmount,
  setUnitAmount,
  totalInvestment,
  projects,
  project,
  setProject,
  setCoupon,
  defaultProject
}) => {
  const [couponCode, setCouponCode] = useState('');
  const [disableCouponInput, setDisableCouponInput] = useState<boolean>(false);
  // Benefits
  const [benefits, setBenefits] = useState([
    {
      coupon: 'DESCUENTO_5_PORCIENTO_NDA',
      isActive: false,
      discount: 5,
      condition: 'invirtiendo todas tus utilidades'
    },
    {
      coupon: 'DESCUENTO_7_5_PORCIENTO_NDA',
      isActive: false,
      discount: 7.5,
      condition: `invirtiendo todas tus utilidades + $${formatNumberWithCommas(8 * NDAUnitValue)}`
    },
    {
      coupon: 'DESCUENTO_10_PORCIENTO_NDA',
      isActive: false,
      discount: 10,
      condition: `invirtiendo todas tus utilidades + $${formatNumberWithCommas(16 * NDAUnitValue)}`
    }
  ]);
  // end benefits
  useEffect(() => {
    setBenefits([
      {
        coupon: 'DESCUENTO_5_PORCIENTO_NDA',
        isActive: false,
        discount: 5,
        condition: 'invirtiendo todas tus utilidades'
      },
      {
        coupon: 'DESCUENTO_7_5_PORCIENTO_NDA',
        isActive: false,
        discount: 7.5,
        condition: `invirtiendo todas tus utilidades + $${formatNumberWithCommas(8 * NDAUnitValue)}`
      },
      {
        coupon: 'DESCUENTO_10_PORCIENTO_NDA',
        isActive: false,
        discount: 10,
        condition: `invirtiendo todas tus utilidades + $${formatNumberWithCommas(16 * NDAUnitValue)}`
      }
    ]);
  }, [NDAUnitValue]);
  const onHandleChange = (e: any) => {
    const value = e.target.value;
    const onlyNumbers = /^[0-9]*$/;
    if (!onlyNumbers.test(value)) {
      return;
    }
    setUnitAmount(value);
  }

  const handleProjectChange = (val: any) => {
    setProject(val)
  }

  const onHandleCouponChange = (e: any) => {
    const value = e.target.value
    setCouponCode(value)
  }

  const onHandleValidateCoupon = async (couponCodeParameter: string | undefined = undefined) => {
    const coupon = await validateCoupon(couponCodeParameter || couponCode)
    if (coupon?.data?.success) {

      const selectedProject = projects.find(
        (project) => project.value === coupon.data.data.projectId
      );

      if (selectedProject) {

        toast.success('Cupón validado exitosamente')
        setDisableCouponInput(true)
        setProject(selectedProject)

        setCoupon({
          code: coupon.data.data.code,
          couponUnitPrice: coupon.data.data.unitPrice,
          couponSubscriptionFeeFree: coupon.data.data.subscriptionFeeFree

        })

      } else toast.error('Cupón no válido')

    } else {
      toast.error('Cupón no válido')
    }
  }

  const handleDiscount = (coupon: string) => {
    const selectedBenefit = benefits.find((benefit) => benefit.coupon === coupon);

    if (selectedBenefit) {
      // Verificar si ya está activo
      if (selectedBenefit.isActive) {
        setBenefits((prevBenefits) =>
          prevBenefits.map((benefit) =>
            benefit.coupon === selectedBenefit.coupon
              ? { ...benefit, isActive: false }
              : benefit
          )
        );
        handleReset()
      } else {
        setBenefits((prevBenefits) =>
          prevBenefits.map((benefit) =>
            benefit.coupon === selectedBenefit.coupon
              ? { ...benefit, isActive: true }
              : { ...benefit, isActive: false }
          )
        );
        setCoupon(coupon);
        setCouponCode(coupon);
        onHandleValidateCoupon(coupon);
      }
    }
  };

  const handleReset = () => {
    setUnitAmount('1');
    setCouponCode('');
    setDisableCouponInput(false);
    setProject(defaultProject)
    setCoupon({
      code: undefined,
      couponUnitPrice: 0,
      couponSubscriptionFeeFree: false
    });
    setBenefits(benefits.map(benefit => ({ ...benefit, isActive: false })));
  }

  const getDiscount = (coupon: string) => {
    const selectedBenefit = benefits.find((benefit) => benefit.coupon === coupon);
    if (!selectedBenefit) return 0;
    return selectedBenefit?.discount;
  };

  return (
    <div className="bg-white flex flex-col rounded-md w-full md:w-3/4 xl:w-2/4 py-4 px-10 max-w-2xl">
      <div className="flex items-center gap-3">
        <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect width="45" height="43" rx="5" fill="#A9DBFF" />
          <path d="M23 32C28.5228 32 33 27.5228 33 22C33 16.4771 28.5228 12 23 12C17.4771 12 13 16.4771 13 22C13 23.5997 13.3756 25.1116 14.0435 26.4525C14.2209 26.8088 14.28 27.2161 14.1771 27.6006L13.5815 29.8267C13.323 30.793 14.207 31.677 15.1733 31.4185L17.3994 30.8229C17.7839 30.72 18.1912 30.7791 18.5475 30.9565C19.8884 31.6244 21.4003 32 23 32Z" stroke="#1C274C" strokeWidth="1.5" />
          <path d="M23 25.3333C24.1046 25.3333 25 24.5871 25 23.6667C25 22.7462 24.1046 22 23 22C21.8954 22 21 21.2538 21 20.3333C21 19.4129 21.8954 18.6667 23 18.6667M23 25.3333C21.8954 25.3333 21 24.5871 21 23.6667M23 25.3333V26M23 18.6667V18M23 18.6667C24.1046 18.6667 25 19.4129 25 20.3333" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
        </svg>
        <h2 className="font-bold text-lg">Ingresa la cantidad que quieres reinvertir</h2>
      </div>

      <div>

        <div className="my-3">
          <p>Selecciona un proyecto</p>
        </div>
        <Select
          primaryColor="violet"
          value={project}
          onChange={handleProjectChange}
          options={projects}
          placeholder="Selecciona un proyecto"
          isDisabled={disableCouponInput}
          classNames={{
            menuButton: () =>
              `secondaryFont flex justify-between h-[40px] border-[1px] text-[#4F4F4F] bg-white
                } px-4  outline-none cursor-pointer focus:border-[#4743E0]`,
            list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52',
            listItem: ({ isSelected }) => `pl-[15px] list-none py-1 cursor-pointer ${isSelected && 'bg-[#4a4a4a] rounded-lg text-white py-2 px-3'}`,
          }}
        />

        <div className="my-3">
          <p>¿Cuánto quieres invertir?</p>
        </div>

        <div className="grid grid-cols-12 border focus:via-violet-700 px-4 py-1 my-3">
          <input
            type="text"
            placeholder="Units"
            className="outline-none text-lg border-r-gray-900 col-span-8 font-roboto font-semibold"
            value={unitAmount}
            onChange={onHandleChange}
          />
          <div className="flex flex-col items-center col-span-4">

            <p className="text-gray-400 font-bold font-roboto">${numberWithCommas(totalInvestment)}</p>

            <div className='flex justify-between'>
              <p className="text-xs text-gray-400">Precio{ couponCode && getDiscount(couponCode) > 0 && " con "}</p>
              {
                couponCode && getDiscount(couponCode) > 0 &&
                <span className='text-xxs text-green-400 font-bold'>&nbsp;{getDiscount(couponCode)}% de descuento</span>
              }
              </div>
          </div>
        </div>

        <div className='flex'>
          <input
            disabled={disableCouponInput}
            type="text"
            placeholder="Cupón Opcional"
            className="
            outline-none
            text-lg
            border-r-gray-900
            font-roboto
            font-normal
            border
            focus:via-violet-700
            px-4
            py-1
            w-full
            h-[40px]
            disabled:opacity-60
          "
            value={couponCode}
            onChange={onHandleCouponChange}
          />
          <button
            disabled={!couponCode}
            onClick={() => onHandleValidateCoupon()}
            className='
            w-1/2
            md:w-1/3
            font-roboto
            bg-green-400
            font-normal
            py-2
            px-4
            disabled:opacity-60
            disabled:cursor-not-allowed
          '
          >
            Validar cupón
          </button>
        </div>

        <div className=' w-[100%] mt-5'>
          <div className="flex items-center gap-3">
            <svg width="45" height="43" viewBox="0 0 45 43" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="45" height="43" rx="5" fill="#A9DBFF" />
              <path d="M23 32C28.5228 32 33 27.5228 33 22C33 16.4771 28.5228 12 23 12C17.4771 12 13 16.4771 13 22C13 23.5997 13.3756 25.1116 14.0435 26.4525C14.2209 26.8088 14.28 27.2161 14.1771 27.6006L13.5815 29.8267C13.323 30.793 14.207 31.677 15.1733 31.4185L17.3994 30.8229C17.7839 30.72 18.1912 30.7791 18.5475 30.9565C19.8884 31.6244 21.4003 32 23 32Z" stroke="#1C274C" strokeWidth="1.5" />
              <path d="M23 25.3333C24.1046 25.3333 25 24.5871 25 23.6667C25 22.7462 24.1046 22 23 22C21.8954 22 21 21.2538 21 20.3333C21 19.4129 21.8954 18.6667 23 18.6667M23 25.3333C21.8954 25.3333 21 24.5871 21 23.6667M23 25.3333V26M23 18.6667V18M23 18.6667C24.1046 18.6667 25 19.4129 25 20.3333" stroke="#1C274C" strokeWidth="1.5" strokeLinecap="round" />
            </svg>
            <h2 className="font-bold text-lg">Beneficios en Nido de Agua</h2>
          </div>
          <p className="my-3">Elige un beneficio y aprovecha descuentos exclusivos en nuestro proyecto Nido de Agua. ¡Haz crecer tu inversión!</p>
          <div className=' w-[100%] mt-3'>
            {benefits.map((benefit, index) => (
              <BenefitItem
                key={index}
                benefit={benefit}
                handleDiscount={handleDiscount}
              />
            ))}
          </div>
        </div>
      </div>

    </div>
  )
}
