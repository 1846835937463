import jwt_decode from "jwt-decode";
import { sendSimulationScenaries } from "../../services/SimulatorService";
import { getNameEmail } from "../../helpers/functions";

export const specialPercentageFormat = (number: number | undefined, decimals = 2) => {
  if (!number) return 0
  return parseFloat((number * 100).toFixed(decimals))
}

export const formatNumberWithCommas = (number: number | undefined, decimals = 1) => {
  if (number === undefined) return "0";
  if (Number.isInteger(number)) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return number.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const avgArray = (numbers: number[] | undefined, decimals = 2) => {
  if (!numbers || numbers.length === 0) return 0;
  const sum = numbers.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
  return ((sum / numbers.length) * 100).toFixed(decimals);
}

export const options = {
  plugins: {
    datalabels: {
      display: false,
      color: function (context: any) {
        if (context.dataset.label === 'Valorización') {
          return '#00A576';
        } else {
          return '#5F4ACA';
        }
      },
      labels: {
        title: {
          font: {
            weight: 'bold' as const,
            size: 13
          }
        },
      },
      align: function (context: any) {
        if (context.dataset.label === 'Valorización') {
          return 'top' as const;
        } else {
          // Conditional to set label position in first two bars TODO: Improve this conditional for cases when valorization is 0
          if (context.dataIndex < 2) {
            return 'top' as const;
          } else {
            return 'right' as const;
          }
        }
      },
      anchor: function (context: any) {
        if (context.dataset.label === 'Valorización') {
          return 'end' as const;
        } else {
          // Conditional to set label position in first two bars TODO: Improve this conditional for cases when valorization is 0
          if (context.dataIndex < 2) {
            return 'end' as const;
          } else {
            return 'center' as const;
          }
        }
      },
      offset: function (context: any) {
        if (context.dataset.label === 'Valorización') {
          return -4;
        } else {
          // Conditional to set label position in first two bars TODO: Improve this conditional for cases when valorization is 0
          if (context.dataIndex < 2) {
            return -4;
          } else {
            return 8;
          }
        }
      },
      formatter: function (value: any, context: any) {
        if (value === 0) return '';
        return value + '%';
      }
    },

    legend: {
      display: false,
      labels: {
        usePointStyle: true,
        pointStyle: 'circle'
      }
    },
    title: {
      display: false,
      text: '',
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: function (context: any) {
          let label = context.dataset.label || '';

          if (label) {
            label += ': ';
          }
          if (context.raw !== null) {
            label += context.raw + '%';
          }
          return label;
        }
      }
    }
  },
  aspectRatio: 1.5,
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index' as const
  },
  scales: {
    x: {
      stacked: true,
      position: 'top' as const,
      grid: {
        display: false,
        drawTicks: false,
        tickWidth: 2,
        tickLength: 2
      },
      ticks: {
        position: 'top',
        padding: 20,
      },
      border: {
        display: false
      }
    },
    y: {
      display: false,
      stacked: true,
      grid: {
        display: false,
        drawTicks: false
      },
      ticks: {
        padding: 10,
      }
    }
  },
  onClick: (event : any, elements  : any) => {
    if (elements.length > 0) {
      const chart = elements[0].element;
      const index = elements[0].index;
      chart.tooltip.setActiveElements(
        [{ datasetIndex: 0, index }],
        { x: event.x, y: event.y }
      );
      chart.tooltip.update();
      chart.draw();
    }
  },
};

export const INSTALLMENTSOPTIONS = [
  {
    value: '0',
    label: 'Pago único'
  },
  {
    value: '3',
    label: '3 meses'
  },
  {
    value: '6',
    label: '6 meses'
  },
  {
    value: '9',
    label: '9 meses'
  },
  {
    value: '12',
    label: '12 meses'
  }
]
export const SCENARIES = [
  {
    value: 'exploratorio',
    label: 'Exploratorio'
  },
  {
    value: 'aventurero',
    label: 'Aventurero'
  },
  {
    value: 'heroe',
    label: 'Héroe'
  }
]

export function checkSessionStorageToken(): boolean {
  const token = localStorage.getItem("token");

  if (token !== null) {

    const decoded: any = jwt_decode(token);
    const dateNow = new Date();

    if (decoded.exp * 1000 < dateNow.getTime()) {
      localStorage.removeItem('token')
      return false;
    }

  } else {

    return false;

  }

  return token !== null;

}

export const isAuthenticatedSimulator = async (simulationData: {
  project: string;
  investmentValue: string;
  installmentsNumber: string;
  name: string;
  phone: string;
  leadOrigin: string;
  unitValue: number;
}) => {
  const url = new URL(window.location.href);

  if (!checkSessionStorageToken()) {
    const urlToRedirect = `/register?redirect_to=${url}?simulationdata=${JSON.stringify(simulationData)}`;
    window.location.href = urlToRedirect;
  } else {
    await sendSimulationScenaries({
      "to": getNameEmail().email,
      "userName": getNameEmail().name,
    })
    return true
  }
  return false
}