import React from 'react'
import img1 from "../../assets/img/blog/card-1.png";
import { motion } from "framer-motion";
import useTitle, { useDescription, useKeywords } from '../../hooks/useSEO';

// import { Tab, TabList } from 'react-tabs';
// import { scrollToSection } from '../../helpers/functions';

function ArticleHospitality() {

  useTitle("Inversiones colaborativas en hospitality una oportunidad para las nuevas generaciones | LOKL");
  useDescription("Las inversiones colaborativas en hospitality permiten a los inversionistas participar en proyectos de propiedad raíz relacionados con el sector de la hospitalidad, como hoteles, restaurantes, etc. Estas inversiones se llevan a cabo a través de plataformas o empresas especializadas en este tipo de proyectos.");
  useKeywords("inversiones, colaborativas, hospitality, oportunidad, nuevas generaciones, propiedad raíz, hoteles, restaurantes, proyectos, plataformas, empresas, especializadas, beneficios, riesgos, apreciación, valor, liquidez, pandemia, mercado inmobiliario, oportunidades, inversionistas, diversificar, cartera, acceso, costosos, ingresos, estable, impacto, positivo, mundo, desventajas, ventajas, comparativos, modelos, inversión, compra, acciones, fondos, mutuos, proyectos, largo plazo, demanda, áreas turísticas, oportunidades, proyectos, hospitalidad, LOKL");
  // const optionsList = [
  //   "Dejanos tus comentario",
  //   "Comentarios",
  // ];

  // const [tabOptions /*, setTabOptions*/] = useState(optionsList);
  // const [activeTabIndex /*, setActiveTabIndex */] = useState(0);

  return (
    <section id='article' className='pt-32 bg-[#F9F9F9]'>
      
      <article className="bg-note-img bg-cover bg-center h-[432px] w-full flex items-center justify-center">

        <div className="wrapper-lokl text-white ">

          <div className="w-[90%] md:w-1/2 ml-[5%] md:ml-0">

            <h1 className="font-lato font-extrabold text-[36px] mb-3">
              Inversiones colaborativas en hospitality una oportunidad para las nuevas generaciones.
            </h1>
          
          </div>

        </div>

      </article>

      <section  className="wrapper-lokl space-y-10 py-16">

        {/* <div className="w-full flex justify-end">

          <div className="flex space-x-2 mr-3">

            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>
            <div className="">
              <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8.54843 0.945475C8.84676 0.0220335 10.1532 0.0220325 10.4516 0.945474L11.991 5.71067C12.1244 6.12351 12.5088 6.40325 12.9426 6.40325H17.9365C18.9039 6.40325 19.3076 7.63995 18.5265 8.21066L14.4765 11.1701C14.128 11.4247 13.9822 11.8742 14.1149 12.2849L15.6596 17.0661C15.9575 17.9884 14.9006 18.7528 14.118 18.181L10.09 15.2376C9.73857 14.9808 9.26143 14.9808 8.91001 15.2376L4.882 18.181C4.09941 18.7528 3.04247 17.9885 3.34044 17.0661L4.88507 12.2849C5.01776 11.8742 4.87197 11.4247 4.52349 11.1701L0.473474 8.21066C-0.307563 7.63995 0.0961287 6.40325 1.06346 6.40325H6.05739C6.49124 6.40325 6.87559 6.12351 7.00897 5.71067L8.54843 0.945475Z" fill="#FFB800"/>
              </svg>
            </div>

          </div>

          <div className="underline cursor-pointer" onClick={() => scrollToSection('comments', 150)}>35 Comentarios</div>

        </div> */}

        <div className="w-full flex justify-center md:justify-between items-end">

          <div className="w-[90%] md:w-[67%] mr-0 md:mr-10">

            <div className="blog-title-md mb-4">
              Las nuevas generaciones
            </div>

            <p className="text-base font-roboto text-justify">
              Tienen un enfoque diferente en cuanto a su relación con el dinero. Muchos jóvenes buscan opciones de inversión que les permitan obtener ganancias, pero también que tengan un impacto positivo en el mundo. Una de las opciones que se están volviendo cada vez más populares son las <a className='hover:text-[#17008A] font-bold' href="/nido">inversiones colaborativas en el sector hospitality</a>. En este post, hablaremos sobre cómo funcionan estas inversiones, las compararemos con otros modelos de inversión y por qué es importante invertir ahora en propiedad raíz.
            </p>

          </div>

        </div>

        <div className="w-[90%] ml-[5%] md:ml-0 md:w-3/4 flex justify-center md:justify-between items-start">

          {/* <img className='' width={525} height={357} src={image} alt="casa-lokl" /> */}

          <div className="flex flex-col justify-center">

            <h2 className='blog-title-md mb-4'>Inversiones colaborativas en hospitality: ¿cómo funcionan?</h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto text-justify">

                Las inversiones colaborativas en hospitality permiten a los inversionistas participar en proyectos de propiedad raíz relacionados con el sector de la hospitalidad, como hoteles, restaurantes, etc. Estas inversiones se llevan a cabo a través de plataformas o empresas especializadas en este tipo de proyectos. <br /> <br />
                Los inversionistas pueden participar en un proyecto con una inversión relativamente pequeña, y compartirán los beneficios y riesgos del proyecto con otros inversionistas. Los proyectos suelen ser de largo plazo y los inversionistas pueden obtener ganancias a través de los ingresos generados por el proyecto, así como de la apreciación del valor de la propiedad.
                Algunas de las plataformas o empresas que ofrecen este tipo de inversión son Housers, Brickstarter o Rentalis. <a className='hover:text-[#17008A] font-bold' href="/#projects">Invierte en Nido de Agua</a>

              </p>

            </div>

          </div>

        </div>

        <div className="w-[90%] ml-[5%] md:ml-0 md:w-3/4 flex flex-col justify-center md:justify-between items-start">

          <div className="w-full flex flex-col justify-center">

            <h2 className='blog-title-md mb-4'>Comparativos con otros modelos de inversión</h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto text-justify">
                Comparado con otros modelos de inversión, las inversiones colaborativas en hospitality tienen varias ventajas. Por ejemplo, permiten a los inversionistas diversificar su cartera y tener acceso a proyectos que de otra manera podrían ser demasiado costosos. Además, al ser proyectos de largo plazo, pueden ofrecer una fuente estable de ingresos. <br /><br />
                Sin embargo, también existen algunas desventajas que deben tenerse en cuenta. Por ejemplo, las inversiones en proyectos de propiedad raíz suelen tener un mayor riesgo que otros tipos de inversión, como la compra de acciones o la inversión en fondos mutuos. Además, la liquidez puede ser un problema, ya que los inversionistas pueden tener dificultades para vender su participación en un proyecto antes de su finalización.
              </p>

            </div>

          </div>

          <div className="w-full flex flex-col justify-start mt-4">

            <h2 className='blog-title-md mb-4'>¿Por qué es importante invertir ahora en propiedad raíz?</h2>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto text-justify">
                A pesar de los riesgos, invertir en propiedad raíz sigue siendo una opción atractiva para muchas personas, especialmente en la actualidad. La pandemia ha tenido un impacto significativo en el mercado inmobiliario, lo que ha creado oportunidades para los inversionistas. En muchas ciudades, los precios de las propiedades han bajado, lo que significa que los inversionistas pueden comprar propiedades por menos dinero. Además, la demanda de propiedades en áreas turísticas sigue siendo alta, lo que significa que hay oportunidades para proyectos de propiedad raíz relacionados con la hospitalidad. <b>Conoce más sobre nuestros <a className='hover:text-[#17008A]' href="/#projects">proyectos LOKL</a></b>
              </p>

            </div>

          </div>

        </div>

        <div className="w-[90%] ml-[5%] md:ml-0 md:w-3/4 flex flex-col justify-center md:justify-between items-start">

          <div className="flex flex-col justify-center space-y-4">

            <h3 className='blog-title-md'>Conclusión</h3>

            <div className="flex flex-col justify-between">

              <p className="text-base font-roboto text-justify">
                Las inversiones colaborativas en hospitality pueden ser una excelente opción para las nuevas generaciones que buscan opciones de inversión que les permitan obtener ganancias y tener un impacto positivo en el mundo. Si bien estas inversiones tienen algunas desventajas, también ofrecen varias ventajas.
              </p>

            </div>

            <div className="">
              Empieza a invertir en el futuro que quieres vivir. <br /> <a className='hover:text-[#17008A]' href="/#projects"><b>Invierte con LOKL</b></a>.
            </div>

            <div className="">
              Escrito por Veronica Porras.
            </div>

            <hr className="border-t border-gray-400"/>

            <div className="text-xl">
              Recuerda <a className='hover:text-[#17008A]' href="/register"><b>registrarte</b></a> para recibir información de primera mano, sobre nuestros proyectos.
            </div>

          </div>

        </div>

        {/* <div id='comments'>

          <TabList className="flex space-x-6 font-bold text-lg list-none mb-8">

            {
              tabOptions.map((tab, key) => 
                <Tab key={key} className={ `min-w-[100px] cursor-pointer outline-none text-center font-roboto ${ key === activeTabIndex ? ' text-black ' : 'text-gray-400' }` }>
                  {tab}
                  { key === activeTabIndex && <div className='w-full h-1 bg-black mt-2'></div> }
                </Tab>
              )
            }

          </TabList>

          <div className="bg-white w-2/3 p-12 flex flex-col items-center rounded-xl">

            <div className="flex flex-col w-full">

              <label htmlFor="commentInput">¿Tienes preguntas?</label>
              <textarea className='outline-none' name="" id="commentInput" cols={30} rows={10}></textarea>

            </div>

            <button className="btn-primary">
              Comentar
            </button>

          </div>

        </div> */}


        <div className="w-full inline-flex overflow-x-auto space-x-4 mb-5">

        <motion.div 
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
            className="flex items-center flex-shrink-0 justify-between w-[700px] p-12 text-white bg-[#0A180E] rounded-xl"
          >
            
            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">INVERSIÓN</div>

              <div className='font-lato font-extrabold text-3xl'>¿Dónde invertir en <br /> Colombia en el 2023?</div>

              <p className='text-sm w-[350px]'>
                Muchos inversionistas buscan oportunidades para hacer crecer su dinero. Con los mercados financieros en constante evolución, es esencial investigar y considerar cuidadosamente las opciones de inversión disponibles.
              </p>

              <div className="bg-[#7271FF] text-black hover:bg-[#7271FF]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
                onClick={() => {
                  window.location.href = "/blog/donde-invertir-colombia-2023"
                }}
              >
                Ver nota
              </div>

            </div>

            <img src={img1} alt="banner-card-lokl" /> 

          </motion.div>

          <motion.div 
            className="flex flex-col items-start flex-shrink-0 justify-between p-12 text-white bg-[#0A180E] rounded-xl"
            initial={{ scale: 0 }}
            animate={{ scale: 1 }}
            transition={{ delay: 1 }}
          >
            
            <div className="flex flex-col items-start space-y-7 mr-5">

              <div className="text-white/50">EDUCACIÓN</div>

              <div className='font-lato font-extrabold text-2xl'>
                5 secretos para usar el <br /> dinero adecuadamente, <br /> planifica e invierte a largo <br /> plazo.
              </div>

            </div>

            <div 
              className="bg-[#FF7171] text-[#010067] hover:bg-[#FF7171]/70 px-16 rounded-full py-2.5 font-medium text-lg text-center cursor-pointer duration-300 ease-in-out disabled:opacity-50 disabled:cursor-not-allowed focus:border-violet-600 no-underline"
              onClick={() => {
                window.location.href = "/blog/planificar-presupuesto-largo-plazo"
              }}
            >
              Ver nota
            </div>

          </motion.div>

        </div>

      </section>

    </section>
  )
}

export default ArticleHospitality;