import { GoogleMapsReviewModel } from '../interfaces/GoogleMapsReviewModel';
import StarRating from './StarRating';


const getColorFromIndex = (index: number): string => {
    const colors = ['#FFB6C1', '#FFD700', '#87CEFA', '#FF69B4', '#98FB98'];
    return colors[index % colors.length];
};

export const GoogleMapsReview = ({ review, index }: { review: GoogleMapsReviewModel, index: number }) => {
    const authorInitial = review.author_name[0]?.toUpperCase();
    const color = getColorFromIndex(index);

    return (
        <div className='flex flex-row  w-full md:w-[500px]'>
            <div className='w-[20%] md:w-[147px]  ml-10 md:ml-0 flex flex-col items-center'>
                <div
                    className="w-full aspect-square md:w-16 md:h-16 rounded-lg flex items-center justify-center text-white font-bold text-4xl"
                    style={{ backgroundColor: color }}
                >
                    {authorInitial}
                </div>
                <div className='flex flex-col md:hidden mt-2'>
                    <StarRating rating={review.rating} />
                    <p className='text-[#888] text-sm secondaryFont'>
                        {review.relative_time_description}
                    </p>
                </div>
            </div>
            <div className='w-[75%] md:w-[300px] ml-10 md:ml-0 flex flex-col justify-between'>
                <div className='flex flex-row items-center justify-between'>
                    <h3 className='flex justify-between' style={{ margin: '0', fontSize: '1.2rem', color: '#333' }}>
                        <a
                            href={review.author_url}
                            target="_blank"
                            rel="noopener noreferrer"
                            className='no-underline text-[#221feb] primaryFont font-semibold'
                        >
                            {review.author_name} 
                        </a>
                    </h3>
                    <div className='hidden md:block '>
                        <StarRating rating={review.rating} />
                    </div>
                </div>
                <p className="secondaryFont mt-2 text-gray-700 max-h-[150px] max-w-[200px] md:max-w-full overflow-hidden text-ellipsis md:text-lg text-base line-clamp-8">
                    {review.text}
                </p>
                <a
                    className="text-blue-600 hover:text-blue-800 secondaryFont"
                    href={review.author_url}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    Ver review completa
                </a>
                <p className='hidden md:block secondaryFont' style={{ margin: '8px 0', fontSize: '0.9rem', color: '#888' }}>
                    {review.relative_time_description} - Rating: {review.rating}/5
                </p>
            </div>
        </div>
    );
};
