import React, { createRef, useState } from 'react';
import GalleryPropertyPage from '../components/GalleryPropertyPage';
import PopupDisclaimerRetorno from '../../../components/PopupDisclaimerRetorno';
import iconInfo from "../../../assets/img/infocircle.svg";
import { isAuthenticated, numberWithCommas, scrollToSection } from '../../../helpers/functions';
import { usePageVisits } from '../../../hooks/usePageVisits';
import calculator from "../../../assets/img/calculator.png";
import { PopupSecondaryMarket } from '../components';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { ProjectStatusCard } from '../../NewHome/components';
import star from '../../../assets/img/nido/star.svg';
import { CapitalizeFirstLetter, CapitalizeWords } from '../../../utils/otherFormat';

interface TopSectionPropertyPageProps {
  projectId: string;
  images: Array<{ url: string; type: string; format: string }>;
  unitValuePhases: Array<{ startDate: string; unitValue: number ; endDate: string }>;
  name: string;
  neighborhood: string;
  municipality: string;
  minRent: number;
  maxRent: number;
  investors: number;
  unitPrice: number;
  description: string;
  phase: string;
  minAmountInvestment: number
  squareMeters: number
  roomCount: number
  value: number
  secondaryMarketEnabled: boolean
  availableSpots: number
  totalSpots: number;
  totalInvestment: number;
  endDate: string;
  maxInvestmentQuota: number
}

export function TopSectionPropertyPage({
  projectId,
  images,
  description,
  name,
  phase,
  neighborhood,
  municipality,
  investors,
  maxRent,
  minRent,
  unitPrice,
  minAmountInvestment,
  squareMeters,
  roomCount,
  value,
  secondaryMarketEnabled,
  availableSpots,
  totalSpots,
  totalInvestment,
  endDate,
  unitValuePhases = [],
  maxInvestmentQuota
}: TopSectionPropertyPageProps) {

  const goToSimulator = () => {
    const viewportWidth = window.innerWidth;
    if (viewportWidth <= 768) scrollToSection('mobileVerticalSimulator', 160);
    else scrollToSection('desktopVerticalSimulator', 160);
  }
 
  const [popoverShowRetorno, setPopoverShowRetorno] = useState(false);
  const [popoverShowSecondaryMarket, setPopoverShowSecondaryMarket] = useState(false);
  const [activeTab, setActiveTab] = useState(0);

  const btnTriggerRefRetorno = createRef<HTMLDivElement>();
  const btnTriggerRefSecondaryMarket = createRef<HTMLDivElement>();

  const { visits } = usePageVisits()

  const goToBuy = () => {
    isAuthenticated(true, undefined, undefined, projectId);
  }
  
  const handleTabSelect = (tabIndex: number) => {
    setActiveTab(tabIndex);
  };

  

  const formattedDate = format(new Date(endDate), "d 'de' MMMM 'de' yyyy", { locale: es });

  return (

    <section className="container w-full flex flex-col items-start space-y-7">
      <div className="secondaryFont z-20 relative">
        <ProjectStatusCard phase={phase} />
      </div>
      <div className="relative flex flex-wrap flex-col sm:flex-row sm:items-center sm:justify-between gap-4 w-[59%]">
        <div>
          <h2 className="font-black primaryFont text-3xl flex">
            {CapitalizeWords(name)}
          </h2>
          <div className="w-full flex items-center secondaryFont font-medium gap-2">
            <p className='flex whitespace-nowrap'>
              <span className="font-extrabold">{neighborhood},&nbsp;</span> {municipality} 
            </p>
            <div className="flex items-center gap-2">
              <img src={star} alt="Star" />
              <p>5.0</p>
            </div>
          </div>
        </div>

        <div className="hidden md:flex items-center gap-6">
          <div className="text-center font-noto">
            <div className="relative">
              <p className="md:text-xl text-sm font-extrabold">
                {`${minRent * 100} - ${maxRent * 100}%`} <span className="text-sm">E.A</span>
              </p>
              <span className="text-gray-500 text-sm">Retorno Estimado</span>
              <div
                className="absolute top-[-5px] right-[-15px]"
                ref={btnTriggerRefRetorno}
                onClick={() => setPopoverShowRetorno(!popoverShowRetorno)}
              >
                <img
                  width={13}
                  height={13}
                  src={iconInfo}
                  alt=""
                  className="cursor-pointer"
                />
              </div>
            </div>
            <PopupDisclaimerRetorno
              triggerRef={btnTriggerRefRetorno}
              isVisible={popoverShowRetorno}
            />
          </div>

          <div className="text-center font-noto">
            <span className="block text-sm md:text-lg font-bold">$ {numberWithCommas(unitPrice)}</span>
            <span className="text-gray-500 text-sm">Valor $ por Unit</span>
          </div>

          <div className="text-center font-noto">
            <span className="block text-sm md:text-lg font-bold">{investors}</span>
            <span className="text-gray-500 text-sm">Inversionistas</span>
          </div>
        </div>
      </div>
      <div className="mx-auto grid grid-cols-1 md:grid-cols-5 gap-6">
        <div className="md:col-span-3 w-full">


          <GalleryPropertyPage
            images={images}
            squareMeters={squareMeters}
            roomCount={roomCount}
            value={value}
            phase={phase}
            name = {name}
          />

          <div className="flex w-full md:hidden mt-4">
            <div className="w-2 h-auto bg-[#DADADA]"></div>
            <div className="w-full flex justify-around bg-[#9393930D] py-2">
              <div className="text-center font-noto">
                <div className="font-black text-xl">{`${minRent * 100} - ${maxRent * 100}%`}</div>
                <div className="text-sm">Retorno Estimado</div>
              </div>
              <div className="text-center font-noto">
                <div className="font-black text-xl">$ {numberWithCommas(unitPrice)}</div>
                <div className="text-sm">Valor $ por Unit</div>
              </div>
              <div className="text-center font-noto">
                <div className="font-black text-xl">{investors}</div>
                <div className="text-sm">Inversionistas</div>
              </div>
            </div>
          </div>
        </div>

        {/* Right */}
        <Tabs className="md:col-span-2  px-6 space-y-3 last:mb-0" onSelect={handleTabSelect}>
          <h1 className="primaryFont text-2xl font-bold mb-4">¿Qué es {CapitalizeFirstLetter(name)}?</h1>
          <p className="secondaryFont text-gray-600 mb-6">{description}</p>

          <div className="text-lg font-bold mb-2 primaryFont">¿A partir de qué monto puedo invertir?</div>

          <div className="flex justify-between relative">
            <div className="">
              <div className="font-bold text-4xl numberFont">
                ${numberWithCommas(minAmountInvestment)} <span className="text-lg secondaryFont"> {maxInvestmentQuota > 1 ? 'Mensuales' : ''}</span>
              </div>
              <div className="text-[#5452F6] font-light underline secondaryFont">
                Válido hasta el {formattedDate}
              </div>
            </div>

            <div className="flex flex-col items-center cursor-pointer relative" onClick={goToSimulator}>
              <div className="w-16 h-16 flex justify-center items-center bg-green-lokl rounded-full">
                <img width={32} height={32} src={calculator} alt="btn-simulador-LOKL" />
              </div>
            </div>
          </div>

          {secondaryMarketEnabled && (
            <div className="mt-4 py-2 px-3 bg-[#DDE4FF]  w-[100%] md:w-fit text-left rounded-lg relative">
              <p className="primaryFont text-sm font-semibold text-gray-800">
                ¡Mercado secundario disponible!
              </p>
              <div
                className="absolute top-[-5px] right-[-15px] cursor-pointer"
                ref={btnTriggerRefSecondaryMarket}
                onClick={() => setPopoverShowSecondaryMarket(!popoverShowSecondaryMarket)}
              >
                <img width={13} height={13} src={iconInfo} alt="Información" />
              </div>
              <PopupSecondaryMarket
                triggerRef={btnTriggerRefSecondaryMarket}
                isVisible={popoverShowSecondaryMarket}
              />
            </div>
          )}

          {!secondaryMarketEnabled && <TabList className="flex space-x-3 mt-6">
            <Tab>
              <button
                className={`${activeTab === 0 ? 'bg-[#DDE4FF]' : 'bg-[#F0F0F0]'} text-[#363636]  font-semibold text-sm rounded px-3 py-2 primaryFont`}
              >
                Cupo de inversión
              </button>
            </Tab>
            <Tab>
              <button
                className={`${activeTab === 1 ? 'bg-[#DDE4FF]' : 'bg-[#F0F0F0]'} text-[#363636]  font-semibold text-sm rounded px-3 py-2 primaryFont`}
              >
                Etapas
              </button>
            </Tab>
          </TabList>}

          <div className="mt-5">
            <TabPanel>
              <div className="flex flex-col items-center gap-2">
                <div className="w-full flex flex-row items-center justify-between secondaryFont">
                  {/* <h3 className="flex-1 whitespace-nowrap">Total invertido</h3>
                  <div className="flex-1 text-center">
                    <span className="inline-block w-full border-t border-dotted border-gray-400"></span>
                  </div> */}
                  <div className="font-extrabold numberFont text-xl">
                    $ {numberWithCommas(totalInvestment)} COP
                  </div>
                </div>

                <div className='flex flex-row w-full h-auto'>
                  <div className="mr-5 flex justify-center">
                    <div className='w-full flex items-end'>
                      <h1 className='font-bold font-lato text-7xl'>{availableSpots}</h1>
                      <h2 className='font-bold font-lato text-3xl -translate-y-1'>/</h2>
                      <h2 className='font-bold font-lato text-[#A4A4A4] text-3xl -translate-y-1'>{totalSpots}</h2>
                    </div>
                  </div>
                  <div className='w-full mt-2 items-center'>
                    <h1 className='primaryFont text-1xl font-extrabold'>Cupos disponibles</h1>
                    <p className='secondaryFont text-sm font-medium '>antes de que suba el precio por unit. No te quedes por fuera!</p>
                  </div>
                </div>
              </div>
            </TabPanel>
            <TabPanel className={`${activeTab === 1 && 'flex space-x-4'}`}>
            {unitValuePhases.map((phase, index) => {
              
              const startDateString =  (phase.startDate);
              const startDate = new Date(startDateString);
              const monthNumber = startDate.getUTCMonth() + 1;
              const monthNames = [
                "Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", 
                "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"
              ];
              
              const formattedMonth = monthNames[monthNumber - 1];
              const finalMonth = formattedMonth.charAt(0).toUpperCase() + formattedMonth.slice(1);
              const endDateString = (phase.endDate); 
              const endDate = new Date(endDateString);

                
                const formattedEndDate = endDate.toISOString().split('T')[0];


              

              return (
                <div key={index} className="bg-[#F6F6F6] py-3 px-4">
                  <div className="secondaryFont text-sm font-extrabold">
                    {(finalMonth)}
                  </div>
                  <div className="secondaryFont text-sm">
                    ${numberWithCommas(phase.unitValue)} x Unit
                  </div>
                  <div className="text-[#5452F6] text-xs">
                    {`Hasta el ${formattedEndDate}`}
                  </div>
                </div>
              );
            })}
          </TabPanel>








          </div>

          <button id="btnGoToCheckOutHeader" onClick={goToBuy} className="primaryFont mt-5 btn-black content-center justify-center">
            Quiero ser socio
          </button>

          <div className="flex gap-2 items-center">
            <div>
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M2.72842 12.7463C2.02015 11.8262 1.66602 11.3661 1.66602 9.99992C1.66602 8.63375 2.02015 8.17369 2.72842 7.25353C4.14265 5.41622 6.51444 3.33325 9.99935 3.33325C13.4843 3.33325 15.856 5.41622 17.2703 7.25353C17.9785 8.17369 18.3327 8.63375 18.3327 9.99992C18.3327 11.3661 17.9785 11.8262 17.2703 12.7463C15.856 14.5836 13.4843 16.6666 9.99935 16.6666C6.51444 16.6666 4.14265 14.5836 2.72842 12.7463Z"
                  stroke="#5452F6"
                  strokeWidth="1.5"
                />
                <path
                  d="M12.5 10C12.5 11.3807 11.3807 12.5 10 12.5C8.61925 12.5 7.5 11.3807 7.5 10C7.5 8.61925 8.61925 7.5 10 7.5C11.3807 7.5 12.5 8.61925 12.5 10Z"
                  stroke="#5452F6"
                  strokeWidth="1.5"
                />
              </svg>
            </div>
            <p className="text-[#3533FF] primaryFont font-medium text-sm md:text-lg">
              {visits} personas viendo este proyecto
            </p>
          </div>
        </Tabs>


      </div>
    </section>



  );
}
