import { useEffect, useState } from "react"
import { VerticalLeadCard, VerticalSimulatorCard, VerticalSimulationDashboard, SimulatorLoading, MessageSent } from "."
import { SimulationResponseAPIInterface, SimulationResponseInterface } from "./simulationInterface"
import { useAuth } from "../../hooks/useAuth"
import { createSimulation, sendSimulationScenaries } from "../../services/SimulatorService"
import { AxiosResponse } from "axios"
import { getNameEmail, unitPrice } from "../../helpers/functions"

export const VerticalSimulator = () => {
    const { isAuth } = useAuth()
    const [simulatorLoading, setSimulatorLoading] = useState(true)
    const [simulationData, setSimulationData] = useState({
        project: '',
        investmentValue: '',
        installmentsNumber: '',
        name: '',
        phone: '',
        email: '',
        leadOrigin: '',
        unitValue: unitPrice(),
        minimumUnitsPurchase: 100,
        installmentOptions: []
    })
    const [simulationResponse, setSimulationResponse] = useState<SimulationResponseInterface>()
    const [flag, setFlag] = useState(false)
    const [messageSent, setMessageSent] = useState(false)

    useEffect(() => {
        if (!flag) {
        let urlParams = new URLSearchParams(window.location.search);
        let hashParams = new URLSearchParams(window.location.hash.split('?')[1])
        let simulationParam = urlParams.get('simulationdata') || hashParams.get('simulationdata')

            if (simulationParam) {
                const parsedSimulationData = JSON.parse(simulationParam) as {
                    project: string;
                    investmentValue: string;
                    installmentsNumber: string;
                    name: string;
                    phone: string;
                    email: string;
                    leadOrigin: string;
                    unitValue: number;
                    minimumUnitsPurchase: number,
                    installmentOptions: any
                };
                setSimulationData(parsedSimulationData)
                setSimulatorLoading(false)
                createSimulation({
                    "investmentValue": parseFloat(parsedSimulationData.investmentValue),
                    "installmentsNumber": parseFloat(parsedSimulationData.installmentsNumber),
                    "projectId": parsedSimulationData.project
                }).then((response: AxiosResponse<SimulationResponseAPIInterface>) => {
                    setSimulationResponse(response.data.data)
                })
                sendSimulationScenaries({
                    "to": getNameEmail().email,
                    "userName": getNameEmail().name,
                }).then(() => {
                    
                    urlParams.delete('simulationdata');
                    hashParams.delete('simulationdata');
                    
                    let url = new URL(window.location.href);
                    url.search = urlParams.toString();
                    url.hash = `#${hashParams.toString()}`; 

                    window.history.replaceState({}, '', url.toString());
                    setMessageSent(true);
                })
            }
            setFlag(true)
        }
    }, [flag])

    return (
        <>
            {
                (simulationData.project === '' || simulationData.installmentsNumber === '')
                    ?
                    <VerticalSimulatorCard simulationData={simulationData} setSimulationData={setSimulationData} setSimulationResponse={setSimulationResponse} />
                    :
                    (!isAuth && (simulationData.name === '' || simulationData.phone === '' || simulationData.leadOrigin === ''))
                        ?
                        <VerticalLeadCard
                            simulationData={simulationData}
                            setSimulationData={setSimulationData}
                            setSimulationResponse={setSimulationResponse}
                        />
                        :
                        (simulatorLoading)
                            ?
                            <SimulatorLoading setSimulatorLoading={setSimulatorLoading} />
                            :
                            <VerticalSimulationDashboard
                                simulationData={simulationData}
                                setMessageSent={setMessageSent}
                                setSimulationData={setSimulationData}
                                setSimulationResponse={setSimulationResponse}
                                simulationResponse={simulationResponse}
                            />
            }
            <MessageSent isOpen={messageSent} setIsOpen={setMessageSent} />
        </>
    )
}
