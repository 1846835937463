export const FormSubmissionStatus = ({ isSubmitting, resetForm }: { isSubmitting: boolean, resetForm: () => void }) => {
  return (
    <div className="flex flex-col items-center justify-center p-6  ">
      {isSubmitting ? (
        <>
          <div className="w-12 h-12 border-4 border-blue-500 border-dashed rounded-full animate-spin"></div>
          <p className="mt-4 text-blue-500 text-lg font-semibold">Enviando información...</p>
        </>
      ) : (
        <>
          <h2 className="text-[#67E0AF] text-xl font-semibold">Datos enviados con éxito</h2>
          <p className="text-gray-600 mt-2">Gracias por inscribirte, nos pondremos en contacto pronto.</p>
          <button
            onClick={resetForm}
            className="mt-4 px-6 py-3 rounded-lg bg-[#4F4CF1] text-white font-semibold hover:bg-blue-700 transition"
          >
            Inscribirse otra vez
          </button>
        </>
      )}
    </div>
  );
};