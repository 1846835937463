import { useCallback, useEffect, useState } from 'react';


const getLastDayOfMonth = (): number => {
  const now = new Date();
  const lastDay = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59);
  return lastDay.getTime();
};

export const useCountdown = () => {
  const [targetDate, setTargetDate] = useState(() => getLastDayOfMonth());
  const [countdown, setCountdown] = useState({
    days: '00',
    hours: '00',
    minutes: '00',
    seconds: '00',
  });

  const setCountdownInterval = useCallback(() => {
    const currentDate = new Date().getTime();
    const timeLeft = targetDate - currentDate;

    if (timeLeft <= 0) {
      setTargetDate(getLastDayOfMonth()); 
      return;
    }

    const days = Math.floor(timeLeft / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeLeft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeLeft % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeLeft % (1000 * 60)) / 1000);

    setCountdown({
      days: formatCountdownValue(days),
      hours: formatCountdownValue(hours),
      minutes: formatCountdownValue(minutes),
      seconds: formatCountdownValue(seconds),
    });
  }, [targetDate]);

  useEffect(() => {
    const countdownInterval = setInterval(() => setCountdownInterval(), 1000);
    return () => clearInterval(countdownInterval);
  }, [setCountdownInterval]);

  const formatCountdownValue = (value: number) => {
    return value.toString().padStart(2, '0');
  };

  return {
    countdown,
  };
};
