import React, { useState, useRef, useEffect, useContext } from "react";
import { ProjectsFormData } from "./ProjectsFormData";
import { InvestorFormHeader } from "../sections";
import {
  QuestionComponent,
  FormSubmissionStatus,
  SkeletonForm,
} from "../Components";
import { ProjectCardDataInterface } from "../Interfaces/FormInterfaces";
import { getProjectCards } from "../../../services/projectService";
import { useParams } from "react-router-dom";
import { sendLead } from "../../../services/leadService";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import {
  createContactInFormQuiiven,
  generateRegisterInCRM,
} from "../../../services/authService";
import toast from "react-hot-toast";
import { CapitalizeFirstLetter } from "../../../utils/otherFormat";
import { UTMContext } from "../../../contexts";

export const ProjectForm = () => {
  const [formValues, setFormValues] = useState<{ [key: string]: any }>({
    phone: { phone: "", countryCode: "" },
  });
  const [dataProjectsCards, setDataProjectsCards] =
    useState<ProjectCardDataInterface | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState<{ [key: string]: boolean }>({});
  const [currentPage, setCurrentPage] = useState(0);
  const questionsPerPage = 7;
  const firstQuestionRef = useRef<HTMLDivElement>(null);
  const { projectCode } = useParams();
  const [isSubmitted, setIsSubmitted] = useState(false);
  const context = useContext(UTMContext);
  const { UTM } = context;

  useEffect(() => {
    const fetchProjectData = async () => {
      try {
        setIsLoading(true);

        const resp = await getProjectCards();

        if (!resp || !resp.success || !resp.data) {
          setDataProjectsCards(null);
          return;
        }

        let selectedProject = resp.data.find(
          (p: ProjectCardDataInterface) =>
            projectCode &&
            p.projectCode.trim().toLowerCase() ===
              projectCode.trim().toLowerCase()
        );
        if (!selectedProject) {
          selectedProject = resp.data;
        }

        console.log("selectedProject: ", selectedProject);

        if (selectedProject) {
          setDataProjectsCards(selectedProject);

          setFormValues((prev) => ({
            ...prev,
            projectIds: [selectedProject.id],
            allProjects: resp.data,
          }));
        } else {
          console.warn(
            `⚠️ No se encontró un proyecto con código: ${projectCode}`
          );
          setDataProjectsCards(null);
        }
      } catch (error) {
        console.error("❌ Error al obtener datos del proyecto:", error);
      } finally {
        setIsLoading(false);
      }
    };

    if (projectCode) {
      fetchProjectData();
    } else {
      setIsLoading(false);
    }
  }, [projectCode]);

  const handleChange = (
    questionId: string,
    value: string | string[] | boolean | { phone: string; countryCode: string }
  ) => {
    setFormValues((prev) => ({
      ...prev,
      [questionId]:
        questionId === "projectIds"
          ? [...(Array.isArray(value) ? value : [])]
          : value,
    }));

    if (value !== "" && value !== null && value !== undefined) {
      setErrors((prev) => ({
        ...prev,
        [questionId]: false,
      }));
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setIsSubmitting(true);

    const newErrors: { [key: string]: boolean } = {};
    let hasError = false;

    ProjectsFormData.questions.forEach(
      (question: { id: string; required: boolean; type: string }) => {
        if (question.required) {
          const value = formValues[question.id];

          if (
            question.type === "phone" &&
            (!value ||
              (typeof value === "object" && "phone" in value && !value.phone))
          ) {
            newErrors[question.id] = true;
            hasError = true;
          } else if (!value || value === "") {
            newErrors[question.id] = true;
            hasError = true;
          }
        }
      }
    );

    if (hasError) {
      setErrors(newErrors);
      toast.error(
        "Por favor, completa todos los campos obligatorios antes de enviar."
      );
      setIsSubmitting(false);
      return;
    }

    let phone = "";
    let countryPhoneCode = "";

    if (
      typeof formValues["phone"] === "object" &&
      formValues["phone"] !== null
    ) {
      const phoneData = formValues["phone"] as {
        phone: string;
        countryCode: string;
      };
      phone = phoneData.phone;
      countryPhoneCode = phoneData.countryCode;
    } else if (typeof formValues["phone"] === "string") {
      const parsedPhone = parsePhoneNumberFromString(formValues["phone"]);
      if (parsedPhone) {
        phone = parsedPhone.nationalNumber;
        countryPhoneCode = `+${parsedPhone.countryCallingCode}`;
      } else {
        phone = formValues["phone"];
      }
    }

    if (!countryPhoneCode && phone.startsWith("+")) {
      const match = phone.match(/^(\+\d{1,2})(\d+)/);

      if (match) {
        countryPhoneCode = match[1];
        phone = match[2];
      }
    }

    if (!countryPhoneCode) {
      if (phone.startsWith("+1")) {
        countryPhoneCode = "+1";
        phone = phone.slice(1);
      } else if (phone.startsWith("44")) {
        countryPhoneCode = "+44";
        phone = phone.slice(2);
      } else if (phone.startsWith("91")) {
        countryPhoneCode = "+91";
        phone = phone.slice(2);
      } else if (phone.startsWith("61")) {
        countryPhoneCode = "+61";
        phone = phone.slice(2);
      } else if (phone.startsWith("81")) {
        countryPhoneCode = "+81";
        phone = phone.slice(2);
      } else if (phone.startsWith("49")) {
        countryPhoneCode = "+49";
        phone = phone.slice(2);
      } else if (phone.startsWith("33")) {
        countryPhoneCode = "+33";
        phone = phone.slice(2);
      } else if (phone.startsWith("55")) {
        countryPhoneCode = "+55";
        phone = phone.slice(2);
      } else if (phone.startsWith("7")) {
        countryPhoneCode = "+7";
        phone = phone.slice(1);
      }
    }

    const projectIds = Array.isArray(formValues["projectIds"])
      ? formValues["projectIds"]
      : formValues["projectIds"]
      ? [formValues["projectIds"]]
      : [];

    const selectedProjectNames = (
      ProjectsFormData.questions.find((q) => q.id === "projectIds")?.options ||
      []
    )
      .filter((opt) => projectIds.includes(opt.value))
      .map((opt) => opt.label);

    const projectNamesText =
      selectedProjectNames?.join(", ") || dataProjectsCards?.name;

    const leadData = {
      firstName: formValues["firstName"] || "",
      lastName: formValues["lastName"] || "",
      phone,
      email: formValues["email"] || "",
      countryPhoneCode,
      leadOrigin: formValues["leadOrigin"] || "",
      projectIds,
    };
    const getBrowserName = () => {
      const userAgent = navigator.userAgent;

      if (userAgent.includes("Firefox")) return "Firefox";
      if (userAgent.includes("Edg")) return "Microsoft Edge";
      if (
        userAgent.includes("Chrome") &&
        !userAgent.includes("Edg") &&
        !userAgent.includes("OPR")
      )
        return "Chrome";
      if (userAgent.includes("Safari") && !userAgent.includes("Chrome"))
        return "Safari";
      if (userAgent.includes("Opera") || userAgent.includes("OPR"))
        return "Opera";
      return "Unknown Browser";
    };

    const CRMData = {
      countryPhoneCode: countryPhoneCode,
      email: formValues.email,
      first_name: formValues["firstName"] || "",
      last_name: formValues["lastName"] || "",
      lead_origin: formValues["leadOrigin"],
      origin: dataProjectsCards?.name
        ? `Formulario de ${CapitalizeFirstLetter(dataProjectsCards?.name)}`
        : "Formulario de pagina web",
      phone: phone,
    };

    const QuiivenData = {
      countryPhoneCode: countryPhoneCode,
      email: formValues.email,
      first_name: formValues["firstName"] || "",
      last_name: formValues["lastName"] || "",
      lead_origin: formValues["leadOrigin"],
      origin: dataProjectsCards?.name
        ? `Formulario de ${CapitalizeFirstLetter(dataProjectsCards?.name)}`
        : "Formulario de pagina web",
      phone: phone,
      projectNames: projectNamesText,
      utmSource: UTM.utmSource || getBrowserName() || "Google",
      utmMedium: UTM.utmMedium || "Directo",
      utmCampaign: UTM.utmCampaign,
      utmTerm: UTM.utmTerm,
      utmContent: UTM.utmContent,
    };

    try {
      await sendLead(leadData);
      await generateRegisterInCRM(CRMData);

      await createContactInFormQuiiven(QuiivenData);
      toast.success(
        "Gracias por tu interés, nos pondremos en contacto contigo pronto."
      );
      setIsSubmitted(true);
    } catch (error) {
      console.error("❌ Error al enviar lead:", error);
      toast.error(
        "Hubo un error al enviar la información, intenta nuevamente."
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (firstQuestionRef.current) {
      firstQuestionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);

  return (
    <main className="flex justify-center items-center p-4">
      {isLoading ? (
        <SkeletonForm />
      ) : (
        <form
          className="bg-white rounded-2xl shadow-lg p-8 mt-16 w-full max-w-xl"
          onSubmit={handleSubmit}
        >
          {currentPage === 0 && (
            <InvestorFormHeader
              urlImg={dataProjectsCards?.imageURL}
              name={dataProjectsCards?.name || ""}
              isSubmitted={isSubmitted}
              setIsSubmitted={setIsSubmitted}
            />
          )}

          {isSubmitted ? (
            <FormSubmissionStatus
              isSubmitting={isSubmitting}
              resetForm={() => setIsSubmitted(false)}
            />
          ) : (
            <>
              {ProjectsFormData.questions
                .slice(
                  currentPage * questionsPerPage,
                  (currentPage + 1) * questionsPerPage
                )
                .map((question, index) => (
                  <div
                    key={question.id}
                    className={`p-4 mb-4 bg-white border ${
                      errors[question.id] ? "border-red-500" : "border-gray-300"
                    } rounded-xl `}
                    ref={index === 0 ? firstQuestionRef : null}
                  >
                    <QuestionComponent
                      question={question}
                      imgurl={dataProjectsCards?.imageURL || ""}
                      name={dataProjectsCards?.name || ""}
                      cardId={dataProjectsCards?.id || ""}
                      value={formValues[question.id] || ""}
                      onChange={(value) => handleChange(question.id, value)}
                    />

                    {errors[question.id] && (
                      <p className="text-red-500 text-sm mt-1">
                        Este campo es obligatorio
                      </p>
                    )}
                  </div>
                ))}

              <div className="flex justify-between mt-4">
                {currentPage > 0 && (
                  <button
                    type="button"
                    className="rounded-xl bg-gray-600 px-6 py-3 text-white text-lg font-semibold hover:bg-gray-700 transition"
                    onClick={() => setCurrentPage((prev) => prev - 1)}
                  >
                    Anterior
                  </button>
                )}
                {(currentPage + 1) * questionsPerPage <
                  ProjectsFormData.questions.length && (
                  <button
                    type="button"
                    className="rounded-xl bg-indigo-600 px-6 py-3 text-white text-lg font-semibold hover:bg-indigo-700 transition"
                    onClick={() => setCurrentPage((prev) => prev + 1)}
                  >
                    Siguiente
                  </button>
                )}
              </div>

              {currentPage * questionsPerPage + questionsPerPage >=
                ProjectsFormData.questions.length && (
                <button
                  type="submit"
                  className="mt-4 w-full rounded-xl bg-[#4F4CF1] px-6 py-3 text-white text-lg font-semibold  transition"
                  disabled={isSubmitting}
                >
                  {isSubmitting ? "Enviando..." : "Enviar"}
                </button>
              )}
            </>
          )}
        </form>
      )}
    </main>
  );
};
