export const SkeletonForm = () => {
    return (
      <div className="h-screen w-full flex items-center justify-center">
        <div className="w-full max-w-lg bg-gray-200 p-6 rounded-xl shadow-md animate-pulse">
          <div className="h-8 w-2/3 bg-gray-300 rounded mb-4"></div>
          <div className="h-12 w-full bg-gray-300 rounded mb-4"></div>
          <div className="h-8 w-1/2 bg-gray-300 rounded mb-4"></div>
          <div className="h-12 w-full bg-gray-300 rounded mb-4"></div>
          <div className="h-8 w-1/2 bg-gray-300 rounded mb-4"></div>
          <div className="h-12 w-full bg-gray-300 rounded mb-4"></div>
          <div className="h-8 w-1/2 bg-gray-300 rounded mb-4"></div>
          <div className="h-12 w-full bg-gray-300 rounded mb-4"></div>
          <div className="h-10 w-full bg-gray-400 rounded-md"></div>
        </div>
      </div>
    );
  };
  

  