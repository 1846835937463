import { useEffect, useRef } from 'react';

export default function useTitle(title: string) {
    const prevTitleRef = useRef(document.title);

    useEffect(() => {
        prevTitleRef.current = document.title;
        document.title = title;
    }, [title]);

    return prevTitleRef.current;
}

export function useDescription(description: string) {
    const prevDescriptionRef = useRef(
        document.querySelector('meta[name="description"]')?.getAttribute('content') || ''
    );

    useEffect(() => {
        const metaDescription = document.querySelector('meta[name="description"]');
        if (metaDescription) {
            prevDescriptionRef.current = metaDescription.getAttribute('content') || '';
            metaDescription.setAttribute('content', description);
        }
    }, [description]);

    return prevDescriptionRef.current;
}

export function useKeywords(keywords: string) {
    const prevKeywordsRef = useRef(
        document.querySelector('meta[name="keywords"]')?.getAttribute('content') || ''
    );

    useEffect(() => {
        const metaKeywords = document.querySelector('meta[name="keywords"]');
        if (metaKeywords) {
            prevKeywordsRef.current = metaKeywords.getAttribute('content') || '';
            metaKeywords.setAttribute('content', keywords);
        }
    }, [keywords]);

    return prevKeywordsRef.current;
}