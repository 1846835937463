import React, { useState } from 'react';
import BenefitSection from './BenefitSection';
import { isAuthenticated } from '../../../helpers/functions';

interface InvestorProfileItemProps {
  img: string;
  type: string;
  price: number;
  benefits: Array<string>;
  amount: number;
}

export const InvestorProfileItem = ({ img, type, price, benefits, amount }: InvestorProfileItemProps) => {
  const [showBenefit, setShowBenefit] = useState(false);

  const goBenefit = () => {
    localStorage.setItem('amount', amount.toString());
    localStorage.setItem('instalmentValue', (amount * price).toString());
    localStorage.setItem('installments', '1');
    isAuthenticated(true);
  };

  const onShowBenefit = () => {
    setShowBenefit(true);
  };

  const onHideBenefit = () => {
    setShowBenefit(false);
  };

  return (
    <div className="relative rounded-xl overflow-hidden">
      <img className="w-full h-full aspect-square min-h-[266px]" src={img} alt={type} />
      <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center bg-black/20">
        
        <BenefitSection
          benefits={benefits}
          showBenefit={showBenefit}
          onShowBenefit={onShowBenefit}
          onHideBenefit={onHideBenefit}
          price={price * amount} 
          type= {type}
         
          
        />
        <button
          id="btnInvestorProfile"
          className="bg-[#B8CCFF] primaryFont btn-color-custom"
          onClick={goBenefit}
        >
          Quiero ser un {type}
        </button>
      </div>
    </div>
  );
};

