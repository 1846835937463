import React, { useState } from 'react'
import playIcon from "../../../assets/img/nido/arrow-next.svg";
import { trigger } from '../../../helpers/events';
import GalleryAllPropertyPage from './GalleryAllPropertyPage';
import { numberWithCommas } from '../../../helpers/functions';



interface GalleryPropertyPageProps {
  images: Array<{ url: string; type: string; format: string }>;
  squareMeters: number;
  roomCount: number;
  value: number
  phase: string;
  name : string;
}

function extractFirstNumber(text: string) {
  const match = text.match(/\d+/);
  return match ? parseInt(match[0], 10) : 0;
}

export default function GalleryPropertyPage({ images, squareMeters, roomCount, value, phase , name}: GalleryPropertyPageProps) {
  const [videoActived, setVideoActived] = useState(false);
  const [activeGallery, setActiveGallery] = useState(false);

  const mainImage = images.find((img) => img.format === "image" && img.type === "main");
  const propertyImage = images.find((img) => img.format === "image" && img.type === "property");
  const videoImage = images.find((img) => img.format === "video");
  const horizontalImage = images.find((img) => img.type === "horizontal");
  const galleryImages = images.filter((img) => img.type === "gallery");

  const openGallery = () => {
    setActiveGallery((prevState) => !prevState);
    trigger('closeGalleryNido');
  };

  return (
    <div className="flex md:relative w-full">
      <div className="flex flex-col gap-2">

        <div className="relative w-full cursor-pointer pb-[56.25%] text-white font-extrabold">
          {videoActived ? (
            <iframe
              width="100%"
              height="100%"
              src={`${videoImage?.url}?autoplay=1`}
              title={`Conoce más de ${name} | lokl`}
              className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          ) : (
            <>
              <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center cursor-pointer">
                <img
                  className="opacity-60 z-10 transform transition-transform duration-300 hover:scale-110"
                  width={50}
                  height={50}
                  src={playIcon}
                  loading="lazy"
                  alt="play-video-icon-lokl"
                  onClick={() => {
                    setVideoActived(true);
                  }}
                />
              </div>

              <img
                className="absolute top-0 left-0 w-full h-full object-cover rounded-lg"
                src={propertyImage?.url ? propertyImage?.url : mainImage?.url}
                alt={`Invierte en  ${name} | lokl`}
                onClick={() => {
                  setVideoActived(true);
                }}
                loading="lazy"
              />
              <div className="absolute bottom-0 left-0 w-full h-full bg-gradient-to-t from-black/70 via-transparent to-transparent rounded-lg pointer-events-none"></div>
            </>
          )}


          {!videoActived && (
            <div className="absolute items-center bottom-0 left-4 flex space-x-4 p-2">
              <div className="text-sm md:text-base numberFont text-white">{numberWithCommas(squareMeters)} m²</div>
              <div className="text-sm md:text-base numberFont text-white">{roomCount} Alojamientos</div>
              <div className="text-sm md:text-base numberFont text-white">${numberWithCommas(value)} Promedio Alojamiento</div>
            </div>
          )}
        </div>


        <div className=" grid-cols-3 gap-2 h-[150px] flex items-center w-full">
          {horizontalImage && (
            <div className="col-span-2">
              <img
                src={horizontalImage.url}
                alt={`Inversion inmobiliara | invierte en  ${name} | lokl`}
                className="w-full h-[150px] object-cover rounded-lg"
                loading="lazy"
              />

            </div>
          )}

          {galleryImages[0] && (
            <div className="col-span-1 relative">
              <div
                className="cursor-pointer absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center text-white p-8 bg-black bg-opacity-50 rounded-lg shadow-lg"
                onClick={openGallery}
              >

                <div className="text-4xl font-extrabold">+{galleryImages.length - 1}</div>
                <div className="hidden md:block secondaryFont underline">Ver más fotos</div>
                <div className="md:hidden block secondaryFont underline">Ver más</div>
              </div>
              <img
                src={galleryImages[0].url}
                alt={`Invierte en  ${name} | lokl`}
                className="w-full h-[150px] object-cover rounded-lg cursor-pointer"
                loading="lazy"
              />
            </div>
          )}
        </div>
        {extractFirstNumber(phase) < 3 && (
          <p className="text-black secondaryFont md:text-sm text-sm">
            *Las imágenes son ilustrativas y no tienen valor contractual. Pueden haber cambios en dimensiones, materiales y colores. Los elementos decorativos no están incluidos.
          </p>
        )}

        {activeGallery && <GalleryAllPropertyPage name={name} images={galleryImages} />}
      </div>
    </div>
  );
}

