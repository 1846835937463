export const SimulatorChartSkeleton = () => {
    return (

        <div className='w-full mt-10'>
            <div>
                <div className='skeleton mt-4 mb-4 rounded-lg w-full h-[20px]'></div>
            </div>
            <div className='w-[80%] ml-[8%] flex flex-row justify-between items-end border-b-4'>
                <div className='skeleton w-[18px] h-[60px] rounded-t-3xl'></div>
                <div className='skeleton w-[18px] h-[100px] rounded-t-3xl'></div>
                <div className='skeleton w-[18px] h-[90px] rounded-t-3xl'></div>
                <div className='skeleton w-[18px] h-[120px] rounded-t-3xl'></div>
                <div className='skeleton w-[18px] h-[135px] rounded-t-3xl'></div>
            </div>
            <div>
                <div className='skeleton mt-4 rounded-lg w-full h-[12px]'></div>
            </div>
        </div>

    )
}
