import React, { useContext, useEffect, useState } from 'react'
import { FieldValues, SubmitHandler, useForm } from 'react-hook-form';
import PhoneInput from 'react-phone-number-input';
import Checkbox from '../../components/Checkbox';
import es from 'react-phone-number-input/locale/es.json';
import { generateRegisterInCRM, serviceRegister , createRegisterInQuiiven} from '../../services/authService';
import Select from 'react-tailwindcss-select';
import ReCAPTCHA from "react-google-recaptcha";

import { HowDidYouHearAboutUs } from './constants/register-constants';
import CombinedInput from '../../components/inputs/CombinedInput';
import Input from '../../components/inputs/Input';
import { Hero } from './components/Hero';
import PasswordInput from '../../components/inputs/PasswordInput';
import { parsePhoneNumber } from 'react-phone-number-input';
import { trigger } from '../../helpers/events';
import { UTMContext } from '../../contexts';
import { CreateContactInAC } from '../../services/acService';


const passwordRegex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+={}[\]\\|;:'",.<>/?])[A-Za-z\d!@#$%^&*()_+={}[\]\\|;:'",.<>/?]{8,}$/;

function Register() {

  const referral_code = localStorage.getItem("referral_code") ?? "";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, touchedFields },
    setError,
    clearErrors,
  } = useForm<FieldValues>({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      password: '',
      phone: '',
      countryPhoneCode: '',
      lead_origin: '',
      terms: false,
      referral_code: referral_code,
      pageOrigin: ''
    },
    mode: 'onTouched'
  });

  const [terms, setTerms] = useState(true);
  const [btnActive, setBtnActive] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const [phone, setPhone] = useState('');
  const [countryPhoneCode, setCountryPhoneCode] = useState('');
  const [captcha, setCaptcha] = useState(false);
  const context = useContext(UTMContext);
  const { UTM } = context;
  const [originSelected, setOriginSelected] = useState(null);
  const redirectLogin = () => {

    const url = new URL(window.location.href);
    const hash = url.hash || '';
    return url.search + hash;

  }

    /**
   * Se ejecuta al enviar hacer en boton de registrar
   * o al hacer enter en algún compo
   * 
   * @param data 
   * @param event 
   */
  const onSubmit: SubmitHandler<FieldValues> = (data, event) => {

    if(data.phone === null || data.phone === "" || data.phone === undefined || !data.countryPhoneCode){

      setError('phone', {type: 'required'});

    } else if(!originSelected) {
      setError('lead_origin', {type: 'required'});
    } else{

      if(captcha === true){
  
        setBtnActive(true);
    
        const url = new URL(window.location.href);
        const getParam = url.searchParams.get('redirect_to');

        sessionStorage.setItem('emailConfirm', data.email);
        
        data.pageOrigin = localStorage.getItem('pageOrigin') || 'Directo';
        if(window.location.href.includes("simulationdata")) data.pageOrigin = 'new simulator'
        // console.log('data register', data);

        data.utmSource = UTM.utmSource
        data.utmMedium = UTM.utmMedium
        data.utmCampaign = UTM.utmCampaign
        data.utmTerm = UTM.utmTerm
        data.utmContent = UTM.utmContent

        serviceRegister(data)
        .then(res => {
          if(res.data){
            // let data = res.data;
            // sessionStorage.setItem('token', data.token);
            setBtnActive(false);
            let token = res.data.data.token; 
            localStorage.setItem('token', token);
            trigger('authed');
            trigger('reload:dashboard');
            // trigger('authed');

            const url = new URL(window.location.href);
            const urlParams = new URLSearchParams(new URL(url).search);
            const parameters = urlParams.toString();
            if(getParam){
              window.location.href = `/confirmation-code?${parameters}&redirect_to=${getParam + url.hash}`;
            }else{
              window.location.href = `/confirmation-code?${parameters}`;
            }
          }
        })
        .catch(err => {
          console.log('error register', err.message);
          sessionStorage.removeItem('emailConfirm');
          setBtnActive(false);
          setErrorMsg(err.message);
          setTimeout(() => {
            setErrorMsg('');
          }, 5000);
        });
        generateRegisterInCRM(data)
        createRegisterInQuiiven(data)
        const getBrowserName = () => {
          const userAgent = navigator.userAgent;
      
          if (userAgent.includes("Firefox")) return "Firefox";
          if (userAgent.includes("Edg")) return "Microsoft Edge";
          if (userAgent.includes("Chrome") && !userAgent.includes("Edg") && !userAgent.includes("OPR")) return "Chrome";
          if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) return "Safari";
          if (userAgent.includes("Opera") || userAgent.includes("OPR")) return "Opera";
          return "Unknown Browser";
      }      
        CreateContactInAC({
          email: data.email,
          firstName: data.first_name,
          lastName: data.last_name,
          phone: data.phone,
          source: UTM.utmSource ||  getBrowserName() ||"Google",
          medium: UTM.utmMedium || "Directo",
          howDidYouMeetUs: data.lead_origin,
          campaign: UTM.utmCampaign,
          term: UTM.utmTerm,
          content: UTM.utmContent,
          tag: "REGISTRO"});       
      }else{
        setErrorMsg('Deber aceptar el captcha');
        setTimeout(() => {
          setErrorMsg('');
        }, 5000);
      }
      
    }

  }

  const onError = (errors: any, e: any) => {

    if(!phone){
      setError('phone', {type: 'required'});
    }

    if(!countryPhoneCode){
      setError('countryPhoneCode', {type: 'required'});
    }

    if(!originSelected){
      setError('lead_origin', {type: 'required'});
    }

  }

  useEffect(() => {
    setValue('terms', terms)
  }, [terms, setValue]);

  /**
   * capcha
   */
  const changeCapcha = (value: any) => {
    const recaptchaValue = value;
    if (recaptchaValue) {
      setCaptcha(true);
    }
  }

  const setCustomValue = (id: string, value: any) => {
    setValue(id, value, {
      shouldDirty: true,
      shouldTouch: true,
      shouldValidate: true
    })
  }

  const handlePhoneChange = (value: any) =>  {
    if (!value) return;
    const formatPhoneNumber = parsePhoneNumber(value)
    clearErrors('phone');
    clearErrors('countryPhoneCode');
    setCustomValue('phone', formatPhoneNumber?.nationalNumber);
    setCustomValue('countryPhoneCode', formatPhoneNumber?.countryCallingCode);
    setPhone(value);
    setCountryPhoneCode(formatPhoneNumber?.countryCallingCode || '');
    if(!value) {
      setError('phone', {type: 'required'});
      setError('countryPhoneCode', {type: 'required'});
    }
  }

  return (
    <div className='flex justify-center pt-[34px] min-h-[calc(100vh-21px)]'>

      <div className="w-full lg:w-[800px] flex justify-center items-center">

        <div className="max-w-[370px] lg:max-w-[400px] px-10 pt-0 lg:pt-10 lg:mt-4">

          <div className="font-black font-lato text-base text-black mt-5 mb-9">

            <div className="text-2xl font-roboto font-bold">
            
            Regístrate para recibir una asesoría personalizada de inversiones inmobiliarias 💰
            
            </div>
            
            { errorMsg !== '' && <div className="text-red-400">{errorMsg}</div> }
          
          </div>


          <form id="formRegister" className='mb-3 space-y-3' onSubmit={handleSubmit(onSubmit, onError)}>

            <CombinedInput
              firstInputLabel='Nombre'
              firstInputId='first_name'
              firstInputRequired={true}
              secondInputLabel='Apellido'
              secondInputId='last_name'
              secondInputRequired={true}
              register={register}
              errors={errors}
              touchedFields={touchedFields}
            />

            <Input
              id='email'
              label='Correo electrónico'
              type='email'
              required={true}
              register={register}
              errors={errors}
              touchedFields={touchedFields}
              pattern={/\S+@\S+\.\S+/}
              patternErrorMessage='El correo electrónico no es válido'
              requireErrorMessage='El correo electrónico es requerido'
            />

            <div className="">
              <PhoneInput
                className={`
                  h-[40px] border-[1px]
                  py-3 px-4
                  rounded-lg
                  focus:border-[#4743E0]
                  ${ errors.phone ? 'border-red-500' : !errors.phone && touchedFields.phone ? 'border-green-500' : 'border-gray-300' }
                    `}
                    international
                    countryCallingCodeEditable={false}
                    defaultCountry='CO'
                    labels={es}
                    placeholder="Teléfono"
                    value={phone}
                    onChange={(value: any) => {
                      handlePhoneChange(value);
                    }}
                  />
              { errors.phone?.type === "required" && <div className='text-red-500 text-sm' id='warning-required-phone'>* Algo anda mal, revisa tu número de celular</div> }

            </div>

            <PasswordInput
              id='password'
              label='Contraseña'
              required={true}
              register={register}
              errors={errors}
              touchedFields={touchedFields}
              pattern={passwordRegex}
              patternErrorMessage='La contraseña debe contener una mayúscula, números, un signo y al menos 8 caracteres'
              requireErrorMessage='La contraseña es requerida'
            />

            <div className="">

              <Select
                classNames={{
                  menuButton: () => `flex justify-between h-[40px] border-[1px] ${ errors.lead_origin ? 'border-red-500' : !errors.lead_origin && touchedFields.lead_origin ? 'border-green-500' : 'border-gray-300' } px-4 rounded-lg outline-none cursor-pointer focus:border-[#4743E0] ${originSelected ? 'text-gray-800': 'text-gray-400'}`,
                  ChevronIcon: ({ open }) => ( open ? 'rotate-90' : 'rotate-180' ),
                  list: 'scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 max-h-52',
                  listGroupLabel: 'pl-[10px] pb-[5px] text-black font-bold',
                  listItem: ({ isSelected }) => `pl-[15px] list-none py-1 cursor-pointer ${ isSelected && 'bg-gray-400 text-white py-2 px-3' } `,
                }}
                primaryColor='violet'
                value={originSelected}
                onChange={(val: any) => {
                  setCustomValue('lead_origin', val.value)
                  setOriginSelected(val);
                  clearErrors("lead_origin");
                }}
                options={HowDidYouHearAboutUs}
                loading={HowDidYouHearAboutUs.length <= 0}
                placeholder="¿Cómo nos conociste?"
              />
              
              { errors.lead_origin?.type === "required" && <div className='text-red-500 text-sm' id='warning-required-lead-origin'>* Campo requerido</div> }

            </div>

            <input 
              className={`w-full h-[40px] border-[1px] border-gray-300 py-3 px-4 rounded-lg outline-none focus:border-[#4743E0]`} 
              type="text"
              defaultValue={referral_code}
              {...register('referral_code')}
              placeholder='Código de referido o influencer'
            />

            <ReCAPTCHA
              sitekey="6LdJ7wklAAAAAHMgAGZJul66MFfF_ujDC_vhoAJS"
              onChange={changeCapcha}
              size={'normal'}
            />

            <div className="flex flex-col justify-between items-center mb-10">

              <Checkbox setTerms={setTerms} terms={terms} name={<div className="flex">Acepto <a target='_blank' rel='noreferrer' href='https://drive.google.com/file/d/1R6aOvsRjYVo-d398PskWJjwL4_WrY9PP/view?usp=drive_link' className="ml-1 font-roboto font-bold text-[#4743E0] no-underline">términos y condiciones</a></div>} />

              <button 
                id="btnRegister" 
                form='formRegister' 
                className="w-full mt-4 btn-login"
                disabled={!terms || btnActive}
              >¡Regístrate!</button>

            </div>

          </form>



          <div className="w-full justify-center items-center text-center mb-16">

            <div className="font-roboto text-gray-400">¿Ya tienes una cuenta?</div>
            <a id="redirectRegister" className='font-roboto text-violet-lokl no-underline' href={`/login${redirectLogin()}`}>Iniciar sesión</a>

          </div>
        
        </div>

      </div>

      <div className='w-full hidden lg:block'>
          <Hero />
      </div>

    </div>
  )
}

export default Register;