// Price in $1.000.000 format
export const numberToCurrencyFormat = (number: any) => {
  number = Number(number)
  let currencyFormat = new Intl.NumberFormat('es-CO', {
    style: 'currency',
    currency: 'COP',
  }).format(number);

  return currencyFormat;

}
export const formatNumberWithCommas = (number: number | undefined, decimals = 1) => {
  if (!number) return "0";
  if (Number.isInteger(number)) {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return number.toFixed(decimals).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};