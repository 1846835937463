import React, { useState, useRef, useEffect } from "react";
import { investorFormData } from "./investorFormData";
import { InvestorFormHeader } from "../sections";
import { QuestionComponent } from "../Components";

export const InvestorForm = () => {
  const [formValues, setFormValues] = useState<{ [key: string]: string | string[] | boolean | { phone: string; countryCode: string; } }>({});
  const [currentPage, setCurrentPage] = useState(0);
  const questionsPerPage = 6;
  const firstQuestionRef = useRef<HTMLDivElement>(null);

  const startIndex = currentPage * questionsPerPage;
  const endIndex = startIndex + questionsPerPage;
  const currentQuestions = investorFormData.questions.slice(startIndex, endIndex);

  const handleChange = (questionId: string, value: string | string[] | boolean | { phone: string; countryCode: string; }) => {
    setFormValues((prev) => ({
      ...prev,
      [questionId]: value,
    }));
  };

  const handleNextPage = () => {
    if (endIndex < investorFormData.questions.length) {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handlePrevPage = () => {
    if (currentPage > 0) {
      setCurrentPage((prev) => prev - 1);
    }
  };

  useEffect(() => {
    if (firstQuestionRef.current) {
      firstQuestionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [currentPage]);
  

  console.log(formValues);
  
  return (
    <main className="flex justify-center items-center min-h-screen bg-green-100 p-4">
      <form className="bg-white rounded-2xl shadow-lg p-8 w-full max-w-xl">
        {currentPage === 0 && <InvestorFormHeader urlImg="" isSubmitted={false} setIsSubmitted={() => {}} />}

        {currentQuestions.map((question, index) => (
          <div
            key={question.id}
            className="p-4 bg-white border border-gray-300 rounded-xl shadow-sm"
            ref={index === 0 ? firstQuestionRef : null}
          >
            <QuestionComponent
              question={question}
              cardId={question.id}
              value={formValues[question.id] || ""}
              onChange={(value) => handleChange(question.id, value)}
              imgurl="{question.imgurl}"
              name="{question.name}"
            />
          </div>
        ))}

        <div className="flex justify-between mt-4">
          {currentPage > 0 && (
            <button
              type="button"
              className="rounded-xl bg-gray-600 px-6 py-3 text-white text-lg font-semibold hover:bg-gray-700 transition"
              onClick={handlePrevPage}
            >
              Anterior
            </button>
          )}
          {endIndex < investorFormData.questions.length && (
            <button
              type="button"
              className="rounded-xl bg-indigo-600 px-6 py-3 text-white text-lg font-semibold hover:bg-indigo-700 transition"
              onClick={handleNextPage}
            >
              Siguiente
            </button>
          )}
        </div>

        {endIndex >= investorFormData.questions.length && (
          <button
            type="submit"
            className="mt-4 w-full rounded-xl bg-indigo-600 px-6 py-3 text-white text-lg font-semibold hover:bg-indigo-700 transition"
          >
            Enviar
          </button>
        )}
      </form>
    </main>
  );
};
