import React, { useEffect, useState } from 'react'
import { PaymentMethodSelect } from '../../Dashboard/components/PaymentMethodSelect';
import { InvestForm } from './components/InvestForm';
import { InstallmentCard } from './components/InstallmentCard';
import { SummaryCard } from './components/SummaryCard';
import { Banner } from './components/Banner';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { createOrUpdateCart } from '../../../services/cartService';
import { getProjectCards } from '../../../services/projectService';
import toast, { Toaster } from 'react-hot-toast';
import { trigger } from '../../../helpers/events';
import { isAuthenticated } from '../../../helpers/functions';
import { useEffectOnce } from '../../../helpers/hooks';
import { validateCoupon } from '../../../services/couponService';
import { ProjectCardResponseInterface } from '../../NewHome/interfaces/ProjectCardDataInterface';
import { useInvestOverView } from '../../../hooks/useInvestmentOverview';

const options = [
  { value: "2", label: "2 Meses" },
  { value: "3", label: "3 Meses" },
  { value: "4", label: "4 Meses" },
  { value: "5", label: "5 Meses" },
  { value: "6", label: "6 Meses" },
  { value: "7", label: "7 Meses" }
];

interface Project {
  value: string,
  label: string,
  imageURL: string,
  unitValue: number,
  subscriptionFeePercentage: number,
  maxInvestmentQuota: number
}
export const InvestX = () => {

  useEffectOnce(() => {
    isAuthenticated();
  });

  // TODO: Verify if is possible to improve this with react-router-dom config
  // Code to scroll to top when change page
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [paymentMethod, setPaymentMethod] = useState<number>(0);
  const [projectId, setProjectId] = useState<string | undefined>(undefined);
  const [projects, setProjects] = useState<Project[]>([]);
  const [project, setProject] = useState<Project>({ label: '', value: '', imageURL: '', unitValue: 0, subscriptionFeePercentage: 0, maxInvestmentQuota: 1 });
  const [searchParams] = useSearchParams();
  const [investUnitAmount, setInvestUnitAmount] = useState<string>('');
  const [couponCode, setCouponCode] = useState<string>('');
  const [installmentSelected, setInstallmentSelected] = useState({ value: "1", label: "Pago único" });
  const [installmentOptions, setInstallmentOptions] = useState(options);

  const [disableCouponInput, setDisableCouponInput] = useState<boolean>(false);

  const [coupon, setCoupon] = useState({
    code: '',
    couponUnitPrice: 0,
    couponSubscriptionFeeFree: false
  });

  const [error, setError] = useState<string | null>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const navigate = useNavigate()

  const {
    totalInvestment,
    installments,
    financingCost,
    subtotal,
    paymentMethodFee,
    total
  } = useInvestOverView(investUnitAmount, installmentSelected?.value, paymentMethod, coupon.couponUnitPrice, coupon.couponSubscriptionFeeFree, project.unitValue, project.subscriptionFeePercentage)

  const handlePaymentMethod = (selectedMethod: number) => {
    setPaymentMethod(selectedMethod);
  }

  const handleInvestAmount = (amount: string) => {
    setInvestUnitAmount(amount);
  }

  const handleCouponCode = (code: string) => {
    setCouponCode(code);
  }

  const handleSelectInstallment = (value: any) => {
    setInstallmentSelected(value)
  }

  const handleProjectChange = (val: any) => {
    setProject(val)
    setProjectId(val.value)
    handleInstallments(val)
    setInstallmentSelected({ value: "1", label: "Pago único" })
  }

  const handleInstallments = (project: Project) => {
    const options: any = [];
    /* options.push({ value: "1", label: "Pago único" }); */
    for (let i = 2; i <= project.maxInvestmentQuota; i++) {
      options.push({ value: i, label: `${i.toString()} Meses` })
    }
    setInstallmentOptions(options)
  }

  const onNextStep = async () => {
    if (paymentMethod !== 1 && paymentMethod !== 2) {
      setTimeout(() => {
        setError('')
      }, 5000)
      setError('Debes seleccionar un método de pago')
      return
    }
    localStorage.setItem('investUnitAmount', investUnitAmount);
    localStorage.setItem('installmentSelected', installmentSelected.value);
    localStorage.setItem('paymentMethod', paymentMethod?.toString());
    localStorage.setItem('totalPayment', total?.toString());
    setIsLoading(true)
    try {
      const response = await createOrUpdateCart({
        projectId: projectId,
        unitsQuantity: investUnitAmount,
        paymentMethod: paymentMethod === 1 ? 'CARD' : paymentMethod === 2 ? 'PSE' : null,
        installmentsQuantity: installmentSelected?.value,
        couponCode
      })
      if (response.success) {
        localStorage.setItem('cartId', response.data.id)
        navigate('/checkout/personal-data')
      } else {
        if (response?.message) {
          setError(response.message)
          setTimeout(() => {
            setError('')
          }, 5000)
        } else {
          setError('Hubo un error creando el carrito de compras')
          setTimeout(() => {
            setError('')
          }, 5000)
        }
      }
      setIsLoading(false)
    } catch (error: any) {
      if (error?.response.status === 403 || error?.response.status === 401) {
        trigger('unauthed')
        toast.error('Tu sesión ha expirado, por favor inicia sesión nuevamente')
        setIsLoading(false)
        return
      }
      setError(`Hubo un error creando el carrito de compras \n ${error?.response?.data?.message || ''}`)
      setTimeout(() => {
        setError('')
      }, 2000)
      setIsLoading(false)
    }
  }

  useEffect(() => {
    setInvestUnitAmount(localStorage.getItem('investUnitAmount') || '100')
    setPaymentMethod(parseInt(localStorage.getItem('paymentMethod') || '0'))
    const installments = localStorage.getItem('installmentSelected')
    if (installments) {
      const result = options.find((option) => option.value === installments)
      setInstallmentSelected(result || { value: "1", label: "Pago único" })
    }
  }, [])

  useEffectOnce(() => {
    const fetchProjectInfo = async () => {
      try {
        setIsLoading(true)
        getProjectCards()
          .then((resp: ProjectCardResponseInterface) => {
            const filteredProjects = resp.data.filter((project) => project.phase !== 'Etapa 0');
            const transformedProjects = filteredProjects.map((project) => ({
              label: project.name,
              value: project.id,
              imageURL: project.imageURL,
              unitValue: project.unitPrice,
              subscriptionFeePercentage: project.subscriptionFeePercentage || 0,
              maxInvestmentQuota: project.maxInvestmentQuota
            }));
            setProjects(transformedProjects)
            const projectIdParam = searchParams.get('projectId')
            if (projectIdParam) {
              const currentProject = filteredProjects.find((project) => project.id === projectIdParam)
              if (currentProject) {
                const currentFormatProject: Project = {
                  label: currentProject.name,
                  value: currentProject.id,
                  imageURL: currentProject.imageURL,
                  unitValue: currentProject.unitPrice,
                  subscriptionFeePercentage: currentProject.subscriptionFeePercentage || 0,
                  maxInvestmentQuota: currentProject.maxInvestmentQuota
                }
                setProjectId(currentProject.id)
                setProject(currentFormatProject)
                handleInstallments(currentFormatProject)
              }
              else {
                navigate("/")
              }
            }
            else {
              const currentFormatProject: Project = {
                label: filteredProjects[0].name,
                value: filteredProjects[0].id,
                imageURL: filteredProjects[0].imageURL,
                unitValue: filteredProjects[0].unitPrice,
                subscriptionFeePercentage: filteredProjects[0].subscriptionFeePercentage || 0,
                maxInvestmentQuota: filteredProjects[0].maxInvestmentQuota
              }
              setProjectId(filteredProjects[0].id)
              setProject(currentFormatProject)
              handleInstallments(currentFormatProject)
            }
          })
          .finally(() => setIsLoading(false))
      } catch (error) {
        console.error(`Error fetching checkout page of ${projectId}}`, error);
        navigate("/");
      }
      finally {
        setIsLoading(false);
      }
    };

    fetchProjectInfo();
  });

  const handleValidateCoupon = async () => {
    const coupon = await validateCoupon(couponCode)
    if (coupon?.data?.success) {
      toast.success('Cupón validado exitosamente')
      setDisableCouponInput(true)
      setInvestUnitAmount(coupon.data.data.minimumUnitsPurchase)
      setCoupon({
        code: coupon.data.data.code,
        couponUnitPrice: coupon.data.data.unitPrice,
        couponSubscriptionFeeFree: coupon.data.data.subscriptionFeeFree

      })
      const selectedProject = projects.find(
        (project) => project.value === coupon.data.data.projectId
      );
      if (selectedProject) {
        setProject(selectedProject)
        setProjectId(selectedProject.value)
        handleInstallments(selectedProject)
        setInstallmentSelected({ value: "1", label: "Pago único" })
      }
    } else {
      toast.error('Cupón no válido')
    }
  }


  return (
    <div>
      <Toaster
        toastOptions={{
          success: {
            style: {
              fontWeight: 'bold'
            },
          },
          error: {
            style: {
              background: '#FF0F0F',
              color: 'white',
              fontWeight: 'bold'
            }
          },
          style: {
            fontWeight: 'bold'
          },
          duration: 5000
        }}
      />
      <div className="grid grid-cols-2 mt-8 md:mt-24">
        {/* Left column */}
        <div className="col-span-2 md:col-span-1">
          <div>
            <h2 className="font-extrabold text-3xl mb-3 font-lato">
              Empieza hoy mismo!
            </h2>
            <p className="text-gray-400">
              Escoge tu mejor opción de pago e inicia tu camino como inversionista en LOKL.
            </p>
          </div>

          <InvestForm
            couponCode={couponCode}
            setCouponCode={handleCouponCode}
            unitAmount={investUnitAmount}
            setUnitAmount={handleInvestAmount}
            installmentsOptions={installmentOptions}
            installmentSelected={installmentSelected}
            setInstallment={handleSelectInstallment}
            totalInvestment={totalInvestment}
            validateCoupon={handleValidateCoupon}
            disableCouponInput={disableCouponInput}
            projects={projects}
            project={project}
            handleProjectChange={handleProjectChange}
          />

          <div className="my-8">
            <hr className="border-gray-400" />
          </div>

          <InstallmentCard
            installmentQty={installmentSelected?.value}
            installmentArray={installmentOptions}
            totalPayment={total}
            unitsAmount={investUnitAmount}
          />

          <div>
            <div className="my-8">
              <h2 className="font-extrabold text-2xl font-lato mb-2">Selecciona tu método de pago</h2>
              <p className="text-gray-500"><span className="font-semibold text-black">Recuerda: </span>Que el pago con tarjeta de crédito puede tener fees adicionales dentro de tu inversión</p>
            </div>
            <div className="pb-2 w-full">
              <PaymentMethodSelect handleMethodSelected={handlePaymentMethod} selectedMethod={paymentMethod} />
            </div>
          </div>
        </div>
        {/* Right column */}
        <div className="col-span-2 md:col-span-1 md:ml-16 flex flex-col gap-6 mt-4 md:mt-0">

          <div className="hidden md:block">
          </div>

          {/* Summary section */}
          <SummaryCard
            totalInvestment={totalInvestment}
            installments={installments}
            financingCost={financingCost}
            subtotal={subtotal}
            paymentMethodFee={paymentMethodFee}
            total={total}
            goNextStep={onNextStep}
            error={error}
            isLoading={isLoading}
            coupon={coupon.code}
            project={project}
          />
          {/* Benefit banner */}
          <Banner investUnitAmount={investUnitAmount} unitValue={project.unitValue} />
        </div>
      </div>
    </div>
  )
}
