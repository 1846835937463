import { useEffect, useState } from 'react'
import { getCreditCardFeePercentage } from '../helpers/functions';

export const useInvestOverView = (
  unitAmount: string,
  installmentsQty: string,
  paymentMethod: number | null,
  couponUnitPrice: number = 0,
  couponSubscriptionFeeFree: boolean = false,
  unitValue: number,
  financingPercentage: number
) => {

  const [investOverViewValues, setInvestOverViewValues] = useState({
    totalInvestment: 0,
    installments: 0,
    financingCost: 0,
    subtotal: 0,
    paymentMethodFee: 0,
    total: 0,
    monthValue: 0
  });

  useEffect(() => {
    let currentUnitValue = unitValue;
    let currentFinancingPercentage =  financingPercentage

    if (couponUnitPrice) {
      currentUnitValue = couponUnitPrice;
    }

    if (couponSubscriptionFeeFree) {
      currentFinancingPercentage = 0;
    }

    const unitAmountNumber = Number(unitAmount);
    const installmentsQtyNumber = Number(installmentsQty);
    const totalInvestment = unitAmountNumber * currentUnitValue;
    let monthlyFinancingCost = 0;
    let paymentMethodFee = 0;

    if (installmentsQtyNumber > 1) {
      monthlyFinancingCost = (totalInvestment * currentFinancingPercentage) / 100
    }

    const subtotal = totalInvestment / installmentsQtyNumber;

    const monthValue = subtotal + monthlyFinancingCost;

    if (paymentMethod === 1) {
      paymentMethodFee = (getCreditCardFeePercentage() * monthValue) / 100;
    }

    const total = parseInt((subtotal + paymentMethodFee + monthlyFinancingCost).toFixed(0));

    setInvestOverViewValues({
      totalInvestment,
      installments: installmentsQtyNumber,
      financingCost: monthlyFinancingCost,
      subtotal,
      paymentMethodFee,
      total,
      monthValue
    });
  }, [unitAmount, installmentsQty, paymentMethod, couponUnitPrice, couponSubscriptionFeeFree, unitValue, financingPercentage]);

  return {
    ...investOverViewValues
  }
}
