import { useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { CapitalizeFirstLetter } from '../../../../utils/otherFormat';
import ModalShareCodeUnit from '../../../../components/PopUpAmbassadors/ModalShareCodeUnits';

export const MainList = ({ projectName, cloudbedsDashboardEnabled }: { projectName: string, cloudbedsDashboardEnabled: boolean }) => {
    const { projectId } = useParams();
    const [isOpen, setIsOpen] = useState(false)
    return (
        <div className=' rounded-lg w-full h-auto flex flex-row justify-between items-center py-2'>

            <NavLink
                to={`/dashboard/dividend/${projectId}/reinvestment`}
                className="bg-[#504bfa] w-[32%] rounded-lg h-32 flex flex-col justify-center items-center text-center text-white no-underline text-xl font-bold primaryFont hover:text-[#221feb] hover:scale-105 py-2 px-3"
            >
                <div className='primaryFont flex flex-col md:flex-row justify-center items-center gap-2 md:gap-[0px]'>
                    <svg className='w-[20px] h-[20px] mr-[5px] text-lg text-white' fill='#ffffff' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 32c0 113.6-84.6 207.5-194.2 222c-7.1-53.4-30.6-101.6-65.3-139.3C290.8 46.3 364 0 448 0l32 0c17.7 0 32 14.3 32 32zM0 96C0 78.3 14.3 64 32 64l32 0c123.7 0 224 100.3 224 224l0 32 0 160c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-160C100.3 320 0 219.7 0 96z" /></svg>
                    Reinvertir
                </div>
                <span className='secondaryFont text-base font-light hidden md:flex'>
                    Multiplica tu inversión.
                </span>
            </NavLink>
            {
                cloudbedsDashboardEnabled ?
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href={`https://api.whatsapp.com/send?phone=573126715883&text=Hola! soy inversionista y me gustaría reservar en  ${projectName && CapitalizeFirstLetter(projectName)}`}
                        className={`bg-[#504bfa] w-[32%] rounded-lg h-32 flex flex-col justify-center items-center text-white no-underline text-xl font-semibold primaryFont hover:text-[#221feb] hover:scale-105 py-2 px-3`}>
                        <div className='primaryFont flex flex-col md:flex-row justify-center items-center gap-2 md:gap-[0px]'>
                            <svg className='w-[20px] h-[24px] mr-[5px] text-lg text-white' fill='#ffffff' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M216 64c-13.3 0-24 10.7-24 24s10.7 24 24 24l16 0 0 33.3C119.6 157.2 32 252.4 32 368l448 0c0-115.6-87.6-210.8-200-222.7l0-33.3 16 0c13.3 0 24-10.7 24-24s-10.7-24-24-24l-40 0-40 0zM24 400c-13.3 0-24 10.7-24 24s10.7 24 24 24l464 0c13.3 0 24-10.7 24-24s-10.7-24-24-24L24 400z" /></svg>
                            Reservar
                        </div>
                        <span className='secondaryFont text-base font-light hidden md:flex'>
                            Tu lugar te espera.
                        </span>
                    </a>
                    :
                    <a
                        rel="noreferrer"
                        target="_blank"
                        href={`https://www.instagram.com/lokl.life/`}
                        className={`bg-[#504bfa] w-[32%] rounded-lg h-32 flex flex-col justify-center items-center text-white no-underline text-xl font-semibold primaryFont hover:text-[#221feb] hover:scale-105 py-2 px-3`}>
                        <div className='primaryFont flex flex-col md:flex-row justify-center items-center gap-2 md:gap-[0px]'>
                            <svg className='w-[20px] h-[20px] mr-[5px] text-lg text-white' fill='#ffffff' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M192 32c0-17.7 14.3-32 32-32C383.1 0 512 128.9 512 288c0 17.7-14.3 32-32 32s-32-14.3-32-32C448 164.3 347.7 64 224 64c-17.7 0-32-14.3-32-32zM60.6 220.6L164.7 324.7l28.4-28.4c-.7-2.6-1.1-5.4-1.1-8.3c0-17.7 14.3-32 32-32s32 14.3 32 32s-14.3 32-32 32c-2.9 0-5.6-.4-8.3-1.1l-28.4 28.4L291.4 451.4c14.5 14.5 11.8 38.8-7.3 46.3C260.5 506.9 234.9 512 208 512C93.1 512 0 418.9 0 304c0-26.9 5.1-52.5 14.4-76.1c7.5-19 31.8-21.8 46.3-7.3zM224 96c106 0 192 86 192 192c0 17.7-14.3 32-32 32s-32-14.3-32-32c0-70.7-57.3-128-128-128c-17.7 0-32-14.3-32-32s14.3-32 32-32z" /></svg>
                            Descubre
                        </div>
                        <span className='secondaryFont text-base font-light hidden md:flex'>
                            Únete a nuestra comunidad.
                        </span>
                    </a>
            }
            <button onClick={() => setIsOpen(true)} className="bg-[#504bfa] w-[32%] rounded-lg h-32 flex-col justify-center items-center text-white flex text-xl font-semibold primaryFont hover:text-[#221feb] hover:scale-105 py-2 px-3">
                <div className='primaryFont flex flex-col md:flex-row justify-center items-center gap-2 md:gap-[0px]'>
                    <svg xmlns="http://www.w3.org/2000/svg" className='w-[20px] h-[20px] mr-[5px] text-lg text-white' fill='#ffffff' viewBox="0 0 384 512"><path d="M192 32c17.7 0 32 14.3 32 32l0 135.5 111.5-66.9c15.2-9.1 34.8-4.2 43.9 11s4.2 34.8-11 43.9L254.2 256l114.3 68.6c15.2 9.1 20.1 28.7 11 43.9s-28.7 20.1-43.9 11L224 312.5 224 448c0 17.7-14.3 32-32 32s-32-14.3-32-32l0-135.5L48.5 379.4c-15.2 9.1-34.8 4.2-43.9-11s-4.2-34.8 11-43.9L129.8 256 15.5 187.4c-15.2-9.1-20.1-28.7-11-43.9s28.7-20.1 43.9-11L160 199.5 160 64c0-17.7 14.3-32 32-32z" /></svg>
                    Referir
                </div>
                <span className='secondaryFont text-base font-light hidden md:flex'>
                    Comparte y gana.
                </span>
            </button>
            {isOpen && <ModalShareCodeUnit setIsOpen={setIsOpen} />}
        </div>
    )
}
