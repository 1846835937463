import React from 'react'
import { SimulationResponseInterface, SimulatorChart } from './'
interface SimulatorGraphicInterface {
  simulationResponse: SimulationResponseInterface | undefined
}

export const SimulatorGraphic: React.FC<SimulatorGraphicInterface> = ({ simulationResponse }) => {

  return (
    <div>
      <div className="relative pb-2">
        <div >
          <div className="px-6 ml-[-20px]">
            {
              simulationResponse && <SimulatorChart simulationResponse={simulationResponse} />
            }
            <div className="my-2">
              <div className="flex items-center justify-between px-9">
                <div className="bg-[#000000] rounded-full w-2 h-2 flex-shrink-0"></div>
                <div className="border-b w-full border-[#000000]"></div>
                <div className="bg-[#000000] rounded-full w-2 h-2 flex-shrink-0"></div>
                <div className="border-b w-full border-[#000000]"></div>
                <div className="bg-[#000000] rounded-full w-2 h-2 flex-shrink-0"></div>
                <div className="border-b w-full border-[#000000]"></div>
                <div className="bg-[#000000] rounded-full w-2 h-2 flex-shrink-0"></div>
                <div className="border-b w-full border-[#000000]"></div>
                <div className="bg-[#000000] rounded-full w-2 h-2 flex-shrink-0"></div>
              </div>
              <div className="flex item-center justify-between px-[12px] mt-2">
                {simulationResponse?.averageAnnualReturnYears.map((averageAnnualReturn, index) => (
                  <p key={index} className="text-[#000000] text-sm font-epilogue font-bold">
                    {(averageAnnualReturn * 100).toFixed(2)}%
                  </p>
                ))}
              </div>
            </div>
          </div>

          <div className="">
            <div className="flex gap-4 justify-center relative">
              <div className="flex items-center gap-2">
                <div className="bg-[#4CF2C3] rounded-full w-2 h-2 flex-shrink-0"></div>
                <p className="font-bold text-xs secondaryFont">Valoración</p>
              </div>
              <div className="flex items-center gap-2">
                <div className="bg-[#5452F6] rounded-full w-2 h-2 flex-shrink-0"></div>
                <p className="font-bold text-xs secondaryFont">Utilidades</p>
              </div>
              <div className="flex items-center gap-2">
                <div className="bg-[#000000] rounded-full w-2 h-2 flex-shrink-0"></div>
                <p className="font-bold text-xs secondaryFont">Retorno en la inversión anual</p>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  )
}
